import React from 'react';
import classnames from 'classnames';

import { isExist } from 'util/helper';
import { useMedia } from 'util/hook/useMedia';

import styles from './index.css';

const SectionTitle = ({ className, title = '', subTitle = '', color = '', vesShare = false }) => {
	const media = useMedia();

	return (
		<div
			className={classnames(
				styles.sectionTitle,
				isExist(color) && styles[color],
				vesShare && styles.vesShare,
				className,
			)}
		>
			<h1>
				{title}
				{media === 'desktop' && subTitle}
			</h1>
			{media !== 'desktop' && subTitle && <h1>{subTitle}</h1>}
			{title === '查看實體門市庫存' ? '' : <div className={styles.hl} />}
		</div>
	);
};

export default SectionTitle;
