/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classnames from 'classnames';
import Popover from 'react-popover';

import CloseIcon from 'images/icon/close.inline.svg';

import styles from './index.css';

export const PopoverPanel = ({ className, onClickClose = () => {}, children }) => (
	<div className={classnames(styles.popoverPanel, className)}>
		<div
			className={styles.close}
			onKeyPress={() => {}}
			role="button"
			tabIndex="0"
			onClick={onClickClose}
		>
			<CloseIcon />
		</div>
		{children}
	</div>
);

export const PopoverComponent = ({ children, ...props }) => {
	return <Popover {...props}>{children}</Popover>;
};

export default PopoverComponent;
