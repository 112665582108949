import React from 'react';
import classnames from 'classnames';

import styles from './index.css';

const Counter = ({ className, count = 0, maxCount, add, subtract }) => {
	return (
		<div className={classnames(styles.wrapper, className)}>
			<button
				className={classnames((count === 1 || maxCount === 0) && styles.disabled)}
				type="button"
				onClick={subtract}
			>
				－
			</button>
			<div className={styles.box}>
				<p>{maxCount === 0 ? 0 : count}</p>
			</div>
			<button
				className={classnames((count === maxCount || maxCount === 0) && styles.disabled)}
				type="button"
				onClick={add}
			>
				＋
			</button>
		</div>
	);
};

export default Counter;
