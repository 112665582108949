// 只需要輸入 vinNumber  最後 6 碼的數字
export const vinSixNumberRegex = /^\d{6}$/;

export const validateEmail = value => {
	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	return emailRegex.test(value);
};

export const validateMobile = value => {
	const mobileRegex = /09\d{8}$/;
	return mobileRegex.test(value);
};
