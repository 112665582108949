import React from 'react';

import { fetchTestDrivePrivacy } from 'models/contract';

export default {
	path: '/privacy-statement',
	components: () => [import('./component')],
	render: ([Privacy]) => <Privacy />,
	onEnter: async ({ store }) => {
		await store.dispatch(fetchTestDrivePrivacy());
	},
};
