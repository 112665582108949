/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import classnames from 'classnames';

import DropdownBasic from 'components/atoms/DropdownBasic';
import Avatar from 'components/atoms/Avatar';
import Link from 'components/atoms/Link';

import ArrowIcon from 'images/icon/arrow-down.inline.svg';

import { useMedia } from 'util/hook/useMedia';

import styles from './index.css';

const DropdownMenu = ({
	className,
	disabled = false,
	setPage,
	name,
	avatar,
	logout,
	hasNotification,
}) => {
	const media = useMedia();
	const options = [
		{ value: '/member/basic', label: '會員基本資料' },
		{ value: '/member/credit-card', label: '付款資訊設定' },
		{ value: '/after-sales/model', label: '我的車款' },
		{ value: '/member/my-order', label: '我的訂單' },
		{ value: '/member/coupon', label: '我的優惠券' },
		{ value: '/member/invitation', label: '好友推薦活動' },
		{ value: '/member/notification-center', label: '訊息中心' },
		{ value: '/', label: '登出' },
	];

	return (
		<DropdownBasic
			className={classnames(styles.dropdownMenu, className)}
			disabled={disabled}
			valueComponent={({ isOpen }) => (
				<div
					className={classnames(styles.avatarButton, {
						[styles.isOpen]: media !== 'desktop' && isOpen,
					})}
					onKeyPress={() => {}}
					role="button"
					tabIndex="0"
					onClick={() => media !== 'desktop' && setPage(2)}
				>
					<Avatar name={name} image={avatar.thumbnail} small={media === 'desktop'} />
					<ArrowIcon />
				</div>
			)}
			preventToogleOnClick={media !== 'desktop'}
			panelClassName={styles.panel}
			panelComponent={({ closePanel }) => (
				<>
					{options.map(option =>
						option.label === '登出' ? (
							<div
								key={option.label}
								className={styles.option}
								onKeyPress={() => {}}
								role="button"
								tabIndex="0"
								onClick={() => {
									logout();
									closePanel();
								}}
							>
								{option.label}
							</div>
						) : (
							<Link
								key={option.label}
								className={styles.option}
								to={option.value}
								onClick={closePanel}
							>
								{option.label}
								{option.label === '訊息中心' && hasNotification && <span />}
							</Link>
						),
					)}
				</>
			)}
		/>
	);
};

export default DropdownMenu;
