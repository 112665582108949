/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classnames from 'classnames';

import FacebookIcon from 'images/icon/facebook.inline.svg';

import Button from '../Button';

import styles from './index.css';

export const ButtonFacebookComponent = ({
	className,
	iconOnly,
	text = '使用 Facebook 註冊',
	...other
}) => (
	<Button
		className={classnames(styles.buttonFacebook, { [styles.text]: iconOnly }, className)}
		Icon={FacebookIcon}
		variant="outlined"
		color="customize"
		{...other}
	>
		{iconOnly ? null : text}
	</Button>
);

const ButtonFacebook = props => {
	const loginFacebook = () => {};

	return <ButtonFacebookComponent onClick={loginFacebook} {...props} />;
};

export default ButtonFacebook;
