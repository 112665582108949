import React from 'react';
import classnames from 'classnames';

import WarningIcon from 'images/icon/status-warning.inline.svg';
import InfoIcon from 'images/icon/status-info.inline.svg';

import styles from './index.css';

const StatusHint = ({ className, type = 'default', children }) => (
	<div className={classnames(styles.statusHint, className)}>
		{type === 'warning' ? <WarningIcon /> : <InfoIcon />}
		<span className={classnames({ [styles.reminder]: type === 'reminder' })}>{children}</span>
	</div>
);

export default StatusHint;
