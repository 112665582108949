import React, { useState } from 'react';

import { useOrder, useIncreasePurchasesForm } from 'models/order';
import { useToastModal, useNormalModal } from 'models/modal';

import SectionTitle from 'components/atoms/SectionTitle';
import ButtonContract from 'components/atoms/ButtonContract';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Modal from 'components/molecules/Modal';
import CounterPurchase from 'components/atoms/CounterPurchase';

import { useBoolean } from 'util/hook';
import { isExist } from 'util/helper';
import { currencyFormat } from 'util/currencyFormat';

import CloseIcon from 'images/icon/contract-close.inline.svg';

import styles from './index.css';

const { IMAGE_ENDPOINT } = process.env;

const AdditionalPurchases = ({ i }) => {
	const [count, setCount] = useState(i.amount);
	const [{ additionalPurchases }, { updateCancelForm }] = useIncreasePurchasesForm();

	return (
		<div>
			<div className={styles.contentRow}>
				<Input
					checkboxClassName={styles.input}
					labelClassName={styles.label}
					type="checkbox"
					label={i.name}
					checked={additionalPurchases.value.map(_ => _.id).includes(i.id)}
					onClick={e => {
						updateCancelForm({
							type: 'increasePurchasesForm',
							key: 'additionalPurchases',
							data: {
								value: e.target.checked
									? [...additionalPurchases.value, { id: i.id, amount: count }]
									: additionalPurchases.value.filter(_i => _i.id !== i.id),
							},
						});
					}}
				/>
				<div
					className={styles.image}
					style={{
						backgroundImage: `url(${IMAGE_ENDPOINT}/thumbnail/${i.image})`,
					}}
				/>
				<div className={styles.infoWrapper}>
					<div className={styles.titleWrapper}>
						<p>{i.name}</p>
						<p>價格：NT$ {currencyFormat(i.price * count)}</p>
						<CounterPurchase
							maxCount={i.amount}
							count={count}
							add={() => {
								const idx = additionalPurchases.value.map(_ => _.id).indexOf(i.id);

								if (count < i.amount) {
									setCount(count + 1);

									if (idx > -1) {
										const list = [
											...additionalPurchases.value.slice(0, idx),
											{
												id: i.id,
												amount: count + 1,
											},
											...additionalPurchases.value.slice(idx + 1),
										];
										updateCancelForm({
											type: 'increasePurchasesForm',
											key: 'additionalPurchases',
											data: {
												value: list,
											},
										});
									}
								}
							}}
							subtract={() => {
								const idx = additionalPurchases.value.map(_ => _.id).indexOf(i.id);

								if (count > 1) {
									setCount(count - 1);

									if (idx > -1) {
										const list = [
											...additionalPurchases.value.slice(0, idx),
											{
												id: i.id,
												amount: count - 1,
											},
											...additionalPurchases.value.slice(idx + 1),
										];
										updateCancelForm({
											type: 'increasePurchasesForm',
											key: 'additionalPurchases',
											data: {
												value: list,
											},
										});
									}
								}
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const CancelAddPurchase = ({ close }) => {
	const [isCheckable, { setTrue: checkable }] = useBoolean({
		defaultBoolean: false,
	});
	const [{ list }, { openNormalModal, closeNormalModal }] = useNormalModal();
	const [, { openModal }] = useToastModal();
	const [{ targetOrder }] = useOrder();
	const [
		{ signature, increasePurchases, loading, additionalPurchases },
		{ updateCancelForm, submitCancel },
	] = useIncreasePurchasesForm();
	const { increase_purchases: ip, additional_purchases: ap } = targetOrder;

	const handleScroll = e => {
		if (isCheckable) {
			return;
		}

		if (e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10) {
			checkable();
		}
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.content} onScroll={handleScroll}>
				<p className={styles.title}>選擇商品</p>
				<p className={styles.subTitle}>加購配備</p>
				{ip.map(i => (
					<div key={i.id}>
						<div className={styles.contentRow}>
							<Input
								checkboxClassName={styles.input}
								labelClassName={styles.label}
								type="checkbox"
								label={i.name}
								checked={increasePurchases.value.includes(i.id)}
								onClick={e => {
									updateCancelForm({
										type: 'increasePurchasesForm',
										key: 'increasePurchases',
										data: {
											value: e.target.checked
												? [...increasePurchases.value, i.id]
												: increasePurchases.value.filter(_i => _i !== i.id),
										},
									});
								}}
							/>
							<div
								className={styles.image}
								style={{
									backgroundImage: `url(${IMAGE_ENDPOINT}/thumbnail/${i.cover})`,
								}}
							/>
							<div className={styles.infoWrapper}>
								<div className={styles.titleWrapper}>
									<p>{i.name}</p>
									<p>價格：NT$ {currencyFormat(i.price)}</p>
								</div>
							</div>
						</div>
					</div>
				))}
				<p className={styles.subTitle}>優惠加價購</p>
				{ap.map(i => (
					<AdditionalPurchases i={i} key={i.id} />
				))}
				<p className={styles.title}>合約更改</p>
				<ButtonContract
					title="審閱合約"
					checked={isExist(signature.value)}
					error={!signature.valid}
					onClick={() => {
						openNormalModal({ type: 'ownershipContract' });
					}}
				/>
			</div>
			<div className={styles.buttonWrapper}>
				<Button
					size="large"
					color="primary"
					disabled={
						loading ||
						(increasePurchases.value.length === 0 && additionalPurchases.value.length === 0) ||
						!signature.value
					}
					onClick={() => {
						submitCancel().then(({ value }) => {
							if (value.success) {
								close();
								setTimeout(() => {
									openModal({
										category: 'toast',
										type: 'success',
										data: { message: '已成功取消加購' },
									});
								}, 500);
							}
						});
					}}
				>
					送出
				</Button>
			</div>
			<Modal
				active={list.includes('cancelAddPurchaseContract')}
				onDeactivate={() => {}}
				className={styles.contractModal}
			>
				<div className={styles.contractWrapper}>
					<CloseIcon
						width={24}
						height={24}
						onClick={() => closeNormalModal({ type: 'cancelAddPurchaseContract' })}
					/>
					<SectionTitle title="簽名板" color="default" />
					<div className={styles.btnWrapper}>
						<Button
							color="primary"
							size="large"
							onClick={() => {
								closeNormalModal({ type: 'cancelAddPurchaseContract' });
							}}
						>
							確認
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default CancelAddPurchase;
