import React from 'react';
import { fetchTestDrivePrivacy } from 'models/contract';

export default {
	path: '/cartour-privacy',
	components: () => [import('./component')],
	render: ([TestDrivePrivacyPreview]) => <TestDrivePrivacyPreview />,
	onEnter: async ({ store }) => {
		await store.dispatch(fetchTestDrivePrivacy());
	},
};
