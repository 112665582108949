import { createAction, handleActions } from 'redux-actions';
import qs from 'qs';

import { wrapFetch, wrapAuthFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { clearUserCarModelFormData, clearReserveMaintenanceFormData } from 'util/clear';
import { vinSixNumberRegex } from 'util/regex';
import { scrollToOffset } from 'util/helper';
import { examReserveMaintenanceFormData } from 'util/exam';

import { openModal } from 'models/modal';
import { pushRoute } from 'models/routing';

const defaultUserCarModelData = {
	name: { value: '', valid: true, error: '' },
	vehicleRegistrationPlate: { value: '', valid: true, error: '' },
	vinNumber: { value: '', valid: true, error: '', verified: false },
	model: '',
	year: '',
	licenseDate: '',
	edit: {
		id: '',
		name: { value: '', valid: true, error: '' },
		vehicleRegistrationPlate: { value: '', valid: true, error: '' },
		vinNumber: { value: '', valid: true, error: '', verified: false },
		editing: false,
	},
	loading: false,
	error: '',
};

const defaultReserveMaintenanceStepsStatus = {
	location: false,
	info: false,
	reserveInfoReview: false,
};

export const defaultTargetShopData = {
	address: '',
	after_sales_business_hours: [],
	after_sales_close_days: [],
	business_hours: [],
	city: { id: 0, name: '' },
	district: { id: 0, name: '' },
	id: 0,
	latitude: '',
	longitude: '',
	name: '',
	phone: '',
	reachLimitDates: [],
	reachLimitDateTime: [],
};

export const defaultReserveMaintenanceFormData = {
	shopId: { value: '', valid: true, error: '' },
	vehicleRegistrationPlate: { value: '', valid: true, error: '' },
	carYearCarType: { value: '', valid: true, error: '' },
	km: { value: '', valid: true, error: '' },
	serviceType: { value: '', valid: true, error: '' },
	firstReservationDate: {
		year: '',
		month: '',
		date: '',
		valid: true,
		error: '',
	},
	firstReservationTime: { value: '', valid: true, error: '' },
	name: { value: '', valid: true, error: '' },
	mobile: { value: '', valid: true, error: '' },
	email: { value: '', valid: true, error: '' },
	note: { value: '', valid: true, error: '' },
	loading: false,
	error: '',
};

export const getAfterSalesUser = createAction('GET_AFTER_SALES_USER', () => async () => {
	const { status, message, data } = await wrapAuthFetch('afterSales/user', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data.data;
});

export const fetchServiceActivity = createAction('FETCH_SERVICE_ACTIVITY', () => async () => {
	const { status, message, data } = await wrapFetch('afterSales/news', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data.data;
});

export const fetchData = createAction('FETCH_AFTER_SALES_DATA', ({ key, api }) => async () => {
	const { status, message, data } = await wrapAuthFetch(api, {
		method: 'GET',
	});

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	return { key, data: data.data };
});

export const fetchCarNotification = createAction('FETCH_CAR_NOTIFICATION', () => async () => {
	const api = 'afterSales/user/car/notifications';
	const { status, message, data } = await wrapAuthFetch(api, {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data.data;
});

export const readInvalidRms = createAction(
	'READ_INVALID_RMS',
	(id, getCarNotification = false) => async dispatch => {
		const api = `afterSales/user/car/notifications/${id}/readInvalidRms`;

		const { status, message } = await wrapAuthFetch(api, {
			method: 'PUT',
		});

		if (status !== 200) {
			throw new Error(message);
		}

		if (getCarNotification) {
			dispatch(fetchCarNotification());
		}
	},
);

export const updateForm = createAction('UPDATE_AFTER_SALES_FORM', ({ type, key, data }) => ({
	type,
	key,
	data,
}));

const validateForm = createAction('VALIDATE_AFTER_SALES_FORM', (type, key, valid, error) => ({
	type,
	key,
	valid,
	error,
}));

export const clearForm = createAction('CLEAR_FORM', type => {
	let clearData;

	if (type === 'userCarModelForm') {
		clearData = defaultUserCarModelData;
	}

	if (type === 'reserveMaintenanceForm') {
		clearData = defaultReserveMaintenanceFormData;
	}

	return {
		type,
		data: clearData,
	};
});

const clearFormError = createAction('CLEAR_FORM_ERROR', type => (_, getState) => {
	const {
		afterSales: { userCarModelForm, reserveMaintenanceForm },
	} = getState();

	let clearData;

	if (type === 'userCarModelForm') {
		clearData = clearUserCarModelFormData(userCarModelForm);
	}

	if (type === 'reserveMaintenanceForm') {
		clearData = clearReserveMaintenanceFormData(reserveMaintenanceForm);
	}

	return {
		type,
		data: clearData,
	};
});

export const fetchUserCarModel = createAction('FETCH_USER_CAR_MODEL', () => async () => {
	const { status, message, data } = await wrapAuthFetch('afterSales/user/cars', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data.data;
});

export const deleteUserCarModel = createAction(
	'DELETE_USER_CAR_MODEL_BY_ID',
	id => async dispatch => {
		const { status, message } = await wrapAuthFetch(`afterSales/user/car/${id}`, {
			method: 'DELETE',
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		dispatch(
			openModal({
				category: 'toast',
				type: 'success',
				data: { message: '刪除成功' },
			}),
		);
		await dispatch(fetchUserCarModel());
	},
);

export const sendUserCarModel = createAction(
	'SEND_USER_CAR_MODEL',
	() => async (dispatch, getState) => {
		dispatch(clearFormError('userCarModelForm'));

		const {
			afterSales: { userCarModelForm },
		} = getState();

		const { status, message, meta } = await wrapAuthFetch('afterSales/user/car', {
			method: 'POST',
			body: JSON.stringify({
				rms_user_name: userCarModelForm.name.value,
				license_plate_number: userCarModelForm.vehicleRegistrationPlate.value,
				vin_last_seventeen_number: userCarModelForm.vinNumber.value,
			}),
		});

		if (status === 400) {
			dispatch(
				openModal({
					category: 'dialog',
					type: 'userCarModelDuplicateData',
				}),
			);
		}

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		if (meta.is_duplicate_to_other) {
			dispatch(
				openModal({
					category: 'dialog',
					type: 'carModelDuplicateToOtherUser',
				}),
			);
		} else {
			dispatch(
				openModal({
					category: 'toast',
					type: 'success',
					data: { message: '儲存成功' },
				}),
			);
		}

		scrollToOffset(0);

		dispatch(clearForm('userCarModelForm'));

		await dispatch(fetchUserCarModel());
		return Promise.resolve({ success: true });
	},
);

export const updateUserCarModel = createAction(
	'UPDATE_USER_CAR_MODEL',
	id => async (dispatch, getState) => {
		dispatch(clearFormError('userCarModelForm'));

		const {
			afterSales: { userCarModelForm },
		} = getState();

		const { status, message, meta } = await wrapAuthFetch(`afterSales/user/car/${id}`, {
			method: 'PUT',
			body: JSON.stringify({
				rms_user_name: userCarModelForm.edit.name.value,
				license_plate_number: userCarModelForm.edit.vehicleRegistrationPlate.value,
				vin_last_seventeen_number: userCarModelForm.edit.vinNumber.value,
			}),
		});

		if (status === 400) {
			dispatch(
				openModal({
					category: 'dialog',
					type: 'userCarModelDuplicateData',
				}),
			);
		}

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		if (meta.is_duplicate_to_other) {
			dispatch(
				openModal({
					category: 'dialog',
					type: 'carModelDuplicateToOtherUser',
					data: { isUpdateData: true },
				}),
			);
		} else {
			dispatch(
				openModal({
					category: 'toast',
					type: 'success',
					data: { message: '儲存成功' },
				}),
			);
		}

		dispatch(clearForm('userCarModelForm'));

		await dispatch(fetchUserCarModel());
		return Promise.resolve({ success: true });
	},
);

export const verifyUserCarData = createAction(
	'VERIFY_USER_CAR_DATA',
	(type, id) => async (dispatch, getState) => {
		const {
			afterSales: { userCarModelForm },
		} = getState();

		const formEditing = userCarModelForm.edit.editing;

		if (!formEditing && !vinSixNumberRegex.test(userCarModelForm.vinNumber.value)) {
			validateForm('userCarModelForm', 'vinNumber', false, '車身號碼 6 碼格式錯誤');
			return;
		}
		if (formEditing && !vinSixNumberRegex.test(userCarModelForm.edit.vinNumber.value)) {
			validateForm('userCarModelForm', 'vinNumber', false, '車身號碼 6 碼格式錯誤');
			return;
		}

		const { status, message } = await wrapAuthFetch('afterSales/rms/verify', {
			method: 'POST',
			body: JSON.stringify({
				customerName: formEditing ? userCarModelForm.edit.name.value : userCarModelForm.name.value,
				license: formEditing
					? userCarModelForm.edit.vehicleRegistrationPlate.value
					: userCarModelForm.vehicleRegistrationPlate.value,
				vin: formEditing ? userCarModelForm.edit.vinNumber.value : userCarModelForm.vinNumber.value,
			}),
		});
		// 車身號碼判斷，資料比對不正確的第一個對話框
		if (status === 404) {
			dispatch(
				openModal({
					category: 'dialog',
					type: 'userCarModelUnauthorized',
					data: { type, id },
				}),
			);
		}

		// 要新增一個情境 503 "Failed to call 3rd party API"

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		if (type === 'add') {
			dispatch(sendUserCarModel());
		} else if (type === 'update') {
			dispatch(updateUserCarModel(id));
		}
	},
);

const setReserveMaintenanceStepStatus = createAction(
	'SET_RESERVE_MAINTENANCE_STEP_STATUS',
	type => ({
		type,
	}),
);

const resetReserveMaintenanceStep = createAction('RESET_RESERVE_MAINTENANCE_STEP');

export const fetchShops = () => dispatch => {
	dispatch(fetchData({ key: 'shops', api: 'shops?service=AFTER_SALES' }));
};

export const fetchTargetShopReachLimitDates = createAction(
	'FETCH_TARGET_SHOP_REACH_LIMIT_DATES',
	id => async () => {
		const { status, message, data } = await wrapFetch(`afterSales/shops/${id}/reachLimitDates`, {
			method: 'GET',
		});

		if (status !== 200) {
			throw new Error(message);
		}

		return data;
	},
);

export const getDistance = createAction('SET_DISTANCE', origin => async (_, getState) => {
	const {
		afterSales: {
			targetShop: { latitude, longitude },
		},
	} = getState();

	const destination = `${latitude},${longitude}`;

	const { rows } = await wrapFetch(`distances?origins=${origin}&destinations=${destination}`, {
		method: 'GET',
	});

	return { data: rows[0].elements[0].distance.text };
});

export const fetchTargetShop = () => async (dispatch, getState) => {
	let origin;

	const {
		afterSales: {
			reserveMaintenanceForm: { shopId },
		},
	} = getState();

	const getOrigin = async () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(position => {
				origin = `${position.coords.latitude},${position.coords.longitude}`;
			});
		}
	};
	await getOrigin();
	await dispatch(fetchData({ key: 'targetShop', api: `shops/${shopId.value}` }));
	dispatch(fetchTargetShopReachLimitDates(shopId.value));
	dispatch(getDistance(origin));
};

export const checkReserveMaintenanceForm = createAction(
	'CHECK_RESERVE_MAINTENANCE_FORM',
	() => async (dispatch, getState) => {
		const {
			afterSales: { reserveMaintenanceForm },
		} = getState();
		const checkData = examReserveMaintenanceFormData(reserveMaintenanceForm);

		await dispatch(clearFormError('reserveMaintenanceForm'));

		if (!checkData.value) {
			checkData.notValid.map(key =>
				dispatch(validateForm('reserveMaintenanceForm', key, false, '此欄位不得空白')),
			);
		}

		if (!reserveMaintenanceForm.vehicleRegistrationPlate.value) {
			dispatch(
				validateForm('reserveMaintenanceForm', 'vehicleRegistrationPlate', false, '未選擇車牌號碼'),
			);
		}

		if (!reserveMaintenanceForm.serviceType.value) {
			dispatch(validateForm('reserveMaintenanceForm', 'serviceType', false, '未選擇服務類型'));
		}

		if (
			reserveMaintenanceForm.km.value &&
			!/^(0|[1-9][0-9]*)$/.test(reserveMaintenanceForm.km.value)
		) {
			dispatch(validateForm('reserveMaintenanceForm', 'km', false, '格式錯誤'));
			return;
		}

		if (
			checkData.value &&
			reserveMaintenanceForm.vehicleRegistrationPlate.value &&
			reserveMaintenanceForm.serviceType.value
		) {
			dispatch(pushRoute({ search: qs.stringify({ step: 'review' }, { addQueryPrefix: true }) }));
		}
	},
);

export const sendReserveMaintenance = createAction(
	'SEND_RESERVE_MAINTENANCE',
	() => async (dispatch, getState) => {
		dispatch(clearFormError('reserveMaintenanceForm'));

		const {
			afterSales: { reserveMaintenanceForm },
		} = getState();
		const firstDate = reserveMaintenanceForm.firstReservationDate;
		const { status, message } = await wrapAuthFetch('afterSales/user/afterSalesReservation', {
			method: 'POST',
			body: JSON.stringify({
				shop_id: reserveMaintenanceForm.shopId.value,
				license_plate_number: reserveMaintenanceForm.vehicleRegistrationPlate.value,
				km: reserveMaintenanceForm.km.value ? reserveMaintenanceForm.km.value : null,
				service_type: reserveMaintenanceForm.serviceType.value,
				first_expect_reservation_time: `${firstDate.year}-${firstDate.month}-${firstDate.date} ${reserveMaintenanceForm.firstReservationTime.value}`,
				reserve_person_name: reserveMaintenanceForm.name.value,
				reserve_person_email: reserveMaintenanceForm.email.value,
				reserve_person_mobile: reserveMaintenanceForm.mobile.value,
				note: reserveMaintenanceForm.note.value,
			}),
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		dispatch(
			openModal({
				category: 'toast',
				type: 'success',
				data: { message: '預約成功' },
			}),
		);

		dispatch(pushRoute({ pathname: '/member/my-order' }));

		dispatch(clearForm('reserveMaintenanceForm'));

		return Promise.resolve({ success: true });
	},
);

export const clearTargetShop = createAction('CLEAR_TARGET_SHOP', type => ({
	type,
}));

export const authorizeAfterSalesUser = createAction(
	'AUTHORIZE_AFTER_SALES_USER',
	({ authorizeStatus, isGetUserCarModel = false }) => async dispatch => {
		const authorizeOptions = authorizeStatus === 'reject' ? '?options=0' : '';
		const api = `afterSales/user/authorizeAfterSales${authorizeOptions}`;

		const { status, message, data } = await wrapAuthFetch(api, {
			method: 'PUT',
		});

		if (status !== 200) {
			throw new Error(message);
		}

		if (isGetUserCarModel) {
			dispatch(fetchUserCarModel());
		}
		return data.data;
	},
);

export const clearAfterSales = createAction('CLEAR_AFTER_SALES');

const reducer = {
	afterSales: handleActions(
		{
			GET_AFTER_SALES_USER_PENDING: state => ({
				...state,

				afterSalesUser: {},
			}),

			GET_AFTER_SALES_USER_FULFILLED: (state, action) => ({
				...state,

				afterSalesUser: action.payload,
			}),

			AUTHORIZE_AFTER_SALES_USER_PENDING: state => ({
				...state,

				afterSalesUser: {},
				loading: {
					...state.loading,
					afterSalesUser: true,
				},
			}),

			AUTHORIZE_AFTER_SALES_USER_FULFILLED: (state, action) => ({
				...state,

				afterSalesUser: action.payload,
				loading: {
					...state.loading,
					afterSalesUser: false,
				},
			}),

			FETCH_SERVICE_ACTIVITY_PENDING: state => ({
				...state,

				activities: [],
			}),

			FETCH_SERVICE_ACTIVITY_FULFILLED: (state, action) => ({
				...state,

				activities: action.payload,
			}),

			FETCH_AFTER_SALES_DATA_PENDING: state => ({
				...state,

				loading: {
					...state.loading,
					default: true,
				},
			}),

			FETCH_AFTER_SALES_DATA_FULFILLED: (state, action) => ({
				...state,

				[action.payload.key]: action.payload.data,
				loading: {
					...state.loading,
					default: false,
				},
			}),

			FETCH_USER_CAR_MODEL_PENDING: state => ({
				...state,

				loading: {
					...state.loading,
					userCarModel: true,
				},
			}),

			FETCH_USER_CAR_MODEL_FULFILLED: (state, action) => ({
				...state,

				userCarModel: action.payload,
				loading: {
					...state.loading,
					userCarModel: false,
				},
			}),

			FETCH_TARGET_SHOP_REACH_LIMIT_DATES_PENDING: state => ({
				...state,

				targetShop: {
					...state.targetShop,
					reachLimitDates: [],
					reachLimitDateTime: [],
				},
			}),

			FETCH_TARGET_SHOP_REACH_LIMIT_DATES_FULFILLED: (state, action) => ({
				...state,

				targetShop: {
					...state.targetShop,
					reachLimitDates: action.payload.reach_limit_dates,
					reachLimitDateTime: action.payload.reach_limit_date_time,
				},
			}),

			UPDATE_AFTER_SALES_FORM: (state, action) => ({
				...state,

				[action.payload.type]: {
					...state[action.payload.type],
					[action.payload.key]: {
						...state[action.payload.type][action.payload.key],
						...action.payload.data,
					},
				},
			}),

			VALIDATE_AFTER_SALES_FORM: (state, action) => ({
				...state,

				[action.payload.type]: {
					...state[action.payload.type],
					[action.payload.key]: {
						...state[action.payload.type][action.payload.key],
						valid: action.payload.valid,
						error: action.payload.error,
					},
				},
			}),

			CLEAR_FORM: (state, action) => ({
				...state,

				[action.payload.type]: action.payload.data,
			}),

			CLEAR_FORM_ERROR: (state, action) => ({
				...state,

				[action.payload.type]: action.payload.data,
			}),

			CLEAR_USER_FULFILLED: state => ({
				...state,

				userCarModelForm: defaultUserCarModelData,
			}),

			VERIFY_USER_CAR_DATA_PENDING: state => ({
				...state,

				userCarModelForm: {
					...state.userCarModelForm,
					loading: true,
				},
			}),

			FETCH_CAR_NOTIFICATION_PENDING: state => ({
				...state,

				loading: {
					...state.loading,
					carNotification: true,
				},
			}),

			FETCH_CAR_NOTIFICATION_FULFILLED: (state, action) => ({
				...state,

				carNotification: action.payload,
				loading: {
					...state.loading,
					carNotification: false,
				},
			}),

			READ_INVALID_RMS_PENDING: state => ({
				...state,

				loading: {
					...state.loading,
					default: true,
				},
			}),

			READ_INVALID_RMS_FULFILLED: state => ({
				...state,

				loading: {
					...state.loading,
					default: false,
				},
			}),

			VERIFY_USER_CAR_DATA_FULFILLED: state => ({
				...state,

				userCarModelForm: {
					...state.userCarModelForm,
					loading: false,
				},
			}),

			SET_DISTANCE_FULFILLED: (state, action) => ({
				...state,

				targetShop: {
					...state.targetShop,
					distance: action.payload.data,
				},
			}),

			SET_RESERVE_MAINTENANCE_STEP_STATUS: (state, action) => ({
				...state,

				reserveMaintenanceStepsStatus: {
					...state.reserveMaintenanceStepsStatus,

					[action.payload.type]: true,
				},
			}),

			RESET_RESERVE_MAINTENANCE_STEP: state => ({
				...state,

				reserveMaintenanceStepsStatus: defaultReserveMaintenanceStepsStatus,
			}),

			CLEAR_TARGET_SHOP: state => ({
				...state,

				targetShop: defaultTargetShopData,
			}),

			CLEAR_AFTER_SALES: state => ({
				...state,

				afterSalesUser: {},
				carNotification: [],
				userCarModel: [],
				userCarModelForm: defaultUserCarModelData,
				reserveMaintenanceStepsStatus: defaultReserveMaintenanceStepsStatus,
				reserveMaintenanceForm: defaultReserveMaintenanceFormData,
				shops: [],
				targetShop: defaultTargetShopData,
				loading: {
					default: false,
					afterSalesUser: false,
					carNotification: false,
					userCarModel: false,
				},
			}),
		},
		{
			afterSalesUser: {},
			activities: [],
			carNotification: [],
			userCarModel: [],
			userCarModelForm: defaultUserCarModelData,
			reserveMaintenanceStepsStatus: defaultReserveMaintenanceStepsStatus,
			reserveMaintenanceForm: defaultReserveMaintenanceFormData,
			shops: [],
			targetShop: defaultTargetShopData,
			loading: {
				default: false,
				afterSalesUser: false,
				carNotification: false,
				userCarModel: false,
			},
		},
	),
};

const selectAfterSalesUser = state => state.afterSales;

export const useAfterSalesUser = () =>
	useRedux(selectAfterSalesUser, {
		getAfterSalesUser,
		authorizeAfterSalesUser,
	});

const selectActivities = state => state.afterSales;

export const useServiceActivity = () =>
	useRedux(selectActivities, {
		fetchServiceActivity,
	});

const selectUserCarModel = state => state.afterSales;

export const useUserCarModel = () =>
	useRedux(selectUserCarModel, {
		fetchUserCarModel,
		deleteUserCarModel,
	});

const selectUserCarModelForm = state => state.afterSales.userCarModelForm;

export const useUserCarModelForm = () =>
	useRedux(selectUserCarModelForm, {
		updateForm,
		validateForm,
		clearForm,
		clearFormError,
		verifyUserCarData,
		sendUserCarModel,
		updateUserCarModel,
	});

const selectUserCarNotification = state => state.afterSales;

export const useUserCarNotification = () =>
	useRedux(selectUserCarNotification, {
		fetchCarNotification,
		readInvalidRms,
	});

const selectReserveMaintenance = state => state.afterSales;

export const useReserveMaintenance = () =>
	useRedux(selectReserveMaintenance, {
		getAfterSalesUser,
		fetchShops,
		fetchTargetShop,
	});

const selectReserveMaintenanceForm = state => state.afterSales.reserveMaintenanceForm;

export const useReserveMaintenanceForm = () =>
	useRedux(selectReserveMaintenanceForm, {
		updateForm,
		clearForm,
		clearFormError,
		clearTargetShop,
		checkReserveMaintenanceForm,
		sendReserveMaintenance,
	});

const selectReserveMaintenanceStepsStatus = state => state.afterSales.reserveMaintenanceStepsStatus;

export const useReserveMaintenanceStepsStatus = () =>
	useRedux(selectReserveMaintenanceStepsStatus, {
		resetReserveMaintenanceStep,
		setReserveMaintenanceStepStatus,
	});

export default { reducer };
