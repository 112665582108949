import React from 'react';
import classnames from 'classnames';

import { useOrder } from 'models/order';

import Input from 'components/atoms/Input';
import StatusHint from 'components/atoms/StatusHint';

import styles from './index.css';

const ReasonSelector = ({ className, index }) => {
	const [{ returnForm }, { updateForm }] = useOrder();

	return (
		<div className={classnames(styles.wrapper, className)}>
			<div>
				<p>退貨原因</p>
				<p>必填</p>
			</div>
			<Input
				className={styles.input}
				type="checkbox"
				label="尺寸不合"
				checked={returnForm[index].reason === '尺寸不合'}
				onClick={() => {
					updateForm({
						index,
						key: 'reason',
						data: '尺寸不合',
					});
				}}
			/>
			<Input
				className={styles.input}
				type="checkbox"
				label="想換顏色"
				checked={returnForm[index].reason === '想換顏色'}
				onClick={() => {
					updateForm({
						index,
						key: 'reason',
						data: '想換顏色',
					});
				}}
			/>
			<Input
				className={styles.input}
				type="checkbox"
				label="商品瑕疵或毀損"
				checked={returnForm[index].reason === '商品瑕疵或毀損'}
				onClick={() => {
					updateForm({
						index,
						key: 'reason',
						data: '商品瑕疵或毀損',
					});
				}}
			/>
			<Input
				className={styles.input}
				type="checkbox"
				label="事後發現別處更便宜之同型商品"
				checked={returnForm[index].reason === '事後發現別處更便宜之同型商品'}
				onClick={() => {
					updateForm({
						index,
						key: 'reason',
						data: '事後發現別處更便宜之同型商品',
					});
				}}
			/>
			<Input
				className={styles.input}
				type="checkbox"
				label="等貨時間太久"
				checked={returnForm[index].reason === '等貨時間太久'}
				onClick={() => {
					updateForm({
						index,
						key: 'reason',
						data: '等貨時間太久',
					});
				}}
			/>
			<Input
				className={classnames(styles.input, styles.otherInput)}
				type="checkbox"
				label="其他"
				checked={returnForm[index].reason === '其他'}
				onClick={() => {
					updateForm({
						index,
						key: 'reason',
						data: '其他',
					});
				}}
			/>
			<Input
				className={styles.textInput}
				value={returnForm[index].other}
				disabled={returnForm[index].reason !== '其他'}
				placeholder="請輸入退貨原因"
				error={!returnForm[index].valid}
				onChange={e => {
					updateForm({
						index,
						key: 'other',
						data: e.target.value,
					});
				}}
			/>
			{returnForm[index].error && (
				<StatusHint type="warning" className={styles.statusHint}>
					{returnForm[index].error}
				</StatusHint>
			)}
		</div>
	);
};

export default ReasonSelector;
