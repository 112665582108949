import React, { useEffect, useRef } from 'react';

import { useCreditCardForm } from 'models/user';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import StatusHint from 'components/atoms/StatusHint';

import styles from './index.css';

const Edit = ({ close = () => {} }) => {
	const [
		{ creditCardNo, creditCardExp, creditCardCvc, loading: formLoading },
		{ updateForm, sendCreditCard, clearForm },
	] = useCreditCardForm();
	const inputRef2 = useRef(null);
	const inputRef3 = useRef(null);
	const inputRef4 = useRef(null);

	useEffect(() => {
		clearForm('creditCardForm');
	}, []);

	return (
		<div className={styles.editWrapper}>
			<div className={styles.inputGroup}>
				<div className={styles.inputItemWrapper}>
					<div className={styles.label}>信用卡卡號</div>
				</div>
				<div className={styles.multiItemsGroup}>
					<Input
						className={styles.cardInput}
						type="number"
						value={creditCardNo.value.slice(0, 4)}
						error={!creditCardNo.valid}
						onChange={e => {
							if (e.target.value.length > 3) {
								inputRef2.current.focus();
							}
							updateForm({
								type: 'creditCardForm',
								key: 'creditCardNo',
								data: { value: e.target.value },
							});
						}}
					/>
					<Input
						className={styles.cardInput}
						type="number"
						value={creditCardNo.value.slice(4, 8)}
						error={!creditCardNo.valid}
						ref={inputRef2}
						onChange={e => {
							if (e.target.value.length > 3) {
								inputRef3.current.focus();
							}
							updateForm({
								type: 'creditCardForm',
								key: 'creditCardNo',
								data: { value: creditCardNo.value.slice(0, 4) + e.target.value },
							});
						}}
					/>
					<Input
						className={styles.cardInput}
						type="number"
						value={creditCardNo.value.slice(8, 12)}
						error={!creditCardNo.valid}
						ref={inputRef3}
						onChange={e => {
							if (e.target.value.length > 3) {
								inputRef4.current.focus();
							}
							updateForm({
								type: 'creditCardForm',
								key: 'creditCardNo',
								data: { value: creditCardNo.value.slice(0, 8) + e.target.value },
							});
						}}
					/>
					<Input
						className={styles.cardInput}
						type="number"
						value={creditCardNo.value.slice(12)}
						error={!creditCardNo.valid}
						ref={inputRef4}
						onChange={e => {
							if (e.target.value.length > 4) {
								return;
							}
							updateForm({
								type: 'creditCardForm',
								key: 'creditCardNo',
								data: { value: creditCardNo.value.slice(0, 12) + e.target.value },
							});
						}}
					/>
				</div>
				<div className={styles.cardStatusHint}>
					{creditCardNo.error && <StatusHint type="warning">{creditCardNo.error}</StatusHint>}
				</div>
				<div className={styles.multiItemsGroup}>
					<Input
						className={styles.input}
						type="text"
						label="信用卡有效日期"
						placeholder="MM/YY"
						value={
							creditCardExp.value.length > 2
								? `${creditCardExp.value.slice(0, 2)}/${creditCardExp.value.slice(2)}`
								: creditCardExp.value
						}
						error={!creditCardExp.valid}
						onChange={e => {
							if (e.target.value.length > 5) {
								return;
							}
							updateForm({
								type: 'creditCardForm',
								key: 'creditCardExp',
								data: { value: e.target.value.split('/').join('') },
							});
						}}
						statusComponent={() => (
							<>
								{creditCardExp.error && (
									<StatusHint type="warning">{creditCardExp.error}</StatusHint>
								)}
							</>
						)}
					/>
					<Input
						className={styles.input}
						type="number"
						label="信用卡安全碼"
						placeholder="CVV"
						value={creditCardCvc.value}
						error={!creditCardCvc.valid}
						onChange={e => {
							if (e.target.value.length > 3) {
								return;
							}
							updateForm({
								type: 'creditCardForm',
								key: 'creditCardCvc',
								data: { value: e.target.value },
							});
						}}
						statusComponent={() => (
							<>
								{creditCardCvc.error && (
									<StatusHint type="warning">{creditCardCvc.error}</StatusHint>
								)}
							</>
						)}
					/>
				</div>
				<div className={styles.actionButtonGroup}>
					<Button
						size="large"
						color="primary"
						disabled={formLoading}
						onClick={() => {
							sendCreditCard().then(({ value }) => {
								if (value.success) {
									close();
								}
							});
						}}
					>
						完成
					</Button>
				</div>
				<div className={styles.actionButtonGroup}>
					<Button size="large" variant="outlined" color="primary" onClick={close}>
						返回
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Edit;
