import { createAction, handleActions } from 'redux-actions';

import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

export const fetchCities = createAction('FETCH_CITIES', (canShip = false) => async () => {
	const { data } = await wrapFetch(
		'cities',
		{
			method: 'GET',
		},
		{
			can_ship: canShip,
		},
	);

	return data.data;
});

const reducer = {
	cities: handleActions(
		{
			FETCH_CITIES_FULFILLED: (state, action) => ({
				...state,

				cities: action.payload,
			}),
		},
		{
			cities: [],
		},
	),
};

const selectCities = state => state.cities;

export const useCities = () =>
	useRedux(selectCities, {
		fetchCities,
	});

export default { reducer };
