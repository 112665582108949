import { wrapAuthFetchFormData, wrapSSOFetchFormData } from 'util/api';

const sendPhoto = async (file, { type, sso = false }) => {
	try {
		const formdata = new FormData();
		formdata.append('base64File', file);
		if (type) {
			formdata.append('type', type);
		}

		const fetchfunc = sso ? wrapSSOFetchFormData : wrapAuthFetchFormData;
		const fetchEndpoint = sso ? 'rentalPayment/uploadPhotoBase64' : 'photosBase64';
		const { status, data, message } = await fetchfunc(fetchEndpoint, {
			method: 'POST',
			body: formdata,
		});

		if (status !== 200 && status !== 201) {
			if (message === 'the image size (width and height) is too large.') {
				throw new Error('圖片尺寸太大');
			}

			if (message === 'The file may not be greater than 5000 kilobytes.') {
				throw new Error('檔案大小超出限制');
			}

			throw new Error('圖片上傳失敗');
		}

		return { data: data.data.filename, valid: true, message: 'success' };
	} catch (error) {
		return {
			data: '',
			valid: false,
			message: error.message === 'Failed to fetch' ? '伺服器發生錯誤' : error.message,
		};
	}
};

export default sendPhoto;
