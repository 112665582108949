import React from 'react';
import { fetchFreeShippingEvents } from 'models/shop';
import { fetchModels } from 'models/ownership';

export default {
	path: '/shop',
	components: () => [],
	render: (_, children) => children,
	onEnter: ({ store }) => {
		store.dispatch(fetchFreeShippingEvents());
		store.dispatch(fetchModels());
	},
	children: [
		{
			path: '',
			components: () => [import('./List')],
			render: ([Shop]) => <Shop />,
		},
		{
			path: '/cart',
			components: () => [],
			render: (_, children) => children,
			children: [
				{
					path: '',
					components: () => [import('./Cart')],
					render: ([ShopCart]) => <ShopCart />,
				},
			],
		},
		{
			path: '/cart/checkout',
			components: () => [],
			render: (_, children) => children,
			children: [
				{
					path: '',
					components: () => [import('./Checkout')],
					render: ([ShopCheckout]) => <ShopCheckout />,
				},
			],
		},
		{
			path: '/:id',
			components: () => [],
			render: (_, children) => children,
			children: [
				{
					path: '',
					components: () => [import('./Inner')],
					render: ([ShopInner]) => <ShopInner />,
				},
			],
		},
	],
};
