import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import storage from 'util/storage';
import { useTransition, animated, config } from 'react-spring';
import qs from 'qs';

import {
	useAuth,
	useLoginForm,
	useForgotForm,
	useResetForm,
	useClearFormError,
	useSignupAccountForm,
	useSignupUserBasicForm,
	useSignupIdUploadForm,
	useSignupCreditCardForm,
	useSignupStepsStatus,
	useGuideUrl,
} from 'models/auth';
import { useCities } from 'models/cities';
import { useRouting } from 'models/routing';

import {
	genderOptions,
	birthYearOptions,
	birthMonthOptions,
	birthDaysOptions,
	countyOptions,
	districtOptions,
	licenseOptions,
} from 'util/options';
import sendPhoto from 'util/sendPhoto';
import { isEmpty } from 'util/helper';
import { useCountdown, useBoolean } from 'util/hook';
import { fileVerify } from 'util/fileVerify';
import { compressor } from 'util/compressor';

import Logo from 'images/logo/logo.inline.svg';
import EmailIcon from 'images/icon/mail-verify.inline.svg';
import ScooterImg from 'images/auth/scooter.png';
import QuestionIcon from 'images/icon/notification-question.inline.svg';

import Division from 'components/atoms/Division';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import ButtonApple from 'components/atoms/ButtonApple';
import ButtonFacebook from 'components/atoms/ButtonFacebook';
import ButtonGoogleSignIn from 'components/atoms/ButtonGoogleSignIn';
import Link from 'components/atoms/Link';
import StatusHint from 'components/atoms/StatusHint';
import { StepBarStatus } from 'components/atoms/StepBar';
import Dropdown from 'components/atoms/Dropdown';
import SectionTitle from 'components/atoms/SectionTitle';
import Popover, { PopoverPanel } from 'components/atoms/Popover';
import VMODHint from 'components/atoms/VMODHint';

import styles from './index.css';

const FormBasic = ({
	className,
	contentClassName,
	footerClassName,
	renderContent = () => null,
	renderFooter = () => null,
}) => (
	<div className={classnames(styles.formBasic, className)}>
		<div className={classnames(styles.content, contentClassName)}>{renderContent()}</div>
		{typeof renderFooter !== 'undefined' && (
			<div className={classnames(styles.footer, footerClassName)}>{renderFooter()}</div>
		)}
	</div>
);

const FormLogin = ({ onChangeModal = () => {}, tempLogin = false }) => {
	const [{ phone, password, error }, { updateForm, normalLogin }] = useLoginForm();
	const [, { loginFacebook, loginApple }] = useAuth();
	const buttonGoogleLoginRef = useRef();

	return (
		<FormBasic
			className={styles.formLogin}
			contentClassName={styles.content}
			renderContent={() => (
				<>
					{tempLogin ? (
						<SectionTitle title="登入會員" color="black" className={styles.sectionTitle} />
					) : (
						<div className={styles.title}>登入</div>
					)}
					<div className={styles.socialButtonGroup}>
						<ButtonApple iconOnly size="medium" onClick={loginApple} />
						<ButtonFacebook iconOnly size="medium" onClick={loginFacebook} />
						<ButtonGoogleSignIn
							buttonRef={buttonGoogleLoginRef}
							actionType="login"
							shape="circle"
							logo_alignment="center"
							type="icon"
						/>
					</div>
					<div className={styles.division}>
						<Division />
						<span>或</span>
						<Division />
					</div>
					<div className={styles.inputGroup}>
						<Input
							className={styles.input}
							type="number"
							placeholder="請輸入手機號碼"
							label="手機號碼"
							value={phone.value}
							error={!phone.valid}
							onChange={e => {
								updateForm({ type: 'loginForm', key: 'phone', data: { value: e.target.value } });
							}}
							statusComponent={() => (
								<>{phone.error && <StatusHint type="warning">{phone.error}</StatusHint>}</>
							)}
						/>
						<Input
							className={styles.input}
							placeholder="請輸入密碼"
							label="密碼"
							type="password"
							value={password.value}
							error={!password.valid}
							statusComponent={() => (
								<>{password.error && <StatusHint type="warning">{password.error}</StatusHint>}</>
							)}
							onChange={e => {
								updateForm({ type: 'loginForm', key: 'password', data: { value: e.target.value } });
							}}
						/>
						<div className={styles.statusWrapper}>
							<button onClick={() => onChangeModal('forgot')} type="button">
								無法登入／忘記密碼
							</button>
							{error && <StatusHint type="warning">{error}</StatusHint>}
						</div>
					</div>
					<div className={styles.actionButtonGroup}>
						<Button onClick={normalLogin}>登入</Button>
					</div>
				</>
			)}
			footerClassName={styles.formLoginFooter}
			renderFooter={() => (
				<>
					還沒有帳號嗎？
					<button onClick={() => onChangeModal('signupLanding')} type="button">
						點此註冊
					</button>
				</>
			)}
		/>
	);
};

const FormForgot = () => {
	const [{ email, loading }, { updateForm, sendResetPassword }] = useForgotForm();

	return (
		<FormBasic
			className={styles.formForgot}
			contentClassName={styles.content}
			renderContent={() => (
				<>
					<div className={styles.title}>忘記密碼</div>
					<div className={styles.inputGroup}>
						<Input
							className={styles.input}
							placeholder="請輸入電子信箱"
							label="電子信箱"
							value={email.value}
							error={!email.valid}
							statusComponent={() => (
								<>{email.error && <StatusHint type="warning">{email.error}</StatusHint>}</>
							)}
							onChange={e => {
								updateForm({ type: 'forgotForm', key: 'email', data: { value: e.target.value } });
							}}
						/>
					</div>
					<div className={styles.actionButtonGroup}>
						<Button onClick={sendResetPassword} disabled={loading}>
							寄送重設密碼信
						</Button>
					</div>
				</>
			)}
		/>
	);
};

const FormReset = () => {
	const [{ newPassword, repeatPassword, loading }, { updateForm, resetPassword }] = useResetForm();

	return (
		<FormBasic
			className={styles.formReset}
			contentClassName={styles.content}
			renderContent={() => (
				<>
					<div className={styles.title}>重設密碼</div>
					<div className={styles.inputGroup}>
						<Input
							className={styles.input}
							placeholder="請設定密碼"
							label="輸入新密碼"
							type="password"
							value={newPassword.value}
							error={!newPassword.valid}
							statusComponent={() => (
								<>
									{newPassword.error && <StatusHint type="warning">{newPassword.error}</StatusHint>}
								</>
							)}
							onChange={e => {
								updateForm({
									type: 'resetForm',
									key: 'newPassword',
									data: { value: e.target.value },
								});
							}}
						/>
						<Input
							className={styles.input}
							placeholder="再次輸入密碼"
							label="再次確認密碼"
							type="password"
							value={repeatPassword.value}
							error={!repeatPassword.valid}
							statusComponent={() => (
								<>
									<StatusHint>密碼請至少設定 8 個英數字</StatusHint>
									{repeatPassword.error && (
										<StatusHint type="warning">{repeatPassword.error}</StatusHint>
									)}
								</>
							)}
							onChange={e => {
								updateForm({
									type: 'resetForm',
									key: 'repeatPassword',
									data: { value: e.target.value },
								});
							}}
						/>
					</div>
					<div className={styles.actionButtonGroup}>
						<Button onClick={resetPassword} disabled={loading}>
							送出
						</Button>
					</div>
				</>
			)}
		/>
	);
};

const FormVerify = () => (
	<FormBasic
		className={styles.formVerify}
		contentClassName={styles.content}
		renderContent={() => (
			<>
				<div className={styles.title}>驗證成功</div>
				<EmailIcon />
				<div className={styles.textContent}>
					<span>電子信箱驗證成功</span>
					<span>開始使用 Vespa 台灣官方商城的服務吧！</span>
				</div>
			</>
		)}
	/>
);

const FormSignupLanding = ({ onChangeModal = () => {} }) => {
	const [, { loginFacebook, loginApple }] = useAuth();
	const [, { getGuideUrlByRegisterEvent }] = useGuideUrl();
	const [{ search }] = useRouting();
	const { reg_event: regEvent } = qs.parse(search, { ignoreQueryPrefix: true });
	const ButtonGoogleSignupRef = useRef();

	useEffect(() => {
		if (regEvent) {
			storage.setItem('regEvent', JSON.stringify(regEvent));
			getGuideUrlByRegisterEvent();
		}
	}, []);

	return (
		<FormBasic
			className={styles.formSignupLanding}
			contentClassName={styles.content}
			renderContent={() => (
				<>
					<div className={styles.title}>使用以下方式註冊</div>
					<ButtonApple
						className={styles.button}
						onClick={() => {
							loginApple().then(({ value }) => {
								if (value.success) {
									onChangeModal('signupFast');
								}
							});
						}}
					/>
					<ButtonFacebook
						className={styles.button}
						onClick={() => {
							loginFacebook().then(({ value }) => {
								if (value.success) {
									onChangeModal('signupFast');
								}
							});
						}}
					/>
					<ButtonGoogleSignIn
						buttonRef={ButtonGoogleSignupRef}
						actionType="signup"
						size="large"
						text="signup_with"
						width="320"
						onChangeModal={onChangeModal}
						shape="pill"
						logo_alignment="center"
					/>
					<p className={styles.or}>或</p>
					<Button
						className={classnames(styles.button, styles.buttonPhone)}
						onClick={() => onChangeModal('signup')}
					>
						使用手機註冊
					</Button>
				</>
			)}
			footerClassName={styles.formSignupFooter}
			renderFooter={() => (
				<>
					已經有帳號了，
					<button onClick={() => onChangeModal('login')} type="button">
						點此登入
					</button>
				</>
			)}
		/>
	);
};

const SignupAccount = ({ fastLogin }) => {
	const [{ search }] = useRouting();
	const { referral_code: referralCodeValue } = qs.parse(search, { ignoreQueryPrefix: true });

	const [
		{ phone, phoneVerify, password, repeatPassword, email, vmodMember, loading, referralCode },
		{ updateForm, verifyPhone, setMobieVerifySuspended, signup },
	] = useSignupAccountForm();
	const [leftTime, { setCountdown }] = useCountdown();
	const [isPopoverOpen, { toggle: togglePopover, setFalse: closePopover }] = useBoolean({
		defaultBoolean: false,
	});
	const { mgm_code: mgmCode } = qs.parse(search, { ignoreQueryPrefix: true });

	useEffect(() => {
		if (mgmCode) {
			updateForm({
				type: 'signupAccountForm',
				key: 'invitationCode',
				data: { value: mgmCode },
			});
		}
	}, [mgmCode]);

	useEffect(() => {
		if (referralCodeValue) {
			const decodedReferralCode = decodeURIComponent(referralCodeValue);
			updateForm({
				type: 'signupAccountForm',
				key: 'referralCode',
				data: { value: decodedReferralCode },
			});
		}
	}, [referralCodeValue]);

	useEffect(() => {
		if (phone.suspended) {
			setCountdown(60);
		}
	}, [phone.suspended]);

	useEffect(() => {
		if (phone.suspended && leftTime === 0) {
			setMobieVerifySuspended(false);
		}
	}, [leftTime]);

	return (
		<>
			<div className={classnames(styles.inputGroup, styles.signup)}>
				<Input
					className={styles.input}
					type="number"
					placeholder="請輸入手機驗證碼"
					label="OTP 簡訊驗證"
					required
					value={phoneVerify.value}
					error={!phoneVerify.valid}
					onChange={e => {
						updateForm({
							type: 'signupAccountForm',
							key: 'phoneVerify',
							data: { value: e.target.value },
						});
					}}
					statusComponent={() => (
						<>{phoneVerify.error && <StatusHint type="warning">{phoneVerify.error}</StatusHint>}</>
					)}
				/>
				<div className={styles.phoneVerifyGroup}>
					<Input
						className={styles.input}
						type="number"
						placeholder="請輸入手機號碼"
						label="帳號"
						required
						value={phone.value}
						error={!phone.valid}
						onChange={e => {
							updateForm({
								type: 'signupAccountForm',
								key: 'phone',
								data: { value: e.target.value },
							});
						}}
						statusComponent={() => (
							<>
								{leftTime !== 0 && (
									<StatusHint type="warning">{`${leftTime} 秒後可重新取得驗證碼`}</StatusHint>
								)}
								{phone.error && <StatusHint type="warning">{phone.error}</StatusHint>}
							</>
						)}
					/>
					<Button color="primary" size="small" onClick={verifyPhone} disabled={leftTime !== 0}>
						驗證
					</Button>
				</div>
				{!fastLogin && (
					<Input
						className={styles.input}
						placeholder="請輸入密碼"
						label="密碼"
						type="password"
						value={password.value}
						error={!password.valid}
						statusComponent={() => (
							<>
								<StatusHint>密碼請至少設定 8 個英數字</StatusHint>
								{password.error && <StatusHint type="warning">{password.error}</StatusHint>}
							</>
						)}
						onChange={e => {
							updateForm({
								type: 'signupAccountForm',
								key: 'password',
								data: { value: e.target.value },
							});
						}}
					/>
				)}
				{!fastLogin && (
					<Input
						className={styles.input}
						placeholder="再次確認密碼"
						label="再次輸入密碼"
						type="password"
						value={repeatPassword.value}
						error={!repeatPassword.valid}
						statusComponent={() => (
							<>
								{repeatPassword.error && (
									<StatusHint type="warning">{repeatPassword.error}</StatusHint>
								)}
							</>
						)}
						onChange={e => {
							updateForm({
								type: 'signupAccountForm',
								key: 'repeatPassword',
								data: { value: e.target.value },
							});
						}}
					/>
				)}
				<Input
					className={styles.input}
					placeholder="請輸入電子信箱"
					label="電子信箱"
					required
					value={email.value}
					error={!email.valid}
					onChange={e => {
						updateForm({
							type: 'signupAccountForm',
							key: 'email',
							data: { value: e.target.value },
						});
					}}
					statusComponent={() => (
						<>{email.error && <StatusHint type="warning">{email.error}</StatusHint>}</>
					)}
				/>
				<Input
					className={styles.input}
					placeholder="請輸入推薦代碼"
					label="推薦代碼"
					value={referralCode.value}
					error={!referralCode.valid}
					onChange={e => {
						updateForm({
							type: 'signupAccountForm',
							key: 'referralCode',
							data: { value: e.target.value, error: '', valid: true },
						});
					}}
					statusComponent={() => (
						<>
							{referralCode.error && <StatusHint type="warning">{referralCode.error}</StatusHint>}
						</>
					)}
				/>
				<div className={classnames(styles.inputWrapper, styles.vmodLink)}>
					<div className={styles.wrapper}>
						<Input
							label="我已經是 VMOD 會員"
							type="checkbox"
							checked={vmodMember.value}
							onChange={e => {
								updateForm({
									type: 'signupAccountForm',
									key: 'vmodMember',
									data: { value: e.target.checked, error: '', valid: true },
								});
							}}
						/>
						{/* <Popover
							offset={media !== 'desktop' && '-12'}
							alignment={media === 'desktop' ? 'end' : 'center'}
							position={media === 'desktop' ? 'right' : 'top'}
							TriggerComponent={QuestionIcon}
						>
							<VMODHint className={styles.vmodHint} />
						</Popover> */}

						<Popover
							isOpen={isPopoverOpen}
							preferPlace="right"
							body={
								// eslint-disable-next-line react/jsx-wrap-multilines
								<PopoverPanel onClickClose={togglePopover}>
									<VMODHint className={styles.vmodHint} />
								</PopoverPanel>
							}
							onOuterAction={() => closePopover()}
						>
							<div
								className={styles.vmodQuestionIcon}
								onKeyPress={() => {}}
								role="button"
								tabIndex="0"
								onClick={() => {
									togglePopover();
								}}
							>
								<QuestionIcon />
							</div>
						</Popover>
					</div>
					{vmodMember.error && (
						<StatusHint className={styles.hint} type="warning">
							{vmodMember.error}
						</StatusHint>
					)}
				</div>
			</div>

			<div className={styles.actionButtonGroup}>
				<Button
					size="large"
					disabled={loading}
					onClick={() => {
						signup(fastLogin);
					}}
				>
					下一步
				</Button>
			</div>
		</>
	);
};

const SignupProfile = () => {
	const [
		{
			name,
			gender,
			birthday,
			idNumber,
			address,
			emergencyContactName,
			emergencyContactMobile,
			loading,
		},
		{ updateForm, sendUserBasic },
	] = useSignupUserBasicForm();
	const [{ cities }, { fetchCities }] = useCities();

	useEffect(() => {
		fetchCities();
	}, []);

	const oCounty = countyOptions(cities);
	const oDistrict = districtOptions(cities, address.county);

	return (
		<>
			<div className={styles.inputGroup}>
				<div className={styles.multiItemsGroup}>
					<Input
						className={styles.input}
						placeholder="請輸入中文姓名"
						label="真實姓名"
						required
						value={name.value}
						error={!name.valid}
						onChange={e => {
							updateForm({
								type: 'signupUserBasicForm',
								key: 'name',
								data: { value: e.target.value },
							});
						}}
						statusComponent={() => (
							<>{name.error && <StatusHint type="warning">{name.error}</StatusHint>}</>
						)}
					/>
					<div>
						<div className={styles.inputItemWrapper}>
							<div className={styles.label}>性別</div>
							<div className={styles.required}>必填</div>
						</div>
						<Dropdown
							placeholder="請選擇性別"
							options={genderOptions}
							defaultValue={[]}
							value={genderOptions.filter(go => go.value === gender.value)}
							error={!gender.valid}
							onChange={e => {
								updateForm({
									type: 'signupUserBasicForm',
									key: 'gender',
									data: { value: e[0].value },
								});
							}}
							statusComponent={() => (
								<>{gender.error && <StatusHint type="warning">{gender.error}</StatusHint>}</>
							)}
						/>
					</div>
				</div>
				<div>
					<div className={styles.inputItemWrapper}>
						<div className={styles.label}>出生年月日</div>
						<div className={styles.required}>必填</div>
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
						<Dropdown
							className={styles.dropdown}
							placeholder="年份"
							options={birthYearOptions}
							defaultValue={[]}
							value={birthYearOptions.filter(yo => yo.value === birthday.year)}
							error={!birthday.valid}
							onChange={e => {
								updateForm({
									type: 'signupUserBasicForm',
									key: 'birthday',
									data: { year: e[0].value },
								});
							}}
						/>
						<Dropdown
							className={styles.dropdown}
							placeholder="月份"
							options={birthMonthOptions(birthday.year)}
							defaultValue={[]}
							value={birthMonthOptions(birthday.year).filter(mo => mo.value === birthday.month)}
							error={!birthday.valid}
							onChange={e => {
								updateForm({
									type: 'signupUserBasicForm',
									key: 'birthday',
									data: { month: e[0].value },
								});
							}}
						/>
						<Dropdown
							className={styles.dropdown}
							placeholder="日期"
							options={birthDaysOptions(birthday.year, birthday.month)}
							defaultValue={[]}
							value={birthDaysOptions(birthday.year, birthday.month).filter(
								dayo => dayo.value === birthday.day,
							)}
							error={!birthday.valid}
							onChange={e => {
								updateForm({
									type: 'signupUserBasicForm',
									key: 'birthday',
									data: { day: e[0].value },
								});
							}}
						/>
					</div>
					{birthday.error ? (
						<StatusHint type="warning" className={styles.statusHint}>
							{birthday.error}
						</StatusHint>
					) : (
						<StatusHint className={styles.statusHint}>欲申請會員需年滿 18 歲</StatusHint>
					)}
				</div>
				<Input
					className={styles.input}
					placeholder="請輸入身分證字號（永久居留證統一證號）"
					label="身分證字號（永久居留證統一證號）"
					required
					value={idNumber.value}
					error={!idNumber.valid}
					onChange={e => {
						updateForm({
							type: 'signupUserBasicForm',
							key: 'idNumber',
							data: { value: e.target.value },
						});
					}}
					statusComponent={() => (
						<>{idNumber.error && <StatusHint type="warning">{idNumber.error}</StatusHint>}</>
					)}
				/>
				<div>
					<div className={styles.inputItemWrapper}>
						<div className={styles.label}>通訊地址</div>
						<div className={styles.required}>必填</div>
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
						<Dropdown
							className={styles.dropdown}
							placeholder="請輸入縣市"
							options={oCounty}
							defaultValue={[]}
							value={oCounty.filter(co => co.value === address.county)}
							error={!address.valid}
							onChange={e => {
								updateForm({
									type: 'signupUserBasicForm',
									key: 'address',
									data: { county: e[0].value },
								});
							}}
						/>
						<Dropdown
							className={styles.dropdown}
							placeholder="請輸入所在區域"
							options={oDistrict}
							defaultValue={[]}
							value={oDistrict.filter(diso => diso.value === address.district)}
							error={!address.valid}
							onChange={e => {
								updateForm({
									type: 'signupUserBasicForm',
									key: 'address',
									data: { district: e[0].value },
								});
							}}
						/>
					</div>
				</div>
				<Input
					className={styles.input}
					placeholder="請輸入詳細地址"
					value={address.other}
					error={!address.valid}
					onChange={e => {
						updateForm({
							type: 'signupUserBasicForm',
							key: 'address',
							data: { other: e.target.value },
						});
					}}
					statusComponent={() => (
						<>{address.error && <StatusHint type="warning">{address.error}</StatusHint>}</>
					)}
				/>
				<div className={styles.basicFormDivision} />
				<Input
					className={styles.input}
					placeholder="請輸入緊急聯絡人姓名"
					label="緊急聯絡人姓名"
					value={emergencyContactName.value}
					error={!emergencyContactName.valid}
					onChange={e => {
						updateForm({
							type: 'signupUserBasicForm',
							key: 'emergencyContactName',
							data: { value: e.target.value },
						});
					}}
					statusComponent={() => (
						<>
							{emergencyContactName.error && (
								<StatusHint type="warning">{emergencyContactName.error}</StatusHint>
							)}
						</>
					)}
				/>
				<Input
					className={styles.input}
					placeholder="請輸入緊急聯絡人手機"
					label="緊急聯絡人手機"
					value={emergencyContactMobile.value}
					error={!emergencyContactMobile.valid}
					onChange={e => {
						updateForm({
							type: 'signupUserBasicForm',
							key: 'emergencyContactMobile',
							data: { value: e.target.value },
						});
					}}
					statusComponent={() => (
						<>
							<StatusHint>如欲租車需填寫緊急聯絡人作為緊急情況使用</StatusHint>
							{emergencyContactMobile.error && (
								<StatusHint type="warning">{emergencyContactMobile.error}</StatusHint>
							)}
						</>
					)}
				/>
			</div>
			<div className={styles.actionButtonGroup}>
				<Button
					size="large"
					disabled={loading}
					onClick={() => {
						sendUserBasic();
					}}
				>
					下一步
				</Button>
			</div>
		</>
	);
};

const SignupIdUpload = ({ setStep }) => {
	const [
		{ idPhotos, driverLicenseType, driverLicensePhotos, loading },
		{ updateForm, sendIdUpload },
	] = useSignupIdUploadForm();
	return (
		<>
			<div className={styles.inputGroup}>
				<Input
					label="上傳身分證（永居證）"
					fileLabel="上傳身分證（永居證）正面"
					type="file"
					required
					isUploaded={idPhotos.front}
					error={!idPhotos.valid && idPhotos.front === ''}
					disabled={idPhotos.loading}
					loading={idPhotos.loading && isEmpty(idPhotos.front) && idPhotos.target === 'front'}
					onChange={async e => {
						const file = e.target.files[0];
						if (!file) return;

						updateForm({
							type: 'signupIdUploadForm',
							key: 'idPhotos',
							data: { front: null, valid: true, error: '', loading: true, target: 'front' },
						});

						const verifyMsg = await fileVerify(file);
						if (verifyMsg) {
							throw new Error(verifyMsg);
						}

						const compressedFile = await compressor(file);

						const reader = new FileReader();
						reader.readAsDataURL(compressedFile);
						reader.onload = async () => {
							const { valid, data, message } = await sendPhoto(reader.result, {
								type: 'idCards',
							});
							if (!valid) {
								updateForm({
									type: 'signupIdUploadForm',
									key: 'idPhotos',
									data: { valid: false, error: message, loading: false, target: '' },
								});
								return;
							}

							updateForm({
								type: 'signupIdUploadForm',
								key: 'idPhotos',
								data: { front: data, valid: true, error: '', loading: false, target: '' },
							});
						};
					}}
				/>
				<Input
					type="file"
					fileLabel="上傳身分證（永居證）反面"
					isUploaded={idPhotos.back}
					error={!idPhotos.valid && idPhotos.back === ''}
					disabled={idPhotos.loading}
					loading={idPhotos.loading && isEmpty(idPhotos.back) && idPhotos.target === 'back'}
					onChange={async e => {
						const file = e.target.files[0];
						if (!file) return;

						updateForm({
							type: 'signupIdUploadForm',
							key: 'idPhotos',
							data: { back: null, valid: true, error: '', loading: true, target: 'back' },
						});

						const verifyMsg = await fileVerify(file);
						if (verifyMsg) {
							throw new Error(verifyMsg);
						}

						const compressedFile = await compressor(file);

						const reader = new FileReader();
						reader.readAsDataURL(compressedFile);
						reader.onload = async () => {
							const { valid, data, message } = await sendPhoto(reader.result, {
								type: 'idCards',
							});
							if (!valid) {
								updateForm({
									type: 'signupIdUploadForm',
									key: 'idPhotos',
									data: { valid: false, error: message, loading: false, target: '' },
								});
								return;
							}

							updateForm({
								type: 'signupIdUploadForm',
								key: 'idPhotos',
								data: { back: data, valid: true, error: '', loading: false, target: '' },
							});
						};
					}}
					statusClassName={styles.fileStatusHint}
					statusComponent={() => (
						<>
							<StatusHint>圖片大小限制 5MB</StatusHint>
							{idPhotos.error && <StatusHint type="warning">{idPhotos.error}</StatusHint>}
						</>
					)}
				/>
				<div className={styles.basicFormDivision} />
				<div className={styles.inputItemWrapper}>
					<div className={styles.label}>上傳駕照</div>
					<div className={styles.required}>必填</div>
				</div>
				<Dropdown
					className={styles.dropdownLicense}
					placeholder="請選擇駕照類別"
					options={licenseOptions}
					defaultValue={[]}
					value={licenseOptions.filter(lo => lo.value === driverLicenseType.value)}
					error={!driverLicenseType.valid}
					onChange={e => {
						updateForm({
							type: 'signupIdUploadForm',
							key: 'driverLicenseType',
							data: { value: e[0].value },
						});
					}}
				/>
				{driverLicenseType.value && (
					<>
						<Input
							fileLabel="機車駕照正面"
							type="file"
							isUploaded={driverLicensePhotos.front}
							error={!driverLicensePhotos.valid && driverLicensePhotos.front === ''}
							disabled={driverLicensePhotos.loading}
							loading={
								driverLicensePhotos.loading &&
								isEmpty(driverLicensePhotos.front) &&
								driverLicensePhotos.target === 'front'
							}
							onChange={async e => {
								const file = e.target.files[0];
								if (!file) return;

								updateForm({
									type: 'signupIdUploadForm',
									key: 'driverLicensePhotos',
									data: { front: null, valid: true, error: '', loading: true, target: 'front' },
								});

								const verifyMsg = await fileVerify(file);
								if (verifyMsg) {
									throw new Error(verifyMsg);
								}

								const compressedFile = await compressor(file);

								const reader = new FileReader();
								reader.readAsDataURL(compressedFile);
								reader.onload = async () => {
									const { valid, data, message } = await sendPhoto(reader.result, {
										type: 'driversLicenses',
									});
									if (!valid) {
										updateForm({
											type: 'signupIdUploadForm',
											key: 'driverLicensePhotos',
											data: { valid: false, error: message, loading: false, target: '' },
										});
										return;
									}

									updateForm({
										type: 'signupIdUploadForm',
										key: 'driverLicensePhotos',
										data: { front: data, valid: true, error: '', loading: false, target: '' },
									});
								};
							}}
						/>
						<Input
							type="file"
							fileLabel="機車駕照反面"
							isUploaded={driverLicensePhotos.back}
							error={!driverLicensePhotos.valid && driverLicensePhotos.back === ''}
							disabled={driverLicensePhotos.loading}
							loading={
								driverLicensePhotos.loading &&
								isEmpty(driverLicensePhotos.back) &&
								driverLicensePhotos.target === 'back'
							}
							onChange={async e => {
								const file = e.target.files[0];
								if (!file) return;

								updateForm({
									type: 'signupIdUploadForm',
									key: 'driverLicensePhotos',
									data: { back: null, valid: true, error: '', loading: true, target: 'back' },
								});

								const verifyMsg = await fileVerify(file);
								if (verifyMsg) {
									throw new Error(verifyMsg);
								}

								const compressedFile = await compressor(file);

								const reader = new FileReader();
								reader.readAsDataURL(compressedFile);
								reader.onload = async () => {
									const { valid, data, message } = await sendPhoto(reader.result, {
										type: 'driversLicenses',
									});
									if (!valid) {
										updateForm({
											type: 'signupIdUploadForm',
											key: 'driverLicensePhotos',
											data: { valid: false, error: message, loading: false, target: '' },
										});
										return;
									}

									updateForm({
										type: 'signupIdUploadForm',
										key: 'driverLicensePhotos',
										data: { back: data, valid: true, error: '', loading: false, target: '' },
									});
								};
							}}
							statusClassName={styles.fileStatusHint}
							statusComponent={() => (
								<>
									<StatusHint>圖片大小限制 5MB</StatusHint>
									{driverLicensePhotos.error && (
										<StatusHint type="warning">{driverLicensePhotos.error}</StatusHint>
									)}
								</>
							)}
						/>
					</>
				)}
				<div className={styles.reminder}>
					<StatusHint type="reminder">拍攝小叮嚀</StatusHint>
					<p className={styles.text}>
						1. 請記得將證件擺正，並可放置於黑色背景前拍攝。
						<br />
						2. 拍攝照片請避免反光或陰影。
						<br />
						3. 請避免證件汙損及裁切，上傳影像限證件正本彩色照片，請勿使用黑白影本。
						<br />
						4. 請確保上傳證件照片的清晰度，以加速會員審核流程。
						<br />
					</p>
				</div>
			</div>
			<div className={styles.actionButtonGroup}>
				<Button
					size="large"
					disabled={loading}
					onClick={() => {
						sendIdUpload();
					}}
				>
					下一步
				</Button>
			</div>
			<div className={styles.actionButtonGroup}>
				<Button
					size="large"
					disabled={loading}
					variant="outlined"
					color="primary"
					onClick={() => setStep(2)}
				>
					上一步
				</Button>
			</div>
			<div className={styles.actionButtonGroup}>
				<Button
					size="large"
					disabled={loading}
					variant="text"
					color="primary"
					onClick={() => setStep(4)}
				>
					略過
				</Button>
			</div>
		</>
	);
};

const SignupCreditCard = ({ setStep }) => {
	const [
		{ creditCardNo, creditCardExp, creditCardCvc, loading },
		{ updateForm, sendCreditCard },
	] = useSignupCreditCardForm();
	const inputRef2 = useRef(null);
	const inputRef3 = useRef(null);
	const inputRef4 = useRef(null);

	return (
		<>
			<div className={styles.inputGroup}>
				<div className={styles.inputItemWrapper}>
					<div className={styles.label}>信用卡卡號</div>
					<div className={styles.required}>必填</div>
				</div>
				<div className={styles.multiItemsGroup}>
					<Input
						className={styles.cardInput}
						type="number"
						value={creditCardNo.value.slice(0, 4)}
						error={!creditCardNo.valid}
						onChange={e => {
							if (e.target.value.length > 3) {
								inputRef2.current.focus();
							}
							updateForm({
								type: 'signupCreditCardForm',
								key: 'creditCardNo',
								data: { value: e.target.value },
							});
						}}
					/>
					<Input
						className={styles.cardInput}
						type="number"
						value={creditCardNo.value.slice(4, 8)}
						error={!creditCardNo.valid}
						ref={inputRef2}
						onChange={e => {
							if (e.target.value.length > 3) {
								inputRef3.current.focus();
							}
							updateForm({
								type: 'signupCreditCardForm',
								key: 'creditCardNo',
								data: { value: creditCardNo.value.slice(0, 4) + e.target.value },
							});
						}}
					/>
					<Input
						className={styles.cardInput}
						type="number"
						value={creditCardNo.value.slice(8, 12)}
						error={!creditCardNo.valid}
						ref={inputRef3}
						onChange={e => {
							if (e.target.value.length > 3) {
								inputRef4.current.focus();
							}
							updateForm({
								type: 'signupCreditCardForm',
								key: 'creditCardNo',
								data: { value: creditCardNo.value.slice(0, 8) + e.target.value },
							});
						}}
					/>
					<Input
						className={styles.cardInput}
						type="number"
						value={creditCardNo.value.slice(12)}
						error={!creditCardNo.valid}
						ref={inputRef4}
						onChange={e => {
							if (e.target.value.length > 4) {
								return;
							}
							updateForm({
								type: 'signupCreditCardForm',
								key: 'creditCardNo',
								data: { value: creditCardNo.value.slice(0, 12) + e.target.value },
							});
						}}
					/>
				</div>
				<div className={styles.cardStatusHint}>
					{creditCardNo.error && <StatusHint type="warning">{creditCardNo.error}</StatusHint>}
				</div>
				<div className={styles.multiItemsGroup}>
					<Input
						className={styles.input}
						type="text"
						required
						label="信用卡有效日期"
						placeholder="MM/YY"
						value={
							creditCardExp.value.length > 2
								? `${creditCardExp.value.slice(0, 2)}/${creditCardExp.value.slice(2)}`
								: creditCardExp.value
						}
						error={!creditCardExp.valid}
						onChange={e => {
							if (e.target.value.length > 5) {
								return;
							}
							updateForm({
								type: 'signupCreditCardForm',
								key: 'creditCardExp',
								data: { value: e.target.value.split('/').join('') },
							});
						}}
						statusComponent={() => (
							<>
								{creditCardExp.error && (
									<StatusHint type="warning">{creditCardExp.error}</StatusHint>
								)}
							</>
						)}
					/>
					<Input
						className={styles.input}
						type="number"
						required
						label="信用卡安全碼"
						placeholder="CVV"
						value={creditCardCvc.value}
						error={!creditCardCvc.valid}
						onChange={e => {
							if (e.target.value.length > 3) {
								return;
							}
							updateForm({
								type: 'signupCreditCardForm',
								key: 'creditCardCvc',
								data: { value: e.target.value },
							});
						}}
						statusComponent={() => (
							<>
								{creditCardCvc.error && (
									<StatusHint type="warning">{creditCardCvc.error}</StatusHint>
								)}
							</>
						)}
					/>
				</div>
			</div>
			<div className={styles.actionButtonGroup}>
				<Button
					size="large"
					disabled={loading}
					onClick={() => {
						sendCreditCard();
					}}
				>
					下一步
				</Button>
			</div>
			<div className={styles.actionButtonGroup}>
				<Button
					size="large"
					disabled={loading}
					variant="outlined"
					color="primary"
					onClick={() => setStep(3)}
				>
					上一步
				</Button>
			</div>
			<div className={styles.actionButtonGroup}>
				<Button
					size="large"
					disabled={loading}
					variant="text"
					color="primary"
					onClick={() => setStep(5)}
				>
					略過
				</Button>
			</div>
		</>
	);
};

const SignupMemberTrial = ({ onClose }) => {
	const [
		{
			status: { identity },
		},
	] = useSignupStepsStatus();
	const [{ search }] = useRouting();
	const { reg_event: regEvent } = qs.parse(search, { ignoreQueryPrefix: true });

	return (
		<>
			<img className={styles.trialImage} src={ScooterImg} alt="" />
			<div className={styles.inputGroup}>
				{identity ? (
					<p className={styles.memberTrialText}>
						已完成註冊
						<br />
						會員資格將於 1 個工作日內審核完成，請耐心等候通知
					</p>
				) : (
					<p className={styles.memberTrialText}>
						您的會員帳號已啟用
						<br />
						<br />
						提醒您若要體驗 Vespa 台灣官方商城之租車及購車服務，請儘速至「 我的帳號 」→「
						會員基本資料
						」中設定完整正確之會員資料，待收到您完整的會員資料後，我們將儘速審核並通知您審核結果，
						<br />
						謝謝。
					</p>
				)}
			</div>

			<div className={styles.actionButtonGroup}>
				<Button size="large" onClick={onClose}>
					{regEvent ? '完成' : '回到首頁'}
				</Button>
			</div>
		</>
	);
};

const SignupStep = ({ step, setStep, fastLogin, onClose }) => {
	if (step === 1) {
		return <SignupAccount fastLogin={fastLogin} />;
	}
	if (step === 2) {
		return <SignupProfile />;
	}
	if (step === 3) {
		return <SignupIdUpload setStep={setStep} />;
	}
	if (step === 4) {
		return <SignupCreditCard setStep={setStep} />;
	}
	if (step === 5) {
		return <SignupMemberTrial onClose={onClose} setStep={setStep} />;
	}
	return null;
};

const FormSignup = ({ onClose, fastLogin = false }) => {
	const [{ search }] = useRouting();
	const [{ status, step }, { resetSignupStep, setSignupStep }] = useSignupStepsStatus();
	const { mgm_code: mgmCode } = qs.parse(search, { ignoreQueryPrefix: true });

	useEffect(() => {
		resetSignupStep();
	}, []);

	return (
		<FormBasic
			className={styles.formSignup}
			contentClassName={styles.content}
			renderContent={() => (
				<>
					<div className={styles.title}>
						{
							{
								1: '帳密設定',
								2: '基本資料',
								3: '證件上傳',
								4: '信用卡綁定',
								5: status.identity ? '會員申請審核中' : '會員帳號已啟用',
							}[step]
						}
					</div>
					{step === 1 && mgmCode && (
						<div className={styles.mgmCodeHint}>
							<p>好友推薦活動</p>
						</div>
					)}
					<StepBarStatus
						className={styles.stepBar}
						step={step}
						steps={[
							{ label: '手機號碼', status: status.signup },
							{ label: '基本資料', status: status.basic },
							{ label: '證件上傳', status: status.identity },
							{ label: '信用卡綁定', status: status.creditCard },
							{ label: status.identity ? '會員審理' : '註冊完成', status: status.memberReview },
						]}
					/>
					<SignupStep step={step} setStep={setSignupStep} fastLogin={fastLogin} onClose={onClose} />
				</>
			)}
		/>
	);
};

const FormAuth = ({ className, type, onChangeModal, onClose }) => {
	const [, { clearFormErrorByFormType }] = useClearFormError();

	const transitions = useTransition(type, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: config.stiff,
	});

	useEffect(() => {
		clearFormErrorByFormType(type);
	}, [type]);

	return (
		<div
			className={classnames(
				styles.formAuth,
				className,
				type === 'tempLogin' && styles.smallFormAuth,
				(type === 'login' || type === 'signupLanding') && styles.withBg,
			)}
		>
			{type !== 'tempLogin' && (
				<Link className={styles.logo} to="/" onClick={() => {}}>
					<Logo />
				</Link>
			)}
			{transitions.map(({ item, props, key }) => (
				<animated.div key={key} className={styles.animate} style={props}>
					{item === 'login' && <FormLogin onChangeModal={onChangeModal} />}
					{item === 'tempLogin' && <FormLogin onChangeModal={onChangeModal} tempLogin />}
					{item === 'forgot' && <FormForgot />}
					{item === 'reset' && <FormReset />}
					{item === 'emailVerify' && <FormVerify />}
					{item === 'signupLanding' && <FormSignupLanding onChangeModal={onChangeModal} />}
					{item === 'signup' && <FormSignup onClose={onClose} />}
					{item === 'signupFast' && <FormSignup onClose={onClose} fastLogin />}
				</animated.div>
			))}
		</div>
	);
};

export default FormAuth;
