import React from 'react';
import CookieConsent from 'react-cookie-consent';

import Link from 'components/atoms/Link';

import styles from './index.css';

const CustomCookieConsent = () => {
	return (
		<CookieConsent
			location="bottom"
			buttonText="我接受"
			cookieName="CookieConsent"
			containerClasses={styles.wrapper}
			contentClasses={styles.container}
			buttonWrapperClasses={styles.btnWrapper}
			buttonClasses={styles.btn}
			buttonStyle={{
				width: 112,
				height: 48,
				backgroundColor: 'white',
				color: '#6dcdb1',
				fontSize: 16,
				fontWeight: '500',
				borderRadius: 24,
			}}
			expires={150}
		>
			<p className={styles.content}>
				我們紀錄 cookie
				資訊，以提供客製化內容及優化使用者體驗，若繼續閱覽本網站內容，即表示您同意我們使用
				cookies。更多關於隱私保護資訊，請閱覽我們的
				<Link to="/privacy-statement">隱私權保護政策</Link>。
			</p>
		</CookieConsent>
	);
};

export default CustomCookieConsent;
