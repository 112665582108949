/* eslint-disable no-nested-ternary */
import { createAction, handleActions } from 'redux-actions';
import dataURLtoBlob from 'blueimp-canvas-to-blob';

import { wrapAuthFetch, wrapAuthFetchFormData } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { openModal } from 'models/modal';

const initReturnForm = createAction('INIT_RETURN_FORM', () => (_, getState) => {
	const {
		order: { targetOrder },
	} = getState();

	return targetOrder.item.map(i => ({
		id: i.id,
		quantity: 1,
		return: false,
		reason: '尺寸不合',
		other: '',
		error: '',
		valid: true,
	}));
});

const initCancelForm = createAction('INIT_CANCEL_FORM');

const validateForm = createAction('VALIDATE_RETURN_FORM', (index, key, valid, error) => ({
	index,
	key,
	valid,
	error,
}));

const clearFormError = createAction('CLEAR_FORM_ERROR', () => (_, getState) => {
	const {
		order: { returnForm },
	} = getState();
	const clearData = returnForm.map(r => ({ ...r, error: '', valid: true }));

	return clearData;
});

const updateContract = createAction('UPDATE_RETURN_CONTRACT');

export const updateForm = createAction('UPDATE_RETURN_FORM', ({ index, key, data }) => ({
	index,
	key,
	data,
}));

export const updateCancelForm = createAction('UPDATE_CANCEL_FORM', ({ type, key, data }) => ({
	type,
	key,
	data,
}));

export const fetchOrder = createAction('FETCH_ORDER', () => async () => {
	const { data, status, message } = await wrapAuthFetch('me/payments');

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	return data.data;
});

export const fetchOrderInner = createAction(
	'FETCH_ORDER_INNER',
	(id = null) => async (dispatch, getState) => {
		const {
			routing: { pathname },
		} = getState();
		const orderId = id || pathname.split('/')[4];
		let orderType;

		switch (pathname.split('/')[3]) {
			case 'rental':
				orderType = 'rentals';
				break;
			case 'carSale':
				orderType = 'carSales';
				break;
			case 'afterSales':
				orderType = 'afterSales/user/afterSalesReservation';
				break;
			default:
				orderType = 'mall';
				break;
		}
		const { data, status, message } = await wrapAuthFetch(`${orderType}/${orderId}`);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return data.data;
	},
);

export const cancelOrder = createAction('CANCEL_ORDER', () => async (dispatch, getState) => {
	const {
		routing: { pathname },
	} = getState();
	const orderType = pathname.split('/')[3];
	const orderId = pathname.split('/')[4];
	const { status, message } = await wrapAuthFetch(
		`${orderType}${orderType === 'mall' ? '' : 's'}/${orderId}/cancel`,
		{
			method: 'PUT',
		},
	);

	// 訂單因貸款狀態變動而無法退款，因此無法取消訂單
	if (status === 400) {
		dispatch(
			openModal({
				category: 'dialog',
				type: 'cancelOrderFailed',
			}),
		);
		throw new Error(message);
	}

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	dispatch(fetchOrderInner());
	dispatch(
		openModal({
			category: 'toast',
			type: 'success',
			data: { message: '已成功取消訂單' },
		}),
	);
});

export const cancelAfterSalesOrder = createAction(
	'CANCEL_AFTER_SALES_ORDER',
	() => async (dispatch, getState) => {
		const {
			routing: { pathname },
		} = getState();

		const orderId = pathname.split('/')[4];
		const { status, message } = await wrapAuthFetch(
			`afterSales/user/afterSalesReservation/${orderId}`,
			{
				method: 'DELETE',
			},
		);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		dispatch(
			openModal({
				category: 'dialog',
				type: 'cancelRequest',
			}),
		);
	},
);

export const submitReturn = createAction('SUBMIT_RETURN', () => async (dispatch, getState) => {
	dispatch(clearFormError());

	const {
		order: {
			returnForm,
			targetOrder: { only_return_all: onlyReturnAll },
		},
		routing: { pathname },
	} = getState();
	let validStatus = 0;
	const orderId = pathname.split('/')[4];

	for (let i = 0; i < returnForm.length; i += 1) {
		if (returnForm[i].return && returnForm[i].reason === '其他' && returnForm[i].other === '') {
			dispatch(validateForm(i, 'other', false, '未填寫退貨原因'));
			validStatus = 400;
		}
	}

	if (validStatus === 400) {
		throw new Error('failed');
	}

	const body = () => {
		if (onlyReturnAll) {
			return {
				return_all: true,
				return_items: returnForm.map(({ id, reason }) => ({
					id,
					quantity: 0,
					reason,
				})),
			};
		}
		return {
			return_all: false,
			return_items: returnForm
				.filter(rf => rf.return)
				.map(({ id, quantity, reason, other }) => ({
					id,
					quantity,
					reason: reason === '其他' ? other : reason,
				})),
		};
	};

	const { status, error_code: errorCode } = await wrapAuthFetch(`mall/${orderId}/return`, {
		method: 'PUT',
		body: JSON.stringify(body()),
	});

	if (status !== 200 && status !== 201) {
		return Promise.resolve({ success: false, errorCode });
	}

	dispatch(fetchOrderInner());

	return Promise.resolve({ success: true });
});

export const submitCancel = createAction('SUBMIT_CANCEL', () => async (dispatch, getState) => {
	const {
		order: { increasePurchasesForm },
		routing: { pathname },
	} = getState();
	const orderId = pathname.split('/')[4];

	const formdata = new FormData();
	formdata.append('_method', 'PUT');
	formdata.append('user_sign', dataURLtoBlob(increasePurchasesForm.signature.value));
	formdata.append(
		'additional_purchases',
		JSON.stringify(increasePurchasesForm.additionalPurchases.value),
	);

	for (let i = 0; i < increasePurchasesForm.increasePurchases.value.length; i += 1) {
		formdata.append('increase_purchases[]', increasePurchasesForm.increasePurchases.value[i]);
	}

	const { status, message } = await wrapAuthFetchFormData(
		`carSales/${orderId}/cancel/increase-purchases`,
		{
			method: 'POST',
			body: formdata,
		},
	);

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	dispatch(fetchOrderInner());
	return Promise.resolve({ success: true });
});

const defaultTargetOrderData = {
	car_freebies: [],
	car_type_name: '',
	discount_desc: '',
	discount_price: null,
	id: null,
	increase_purchases: [],
	additional_purchases: [],
	order_no: '',
	payment_type: '',
	price: null,
	shop: {
		address: '',
		city: { id: null, name: '' },
		district: { id: null, name: '' },
		id: null,
		latitude: '',
		longitude: '',
		name: '',
		open_hours: '',
		phone: '',
	},
	status: '',
	stock_name: '',
	total: null,
	company_name: '',
	invoice_address: '',
	invoice_carrier_num: null,
	invoice_carrier_type: '',
	love_code: null,
	pick_up_shop: {
		address: '',
		city: { id: null, name: '' },
		district: { id: null, name: '' },
		id: null,
		latitude: '',
		longitude: '',
		name: '',
		open_hours: '',
		phone: '',
	},
	refuelling_charge: null,
	rental_amount: null,
	renting_at: '',
	returning_at: '',
	returning_shop: {
		address: '',
		city: { id: null, name: '' },
		district: { id: null, name: '' },
		id: null,
		latitude: '',
		longitude: '',
		name: '',
		open_hours: '',
		phone: '',
	},
	tax_num: '',
	timeout_charge: null,
	total_amount: null,
	amount: null,
	discount_coupon: null,
	discount_rebate: null,
	sum: null,
	shipping: null,
	item: [],
	freebie: [],
	send_coupon: {},
	only_return_all: false,
	start_service_at: '',
	finish_service_at: '',
	leave_service_at: '',
	shop_name: '',
	shop_address: '',
	shop_phone: '',
	car_type: '',
	checked_reservation_time: '',
	reserve_person_name: '',
	year: '',
	first_expect_reservation_time: '',
	service_summary: '',
	laoding: false,
	error: '',
};

const defaultIncreasePurchasesData = {
	increasePurchases: { value: [], valid: true, error: '' },
	additionalPurchases: { value: [], valid: true, error: '' },
	signature: { value: '', valid: true, error: '' },
};

const reducer = {
	order: handleActions(
		{
			INIT_RETURN_FORM: (state, action) => ({
				...state,

				returnForm: action.payload,
				contract: { value: false, valid: true, error: '' },
			}),

			INIT_CANCEL_FORM: state => ({
				...state,
				increasePurchasesForm: defaultIncreasePurchasesData,
			}),

			VALIDATE_RETURN_FORM: (state, action) => ({
				...state,

				returnForm: [
					...state.returnForm.slice(0, action.payload.index),
					{
						...state.returnForm[action.payload.index],

						valid: action.payload.valid,
						error: action.payload.error,
					},
					...state.returnForm.slice(action.payload.index + 1),
				],
			}),

			CLEAR_FORM_ERROR: (state, action) => ({
				...state,

				returnForm: action.payload,
			}),

			UPDATE_RETURN_FORM: (state, action) => ({
				...state,

				returnForm: [
					...state.returnForm.slice(0, action.payload.index),
					{
						...state.returnForm[action.payload.index],

						[action.payload.key]: action.payload.data,
					},
					...state.returnForm.slice(action.payload.index + 1),
				],
			}),

			UPDATE_CANCEL_FORM: (state, action) => ({
				...state,

				[action.payload.type]: {
					...state[action.payload.type],

					[action.payload.key]: {
						...state[action.payload.type][action.payload.key],
						...action.payload.data,
					},
				},
			}),

			UPDATE_RETURN_CONTRACT: state => ({
				...state,

				contract: { value: true, valid: true, error: '' },
			}),

			FETCH_ORDER_PENDING: state => ({
				...state,

				loading: true,
				order: [],
			}),

			FETCH_ORDER_FULFILLED: (state, action) => ({
				...state,

				loading: false,
				order: action.payload,
			}),

			FETCH_ORDER_INNER_PENDING: state => ({
				...state,

				targetOrder: defaultTargetOrderData,
				loading: true,
			}),

			FETCH_ORDER_INNER_FULFILLED: (state, action) => ({
				...state,

				targetOrder: {
					...state.targetOrder,

					...action.payload,
				},
				loading: false,
			}),

			CANCEL_ORDER_PENDING: state => ({
				...state,

				targetOrder: {
					...state.targetOrder,
					loading: true,
				},
			}),

			CANCEL_ORDER_FULFILLED: state => ({
				...state,

				targetOrder: {
					...state.targetOrder,
					loading: false,
				},
			}),

			CANCEL_ORDER_REJECTED: (state, action) => ({
				...state,

				targetOrder: {
					...state.targetOrder,
					loading: false,
					error: action.payload.message,
				},
			}),

			CANCEL_AFTER_SALES_ORDER_PENDING: state => ({
				...state,

				targetOrder: {
					...state.targetOrder,
					loading: true,
				},
			}),

			CANCEL_AFTER_SALES_ORDER_FULFILLED: state => ({
				...state,

				targetOrder: {
					...state.targetOrder,
					loading: false,
				},
			}),

			CANCEL_AFTER_SALES_ORDER_REJECTED: (state, action) => ({
				...state,

				targetOrder: {
					...state.targetOrder,
					loading: false,
					error: action.payload.message,
				},
			}),

			SUBMIT_RETURN_PENDING: state => ({
				...state,

				loading: true,
			}),

			SUBMIT_RETURN_FULFILLED: state => ({
				...state,

				loading: false,
			}),

			SUBMIT_RETURN_REJECTED: (state, action) => ({
				...state,

				loading: false,
				error: action.payload.message,
			}),
		},
		{
			order: [],
			targetOrder: defaultTargetOrderData,
			returnForm: [],
			increasePurchasesForm: defaultIncreasePurchasesData,
			contract: { value: false, valid: true, error: '' },
			loading: false,
			error: '',
		},
	),
};

const selectOrder = state => state.order;

export const useOrder = () =>
	useRedux(selectOrder, {
		fetchOrder,
		fetchOrderInner,
		cancelOrder,
		cancelAfterSalesOrder,
		initReturnForm,
		initCancelForm,
		updateForm,
		updateContract,
		submitReturn,
	});

const selectIncreasePurchasesForm = state => state.order.increasePurchasesForm;

export const useIncreasePurchasesForm = () =>
	useRedux(selectIncreasePurchasesForm, {
		updateCancelForm,
		validateForm,
		submitCancel,
	});

export default { reducer };
