/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import classnames from 'classnames';

import { useBoolean } from 'util/hook';

import Button from 'components/atoms/Button';

import EyeShowIcon from 'images/icon/password-eye-show.inline.svg';
import EyeHideIcon from 'images/icon/password-eye-hide.inline.svg';
import CheckIcon from 'images/icon/check.inline.svg';
import UploadIcon from 'images/icon/upload.inline.svg';
import UploadedIcon from 'images/icon/uploaded.inline.svg';
import LoadingIcon from 'images/icon/loading.inline.svg';

import styles from './index.css';

const EyeIcon = ({ show, ...other }) =>
	show ? <EyeHideIcon {...other} /> : <EyeShowIcon {...other} />;

const Input = (
	{
		className,
		label = '',
		labelClassName,
		iconClassName,
		required = false,
		verifyRequired = false,
		verified = false,
		error = false,
		type = 'text',
		disabled = false,
		statusComponent,
		statusClassName,
		checked,
		isUploaded,
		fileLabel,
		fileIcon = true,
		checkboxClassName,
		checkboxHasChildren = false,
		loading = false,
		renderIcon,
		children,
		...other
	},
	ref,
) => {
	const [isShowPassword, { toggle: togglePassword }] = useBoolean({
		defaultBoolean: false,
	});
	const renderFileIcon = () => {
		if (loading) {
			return <LoadingIcon />;
		}
		if (isUploaded) {
			return <UploadedIcon />;
		}
		return <UploadIcon />;
	};
	const onKeyDown = e => {
		if (type === 'number' && (e.keyCode === 69 || e.keyCode === 190)) {
			e.preventDefault();
		}
	};

	return (
		<div
			className={classnames(styles.input, className, {
				[styles.error]: error,
			})}
		>
			{(label || required || verifyRequired) && (
				<div className={classnames(styles.header, type === 'checkbox' && styles.checkboxHeader)}>
					{type === 'checkbox' && (
						<div
							className={classnames(styles.checkboxWrapper, checkboxClassName, {
								[styles.disabledCheckbox]: disabled,
							})}
						>
							<input type="checkbox" checked={checked} {...other} disabled={disabled} />
							<div className={styles.svgWrapper}>
								<CheckIcon />
							</div>
						</div>
					)}
					<div className={classnames(styles.label, disabled && styles.disabled, labelClassName)}>
						{type === 'checkbox' && checkboxHasChildren ? children : label}
					</div>
					{required && <div className={styles.required}>必填</div>}
					{verifyRequired && (
						<div className={classnames(styles.required, disabled && styles.disabled)}>
							{verified ? '已驗證' : '未驗證'}
						</div>
					)}
				</div>
			)}
			<div className={styles.inputWrapper}>
				{type !== 'checkbox' && type !== 'file' && type !== 'textarea' && (
					<>
						{typeof renderIcon === 'function' && (
							<div className={classnames(styles.search, iconClassName)}>{renderIcon()}</div>
						)}
						<input
							className={classnames(
								type === 'password' && styles.password,
								disabled && styles.disabled,
								typeof renderIcon === 'function' && styles.searchInput,
							)}
							onKeyDown={onKeyDown}
							type={isShowPassword ? 'text' : type}
							ref={ref}
							readOnly={disabled}
							{...other}
						/>
					</>
				)}
				{type === 'textarea' && <textarea readOnly={disabled} {...other} />}
				{type === 'password' && (
					<EyeIcon
						show={isShowPassword}
						onClick={togglePassword}
						onKeyPress={() => {}}
						role="button"
						tabIndex="0"
					/>
				)}
				{type === 'file' && (
					<Button
						className={classnames(
							styles.fileButton,
							label && styles.fileButtonWithLabel,
							isUploaded && styles.uploaded,
							{
								[styles.fileButtonError]: error,
								[styles.fileButtonDisabled]: disabled,
							},
							disabled && styles.disabled,
						)}
						size="large"
						disabled={disabled}
					>
						<label
							htmlFor={fileLabel}
							className={classnames({
								[styles.loading]: loading,
							})}
						>
							{fileIcon && renderFileIcon()}
							{loading ? '上傳中' : fileLabel}
							<input type="file" {...other} id={fileLabel} disabled={disabled} />
						</label>
					</Button>
				)}
			</div>
			{statusComponent && (
				<div className={classnames(styles.status, statusClassName)}>{statusComponent()}</div>
			)}
		</div>
	);
};

export default forwardRef(Input);
