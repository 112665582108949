/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import classnames from 'classnames';

import DropdownBasic from 'components/atoms/DropdownBasic';

import ArrowIcon from 'images/icon/arrow-down.inline.svg';

import { useSelect } from 'util/hook';

import styles from './index.css';

const Dropdown = ({
	className,
	defaultValue = [],
	value: propsValue,
	onChange = () => {},
	placeholder,
	options = [],
	disabled = false,
	error = false,
	empty = false,
	emptyText = '',
	EmptyImage = () => {},
	background = false,
	multipleOptions = false,
	optionBorder = false,
	optionBorderColumn = false,
}) => {
	const [value, onChangeValue] = useSelect({ defaultValue, onChange, propsValue });

	const hasValue = value.length > 0 && options.some(item => item.value === value[0].value);

	const handleValueAndPlaceholder = () => {
		const labels = value.length > 0 && value.sort((a, b) => a.id - b.id).map(item => item.label);
		const multipleResult = labels && labels.join('、');

		if (!hasValue) {
			return placeholder;
		}
		return multipleOptions ? multipleResult : value[0].label;
	};

	return (
		<DropdownBasic
			className={className}
			disabled={disabled}
			valueComponent={({ isOpen }) => (
				<div
					className={classnames(styles.value, {
						[styles.hasValue]: hasValue,
						[styles.isOpen]: isOpen,
						[styles.error]: error,
						[styles.disabled]: disabled,
					})}
				>
					<div
						className={classnames({
							[styles.placeholder]: !hasValue,
						})}
					>
						{handleValueAndPlaceholder()}
					</div>
					<ArrowIcon />
				</div>
			)}
			panelClassName={classnames(styles.panel, {
				[styles.emptyPanel]: empty,
				[styles.flexPanel]: options.length > 0 && optionBorder,
				[styles.columnPanel]: optionBorderColumn,
			})}
			panelComponent={({ closePanel }) => {
				if (empty) {
					return (
						<div className={styles.emptyContent}>
							<EmptyImage />
							<p>{emptyText}</p>
						</div>
					);
				}
				return options && optionBorder ? (
					<>
						{options.map(option => {
							return (
								<div
									key={option.value}
									className={classnames(styles.option, styles.optionBorder, {
										[styles.isSelected]:
											(value.length > 1
												? value.some(item => item.value === option.value)
												: false) ||
											(value.length > 0 && option.value === value[0].value),
										[styles.optionDisabled]: option.disabled,
										[styles.background]: background,
									})}
									onClick={() => {
										if (!multipleOptions) {
											onChangeValue(option);
											closePanel();
										} else {
											onChangeValue(option, true);
										}
									}}
									role="presentation"
								>
									{option.label}
								</div>
							);
						})}
					</>
				) : (
					<>
						{options.map(option => (
							<div
								key={option.value}
								className={classnames(styles.option, {
									[styles.isSelected]: value.length > 0 && option.value === value[0].value,
									[styles.optionDisabled]: option.disabled,
									[styles.background]: background,
								})}
								onClick={() => {
									onChangeValue(option);
									closePanel();
								}}
								role="presentation"
							>
								{option.label}
								{option.reserved && <span>已預約</span>}
							</div>
						))}
					</>
				);
			}}
		/>
	);
};

export const DropdownField = ({
	className,
	dropdownClassName,
	defaultValue,
	value,
	label = '',
	required = false,
	requiredField = false,
	disabled = false,
	onChange,
	placeholder,
	options,
	statusComponent,
	statusClassName,
	error = false,
	background = false,
	multipleOptions = false,
	optionBorder = false,
	optionBorderColumn = false,
}) => (
	<div className={classnames(styles.dropdownField, className, { [styles.disabled]: disabled })}>
		<div className={styles.header}>
			<div className={styles.label}>{label}</div>
			{required && <div className={styles.required}>必選</div>}
			{requiredField && <div className={styles.required}>必填</div>}
		</div>
		<Dropdown
			className={classnames(styles.dropdown, dropdownClassName)}
			defaultValue={defaultValue}
			value={value}
			placeholder={placeholder}
			disabled={disabled}
			error={error}
			options={options}
			onChange={onChange}
			background={background}
			multipleOptions={multipleOptions}
			optionBorder={optionBorder}
			optionBorderColumn={optionBorderColumn}
		/>
		{statusComponent && (
			<div className={classnames(styles.status, statusClassName)}>{statusComponent()}</div>
		)}
	</div>
);

export default Dropdown;
