import React from 'react';
import classnames from 'classnames';

import { isExist } from 'util/helper';
import { useRouting } from 'models/routing';

import styles from './index.css';

export const ButtonLink = ({
	children,
	className,
	type = 'internal',
	href,
	variant = 'normal',
	onClick = () => {},
	size,
	Icon,
	color = 'primary',
	disabled = false,
}) => {
	return (
		<a
			className={classnames(
				styles.button,
				styles.link,
				className,
				{
					[styles.disabled]: disabled,
				},
				isExist(variant) && styles[variant],
				isExist(size) && styles[size],
				isExist(color) && styles[color],
			)}
			href={href}
			target={type === 'external' ? '_blank' : '_self'}
			rel={type === 'external' ? 'noopener noreferrer' : ''}
			onClick={onClick}
		>
			<div>
				{Icon && <Icon className={styles.icon} />}
				{children}
			</div>
		</a>
	);
};

const Button = ({
	children,
	className,
	iconClassName,
	variant = 'normal', // normal, outlined, invert, text
	onClick = () => {},
	size,
	Icon,
	color,
	disabled = false,
}) => {
	const [
		{
			queries: { src },
		},
	] = useRouting();

	return (
		<button
			className={classnames(
				styles.button,
				className,
				{
					[styles.disabled]: disabled,
				},
				isExist(variant) && styles[variant],
				isExist(size) && styles[size],
				isExist(color) && styles[color],
			)}
			type="button"
			onTouchEnd={() => {
				if (!disabled) {
					if (src === 'app' && window.webkit) {
						// webview && ios
						onClick();
					}
				}
			}}
			onClick={e => {
				if (!disabled) {
					if (src === 'app' && window.webkit) {
						// webview && ios
						return;
					}
					onClick(e);
				}
			}}
		>
			<div>
				{Icon && (
					<Icon
						className={classnames(styles.icon, iconClassName, {
							[styles.margin]: isExist(children),
						})}
					/>
				)}
				{children}
			</div>
		</button>
	);
};

export default Button;
