import React from 'react';
import classnames from 'classnames';

import Link from 'components/atoms/Link';
import Button from 'components/atoms/Button';

// import { detectMobileOS } from 'util/helper';

import Close from 'images/icon/app-banner-close.inline.svg';
import Logo from 'images/logo/app-logo.inline.svg';

import styles from './index.css';

// const { APPLE_STORE_URL, GOOGLE_PLAY_URL } = process.env;
const { SELF_HOST_ENDPOINT, DYNAMIC_LINK_URL, DYNAMIC_LINK_IBI } = process.env;

const AppBanner = ({ className, open, onClose = () => {} }) => {
	// const os = detectMobileOS();

	const handleLink = () => {
		const { pathname } = window.location;

		// if (os === 'ios') {
		// 	return `https://25sprout.page.link?link=${APPLE_STORE_URL}&isi=`;
		// }
		// if (os === 'android') {
		// 	return `https://25sprout.page.link?link=${GOOGLE_PLAY_URL}&apn=com.taikoo.vespa`;
		// }

		return `${DYNAMIC_LINK_URL}/?link=${
			pathname.indexOf('/news') >= 0 ? `${SELF_HOST_ENDPOINT}${pathname}` : `${SELF_HOST_ENDPOINT}`
		}&apn=com.taikoo.vespa&isi=1567793078&ibi=${DYNAMIC_LINK_IBI}`;
	};

	return (
		<div className={classnames(styles.wrapper, open && styles.show, className)}>
			<Close onClick={onClose} />
			<Logo />
			<div className={styles.titleWrapper}>
				<p>滿足你對 Vespa 的熱愛</p>
				<p>Vespa 官方商城 APP</p>
			</div>
			<Link to={handleLink()} isExternal>
				<Button className={styles.button} color="primary" onClick={() => {}}>
					開啟
				</Button>
			</Link>
		</div>
	);
};

export default AppBanner;
