import React from 'react';
import classnames from 'classnames';

import { useRouting } from 'models/routing';

import styles from './index.css';

const onClickHandler = (callback = () => {}, pushRoute = () => {}) => {
	return e => {
		e.preventDefault();
		pushRoute({
			pathname: e.currentTarget.pathname,
			search: e.currentTarget.search,
			hash: e.currentTarget.hash,
		});
		callback(e);
	};
};

const Link = ({ className, to, onClick = () => {}, children, isExternal = false, style }) => {
	const [, { pushRoute }] = useRouting();

	return (
		<a
			className={classnames(styles.link, className)}
			href={to}
			role="button"
			tabIndex={0}
			target={isExternal ? '_blank' : '_self'}
			onClick={!isExternal ? onClickHandler(onClick, pushRoute) : () => {}}
			onKeyPress={() => {}}
			style={style}
		>
			{children}
		</a>
	);
};

export default Link;
