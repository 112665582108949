import { useState, useEffect, useRef } from 'react';

// Handle the Dom event
export const useDom = eventHandlers => {
	// Only subscribe/unsubscribe on mount/unmount lifecycle
	useEffect(() => {
		Object.keys(eventHandlers).forEach(event =>
			window.addEventListener(event, eventHandlers[event]),
		);

		return () => {
			Object.keys(eventHandlers).forEach(event =>
				window.removeEventListener(event, eventHandlers[event]),
			);
		};
	}, []);
};

export const useScroll = () => {
	const [scrollY, setState] = useState(0);

	const scrollEvent = () => {
		setState(window.scrollY);
	};

	useDom({ scroll: scrollEvent });

	return { scrollY };
};

export const useElement = (
	{
		defaultHeight,
		defaultWidth,
		onUpdateOffsetTop = () => {},
		onUpdateHeight = () => {},
		onUpdateWidth = () => {},
	},
	triggerEffect = [],
) => {
	const [{ height, width, offsetTop }, setState] = useState({
		height: defaultHeight,
		width: defaultWidth,
		offsetTop: null,
	});
	const refChild = useRef(null);

	const handleOffsetTop = () => {
		const node = refChild.current;
		const bodyRect = document.body.getBoundingClientRect();
		const elemRect = node.getBoundingClientRect();
		const newOffsetTop = elemRect.top - bodyRect.top;

		if (offsetTop !== newOffsetTop) {
			setState(prevState => ({ ...prevState, offsetTop: newOffsetTop }));

			onUpdateOffsetTop(newOffsetTop);
		}
	};

	const handleHeight = () => {
		const node = refChild.current;

		if (height !== node.offsetHeight && node.offsetHeight > 0) {
			// Update the component height
			setState(prevState => ({ ...prevState, height: node.offsetHeight }));

			onUpdateHeight(node.offsetHeight);
		}
	};

	const handleWidth = () => {
		const node = refChild.current;

		if (width !== node.offsetWidth && node.offsetWidth > 0) {
			// Update the component width
			setState(prevState => ({ ...prevState, width: node.offsetWidth }));

			onUpdateWidth(node.offsetWidth);
		}
	};

	const handleDomEvent = () => {
		if (refChild.current !== null && refChild.current.node !== null) {
			handleHeight();
			handleWidth();
			handleOffsetTop();
		}
	};

	const handleScroll = () => {
		window.requestAnimationFrame(handleDomEvent);
	};

	const handleResize = () => {
		window.requestAnimationFrame(handleDomEvent);
	};

	useDom({ scroll: handleScroll, resize: handleResize });

	useEffect(() => {
		let didUnsubscribe = false;

		const updateDomHandle = () => {
			if (didUnsubscribe) {
				return;
			}
			handleDomEvent();
		};

		// Setup initial value
		updateDomHandle();

		return () => {
			didUnsubscribe = true;
		};
	}, [refChild, ...triggerEffect]);

	return { refChild, height, width, offsetTop };
};

export const useScrollDirection = () => {
	const [scrollDir, setScrollDir] = useState('down');

	useEffect(() => {
		setScrollDir('up');
	}, []);

	useEffect(() => {
		const threshold = 40;
		let lastScrollY = window.pageYOffset;
		let ticking = false;

		const updateScrollDir = () => {
			const scrollY = window.pageYOffset;

			if (Math.abs(scrollY - lastScrollY) < threshold) {
				ticking = false;
				return;
			}
			setScrollDir(scrollY > lastScrollY ? 'down' : 'up');
			lastScrollY = scrollY > 0 ? scrollY : 0;
			ticking = false;
		};

		const onScroll = () => {
			if (!ticking) {
				window.requestAnimationFrame(updateScrollDir);
				ticking = true;
			}
		};

		window.addEventListener('scroll', onScroll);

		return () => window.removeEventListener('scroll', onScroll);
	}, [scrollDir]);

	return scrollDir;
};
