/* eslint-disable react/no-danger */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import QRCode from 'qrcode.react';
import Slider from 'react-slick';
import dayjs from 'dayjs';
import qs from 'qs';

import { useDialogModal } from 'models/modal';
import { useRouting } from 'models/routing';
import { useOwnershipForm } from 'models/ownership';
import { useUserData, useUserCreditCard } from 'models/user';
import { useRental } from 'models/rental';
import { useReceiveForm } from 'models/shop';
import { useOrder } from 'models/order';
import {
	useAfterSalesUser,
	useUserCarModel,
	useUserCarModelForm,
	useUserCarNotification,
} from 'models/afterSales';
import { useTestDriveForm } from 'models/testDrive';
import { useContract } from 'models/contract';

import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';
import SectionTitle from 'components/atoms/SectionTitle';
import CreditCardEdit from 'components/molecules/CreditCardEdit';
import ReturnRequestEdit from 'components/molecules/ReturnRequest';
import CancelAddPurchaseEdit from 'components/molecules/CancelAddPurchase';
import OwnerShipStoreStock from 'components/organisms/OwnerShipStoreStock';
import Input from 'components/atoms/Input';

import { scrollToOffset } from 'util/helper';
import { useBoolean } from 'util/hook';

import ModalClose from 'images/icon/modal-close.inline.svg';
import OrderSuccess from 'images/icon/order-success.inline.svg';
import OrderFailure from 'images/icon/order-failure.inline.svg';
import CloseIcon from 'images/icon/contract-close.inline.svg';

import 'slick-carousel/slick/slick.css';
import styles from './index.css';

const { IMAGE_ENDPOINT } = process.env;

const MemberIncompleteDialog = ({ closeModal = () => {}, pushRoute = () => {} }) => {
	const [
		{
			queries: { src },
		},
	] = useRouting();

	return (
		<>
			<h3>會員基本資料尚不齊全</h3>
			<p>請先完成會員基本資料填寫，才可上傳相關證件並送出會員審核申請，謝謝！</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();

						// webview
						if (src === 'app') {
							if (window.webkit) {
								window.webkit.messageHandlers.showMemberPage.postMessage('hello world');
							} else if (window.AppJS) {
								window.AppJS.showMemberPage();
							}
							return;
						}

						pushRoute({ pathname: '/member/basic' });
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const MemberIncompleteInShopDialog = ({ closeModal = () => {}, pushRoute = () => {} }) => {
	const [
		{
			queries: { src },
		},
	] = useRouting();

	return (
		<>
			<h3>尚未填寫完整會員資料</h3>
			<p>您的會員資料尚不齊全，請先至會員中心將完整會員基本資料填寫完成後，才可成立訂單，謝謝！</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();

						// webview
						if (src === 'app') {
							if (window.webkit) {
								window.webkit.messageHandlers.showMemberPage.postMessage('hello world');
							} else if (window.AppJS) {
								window.AppJS.showMemberPage();
							}
							return;
						}

						pushRoute({ pathname: '/member/basic' });
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const RentalSuccessDialog = ({ closeModal = () => {}, pushRoute = () => {} }) => (
	<>
		<h3>訂單已成立</h3>
		<p>
			您的租車訂單已成立，請於預定取車時間前 10
			分鐘至門市取車。如有任何問題，歡迎透過我的訂單查詢訂單詳情或洽詢門市，謝謝！
		</p>
		<div className={styles.hl} />
		<div className={styles.buttonWrapperDialog}>
			<Button
				className={styles.button}
				size="small"
				color="primary"
				variant="invert"
				onClick={() => {
					closeModal();
					pushRoute({ pathname: '/' });
				}}
			>
				確認
			</Button>
		</div>
	</>
);

const RentalFailedDialog = ({ closeModal = () => {}, pushRoute = () => {} }) => (
	<>
		<h3>訂單未成立</h3>
		<p>您所選擇之門市車輛於該時段已被預約，請重新選擇租車時段或其他門市車輛，謝謝！</p>
		<div className={styles.hl} />
		<div className={styles.buttonWrapperDialog}>
			<Button
				className={styles.button}
				size="small"
				color="primary"
				variant="invert"
				onClick={() => {
					closeModal();
					pushRoute({ pathname: '/VesShare' });
				}}
			>
				確認
			</Button>
		</div>
	</>
);

const CreditCardExceptionDialog = ({ pathname, closeModal = () => {}, pushRoute = () => {} }) => {
	const [, { initVesShareForm }] = useRental();
	const [
		{
			data: { orderMessage },
		},
	] = useDialogModal();

	return (
		<>
			<h3>訂單未成立</h3>
			<p>信用卡異常或交易發生錯誤，請重新綁定信用卡或聯絡發卡銀行，謝謝！</p>
			<p className={styles.orderMessage}>{orderMessage}</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
						pushRoute(
							{ pathname: pathname.includes('vespa-models') ? '/vespa-models' : '/' },
							initVesShareForm,
						);
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const PurchaseFailedDialog = ({ closeModal = () => {}, pushRoute = () => {} }) => {
	const [, { clearForm }] = useOwnershipForm();

	return (
		<>
			<h3>訂單未成立</h3>
			<p>您所選擇的車款或配備可能暫無庫存，請重新選擇商品，或洽詢客服專線 02-2795-3696，謝謝！</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
						pushRoute({ pathname: '/vespa-models' }, clearForm);
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const PurchaseAbnormalDialog = ({ closeModal = () => {} }) => {
	const [, { historyBack }] = useRouting();

	return (
		<>
			<h3>加價購活動異動</h3>
			<p>很抱歉，原加價購商品活動已異動，請重新確認欲購買的數量或挑選類似商品。</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
						historyBack();
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const RentalFailedByStatusRejectDialog = ({ closeModal = () => {}, pushRoute = () => {} }) => {
	const [, { getUser }] = useUserData();
	return (
		<>
			<h3>訂單未成立</h3>
			<p>
				因您違反Vespa會員服務條款或租車規範，已取消您的會員資格與終止使用各項服務之權利，故此訂單無法成立。如有任何問題，請洽詢客服專線
				02-2795-3696，謝謝！
			</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
						getUser();
						pushRoute({ pathname: '/' });
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const LoanSuccessDialog = ({ closeModal = () => {}, pushRoute = () => {} }) => {
	const [
		{
			queries: { src },
		},
	] = useRouting();
	const [, { clearForm }] = useOwnershipForm();
	const [{ data }] = useDialogModal();
	const farTrustUrl = data.data.fartrust_url;

	return (
		<>
			<h3>已收到貸款分期申請</h3>
			<p>
				感謝您選用貸款分期方案，請點擊下方連結完成線上申請作業。須等貸款撥款後，所選車輛才會予以保留，謝謝！
			</p>
			<p>點擊按鈕後將導向 Vespa 總代理太古運通配合之遠信國際融資網頁。</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Link isExternal to={farTrustUrl}>
					<Button
						className={classnames(styles.button, styles.widthNotFixed)}
						size="small"
						color="primary"
						variant="invert"
						onClick={() => {
							if (src === 'app') {
								if (window.webkit) {
									window.webkit.messageHandlers.loanUrl.postMessage(farTrustUrl);
								}
							}
							closeModal();
							pushRoute({ pathname: '/vespa-models' }, clearForm);
						}}
					>
						填寫貸款申請資料
					</Button>
				</Link>
			</div>
		</>
	);
};

const MemberStatusDialog = ({ pathname = '', closeModal = () => {}, pushRoute = () => {} }) => {
	const [
		{
			queries: { src },
		},
	] = useRouting();

	return (
		<>
			<h3>尚未通過會員資格審核</h3>
			<p>
				您尚未通過會員資格審核，請耐心等待審核結果，或先至會員中心填寫完整會員資料，待會員審核通過後即可使用
				{pathname === '/VesShare' ? '租車' : '線上購車'}
				服務，謝謝！
			</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();

						// webview
						if (src === 'app') {
							if (window.webkit) {
								window.webkit.messageHandlers.showMemberPage.postMessage('hello world');
							} else if (window.AppJS) {
								window.AppJS.showMemberPage();
							}
							return;
						}

						pushRoute({ pathname: '/member/basic' });
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const MemberStatusReviewDialog = ({ closeModal = () => {} }) => {
	return (
		<>
			<h3>會員資格審核中</h3>
			<p>會員資格將於 1 個工作日內審核完成，請耐心等候通知，謝謝！</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={closeModal}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const MemberStatusRebootDialog = ({ closeModal = () => {} }) => {
	const [, { sendUserBasic }] = useUserData();

	return (
		<>
			<h3>變更會員資料需重新審核</h3>
			<p>更動 姓名/出生年月日/身分證字號（永久居留證統一證號） 需重新審核，是否確認更動？</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
						window.location.reload();
					}}
				>
					取消
				</Button>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						sendUserBasic();
						closeModal();
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const MemberStatusRejectDialog = ({
	pathname = '',
	closeModal = () => {},
	pushRoute = () => {},
}) => {
	const [, { getUser }] = useUserData();
	return (
		<>
			<h3>取消會員資格 </h3>
			<p>
				因您違反 Vespa
				會員服務條款與租車規範，已取消您的會員資格與終止使用各項服務之權利，如有任何問題，請洽詢客服專線
				<br />
				02-2795-3696，謝謝！
			</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
						getUser();
						if (pathname.includes('vespa-models')) {
							pushRoute({ pathname: '/vespa-models' });
						} else if (pathname.includes('shop')) {
							pushRoute({ pathname: '/shop' });
						} else {
							pushRoute({ pathname: '/' });
						}
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const ReachedSendingEmailLimitDialog = ({ closeModal = () => {} }) => {
	return (
		<>
			<h3>已達寄發上限</h3>
			<p>已達每分鐘寄發 email 上限，請於 60 秒後再嘗試</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const MemberUpdatedDialog = ({ closeModal = () => {} }) => (
	<>
		<h3>個人資料已更新</h3>
		<p>個人資料已更新完成，謝謝！</p>
		<div className={styles.hl} />
		<div className={styles.buttonWrapperDialog}>
			<Button
				className={styles.button}
				size="small"
				color="primary"
				variant="invert"
				onClick={closeModal}
			>
				確認
			</Button>
		</div>
	</>
);

const MemberCreditCardDialog = ({ closeModal = () => {} }) => (
	<>
		<h3>尚未綁定信用卡</h3>
		<p>您尚未綁定信用卡，請先綁定信用卡後才可成立訂單，謝謝！</p>
		<div className={styles.hl} />
		<div className={styles.buttonWrapperDialog}>
			<Button
				className={styles.button}
				size="small"
				color="primary"
				variant="invert"
				onClick={closeModal}
			>
				確認
			</Button>
		</div>
	</>
);

const CreditCardDeleteDialog = ({ closeModal = () => {} }) => {
	const [, { deleteCreditCard }] = useUserCreditCard();

	return (
		<>
			<h3>是否要刪除信用卡</h3>
			<p>刪除後需重新綁定信用卡，才可以使用本網站完整服務。</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
					}}
				>
					取消
				</Button>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						deleteCreditCard();
						closeModal();
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const StarItem = ({ checked, colored, setStars, value }) => (
	<div
		className={classnames(styles.starItem, colored && styles.starItemSelected)}
		onClick={() => setStars(value)}
		onKeyPress={() => {}}
		role="button"
		tabIndex="0"
	>
		<input
			className={styles.input}
			checked={checked}
			type="radio"
			value={value}
			onChange={() => setStars(value)}
		/>
	</div>
);

const StarSelector = ({ stars, setStars }) => {
	const starRow = [1, 2, 3, 4, 5].map(item => (
		<StarItem
			key={item}
			colored={stars >= item}
			checked={stars === item}
			value={item}
			setStars={setStars}
		/>
	));

	return <div className={styles.starWrapper}>{starRow}</div>;
};

const Rate = ({ closeModal = () => {} }) => {
	const [stars, setStars] = useState(0);
	const [, { rate }] = useRental();
	const [
		{
			data: { id, scoreUrl },
		},
	] = useDialogModal();

	return (
		<div className={styles.largeDialog}>
			<ModalClose className={styles.close} onClick={closeModal} />
			<SectionTitle className={styles.modalSectionTitle} title="租車整體體驗" color="default" />
			<div className={styles.textWrapper}>
				<p>您滿意本次的租車體驗嗎？</p>
				<p>請點選星星來為這次的租車體驗評分</p>
			</div>
			<StarSelector stars={stars} setStars={setStars} />
			<div className={styles.buttonWrapper}>
				<Button
					className={styles.button}
					size="large"
					color="primary"
					variant="outlined"
					onClick={() => {
						closeModal();
					}}
				>
					取消
				</Button>
				<Button
					className={styles.button}
					size="large"
					color="primary"
					variant="normal"
					onClick={() => {
						rate(id, stars, scoreUrl);
						closeModal();
					}}
				>
					送出
				</Button>
			</div>
		</div>
	);
};

const AddCreditCard = ({ closeModal = () => {} }) => (
	<div className={classnames(styles.largeDialog, styles.addCreditCard)}>
		<ModalClose className={styles.close} onClick={closeModal} />
		<SectionTitle className={styles.modalSectionTitle} title="信用卡設定" color="default" />
		<CreditCardEdit close={closeModal} />
	</div>
);

const Ie = () => (
	<>
		<h3>瀏覽器更換建議</h3>
		<p>本網站不支援 IE 瀏覽器</p>
		<p>為了取得最佳的網頁瀏覽體驗，建議您使用 Google Chrome 或 Safari 等瀏覽器。</p>
	</>
);

const ServerError = ({ closeModal = () => {} }) => (
	<>
		<h3>500 Server Error</h3>
		<p>很抱歉，目前發生伺服器錯誤，請稍後再試試，謝謝。</p>
		<div className={styles.hl} />
		<div className={styles.buttonWrapperDialog}>
			<Button
				className={styles.button}
				size="small"
				color="primary"
				variant="invert"
				onClick={() => {
					closeModal();
				}}
			>
				確認
			</Button>
		</div>
	</>
);

const ShopFailedDialog = ({ closeModal = () => {}, pushRoute = () => {} }) => {
	const [, { initReceiveForm }] = useReceiveForm();
	const [
		{
			data: { orderMessage },
		},
	] = useDialogModal();

	return (
		<>
			<h3>付款失敗，訂單未成立</h3>
			<p>
				請確認您的付款資訊，如有其他問題，請洽信用卡發卡銀行或 Vespa
				台灣官方商城客服專線：02-2795-3696，謝謝！
			</p>
			<p className={styles.orderMessage}>{orderMessage}</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
						pushRoute({ pathname: '/shop' }, initReceiveForm);
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const ShopCheckOutNotEnoughStockDialog = ({ closeModal = () => {}, pushRoute = () => {} }) => {
	const [, { initReceiveForm }] = useReceiveForm();
	const [
		{
			data: { orderMessage },
		},
	] = useDialogModal();

	return (
		<>
			<h3>商品庫存異動，訂單未成立</h3>
			<p>很抱歉，原購物清單內之商品庫存已異動，請重新確認欲購買的數量或挑選類似商品，謝謝！</p>
			<p className={styles.orderMessage}>{orderMessage}</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
						pushRoute({ pathname: '/shop' }, initReceiveForm);
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const AdditionalPurchaseDialog = ({ closeModal = () => {} }) => (
	<>
		<h3>加購數量已達上限</h3>
		<p>此優惠加購商品已達購買數量上限</p>
		<div className={styles.hl} />
		<div className={styles.buttonWrapperDialog}>
			<Button
				className={styles.button}
				size="small"
				color="primary"
				variant="invert"
				onClick={closeModal}
			>
				確認
			</Button>
		</div>
	</>
);

const ShopCartNotEnoughStockDialog = ({ closeModal = () => {} }) => (
	<>
		<h3>商品庫存數量異動</h3>
		<p>很抱歉，原購物車內所挑選之商品庫存已異動，請重新確認欲購買的數量或挑選類似商品。</p>
		<div className={styles.hl} />
		<div className={styles.buttonWrapperDialog}>
			<Button
				className={styles.button}
				size="small"
				color="primary"
				variant="invert"
				onClick={closeModal}
			>
				確認
			</Button>
		</div>
	</>
);

const ShopCheckoutNotEnoughFreebieDialog = ({ closeModal = () => {} }) => {
	const [, { submitReceiveForm }] = useReceiveForm();

	return (
		<>
			<h3>贈品已送完</h3>
			<p>本次活動贈品已送完，是否繼續結帳？</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
					}}
				>
					取消
				</Button>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						submitReceiveForm();
						closeModal();
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const ShopCheckoutFreeShippingEventDialog = ({ closeModal = () => {} }) => {
	const [, { submitReceiveForm }] = useReceiveForm();

	return (
		<>
			<h3>免運活動已截止</h3>
			<p>本次免運活動已截止，是否繼續結帳？</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
						window.location.reload();
					}}
				>
					取消
				</Button>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						submitReceiveForm();
						closeModal();
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const TransactionLimitDialog = ({ closeModal = () => {}, pushRoute = () => {} }) => {
	const [, { initReceiveForm }] = useReceiveForm();
	const [
		{
			data: { orderMessage },
		},
	] = useDialogModal();

	return (
		<>
			<h3>因應政府『單筆交易限額 20 萬元』的政策</h3>
			<p>
				因應政府「單筆交易限額 20
				萬」政策，為確保您的交易安全，請您協助將商品分開結帳讓單筆金額限縮在 20 萬內，感謝您對 Vespa
				商城的支持
			</p>
			<p className={styles.orderMessage}>{orderMessage}</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
						pushRoute({ pathname: '/shop/cart' }, initReceiveForm);
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const TransactionLimitReminderDialog = ({ closeModal = () => {} }) => {
	const [{ paymentType }, { purchase }] = useOwnershipForm();

	return (
		<>
			<h3>因應政府『單筆交易限額 20 萬元』的政策</h3>
			<p>
				因應政府「單筆交易限額」實名制政策，超過 20
				萬元之訂單付款後，系統將自動傳送受買人身分證正面資料給予金流平台以確保受買人交易安全，確認沒問題後請繼續付款流程。
			</p>
			{paymentType.value === 'LOAN' && (
				<p>若選擇「貸款分期」者，須等貸款撥款後，所選車輛才會予以保留。</p>
			)}
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={classnames(styles.button, styles.cancelButton, styles.widthNotFixed)}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
					}}
				>
					{paymentType.value === 'LOAN' ? '取消申貸' : '取消結帳'}
				</Button>
				<Button
					className={classnames(styles.button, styles.widthNotFixed)}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
						purchase();
					}}
				>
					{paymentType.value === 'LOAN' ? '確認申貸' : '確認結帳'}
				</Button>
			</div>
		</>
	);
};

const ReturnRequest = ({ closeModal = () => {} }) => (
	<div className={classnames(styles.largeDialog, styles.returnRequest)}>
		<div className={styles.header}>
			<ModalClose className={styles.close} onClick={closeModal} />
			<SectionTitle
				className={classnames(styles.modalSectionTitle, styles.returnRequestSectionTitle)}
				title="退貨申請"
				color="default"
			/>
		</div>
		<ReturnRequestEdit close={closeModal} />
	</div>
);

const ReturnSuccessDialog = ({ closeModal = () => {} }) => (
	<>
		<h3>已收到商品退貨申請</h3>
		<p>已收到您的退貨申請，將在 1-3 個工作天內由專人向您收取欲退貨的商品，請密切留意通知，謝謝！</p>
		<div className={styles.hl} />
		<div className={styles.buttonWrapperDialog}>
			<Button
				className={styles.button}
				size="small"
				color="primary"
				variant="invert"
				onClick={closeModal}
			>
				確認
			</Button>
		</div>
	</>
);

const CancelRequestDialog = ({ closeModal = () => {}, pathname = '', pushRoute = () => {} }) => {
	const [, { cancelOrder, cancelAfterSalesOrder, fetchOrderInner }] = useOrder();
	const [reserveAgain, setReserveAgain] = useState(false);
	const isAfterSales = pathname.split('/')[3] === 'afterSales';

	return (
		<div className={styles.largeDialog}>
			<ModalClose className={styles.close} onClick={closeModal} />
			<SectionTitle
				className={styles.modalSectionTitle}
				// eslint-disable-next-line no-nested-ternary
				title={!isAfterSales ? '取消訂單' : reserveAgain ? '再次預約' : '取消預約'}
				color="default"
			/>
			<div className={styles.textWrapper}>
				{reserveAgain ? (
					<>
						<p>您已取消預約，</p>
						<p>是否要再進行預約？</p>
					</>
				) : (
					<>
						<p>您是否{isAfterSales ? '要取消預約' : '確認要取消訂單'}？</p>
						<p>{!isAfterSales && '訂單'}取消後將無法還原。</p>
					</>
				)}
			</div>
			<div className={styles.buttonWrapper}>
				<Button
					className={classnames(styles.button, { [styles.moreWord]: isAfterSales })}
					size="large"
					color="primary"
					variant="outlined"
					onClick={() => {
						closeModal();
						if (reserveAgain) {
							fetchOrderInner();
						}
					}}
				>
					{!isAfterSales && '再想想'}
					{isAfterSales && !reserveAgain && '不取消！謝謝'}
					{isAfterSales && reserveAgain && '不用！謝謝'}
				</Button>
				<Button
					className={styles.button}
					size="large"
					color="primary"
					variant="normal"
					onClick={() => {
						if (reserveAgain) {
							closeModal();
							pushRoute({ pathname: '/after-sales/maintenance' });
							return;
						}

						if (isAfterSales) {
							cancelAfterSalesOrder();
							setReserveAgain(true);
						} else {
							cancelOrder();
							closeModal();
						}
					}}
				>
					{reserveAgain ? '立即預約' : '確定'}
				</Button>
			</div>
		</div>
	);
};

const OrderStatusDialog = ({ closeModal = () => {}, replaceRoute = () => {} }) => {
	const [
		{
			queries: { src },
		},
	] = useRouting();

	const [
		{
			data: { type, orderMessage },
		},
	] = useDialogModal();

	if (orderMessage === 'failure') {
		return (
			<div className={classnames(styles.largeDialog, styles.orderStatusWrapper)}>
				<ModalClose
					className={styles.close}
					onClick={() => {
						// webview
						if (src === 'app') {
							if (window.webkit) {
								window.webkit.messageHandlers.reviewApp.postMessage('hello world');
							} else if (window.AppJS) {
								window.AppJS.reviewApp();
							}
						}

						closeModal();
						replaceRoute({ search: '' });
					}}
				/>
				<OrderFailure />
				<SectionTitle
					className={styles.modalSectionTitle}
					title="付款失敗，訂單未成立"
					color="default"
				/>
				<div className={styles.textWrapper}>
					<p>您並未通過信用卡驗證程序，請重新成立訂單。</p>
					<br />
					<p>如有任何問題，請洽信用卡發卡銀行或 Vespa 台灣官方商城客服專線：02-2795-3696，謝謝！</p>
				</div>
			</div>
		);
	}

	return (
		<div className={classnames(styles.largeDialog, styles.orderStatusWrapper)}>
			<ModalClose
				className={styles.close}
				onClick={() => {
					// webview
					if (src === 'app') {
						if (window.webkit) {
							window.webkit.messageHandlers.reviewApp.postMessage('hello world');
						} else if (window.AppJS) {
							window.AppJS.reviewApp();
						}
					}

					closeModal();
					replaceRoute({ search: '' });
				}}
			/>
			<OrderSuccess />
			<SectionTitle
				className={styles.modalSectionTitle}
				title="付款成功，訂單已成立"
				color="default"
			/>
			{type === 'mall' ? (
				<div className={styles.textWrapper}>
					<p>感謝您的購買。</p>
					<br />
					<p>如有任何問題，歡迎透過我的訂單查詢訂單詳情，謝謝！</p>
				</div>
			) : (
				<div className={styles.textWrapper}>
					<p>感謝您的購買，後續交車事宜將由門市人員與您聯繫。</p>
					<br />
					<p>如有任何問題，歡迎透過我的訂單查詢訂單詳情或洽詢取車門市，謝謝！</p>
				</div>
			)}
		</div>
	);
};

// 訂單成立但是貸款不成立
const LoanOrderStatusDialog = ({ closeModal = () => {}, replaceRoute = () => {} }) => {
	const [
		{
			queries: { src },
		},
	] = useRouting();

	return (
		<div className={classnames(styles.largeDialog, styles.orderStatusWrapper)}>
			<ModalClose
				className={styles.close}
				onClick={() => {
					// webview
					if (src === 'app') {
						if (window.webkit) {
							window.webkit.messageHandlers.reviewApp.postMessage('hello world');
						} else if (window.AppJS) {
							window.AppJS.reviewApp();
						}
					}

					closeModal();
					replaceRoute({ pathname: '/member/my-order' });
				}}
			/>
			<OrderSuccess />
			<SectionTitle className={styles.modalSectionTitle} title="貸款申請中" color="default" />
			<div className={styles.textWrapper}>
				<p>感謝您的購買，後續貸款事宜將由遠信國際融資與您聯繫。</p>
				<br />
				<p>如有任何貸款問題，請洽詢遠信國際融資客服電話：(02)2950-0200</p>
			</div>
		</div>
	);
};

const CancelAddPurchasesDialog = ({ closeModal = () => {} }) => (
	<div className={classnames(styles.largeDialog, styles.returnRequest)}>
		<div className={styles.header}>
			<ModalClose className={styles.close} onClick={closeModal} />
			<SectionTitle
				className={classnames(styles.modalSectionTitle, styles.returnRequestSectionTitle)}
				title="取消加購申請"
				color="default"
			/>
		</div>
		<CancelAddPurchaseEdit close={closeModal} />
	</div>
);

const QrCodeDialog = ({ closeModal = () => {} }) => {
	const [
		{
			data: { url },
		},
	] = useDialogModal();
	return (
		<div className={styles.largeDialog}>
			<ModalClose className={styles.close} onClick={closeModal} />
			<SectionTitle className={styles.modalSectionTitle} title="專屬連結 QRcode" color="default" />
			<div className={styles.qrCodeWrapper}>
				<QRCode value={url} size={200} />
			</div>
		</div>
	);
};

const VesShareEventDialog = ({ closeModal = () => {} }) => {
	const [currentIdx, setCurrentIdx] = useState(0);
	const [isScroll, setIsScroll] = useState(false);
	const [isScrollInBottom, setIsScrollInBottom] = useState(false);
	const [{ events }] = useRental();
	const viewHeight =
		window.innerWidth > 960
			? Math.round(window.innerHeight * 0.65)
			: Math.round(window.innerHeight * 0.6);

	const settings = {
		autoplay: true,
		autoplaySpeed: 7000,
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		dotsClass: styles.dots,
		customPaging: i => (
			<div
				className={classnames(styles.indicatorButton, {
					[styles.active]: i === currentIdx,
				})}
			/>
		),
		appendDots: dots => (
			<div>
				<ul style={{ padding: 0, margin: 0, display: 'flex', listStyle: 'none' }}> {dots} </ul>
			</div>
		),
		beforeChange: (_, idx) => setCurrentIdx(idx),
	};

	const OnScroll = e => {
		setIsScroll(e.target.scrollTop > 0);

		if (Math.round(e.target.scrollTop) + viewHeight >= e.target.scrollHeight) {
			setIsScrollInBottom(false);
		} else {
			setIsScrollInBottom(true);
		}
	};

	useEffect(() => {
		if (document.getElementsByClassName(styles.event)[currentIdx + 1]) {
			/* if can't scroll or scrollToBottom */
			if (document.getElementsByClassName(styles.event)[currentIdx + 1].scrollHeight > viewHeight) {
				setIsScrollInBottom(true);
			} else {
				setIsScrollInBottom(false);
			}
			/* if scrollToTop */
			if (document.getElementsByClassName(styles.event)[currentIdx + 1].scrollTop > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		} else if (document.getElementsByClassName(styles.event)[0].scrollHeight > viewHeight) {
			setIsScrollInBottom(true);
		} else {
			setIsScrollInBottom(false);
		}
	}, [currentIdx]);

	return (
		<div
			className={classnames(styles.vesShareEventDialog, {
				[styles.isScroll]: isScroll,
				[styles.isScrollInBottom]: isScrollInBottom,
			})}
		>
			<ModalClose className={styles.close} onClick={closeModal} />
			{/* eslint-disable react/jsx-props-no-spreading */}
			<Slider className={styles.eventWrapper} {...settings}>
				{events.length > 0 &&
					events.map(e => {
						const {
							title,
							is_popup: isPopup,
							popup_img: popupImg,
							event_type: eventType,
							display_car_type_fee: displayCarTypeFee,
							begin_at: beginAt,
							end_at: endAt,
							display_is_exclude_weekend: displayIsExcludeWeekend,
							rental_discount_times: rentalDiscountTimes,
							popup_content: popupContent,
						} = e;

						return (
							isPopup && (
								<div className={styles.event} key={title} onScroll={OnScroll}>
									<h1>{title}</h1>
									<div
										className={styles.img}
										style={{ backgroundImage: `url(${IMAGE_ENDPOINT}/${popupImg})` }}
									/>
									<div className={styles.textWrapper}>
										<p>
											適用優惠：
											{eventType === 'FIRST_LEASE' ? (
												'首租基本費 0 元優惠'
											) : (
												<span>{displayCarTypeFee}</span>
											)}
										</p>
										<p>
											活動時間：
											{`${dayjs(beginAt).format('YYYY/MM/DD')} - ${dayjs(endAt).format(
												'YYYY/MM/DD',
											)}${displayIsExcludeWeekend ? '（不含週末）' : ''}`}
											{rentalDiscountTimes.length > 0 && ' , '}
											{rentalDiscountTimes.length > 0 &&
												rentalDiscountTimes.map((time, idx) => {
													const _time = `${dayjs(time.begin_at, 'HH:mm').format('HH:mm')} - ${dayjs(
														time.end_at,
														'HH:mm',
													).format('HH:mm')}`;

													return `${_time}${idx !== rentalDiscountTimes.length - 1 ? ' & ' : ''}`;
												})}
										</p>
										<p dangerouslySetInnerHTML={{ __html: popupContent }} />
									</div>
								</div>
							)
						);
					})}
			</Slider>
		</div>
	);
};

const StoreStockDialog = ({ closeModal = () => {} }) => {
	const [, { clearForm }] = useOwnershipForm();
	window.onpopstate = () => {
		closeModal();
		clearForm();
	};
	return (
		<div className={classnames(styles.largeDialog, styles.ownerShipStoreStock)}>
			<div className={styles.header}>
				<ModalClose className={styles.close} onClick={closeModal} />
				<SectionTitle
					className={classnames(styles.modalSectionTitle, styles.returnRequestSectionTitle)}
					title="查看實體門市庫存"
					color="default"
				/>
			</div>
			<div className={styles.ownerShipStoreStock}>
				<OwnerShipStoreStock />
			</div>
		</div>
	);
};

const AuthorizeAfterSalesMemberDialog = ({
	pathname = '',
	closeModal = () => {},
	pushRoute = () => {},
}) => {
	const [, { authorizeAfterSalesUser }] = useAfterSalesUser();
	const [{ search }] = useRouting();
	const path = pathname.split('/')[2];
	const query = qs.parse(search, { ignoreQueryPrefix: true });

	return (
		<>
			<h3>尚未授權</h3>
			<p>
				您已在 Vespa 官方商城註冊過會員資料，請問您是否同意將您註冊過的會員資料使用於 Vespa
				線上售後系統？
			</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={classnames(styles.button, styles.cancel)}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						authorizeAfterSalesUser({ authorizeStatus: 'reject' });
						closeModal();
						pushRoute({ pathname: '/' });
					}}
				>
					不同意
				</Button>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						authorizeAfterSalesUser({ authorizeStatus: 'accept', isGetUserCarModel: true });
						closeModal();
						if (path === 'maintenance' && !query.step) {
							pushRoute({ pathname: '/after-sales/model' });
						}
					}}
				>
					同意
				</Button>
			</div>
		</>
	);
};

const UserCarModelUnauthorizedDialog = ({ closeModal = () => {} }) => {
	const [, { sendUserCarModel, updateUserCarModel }] = useUserCarModelForm();
	const [
		{
			data: { type, id },
		},
	] = useDialogModal();

	return (
		<>
			<h3>車款資料已暫存，可開始使用預約保修服務</h3>
			<p>請注意，目前⾞牌號碼暫時無法⽐對，請回廠時攜帶⾏照供服務廠接待⼈員⽐對，謝謝！</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						switch (type) {
							case 'add':
								sendUserCarModel();
								scrollToOffset(0);
								break;
							case 'update':
								updateUserCarModel(id);
								break;
							default:
								break;
						}

						closeModal();
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const UserCarModelDuplicateDataDialog = ({ closeModal = () => {} }) => (
	<>
		<h3>車款資料已存在</h3>
		<p>您已新增過此車牌號碼，謝謝！</p>
		<div className={styles.hl} />
		<div className={styles.buttonWrapperDialog}>
			<Button
				className={styles.button}
				size="small"
				color="primary"
				variant="invert"
				onClick={closeModal}
			>
				確認
			</Button>
		</div>
	</>
);

const CarModelDuplicateToOtherUserDialog = ({ closeModal = () => {} }) => {
	const [
		{
			data: { isUpdateData },
		},
	] = useDialogModal();

	const onClick = () => {
		closeModal();

		if (!isUpdateData) {
			scrollToOffset(0);
		}
	};

	return (
		<>
			<h3>車款資料已存在</h3>
			<p>該車牌號碼已於其他帳號新增過，請回廠時攜帶⾏照供服務廠接待⼈員⽐對，謝謝！</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={onClick}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const DeleteUserCarModelDialog = ({ closeModal = () => {} }) => {
	const [
		{
			data: { car_type_name: carTypeName, id },
		},
	] = useDialogModal();
	const [, { deleteUserCarModel }] = useUserCarModel();

	return (
		<>
			<h3>刪除車款</h3>
			<p>
				是否刪除 {carTypeName}？
				<br />
				此動作無法還原。
			</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={classnames(styles.button, styles.cancelButton)}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
					}}
				>
					取消
				</Button>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						deleteUserCarModel(id);
						closeModal();
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const WithoutUserCarModelDataDialog = ({
	closeModal = () => {},
	pathname = '',
	pushRoute = () => {},
}) => {
	const path = pathname.split('/')[2];
	let pageTitle;

	if (path.includes('notifications')) {
		pageTitle = '愛車提醒';
	} else if (path.includes('maintenance')) {
		pageTitle = '預約保修';
	}

	return (
		<>
			<h3>尚未綁定我的車款</h3>
			<p>
				您尚未綁定我的車款，請先至我的車款填寫完整車款資料，待驗證通過即可使用{pageTitle}
				服務，謝謝！
			</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
						pushRoute({ pathname: '/after-sales/model' });
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const CarNotificationVerifyDialog = ({ closeModal = () => {}, pushRoute = () => {} }) => {
	const [{ carNotification }, { readInvalidRms }] = useUserCarNotification();
	const unverifiedCar =
		carNotification.length > 0 && carNotification.filter(data => !data.is_validate_rms);

	return (
		<>
			<h3>我的車款尚未驗證完成</h3>
			<p>
				⾞牌號碼&nbsp;
				{unverifiedCar &&
					unverifiedCar.map(
						(data, i) =>
							data.license_plate_number &&
							i === 0 && <span key={data.id}>{data.license_plate_number}</span>,
					)}
				{unverifiedCar &&
					unverifiedCar.map(
						(data, i) =>
							data.license_plate_number &&
							i > 0 && <span key={data.id}>、{data.license_plate_number}</span>,
					)}
				&nbsp;暫時無法⽐對，請回廠時攜帶⾏照供服務廠接待⼈員⽐對，待⽐對通過即可使⽤愛⾞提醒服務，謝謝！
			</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
						if (!carNotification) return;
						carNotification.map(item => !item.is_validate_rms && readInvalidRms(item.id, true));
						pushRoute({ pathname: '/after-sales/maintenance' });
					}}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const IneligibleLicenseDialog = ({ closeModal = () => {}, pushRoute = () => {} }) => {
	return (
		<>
			<h3>租用車款不符合駕照資格</h3>
			<p>
				您目前的駕照資格無法租用所選車款，請先至「會員基本資料」之「身分照與駕照」頁面更新您的駕照，待審核通過後即可租用，謝謝！
			</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={classnames(styles.button, styles.ineligibleButton)}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
						pushRoute({ pathname: '/member/identities' });
					}}
				>
					前往更新駕照
				</Button>
				<Button
					className={classnames(styles.button, styles.cancelButton)}
					size="small"
					color="primary"
					variant="invert"
					onClick={() => {
						closeModal();
					}}
				>
					關閉
				</Button>
			</div>
		</>
	);
};

const OutOfCarTypeStockDialog = ({ closeModal = () => {} }) => {
	return (
		<>
			<h3>訂單未成立</h3>
			<p>您所選擇之門市車輛於該時段已被預約，請重新選擇租車時段或其他門市車輛，謝謝！</p>
			<div className={styles.hl} />
			<div className={styles.buttonWrapperDialog}>
				<Button
					className={styles.button}
					size="small"
					color="primary"
					variant="invert"
					onClick={closeModal}
				>
					確認
				</Button>
			</div>
		</>
	);
};

const SystemMaintenanceDialog = ({ closeModal = () => {} }) => (
	<>
		<h3>網站例行維護作業通知</h3>
		<p>
			親愛的車主，為了提供您更優質的服務，Vespa 官方商城將在 2023/5/20（六）21:00 ~
			2023/5/21（日）09:00
			進行系統設備更新作業，屆時售後服務及線上商城相關頁面更新訊息將暫時無法使用，對您於該期間使用相關功能所造成的不便，敬請見諒。
		</p>
		<div className={styles.hl} />
		<div className={styles.buttonWrapperDialog}>
			<Button
				className={styles.button}
				size="small"
				color="primary"
				variant="invert"
				onClick={() => {
					closeModal();
				}}
			>
				確認
			</Button>
		</div>
	</>
);

const PrivacyPolicyDialog = ({ closeModal = () => {} }) => {
	const [{ privacyPolicy }, { updateTestDriveForm }] = useTestDriveForm();
	const [{ testDrivePrivacy }] = useContract();
	const [{ search }] = useRouting();
	const query = qs.parse(search, { ignoreQueryPrefix: true });
	const isPreview = query.preview_key;

	const [isCheckable, { setTrue: checkable }] = useBoolean({
		defaultBoolean: false,
	});

	const handleScroll = e => {
		if (isCheckable) {
			return;
		}
		if (e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10) {
			checkable();
		}
	};

	return (
		<div className={styles.privacyPolicy}>
			<div className={styles.topBar}>
				<SectionTitle className={styles.sectionTitle} title="隱私權政策" color="default" />
				<CloseIcon
					onClick={() => {
						if (isPreview) return;
						closeModal();
					}}
				/>
			</div>
			<div className={styles.content} onScroll={handleScroll}>
				<div
					className={styles.innerHtml}
					// eslint-disable-next-line
					dangerouslySetInnerHTML={{ __html: testDrivePrivacy }}
				/>
			</div>
			<div className={classnames(styles.topBar, styles.bottomBar)}>
				<Input
					label="我同意依照隱私權政策將本人之個人資料用於 Vespa 行銷相關活動"
					type="checkbox"
					className={classnames(styles.checkbox, {
						[styles.disabled]: !isCheckable && !privacyPolicy.checked,
					})}
					labelClassName={styles.label}
					checked={privacyPolicy.checked}
					disabled={!isCheckable && !privacyPolicy.checked}
					onChange={e => {
						if (isPreview) return;
						if (e.target.checked) {
							closeModal();
						}
						updateTestDriveForm({
							key: 'privacyPolicy',
							data: { checked: e.target.checked },
						});
					}}
				/>
			</div>
		</div>
	);
};

const AppointmentTestDriveFormSuccessDialog = ({ closeModal = () => {} }) => {
	return (
		<div className={classnames(styles.largeDialog, styles.appointmentTestDriveForm)}>
			<SectionTitle className={styles.modalSectionTitle} title="預約賞車成功" color="default" />
			<Button
				className={styles.button}
				size="large"
				color="primary"
				variant="normal"
				onClick={() => {
					closeModal();
					window.scrollTo({
						top: 0,
						behavior: 'smooth',
					});
				}}
			>
				回到車款介紹頁
			</Button>
		</div>
	);
};

const CannotCanceledOrderDialog = ({ closeModal = () => {} }) => (
	<>
		<h3>取消訂單失敗</h3>
		<p>此訂單無法取消，請聯繫門市人員確認</p>
		<div className={styles.hl} />
		<div className={styles.buttonWrapperDialog}>
			<Button
				className={styles.button}
				size="small"
				color="primary"
				variant="invert"
				onClick={closeModal}
			>
				確定
			</Button>
		</div>
	</>
);

const RecaptchaVerifyFailedDialog = ({ closeModal = () => {} }) => (
	<>
		<h3>預約賞車失敗</h3>
		<p>google reCAPTCHA 檢測到異常行為，請關閉可能造成異常的瀏覽器外掛程式後重新進入網站預約</p>
		<div className={styles.hl} />
		<div className={styles.buttonWrapperDialog}>
			<Button
				className={styles.button}
				size="small"
				color="primary"
				variant="invert"
				onClick={closeModal}
			>
				確定
			</Button>
		</div>
	</>
);

const ThirdPartyApiErrorDialog = ({ closeModal = () => {} }) => (
	<>
		<p className={styles.noTitle}>第三方金流服務異常中，請稍後再試</p>
		<div className={styles.hl} />
		<div className={styles.buttonWrapperDialog}>
			<Button
				className={styles.button}
				size="small"
				color="primary"
				variant="invert"
				onClick={closeModal}
			>
				確定
			</Button>
		</div>
	</>
);

const DialogMap = {
	memberIncomplete: MemberIncompleteDialog,
	memberIncompleteInShop: MemberIncompleteInShopDialog,
	status: MemberStatusDialog,
	statusReview: MemberStatusReviewDialog,
	statusReboot: MemberStatusRebootDialog,
	statusReject: MemberStatusRejectDialog,
	ineligibleLicense: IneligibleLicenseDialog,
	outOfCarTypeStock: OutOfCarTypeStockDialog,
	reachedSendingEmailLimit: ReachedSendingEmailLimitDialog,
	updated: MemberUpdatedDialog,
	creditCard: MemberCreditCardDialog,
	deleteCreditCard: CreditCardDeleteDialog,
	rentalSuccess: RentalSuccessDialog,
	rentalFailed: RentalFailedDialog,
	creditCardException: CreditCardExceptionDialog,
	PurchaseAbnormal: PurchaseAbnormalDialog,
	purchaseFailed: PurchaseFailedDialog,
	rentalFailedByStatusReject: RentalFailedByStatusRejectDialog,
	loanSuccess: LoanSuccessDialog,
	rate: Rate,
	addCreditCard: AddCreditCard,
	ie: Ie,
	serverError: ServerError,
	shopFailed: ShopFailedDialog,
	shopCheckOutNotEnoughStock: ShopCheckOutNotEnoughStockDialog,
	shopCartNotEnoughStock: ShopCartNotEnoughStockDialog,
	AdditionalPurchase: AdditionalPurchaseDialog,
	ShopCheckoutNotEnoughFreebie: ShopCheckoutNotEnoughFreebieDialog,
	ShopCheckoutFreeShippingEvent: ShopCheckoutFreeShippingEventDialog,
	TransactionLimit: TransactionLimitDialog,
	TransactionLimitReminder: TransactionLimitReminderDialog,
	returnRequest: ReturnRequest,
	returnSuccess: ReturnSuccessDialog,
	cancelRequest: CancelRequestDialog,
	orderStatus: OrderStatusDialog,
	LoanOrderStatus: LoanOrderStatusDialog,
	cancelAddPurchases: CancelAddPurchasesDialog,
	qrCode: QrCodeDialog,
	vesShareEvent: VesShareEventDialog,
	storeStock: StoreStockDialog,
	authorizeAfterSalesMember: AuthorizeAfterSalesMemberDialog,
	userCarModelUnauthorized: UserCarModelUnauthorizedDialog,
	userCarModelDuplicateData: UserCarModelDuplicateDataDialog,
	carModelDuplicateToOtherUser: CarModelDuplicateToOtherUserDialog,
	deleteUserCarModel: DeleteUserCarModelDialog,
	withoutUserCarModelData: WithoutUserCarModelDataDialog,
	carNotificationVerify: CarNotificationVerifyDialog,
	systemMaintenance: SystemMaintenanceDialog,
	privacyPolicy: PrivacyPolicyDialog,
	appointmentTestDriveFormSuccess: AppointmentTestDriveFormSuccessDialog,
	cancelOrderFailed: CannotCanceledOrderDialog,
	recaptchaVerifyFailed: RecaptchaVerifyFailedDialog,
	thirdPartyApiError: ThirdPartyApiErrorDialog,
};

const EmptyComponent = () => null;

const FormDialog = ({ className, type = '' }) => {
	const [, { closeModal, openModal }] = useDialogModal();
	const [{ pathname }, { pushRoute, replaceRoute }] = useRouting();
	const closeDialog = () => {
		closeModal({ category: 'dialog' });
	};

	const DialogComponent = DialogMap[type] || EmptyComponent;

	return (
		<div
			className={classnames(
				styles.formDialog,
				{
					[styles.largeFormDialog]:
						type === 'rate' || type === 'addCreditCard' || type === 'orderStatus',
					[styles.extraLargeFormDialog]:
						type === 'returnRequest' ||
						type === 'cancelAddPurchases' ||
						type === 'qrCode' ||
						type === 'storeStock',
					[styles.ultraLargeFormDialog]: type === 'vesShareEvent' || type === 'privacyPolicy',
					[styles.wholeHeight]: type === 'storeStock',
				},
				className,
			)}
		>
			<DialogComponent
				pathname={pathname}
				closeModal={closeDialog}
				openModal={openModal}
				pushRoute={pushRoute}
				replaceRoute={replaceRoute}
			/>
		</div>
	);
};

export default FormDialog;
