/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import { isExist } from 'util/helper';
import { useMedia } from 'util/hook/useMedia';
import { useAuthModal } from 'models/modal';

import Modal from 'components/molecules/Modal';
import FormAuth from 'components/molecules/FormAuth';

import CloseIcon from 'images/icon/close.inline.svg';
import Background from 'images/auth/background.png';

import styles from './index.css';

const ModalAuth = ({ className }) => {
	const media = useMedia();
	const [{ type }, { openModal, closeModal }] = useAuthModal();
	const [cachType, updateCachType] = useState(type);

	const openAuthModal = newType => {
		openModal({ category: 'auth', type: newType });
	};

	const closeAuthModal = () => {
		closeModal({ category: 'auth' });
	};

	useEffect(() => {
		if (type !== '') {
			updateCachType(type);
		}
	}, [type]);

	return (
		<Modal
			className={classnames(styles.modalAuth, className, {
				[styles.modalTemp]: cachType === 'tempLogin',
				[styles.withBg]:
					(cachType === 'login' || cachType === 'signupLanding') && media !== 'phone',
			})}
			active={isExist(type)}
		>
			<div className={styles.formWrapper}>
				<button className={styles.closeButton} type="button" onClick={closeAuthModal}>
					<CloseIcon />
				</button>

				<FormAuth type={cachType} onClose={closeAuthModal} onChangeModal={openAuthModal} />
			</div>
			{cachType !== 'tempLogin' && media !== 'phone' && (
				<div
					className={styles.background}
					style={{
						backgroundImage: `url(${Background})`,
					}}
				/>
			)}
		</Modal>
	);
};

export default ModalAuth;
