import { createAction, handleActions } from 'redux-actions';

import { wrapAuthFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

export const fetchNotification = createAction('FETCH_NOTIFICATION', () => async () => {
	const { data, status, message } = await wrapAuthFetch('me/notifications');

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	return data.data;
});

export const readNotification = createAction('READ_NOTIFICATION', () => async () => {
	const { status, message } = await wrapAuthFetch('me/notifications', {
		method: 'PUT',
	});

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	return null;
});

export const deleteNotification = createAction('DELETE_NOTIFICATION', id => async () => {
	const { status, message } = await wrapAuthFetch(`me/notifications/${id}`, {
		method: 'DELETE',
	});

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	return id;
});

const reducer = {
	notification: handleActions(
		{
			FETCH_NOTIFICATION_FULFILLED: (state, action) => ({
				...state,

				notification: action.payload,
			}),

			DELETE_NOTIFICATION_FULFILLED: (state, action) => ({
				...state,

				notification: state.notification.filter(n => n.id !== action.payload),
			}),
		},
		{
			notification: [],
		},
	),
};

const selectNotification = state => state.notification;

export const useNotification = () =>
	useRedux(selectNotification, {
		fetchNotification,
		readNotification,
		deleteNotification,
	});

export default { reducer };
