/* eslint-disable indent */
import dayjs from 'dayjs';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import qs from 'qs';
import dataURLtoBlob from 'blueimp-canvas-to-blob';
import lodash from 'lodash';

import { wrapFetch, wrapAuthFetch, wrapSSOFetchFormData } from 'util/api';
import { useRedux } from 'util/hook/redux';
import {
	examRentalFormData,
	examInvoiceData,
	examRentalPickupFormData,
	examRentalReturnFormData,
} from 'util/exam';
import { clearVesShareFormData, clearPickupFormData, clearReturnFormData } from 'util/clear';

import { fetchNotification } from 'models/notification';
import { openModal, closeModal } from 'models/modal';
import { pushRoute } from 'models/routing';
import { checkUserUsageRight } from 'models/user';

const validateForm = createAction('VALIDATE_RENTAL_FORM', (type, key, valid, error) => ({
	type,
	key,
	valid,
	error,
}));

const clearFormError = createAction('CLEAR_RENTAL_FORM_ERROR', type => (_, getState) => {
	const {
		rental: { vesShareForm, pickupForm, returnForm },
	} = getState();
	let clearData;

	if (type === 'vesShareForm') {
		clearData = clearVesShareFormData(vesShareForm);
	} else if (type === 'pickupForm') {
		clearData = clearPickupFormData(pickupForm);
	} else if (type === 'returnForm') {
		clearData = clearReturnFormData(returnForm);
	}

	return {
		type,
		data: clearData,
	};
});

const clearVesShareForm = createAction('CLEAR_RENTAL_FORM', type => ({
	type,
}));

export const clearTargetShop = createAction('CLEAR_TARGET_SHOP', type => ({
	type,
}));

export const initVesShareForm = createAction('INIT_VES_SHARE_FORM', () => dispatch => {
	dispatch(clearFormError('vesShareForm'));
	dispatch(clearVesShareForm('rentalForm'));
	dispatch(clearVesShareForm('invoiceForm'));
});

export const updateForm = createAction('UPDATE_RENTAL_FORM', ({ type, key, data }) => dispatch => {
	if (key === 'invoiceCarrierType') {
		dispatch(clearVesShareForm('invoiceForm'));
	}

	return {
		type,
		key,
		data,
	};
});

const fetchData = createAction('FETCH_DATA', ({ key, api, query = {} }) => async (_, getState) => {
	const {
		routing: { search },
	} = getState();
	const { preview_key: previewKey } = qs.parse(search, { ignoreQueryPrefix: true });
	const headers = previewKey ? { 'X-VESPA-PREVIEW-KEY': previewKey } : {};
	const { status, message, data } = await wrapAuthFetch(
		api,
		{
			method: 'GET',
			headers,
		},
		query,
	);

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	return { key, data: data.data };
});

export const fetchEvents = () => dispatch => {
	dispatch(fetchData({ key: 'events', api: 'rentalDiscountEvents/popup' }));
};

export const fetchAvailableEvents = () => (dispatch, getState) => {
	const {
		rental: {
			vesShareForm: { rentalCarTypesId, date, rentingTime, returningTime },
		},
	} = getState();
	const query = {
		rental_car_type_id: rentalCarTypesId.value,
		date: dayjs(`${date.year}/${date.month}/${date.date}`, 'YYYY/M/D').format('YYYY/MM/DD'),
		renting_time: rentingTime.value,
		renting_hours: dayjs(returningTime.value, 'HH:mm').diff(
			dayjs(rentingTime.value, 'HH:mm'),
			'hours',
		),
	};
	dispatch(fetchData({ key: 'availableEvents', api: 'rentalDiscountEvents/available', query }));
};

export const fetchShops = () => dispatch => {
	dispatch(fetchData({ key: 'shops', api: 'shops' }));
};

export const getDistance = createAction('SET_DISTANCE', origin => async (_, getState) => {
	const {
		rental: {
			targetShop: { latitude, longitude },
		},
	} = getState();

	const destination = `${latitude},${longitude}`;

	const { rows } = await wrapFetch(`distances?origins=${origin}&destinations=${destination}`, {
		method: 'GET',
	});

	return { data: rows[0].elements[0].distance.text };
});

export const fetchTargetShop = () => async (dispatch, getState) => {
	let origin;
	const {
		rental: {
			vesShareForm: { shopId },
		},
	} = getState();
	const getOrigin = async () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(position => {
				origin = `${position.coords.latitude},${position.coords.longitude}`;
			});
		}
	};
	await getOrigin();
	await dispatch(fetchData({ key: 'targetShop', api: `shops/${shopId.value}` }));
	dispatch(getDistance(origin));
};

export const fetchCarType = createAction(
	'FETCH_RENTAL_CAR_TYPES',
	() => async (dispatch, getState) => {
		const {
			rental: {
				vesShareForm: { shopId, date, rentingTime, returningTime },
			},
		} = getState();

		if (!shopId.value) {
			return [];
		}

		const { status, message, data } = await wrapAuthFetch(
			'rentalCarTypes',
			{
				method: 'GET',
			},
			{
				shop_id: shopId.value,
				...(date.year && {
					date: dayjs({
						y: date.year,
						M: date.month - 1,
						d: date.date,
					}).format('YYYY/MM/DD'),
				}),
				...(rentingTime.value && {
					renting_time: rentingTime.value,
				}),
				...(returningTime.value && {
					returning_time: returningTime.value,
				}),
			},
		);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return data.data || [];
	},
);

export const fetchTime = (rentTime = null) => (dispatch, getState) => {
	const {
		rental: {
			vesShareForm: { shopId, rentalCarTypesId, date },
		},
	} = getState();

	if (shopId.value && rentalCarTypesId.value && date.date !== '') {
		if (!rentTime) {
			const api = `rentalTimes?shop_id=${shopId.value}&rental_car_type_id=${
				rentalCarTypesId.value
			}&date=${dayjs(`${date.year}/${date.month}/${date.date}`, 'YYYY/M/D').format('YYYY/MM/DD')}`;

			dispatch(
				fetchData({
					key: 'rentTimes',
					api,
				}),
			);
		} else {
			const api = `rentalTimes?shop_id=${shopId.value}&rental_car_type_id=${
				rentalCarTypesId.value
			}&date=${dayjs(`${date.year}/${date.month}/${date.date}`, 'YYYY/M/D').format(
				'YYYY/MM/DD',
			)}&rent_time=${rentTime}`;

			dispatch(
				fetchData({
					key: 'returnTimes',
					api,
				}),
			);
		}
	}
};

export const fetchCarPlates = createAction(
	'FETCH_RENTAL_CAR_PLATES',
	() => async (dispatch, getState) => {
		const {
			rental: {
				rentalCarPlates,
				vesShareForm: { shopId, rentalCarTypesId, date },
			},
		} = getState();

		if (!shopId.value) {
			return [];
		}

		const { status, message, data } = await wrapAuthFetch(
			'rentalCarPlates',
			{
				method: 'GET',
			},
			{
				shop_id: shopId.value,
				...(rentalCarTypesId.value && { rental_car_type_id: rentalCarTypesId.value }),
				...(date.year && {
					date: dayjs({
						y: date.year,
						M: date.month - 1,
						d: date.date,
					}).format('YYYY/MM/DD'),
				}),
			},
		);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		if (rentalCarPlates.rantal_available) {
			await dispatch(fetchTime());
		}

		return data.data;
	},
);

export const fetchDate = createAction('FETCH_RENTAL_DATE', () => async (dispatch, getState) => {
	const {
		rental: {
			vesShareForm: { shopId, rentalCarTypesId },
		},
	} = getState();

	const { status, message, data } = await wrapAuthFetch(
		'rentalDates',
		{
			method: 'GET',
		},
		{
			shop_id: shopId.value,
			...(rentalCarTypesId.value && { rental_car_type_id: rentalCarTypesId.value }),
		},
	);

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	return data.data;
});

export const fetchRentalPrice = createAction(
	'FETCH_RENTAL_PRICE',
	() => async (dispatch, getState) => {
		const {
			rental: {
				vesShareForm: { rentalCarTypesId, rentingTime, returningTime, coupon, date, discountEvent },
			},
		} = getState();
		const query = () => {
			let queryValue = {
				rental_car_type_id: rentalCarTypesId.value,
				date: dayjs(`${date.year}/${date.month}/${date.date}`, 'YYYY/M/D').format('YYYY/MM/DD'),
				renting_time: rentingTime.value,
				renting_hours: dayjs(returningTime.value, 'HH:mm').diff(
					dayjs(rentingTime.value, 'HH:mm'),
					'hours',
				),
			};
			if (coupon.checked && coupon.value) {
				queryValue = { ...queryValue, coupon_id: coupon.value };
			}
			if (discountEvent.checked && discountEvent.value) {
				queryValue = { ...queryValue, rental_discount_event_id: discountEvent.value };
			}
			return queryValue;
		};
		const { status, message, data } = await wrapAuthFetch(
			'rentals/calc',
			{
				method: 'GET',
			},
			query(),
		);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return data.data;
	},
);

export const checkVesShareRentalForm = createAction(
	'CHECK_VES_SHARE_RENTAL_FORM',
	() => async (dispatch, getState) => {
		await dispatch(clearFormError('vesShareForm'));
		const {
			rental: { vesShareForm },
		} = getState();

		const checkData = examRentalFormData(vesShareForm);
		if (!checkData.value) {
			checkData.notValid.map(key =>
				dispatch(validateForm('vesShareForm', key, false, '此欄位不得空白')),
			);
			throw new Error('failed');
		}

		dispatch(checkUserUsageRight()).then(({ value: { success } }) => {
			if (success) {
				dispatch(
					pushRoute({ search: qs.stringify({ step: 'checkout' }, { addQueryPrefix: true }) }),
				);
				dispatch(closeModal({ category: 'auth' }));
			}
		});
	},
);

export const submitVesShareRental = createAction(
	'SUBMIT_VES_SHARE_RENTAL',
	() => async (dispatch, getState) => {
		await dispatch(clearFormError('vesShareForm'));
		const {
			rental: { vesShareForm },
			cities: { cities },
		} = getState();
		let validStatus = 0;
		// 手機條碼
		if (
			vesShareForm.invoiceCarrierType.value === 'MOBILE' &&
			!/^\/[-+.a-zA-Z0-9]{7}$/.test(vesShareForm.invoiceCarrierNum.value)
		) {
			dispatch(validateForm('vesShareForm', 'invoiceCarrierNum', false, '手機條碼格式不正確'));
			validStatus = 400;
		}

		// 發票捐贈
		if (
			vesShareForm.invoiceCarrierType.value === 'DONATE' &&
			!/^[0-9]{3,7}$/.test(vesShareForm.loveCode.value)
		) {
			dispatch(validateForm('vesShareForm', 'loveCode', false, '愛心碼格式不正確'));
			validStatus = 400;
		}

		// 自然人憑證
		if (
			vesShareForm.invoiceCarrierType.value === 'MOICA' &&
			!/^[A-Z]{2}[0-9]{14}$/.test(vesShareForm.invoiceCarrierNum.value)
		) {
			dispatch(validateForm('vesShareForm', 'invoiceCarrierNum', false, '自然人憑證格式不正確'));
			validStatus = 400;
		}

		// 公司發票
		if (
			vesShareForm.invoiceCarrierType.value === 'BUSINESS' &&
			!/^[0-9]{8}$/.test(vesShareForm.taxNum.value)
		) {
			dispatch(validateForm('vesShareForm', 'taxNum', false, '統一編號格式不正確'));
			validStatus = 400;
		}

		const checkData = examInvoiceData(vesShareForm);
		if (!checkData.value) {
			checkData.notValid.forEach(key => {
				if (key === 'contract') {
					dispatch(validateForm('vesShareForm', 'contract', false, '尚未審閱合約'));
					return;
				}
				dispatch(validateForm('vesShareForm', key, false, '此欄位不得空白'));
			});
			validStatus = 400;
		}

		if (validStatus === 400) {
			throw new Error('failed');
		}

		// API: POST rentals api
		const county = cities.filter(c => c.id === vesShareForm.invoiceAddress.county)[0];
		const district =
			county && county.districts.filter(d => d.id === vesShareForm.invoiceAddress.district)[0];

		const body = {
			shop_id: vesShareForm.shopId.value,
			rental_car_type_id: vesShareForm.rentalCarTypesId.value,
			date: dayjs(
				`${vesShareForm.date.year}/${vesShareForm.date.month}/${vesShareForm.date.date}`,
				'YYYY/M/D',
			).format('YYYY/MM/DD'),
			renting_time: vesShareForm.rentingTime.value,
			returning_time: vesShareForm.returningTime.value,
			...(vesShareForm.coupon.checked &&
				vesShareForm.coupon.value !== '' && {
					coupon_id: vesShareForm.coupon.value,
				}),
			...(vesShareForm.discountEvent.checked &&
				vesShareForm.discountEvent.value !== '' && {
					rental_discount_event_id: vesShareForm.discountEvent.value,
				}),
			...(vesShareForm.invoiceCarrierType.value === 'DEFAULT' && {
				invoice_carrier_type: vesShareForm.invoiceCarrierType.value,
				invoice_address: `${county.name}${district.name}${vesShareForm.invoiceAddress.other}`,
			}),
			...((vesShareForm.invoiceCarrierType.value === 'MOBILE' ||
				vesShareForm.invoiceCarrierType.value === 'MOICA') && {
				invoice_carrier_type: vesShareForm.invoiceCarrierType.value,
				invoice_carrier_num: vesShareForm.invoiceCarrierNum.value,
			}),
			...(vesShareForm.invoiceCarrierType.value === 'DONATE' && {
				invoice_carrier_type: vesShareForm.invoiceCarrierType.value,
				love_code: vesShareForm.loveCode.value,
			}),
			...(vesShareForm.invoiceCarrierType.value === 'BUSINESS' && {
				invoice_carrier_type: vesShareForm.invoiceCarrierType.value,
				tax_num: vesShareForm.taxNum.value,
				company_name: vesShareForm.companyName.value,
			}),
		};

		const { status, message, error_code: errorCode, extra } = await wrapAuthFetch('rentals', {
			method: 'POST',
			body: JSON.stringify(body),
		});

		if (status !== 200 && status !== 201) {
			if (message === 'the user was invalid') {
				dispatch(openModal({ category: 'dialog', type: 'rentalFailedByStatusReject' }));
			} else if (errorCode === 'newebpay_error') {
				dispatch(
					openModal({
						category: 'dialog',
						type: 'creditCardException',
						data: { orderMessage: extra.data },
					}),
				);
			} else if (errorCode === 'third_party_api_unavailable') {
				dispatch(openModal({ category: 'dialog', type: 'thirdPartyApiError' }));
			} else {
				dispatch(openModal({ category: 'dialog', type: 'rentalFailed' }));
			}

			throw new Error(message);
		}

		dispatch(initVesShareForm());
		dispatch(fetchNotification());
		dispatch(openModal({ category: 'dialog', type: 'rentalSuccess' }));
	},
);

export const sendRentalPickup = createAction(
	'SEND_RENTAL_PICKUP',
	() => async (dispatch, getState) => {
		dispatch(clearFormError('pickupForm'));
		const {
			routing: { pathname },
			rental: { pickupForm },
		} = getState();

		const rentalId = pathname.split('/')[2];

		const checkData = examRentalPickupFormData(pickupForm);
		if (!checkData.value || !pickupForm.signed.value) {
			checkData.notValid.map(key =>
				dispatch(validateForm('pickupForm', key, false, '此欄位不得空白')),
			);
			if (!pickupForm.signed.value) {
				dispatch(validateForm('pickupForm', 'signed', false, '必填，請親自簽名'));
			}
			return;
		}

		const { year, month, day, hour, min } = pickupForm.time;

		const formdata = new FormData();
		formdata.append('_method', 'PUT');
		formdata.append('license_plate', pickupForm.licensePlate.value);
		formdata.append(
			'picked_up_at',
			dayjs({ y: year, M: month - 1, d: day, h: hour, m: min }).format('YYYY-MM-DD HH:mm:ss'),
		);
		formdata.append('pick_up_fuel_capacity', pickupForm.fuelCapacity.value);
		formdata.append('pick_up_mileage', pickupForm.mileage.value);
		formdata.append('pick_up_description', pickupForm.description.value);
		formdata.append('picked_up_user_sign', dataURLtoBlob(pickupForm.signature.value));
		for (let i = 0; i < pickupForm.photos.value.length; i += 1) {
			formdata.append('photos[]', pickupForm.photos.value[i]);
		}

		const { status, message } = await wrapSSOFetchFormData(`rentalPayment/${rentalId}/pickup`, {
			method: 'POST',
			body: formdata,
		});

		if (status !== 200 && status !== 201) {
			let msg = '';
			if (message === 'the rental was expired.') {
				msg = '此訂單已完成取車';
			} else {
				msg = message;
			}

			dispatch(
				openModal({
					category: 'toast',
					type: 'failed',
					data: { message: msg },
				}),
			);
			throw new Error(message);
		}

		dispatch(
			openModal({
				category: 'toast',
				type: 'success',
				data: { message: '已送出車輛檢查表' },
			}),
		);
	},
);

export const sendRentalReturn = createAction(
	'SEND_RENTAL_RETURN',
	() => async (dispatch, getState) => {
		dispatch(clearFormError('returnForm'));
		const {
			routing: { pathname },
			rental: { returnForm },
		} = getState();

		const rentalId = pathname.split('/')[2];

		const checkData = examRentalReturnFormData(returnForm);
		if (!checkData.value) {
			checkData.notValid.map(key =>
				dispatch(validateForm('returnForm', key, false, '此欄位不得空白')),
			);
			return;
		}

		const { year, month, day, hour, min } = returnForm.time;

		const formdata = new FormData();
		formdata.append('_method', 'PUT');
		formdata.append(
			'returning_at',
			dayjs({ y: year, M: month - 1, d: day, h: hour, m: min }).format('YYYY-MM-DD HH:mm:ss'),
		);
		formdata.append('returning_fuel_capacity', returnForm.fuelCapacity.value);
		formdata.append('returning_mileage', returnForm.mileage.value);
		formdata.append('returning_description', returnForm.description.value);
		formdata.append('returning_user_sign', dataURLtoBlob(returnForm.signature.value));
		for (let i = 0; i < returnForm.photos.value.length; i += 1) {
			formdata.append('photos[]', returnForm.photos.value[i]);
		}

		const { status, message } = await wrapSSOFetchFormData(`rentalPayment/${rentalId}/returning`, {
			method: 'POST',
			body: formdata,
		});

		if (status === 503) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'failed',
					data: { message: '第三方金流服務異常中，請稍後再試' },
				}),
			);
			throw new Error(message);
		}

		if (status !== 200 && status !== 201) {
			let msg = '';
			if (message === '此訂單已完成取車') {
				msg = '此訂單已完成還車';
			} else {
				msg = message;
			}

			dispatch(
				openModal({
					category: 'toast',
					type: 'failed',
					data: { message: msg },
				}),
			);
			throw new Error(message);
		}

		dispatch(
			openModal({
				category: 'toast',
				type: 'success',
				data: { message: '已送出車輛檢查表' },
			}),
		);
	},
);

export const rate = createAction('RATE', (id, score, scoreUrl = '') => async () => {
	if (scoreUrl !== '') {
		const { signature } = qs.parse(scoreUrl.split('?')[1], { ignoreQueryPrefix: true });
		const { status, message } = await wrapFetch(
			`rentals/${id}/score-no-login`,
			{
				method: 'PUT',
				body: JSON.stringify({ score }),
			},
			{ signature },
		);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}
	} else {
		const { status, message } = await wrapAuthFetch(`rentals/${id}/score`, {
			method: 'PUT',
			body: JSON.stringify({ score }),
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}
	}
});

export const getReturnInfo = createAction('GET_RETURN_INFO', () => async (_, getState) => {
	const {
		routing: { pathname },
	} = getState();

	const rentalId = pathname.split('/')[2];
	const { status, message, data } = await wrapSSOFetchFormData(`rentalPayment/${rentalId}/info`, {
		method: 'GET',
	});

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	return { data: { ...data.data } };
});

export const defaultTargetShopData = {
	address: '',
	city: { id: 0, name: '' },
	district: { id: 0, name: '' },
	id: 0,
	name: '',
	openHours: '',
	phone: '',
	rentalCarTypes: [],
	distance: '',
	latitude: '',
	longitude: '',
};

export const defaultCarPlates = {
	rantal_available: false,
};

const defaultVesShareRentalFormData = {
	shopId: { value: '', valid: true, error: '' },
	rentalCarTypesId: { value: '', valid: true, error: '' },
	date: {
		year: '',
		month: '',
		date: '',
		valid: true,
		error: '',
	},
	rentingTime: { value: '', valid: true, error: '' },
	returningTime: { value: '', valid: true, error: '' },
};

const defaultVesShareInvoiceFormData = {
	invoiceCarrierType: { value: '', valid: true, error: '' },
	invoiceAddress: { county: '', district: '', other: '', valid: true, error: '' },
	invoiceCarrierNum: { value: '', valid: true, error: '' },
	loveCode: { value: '', valid: true, error: '' },
	taxNum: { value: '', valid: true, error: '' },
	companyName: { value: '', valid: true, error: '' },
	contract: { value: false, valid: true, error: '' },
	discountEvent: { checked: false, value: '', valid: true, error: '' },
	coupon: { checked: false, value: '', valid: true, error: '' },
	price: { sum: null, discount: null, amount: 0, use_coupon: false, coupons: [] },
};

export const defaultVesShareFormData = {
	couponList: [],
	...defaultVesShareRentalFormData,
	...defaultVesShareInvoiceFormData,
	loading: false,
	error: '',
};

export const defaultPickupFormData = {
	licensePlate: { value: '', valid: true, error: '' },
	time: {
		year: dayjs().year(),
		month: dayjs().month() + 1,
		day: dayjs().date(),
		hour: dayjs().hour(),
		min: dayjs().minute(),
		valid: true,
		error: '',
	},
	fuelCapacity: { value: 'FULL', valid: true, error: '' },
	mileage: { value: '', valid: true, error: '' },
	description: { value: '', valid: true, error: '' },
	signed: { value: false, valid: true, error: '' },
	signature: { value: '', valid: true, error: '' },
	photos: { value: [], valid: true, error: '', loading: false },
	loading: false,
};

export const defaultReturnFormData = {
	licensePlate: { value: '', valid: true, error: '' },
	time: {
		year: dayjs().year(),
		month: dayjs().month() + 1,
		day: dayjs().date(),
		hour: dayjs().hour(),
		min: dayjs().minute(),
		valid: true,
		error: '',
	},
	fuelCapacity: { value: '', valid: true, error: '' },
	mileage: { value: '', valid: true, error: '' },
	description: { value: '', valid: true, error: '' },
	signed: { value: false, valid: true, error: '' },
	signature: { value: '', valid: true, error: '' },
	photos: { value: [], valid: true, error: '', loading: false },
	loading: false,
};

export const defaultReturnInfo = {
	id: '',
	planned_returning_time: '',
	hour_fee: '',
};

export const defaultAvailableEventsData = {
	is_priority_use: false,
	rental_discount_event: null,
};

const reducer = {
	rental: handleActions(
		{
			INIT_VES_SHARE_FORM: state => ({
				...state,
				vesShareForm: {
					...state.vesShareForm,
					loading: false,
					error: '',
				},
				availableEvents: defaultAvailableEventsData,
			}),

			FETCH_DATA_FULFILLED: (state, action) => ({
				...state,

				[action.payload.key]: action.payload.data,
			}),

			FETCH_RENTAL_DATE_FULFILLED: (state, action) => ({
				...state,

				dates: action.payload,
			}),

			FETCH_RENTAL_CAR_TYPES_FULFILLED: (state, action) => ({
				...state,

				rentalCarTypes: action.payload,
			}),

			FETCH_RENTAL_CAR_PLATES_FULFILLED: (state, action) => ({
				...state,

				rentalCarPlates: action.payload,
			}),

			UPDATE_RENTAL_FORM: (state, action) => ({
				...state,

				[action.payload.type]: {
					...state[action.payload.type],
					[action.payload.key]: {
						...state[action.payload.type][action.payload.key],
						...action.payload.data,
					},
				},

				availableEvents:
					action.payload.key === 'shopId' ||
					action.payload.key === 'rentalCarTypesId' ||
					action.payload.key === 'date' ||
					action.payload.key === 'rentingTime' ||
					action.payload.key === 'returningTime'
						? defaultAvailableEventsData
						: state.availableEvents,
			}),

			VALIDATE_RENTAL_FORM: (state, action) => ({
				...state,

				[action.payload.type]: {
					...state[action.payload.type],
					[action.payload.key]: {
						...state[action.payload.type][action.payload.key],
						valid: action.payload.valid,
						error: action.payload.error,
					},
				},
			}),

			CLEAR_RENTAL_FORM_ERROR: (state, action) => ({
				...state,

				[action.payload.type]: action.payload.data,
			}),

			CLEAR_RENTAL_FORM: (state, action) => {
				if (action.payload.type === 'rentalForm') {
					return {
						...state,
						availableEvents: defaultAvailableEventsData,
						vesShareForm: {
							...state.vesShareForm,
							...defaultVesShareRentalFormData,
						},
					};
				}
				if (action.payload.type === 'invoiceForm') {
					return {
						...state,

						vesShareForm: {
							...state.vesShareForm,
							...lodash.omit(defaultVesShareInvoiceFormData, ['discountEvent', 'coupon', 'price']),
						},
						availableEvents: defaultAvailableEventsData,
					};
				}
				return state;
			},

			SET_DISTANCE_FULFILLED: (state, action) => ({
				...state,

				targetShop: {
					...state.targetShop,
					distance: action.payload.data,
				},
			}),

			FETCH_RENTAL_PRICE_FULFILLED: (state, action) => ({
				...state,

				vesShareForm: {
					...state.vesShareForm,

					price: lodash.omit(action.payload, ['coupons']),
					couponList: action.payload.coupons,
				},
			}),

			CHECK_VES_SHARE_RENTAL_FORM_PENDING: state => ({
				...state,
				vesShareForm: {
					...state.vesShareForm,
					loading: true,
					error: '',
				},
			}),

			CHECK_VES_SHARE_RENTAL_FORM_FULFILLED: state => ({
				...state,
				vesShareForm: {
					...state.vesShareForm,
					loading: false,
					error: '',
				},
			}),

			CHECK_VES_SHARE_RENTAL_FORM_REJECTED: (state, action) => ({
				...state,
				vesShareForm: {
					...state.vesShareForm,
					loading: false,
					error: action.payload.message,
				},
			}),

			SUBMIT_VES_SHARE_RENTAL_PENDING: state => ({
				...state,
				vesShareForm: {
					...state.vesShareForm,
					loading: true,
					error: '',
				},
			}),

			SUBMIT_VES_SHARE_RENTAL_FULFILLED: state => ({
				...state,

				vesShareForm: {
					...state.vesShareForm,
					loading: false,
				},
			}),

			SUBMIT_VES_SHARE_RENTAL_REJECTED: (state, action) => ({
				...state,

				vesShareForm: {
					...state.vesShareForm,
					loading: false,
					error: action.payload.message,
				},
			}),

			GET_RETURN_INFO_FULFILLED: (state, action) => ({
				...state,

				returnInfo: action.payload.data,
			}),

			CLEAR_TARGET_SHOP: state => ({
				...state,
				targetShop: defaultTargetShopData,
			}),
		},
		{
			vesShareForm: defaultVesShareFormData,
			pickupForm: defaultPickupFormData,
			returnForm: defaultReturnFormData,
			events: [],
			availableEvents: defaultAvailableEventsData,
			shops: [],
			targetShop: defaultTargetShopData,
			rentalCarTypes: [],
			rentalCarPlates: defaultCarPlates,
			dates: [],
			rentTimes: [],
			returnTimes: [],
			returnInfo: defaultReturnInfo,
		},
	),
};

const selectRental = state => state.rental;

export const useRental = () =>
	useRedux(selectRental, {
		initVesShareForm,
		fetchEvents,
		fetchAvailableEvents,
		fetchShops,
		fetchTargetShop,
		fetchCarType,
		fetchCarPlates,
		fetchDate,
		fetchTime,
		getDistance,
		rate,
	});

const selectVesShareForm = state => state.rental.vesShareForm;

export const useVesShareForm = () =>
	useRedux(selectVesShareForm, {
		updateForm,
		validateForm,
		fetchRentalPrice,
		checkVesShareRentalForm,
		submitVesShareRental,
		clearVesShareForm,
	});

const selectPickupForm = state => state.rental.pickupForm;

export const usePickupForm = () =>
	useRedux(selectPickupForm, {
		updateForm,
		sendRentalPickup,
	});

const selectReturnForm = state => state.rental.returnForm;

export const useReturnForm = () =>
	useRedux(selectReturnForm, {
		updateForm,
		sendRentalReturn,
	});

export const useClearFormError = () =>
	useRedux(
		{},
		{
			clearFormError,
		},
	);

const selectReturnInfo = createSelector(
	state => state.rental.returnInfo,
	data => data,
);

export const useReturnInfo = () => useRedux(selectReturnInfo, { getReturnInfo });

export default { reducer };
