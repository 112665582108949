import { createAction, handleActions } from 'redux-actions';

import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { clearTestDriveFormData } from 'util/clear';
import { validateMobile, validateEmail } from 'util/regex';
import { examTestDriveFormData } from 'util/exam';

import { openModal } from 'models/modal';

export const updateTestDriveForm = createAction('UPDATE_TEST_DRIVE_FORM', ({ key, data }) => ({
	key,
	data,
}));

const validateForm = createAction('VALIDATE_TEST_DRIVE_FORM', (type, key, valid, error) => ({
	type,
	key,
	valid,
	error,
}));

export const clearTestDriveForm = createAction('CLEAR_TEST_DRIVE_FORM');

const clearFormError = createAction('CLEAR_TEST_DRIVE_FORM_ERROR', type => (_, getState) => {
	const {
		testDrive: { testDriveForm },
	} = getState();

	return {
		type,
		data: clearTestDriveFormData(testDriveForm),
	};
});

export const initTestDriveForm = createAction('INIT_TEST_DRIVE_FORM');

export const submitTestDriveForm = createAction(
	'SUBMIT_TEST_DRIVE_FORM',
	() => async (dispatch, getState) => {
		const {
			testDrive: { testDriveForm },
		} = getState();

		const { status, message } = await wrapFetch('carTours', {
			method: 'POST',
			body: JSON.stringify({
				car_category_id: testDriveForm.carType.id,
				shop_id: testDriveForm.shopId.value,
				contact_morning: testDriveForm.contactMorning.selected ? 1 : 0,
				contact_afternoon: testDriveForm.contactAfternoon.selected ? 1 : 0,
				contact_night: testDriveForm.contactNight.selected ? 1 : 0,
				name: testDriveForm.name.value,
				gender: testDriveForm.gender.value,
				email: testDriveForm.email.value,
				mobile: testDriveForm.mobile.value,
			}),
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		dispatch(openModal({ category: 'dialog', type: 'appointmentTestDriveFormSuccess' }));
		dispatch(clearTestDriveForm());
	},
);

export const recaptchaVerify = createAction(
	'RECAPTCHA_VERIFY',
	token => async (dispatch, getState) => {
		const {
			testDrive: { testDriveForm },
		} = getState();
		const checkData = examTestDriveFormData(testDriveForm);

		dispatch(clearFormError('testDriveForm'));

		if (!checkData.value) {
			checkData.notValid.map(key =>
				dispatch(validateForm('testDriveForm', key, false, '此欄位不得空白')),
			);
		}

		if (
			!testDriveForm.contactMorning.selected &&
			!testDriveForm.contactAfternoon.selected &&
			!testDriveForm.contactNight.selected
		) {
			dispatch(
				updateTestDriveForm({
					key: 'contactMorning',
					data: { error: '未選擇方便聯繫時段', valid: false },
				}),
			);
		}

		if (testDriveForm.mobile.value && !validateMobile(testDriveForm.mobile.value)) {
			dispatch(
				updateTestDriveForm({
					key: 'mobile',
					data: { error: '手機號碼格式不正確', valid: false },
				}),
			);
		}

		if (testDriveForm.email.value && !validateEmail(testDriveForm.email.value)) {
			dispatch(
				updateTestDriveForm({
					key: 'email',
					data: { error: '電子信箱格式不不正確', valid: false },
				}),
			);
		}

		if (!testDriveForm.privacyPolicy.checked) {
			dispatch(
				updateTestDriveForm({
					key: 'privacyPolicy',
					data: { error: '此欄位不得空白', valid: false },
				}),
			);
		}

		if (
			!checkData.value ||
			!testDriveForm.privacyPolicy.checked ||
			(!testDriveForm.contactMorning.selected &&
				!testDriveForm.contactAfternoon.selected &&
				!testDriveForm.contactNight.selected)
		) {
			return;
		}

		try {
			// 改成const
			const { status, data, message } = await wrapFetch('recaptchaVerify', {
				method: 'POST',
				body: JSON.stringify({
					token,
				}),
			});

			if (status !== 200 && status !== 201) {
				dispatch(openModal({ category: 'dialog', type: 'recaptchaVerifyFailed' }));
				throw new Error(message);
			}
			if (data.data.success && data.data.score >= 0.5) {
				dispatch(submitTestDriveForm());
			} else {
				dispatch(openModal({ category: 'dialog', type: 'recaptchaVerifyFailed' }));
				throw new Error('reCAPTCHA verification failed');
			}
		} catch (error) {
			dispatch(openModal({ category: 'dialog', type: 'recaptchaVerifyFailed' }));
			throw new Error(error);
		}
	},
);

export const fetchCarTourShops = createAction(
	'FETCH_CAR_TOUR_SHOPS',
	(latitude, longitude) => async dispatch => {
		const apiParameter =
			latitude && longitude ? `&latitude=${latitude}&longitude=${longitude}` : '';
		const { status, data, message } = await wrapFetch(`shops?service=CAR_TOUR${apiParameter}`, {
			method: 'GET',
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		if (latitude && longitude) {
			await dispatch(
				updateTestDriveForm({
					key: 'shopId',
					data: {
						value: data && data.data[0].id,
					},
				}),
			);
		}

		return data;
	},
);

const defaultTestDriveFormData = {
	carType: { id: '', value: '', valid: true, error: '' },
	shopId: { value: '', valid: true, error: '' },
	contactMorning: { selected: false, valid: true, error: '' },
	contactAfternoon: { selected: false, valid: true, error: '' },
	contactNight: { selected: false, valid: true, error: '' },
	name: { value: '', valid: true, error: '' },
	gender: { value: '', valid: true, error: '' },
	email: { value: '', valid: true, error: '' },
	mobile: { value: '', valid: true, error: '' },
	privacyPolicy: { checked: false, valid: true, error: '' },
	autofillUserData: { checked: false },
	loading: false,
	error: '',
};

const reducer = {
	testDrive: handleActions(
		{
			INIT_TEST_DRIVE_FORM: state => ({
				...state,

				testDriveForm: defaultTestDriveFormData,
			}),

			UPDATE_TEST_DRIVE_FORM: (state, action) => ({
				...state,

				testDriveForm: {
					...state.testDriveForm,
					[action.payload.key]: {
						...state.testDriveForm[action.payload.key],
						...action.payload.data,
					},
				},
			}),

			CLEAR_TEST_DRIVE_FORM: state => ({
				...state,

				testDriveForm: {
					...state.testDriveForm,
					contactMorning: { selected: false, valid: true, error: '' },
					contactAfternoon: { selected: false, valid: true, error: '' },
					contactNight: { selected: false, valid: true, error: '' },
					name: { value: '', valid: true, error: '' },
					gender: { value: '', valid: true, error: '' },
					email: { value: '', valid: true, error: '' },
					mobile: { value: '', valid: true, error: '' },
					privacyPolicy: { checked: false, valid: true, error: '' },
					autofillUserData: { checked: false },
					loading: false,
					error: '',
				},
			}),

			CLEAR_TEST_DRIVE_FORM_ERROR: (state, action) => ({
				...state,

				[action.payload.type]: action.payload.data,
			}),

			VALIDATE_TEST_DRIVE_FORM: (state, action) => ({
				...state,

				[action.payload.type]: {
					...state[action.payload.type],
					[action.payload.key]: {
						...state[action.payload.type][action.payload.key],
						valid: action.payload.valid,
						error: action.payload.error,
					},
				},
			}),

			FETCH_CAR_TOUR_SHOPS_FULFILLED: (state, action) => ({
				...state,

				shops: action.payload.data,
			}),

			SUBMIT_TEST_DRIVE_FORM_PENDING: state => ({
				...state,

				testDriveForm: {
					...state.testDriveForm,

					loading: true,
				},
			}),

			SUBMIT_TEST_DRIVE_FORM_FULFILLED: state => ({
				...state,

				testDriveForm: {
					...state.testDriveForm,

					loading: false,
				},
			}),

			SUBMIT_TEST_DRIVE_FORM_REJECTED: (state, action) => ({
				...state,

				testDriveForm: {
					...state.testDriveForm,

					loading: false,
					error: action.payload.message,
				},
			}),
		},
		{
			testDriveForm: defaultTestDriveFormData,
			shops: [],
		},
	),
};

const selectTestDrive = state => state.testDrive;

export const useTestDrive = () =>
	useRedux(selectTestDrive, {
		fetchCarTourShops,
		recaptchaVerify,
		submitTestDriveForm,
	});

const selectTestDriveForm = state => state.testDrive.testDriveForm;

export const useTestDriveForm = () =>
	useRedux(selectTestDriveForm, {
		updateTestDriveForm,
		clearTestDriveForm,
		validateForm,
		initTestDriveForm,
	});

export default { reducer };
