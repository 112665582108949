import React from 'react';

import { useAuth } from 'models/auth';

import Link from 'components/atoms/Link';
import AppleStore from 'images/home/applestore-sm.png';
import GooglePlay from 'images/home/googleplay-sm.png';
import Facebook from 'images/home/icon-facebook.png';
import Instagram from 'images/home/icon-instagram.png';
import Line from 'images/home/icon-line.png';
import Logo from 'images/logo/logo-white.inline.svg';

import style from './index.css';

const { APPLE_STORE_URL, GOOGLE_PLAY_URL } = process.env;

const Footer = () => {
	const [{ isLogin }] = useAuth();

	return (
		<footer className={style.footer}>
			<Link to="/">
				<Logo />
			</Link>
			<ol>
				<Link to="/VesShare">
					<li>開始租車</li>
				</Link>
				<Link to="/vespa-models">
					<li>成為車主</li>
				</Link>
				<Link to="/shop">
					<li>線上商城</li>
				</Link>
				<Link to={isLogin ? '/after-sales/notifications' : '/after-sales/service-activity'}>
					<li>售後服務</li>
				</Link>
				<Link to="/news">
					<li>最新消息</li>
				</Link>
				<Link to="https://www.vespa.com/tw_ZH/" isExternal>
					<li>官方網站</li>
				</Link>
			</ol>
			<div className={style.imgWrapper}>
				<Link to={APPLE_STORE_URL} isExternal>
					<img src={AppleStore} alt="" />
				</Link>
				<Link to={GOOGLE_PLAY_URL} isExternal>
					<img src={GooglePlay} alt="" />
				</Link>
			</div>
			<div className={style.socialWrapper}>
				<Link to="https://www.facebook.com/VespaTaiwan/" isExternal>
					<img src={Facebook} alt="" />
				</Link>
				<Link to="https://www.instagram.com/vespa_taiwan/" isExternal>
					<img src={Instagram} alt="" />
				</Link>
				<Link to="http://line.me/ti/p/@599rfuxk" isExternal>
					<img src={Line} alt="" />
				</Link>
			</div>
			<p className={style.faq}>
				<Link to="/faq">FAQ</Link>
			</p>
			<p>
				<Link to="/privacy-statement">隱私權聲明</Link> | <Link to="/disclaimer">免責聲明</Link> |{' '}
				<Link to="/membership-terms">會員服務條款</Link> |{' '}
				<Link to="/vesshare-regulations">租車規範</Link>
			</p>
			<p>
				Vespa is a registered trademark of Piaggio & C S. p.a. YUNTUNG MOTORS LIMITED, TAIWAN BRANCH
				(H.K.) is the sole authorized Taiwan distributor of Vespa.
				<br />© 2020 Vespa Taiwan. All rights reserved.
			</p>
		</footer>
	);
};

export const DisabledFooter = () => (
	<div className={style.disabledFooterWrapper}>
		<div className={style.disabledFooter} />
		<Footer />
	</div>
);

export default Footer;
