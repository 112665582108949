import React from 'react';
import classnames from 'classnames';

import { useDialogModal } from 'models/modal';

import Modal from 'components/molecules/Modal';
import FormDialog from 'components/molecules/FormDialog';

import styles from './index.css';

const ModalDialog = ({ className }) => {
	const [{ type }] = useDialogModal();
	return (
		<Modal
			active={type}
			onDeactivate={() => {}}
			className={classnames(
				styles.modalDialog,
				{
					[styles.filter]:
						type === 'rate' ||
						type === 'orderStatus' ||
						type === 'addCreditCard' ||
						type === 'cancelRequest' ||
						type === 'returnRequest' ||
						type === 'cancelAddPurchases' ||
						type === 'qrCode' ||
						type === 'storeStock' ||
						type === 'vesShareEvent' ||
						type === 'authorizeAfterSalesMember' ||
						type === 'userCarModelUnauthorized' ||
						type === 'userCarModelDuplicateData' ||
						type === 'carModelDuplicateToOtherUser' ||
						type === 'withoutUserCarModelData' ||
						type === 'carNotificationVerify' ||
						type === 'privacyPolicy' ||
						type === 'appointmentTestDriveFormSuccess',
					[styles.special]: type === 'storeStock',
				},
				className,
			)}
		>
			<FormDialog type={type} />
		</Modal>
	);
};

export default ModalDialog;
