const setting = {
	// max file size: 5M
	maxSize: 5 * 1024 * 1024,
	regExp: /png$|PNG$|jpg$|JPG$|jpeg$|JPEG$/i,
	accept: 'image/*',
};

export const fileVerify = file =>
	new Promise(resolve => {
		if (!setting.regExp.test(file.name)) {
			console.log('檔案格式不符');
			resolve('檔案格式不符');
		}
		if (file.size > setting.maxSize) {
			console.log('檔案大小超出限制');
			resolve('檔案大小超出限制');
		}
		resolve('');
	});
