import React from 'react';
import { openModal } from 'models/modal';

export default {
	path: '/reset',
	components: () => [import(/* webpackChunkName: 'reset' */ './component')],
	render: ([Reset]) => <Reset />,
	onEnter: async ({ store }) => {
		await store.dispatch(openModal({ category: 'auth', type: 'reset' }));
	},
};
