import React, { Suspense, useEffect } from 'react';

import App from 'layouts/App';

import { HistoryContext } from 'models/routing';
import { useAuth } from 'models/auth';
import { useUserData } from 'models/user';

import useRouter from 'util/hook/useRouter';
import { useBoolean } from 'util/hook';

const Loading = () => <div />;

const Router = ({ routes, history, store }) => {
	const { loading, component } = useRouter(routes, history, store);
	const [{ hasToken }] = useAuth();
	const [, { getUserProcess }] = useUserData();
	const [rootLoading, { setTrue: startRootLoading, setFalse: endRootLoading }] = useBoolean({
		defaultBoolean: true,
	});

	async function asyncRootFetching() {
		startRootLoading();
		if (hasToken) {
			await getUserProcess();
		}
		endRootLoading();
	}

	useEffect(() => {
		asyncRootFetching();
	}, []);

	return (
		<HistoryContext.Provider value={history}>
			<App>
				{loading || rootLoading ? (
					<Loading />
				) : (
					<Suspense fallback={<Loading />}>{component}</Suspense>
				)}
			</App>
		</HistoryContext.Provider>
	);
};

export default Router;
