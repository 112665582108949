import { createAction, handleActions } from 'redux-actions';
import qs from 'qs';
import DOMPurify from 'dompurify';

import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { decodeHtmlEntities } from 'util/helper';

const fetchMembershipTerms = createAction('FETCH_MEMBERSHIP_TERMS', () => async (_, getState) => {
	const {
		routing: { search },
	} = getState();
	const { preview_key: previewKey } = qs.parse(search, { ignoreQueryPrefix: true });

	const headers = previewKey ? { 'X-VESPA-PREVIEW-KEY': previewKey } : {};
	const { data } = await wrapFetch('contracts/membership-terms', {
		method: 'GET',
		headers,
	});

	const sanitizeData = DOMPurify.sanitize(decodeHtmlEntities(data.data.html));

	return sanitizeData;
});

const fetchVesshareRegulations = createAction(
	'FETCH_VESSHARE_REGULATIONS',
	() => async (_, getState) => {
		const {
			routing: { search },
		} = getState();
		const { preview_key: previewKey } = qs.parse(search, { ignoreQueryPrefix: true });

		const headers = previewKey ? { 'X-VESPA-PREVIEW-KEY': previewKey } : {};
		const { data } = await wrapFetch('contracts/vesshare-regulations', {
			method: 'GET',
			headers,
		});
		const sanitizeData = DOMPurify.sanitize(decodeHtmlEntities(data.data.html));

		return sanitizeData;
	},
);

export const fetchTestDrivePrivacy = createAction(
	'FETCH_TEST_DRIVE_PRIVACY',
	() => async (_, getState) => {
		const {
			routing: { search },
		} = getState();
		const { preview_key: previewKey } = qs.parse(search, { ignoreQueryPrefix: true });

		const headers = previewKey ? { 'X-VESPA-PREVIEW-KEY': previewKey } : {};
		const { data } = await wrapFetch('contracts/cartour-privacy', {
			method: 'GET',
			headers,
		});
		const sanitizeData = DOMPurify.sanitize(decodeHtmlEntities(data.data.html));

		return sanitizeData;
	},
);

const reducer = {
	contract: handleActions(
		{
			FETCH_MEMBERSHIP_TERMS_PENDING: state => ({
				...state,

				loading: true,
				membershipTerms: '',
			}),

			FETCH_MEMBERSHIP_TERMS_FULFILLED: (state, action) => ({
				...state,

				loading: false,
				membershipTerms: action.payload,
			}),

			FETCH_MEMBERSHIP_TERMS_REJECTED: state => ({
				...state,

				loading: false,
				membershipTerms: '',
			}),

			FETCH_VESSHARE_REGULATIONS_PENDING: state => ({
				...state,

				loading: true,
				vesshareRegulations: '',
			}),

			FETCH_VESSHARE_REGULATIONS_FULFILLED: (state, action) => ({
				...state,

				loading: false,
				vesshareRegulations: action.payload,
			}),

			FETCH_VESSHARE_REGULATIONS_REJECTED: state => ({
				...state,

				loading: false,
				vesshareRegulations: '',
			}),

			FETCH_TEST_DRIVE_PRIVACY_PENDING: state => ({
				...state,

				loading: true,
				testDrivePrivacy: '',
			}),

			FETCH_TEST_DRIVE_PRIVACY_FULFILLED: (state, action) => ({
				...state,

				loading: false,
				testDrivePrivacy: action.payload,
			}),

			FETCH_TEST_DRIVE_PRIVACY_REJECTED: state => ({
				...state,

				loading: false,
				testDrivePrivacy: '',
			}),
		},
		{
			membershipTerms: '',
			vesshareRegulations: '',
			testDrivePrivacy: '',
			loading: false,
		},
	),
};

const selectContract = state => state.contract;

export const useContract = () =>
	useRedux(selectContract, {
		fetchMembershipTerms,
		fetchVesshareRegulations,
		fetchTestDrivePrivacy,
	});

export default { reducer };
