import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import dayjs from 'dayjs';

import { useRedux } from 'util/hook/redux';
import { wrapAuthFetch } from 'util/api';
import { getImageUrl, isExist } from 'util/helper';
import {
	examAge,
	examSignupUserBasicFormData,
	examSignupIdUploadFormData,
	examSignupCreditCardFormData,
	examMemberSetPasswordFormData,
} from 'util/exam';
import {
	clearUserBasicFormData,
	clearSignupIdUploadFormData,
	clearSignupCreditCardFormData,
	clearMemberSetPasswordFormData,
	clearInvoiceData,
} from 'util/clear';

import { fetchNotification } from 'models/notification';
import { openModal, closeModal } from 'models/modal';
import { setLogin } from 'models/auth';
import { initVesShareForm, fetchCarType } from 'models/rental';
import { fetchCities } from 'models/cities';

export const defaultCreditCardData = {
	id: null,
	creditCardNo: { value: '尚未綁定信用卡', valid: true, error: '' },
	creditCardExp: { value: '', valid: true, error: '' },
	message: 'not yet binding credit card.',
	countDown: 0,
};

export const defaultUserData = {
	id: null,
	name: '',
	avatar: '',
	mobile: '',
	birth: '',
	mgm: { code: '', url: '', url_title: '' },
};

const defaultAvatarFormData = {
	avatar: { value: null, valid: true, error: '', loading: false },
	loading: false,
	error: '',
};

const defaultUserBasicData = {
	email: { value: '', valid: true, error: '', hasSent: false },
	name: { value: '', valid: true, error: '' },
	gender: { value: '', valid: true, error: '' },
	birthday: { year: null, month: null, day: null, valid: true, error: '' },
	idNumber: { value: '', valid: true, error: '' },
	address: { county: '', district: '', other: '', valid: true, error: '' },
	emergencyContactName: { value: '', valid: true, error: '' },
	emergencyContactMobile: { value: '', valid: true, error: '' },
	loading: false,
	reboot: false,
	error: '',
};

const defaultIdUploadData = {
	idPhotos: { front: null, back: null, valid: true, error: '', loading: false, target: '' },
	driverLicenseType: { value: '', valid: true, error: '' },
	driverLicensePhotos: {
		front: null,
		back: null,
		valid: true,
		error: '',
		loading: false,
		target: '',
	},
	loading: false,
	error: '',
};

const defaultCreditCardForm = {
	creditCardNo: { value: '', valid: true, error: '' },
	creditCardExp: { value: '', valid: true, error: '' },
	creditCardCvc: { value: '', valid: true, error: '' },
	loading: false,
	error: '',
};

const defaultSetPassword = {
	oldPassword: { value: '', valid: true, error: '' },
	password: { value: '', valid: true, error: '' },
	repeatPassword: { value: '', valid: true, error: '' },
	loading: false,
};

const defaultInvoiceData = {
	invoiceCarrierType: { value: '', valid: true, error: '' },
	invoiceAddress: { county: '', district: '', other: '', valid: true, error: '' },
	invoiceCarrierNum: { value: '', valid: true, error: '' },
	loveCode: { value: '', valid: true, error: '' },
	taxNum: { value: '', valid: true, error: '' },
	companyName: { value: '', valid: true, error: '' },
	loading: false,
};

const countDown = createAction('COUNT_DOWN', (leftTime = 30) => dispatch => {
	if (leftTime === 0) {
		return { leftTime };
	}

	setTimeout(() => dispatch(countDown(leftTime - 1)), 1000);
	return { leftTime };
});

export const updateForm = createAction('UPDATE_MEMBER_FORM', ({ type, key, data }) => ({
	type,
	key,
	data,
}));

const validateForm = createAction('VALIDATE_MEMBER_FORM', (type, key, valid, error) => ({
	type,
	key,
	valid,
	error,
}));

const clearFormError = createAction('CLEAR_FORM_ERROR', type => (_, getState) => {
	const {
		user: { userBasicForm, idUploadForm, creditCardForm, setPasswordForm, invoiceForm },
	} = getState();
	let clearData;
	if (type === 'userBasicForm') {
		clearData = clearUserBasicFormData(userBasicForm);
	} else if (type === 'idUploadForm') {
		clearData = clearSignupIdUploadFormData(idUploadForm);
	} else if (type === 'creditCardForm') {
		clearData = clearSignupCreditCardFormData(creditCardForm);
	} else if (type === 'setPasswordForm') {
		clearData = clearMemberSetPasswordFormData(setPasswordForm);
	} else if (type === 'invoiceForm') {
		clearData = clearInvoiceData(invoiceForm);
	}

	return {
		type,
		data: clearData,
	};
});

const clearForm = createAction('CLEAR_USER_FORM', type => async dispatch => {
	let clearData;
	if (type === 'setPasswordForm') {
		clearData = defaultSetPassword;
	}
	if (type === 'creditCardForm') {
		clearData = defaultCreditCardForm;
	}
	if (type === 'invoiceForm') {
		await dispatch(clearFormError('invoiceForm'));
		clearData = defaultInvoiceData;
		const body = {
			type: 'INITIAL',
			city: '',
			district: '',
			detail: '',
			mobile_num: '',
			moica_num: '',
			love_code: '',
			tax_num: '',
			company_name: '',
		};

		try {
			const { status, message } = await wrapAuthFetch('me/invoice', {
				method: 'PUT',
				body: JSON.stringify(body),
			});

			if (status !== 200) {
				throw new Error(message);
			}
		} catch (error) {
			throw new Error(error);
		}
	}

	return {
		type,
		data: clearData,
	};
});

export const getUser = createAction('FETCH_USER', () => async () => {
	const { status, message, data } = await wrapAuthFetch('me', {
		method: 'GET',
	});

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	return { data: { ...data.data, avatar: getImageUrl(data.data.avatar) } };
});

export const checkUserUsageRight = createAction(
	'CHECK_USER_USAGE_RIGHT',
	() => async (dispatch, getState) => {
		await dispatch(fetchCarType());

		const {
			auth: {
				isLogin,
				token: { access_token: token },
				signupStep,
			},
			user: {
				data: { valid, status },
			},
			routing: { pathname },
		} = getState();

		const hasToken = isExist(token);

		if (!hasToken) {
			dispatch(openModal({ category: 'auth', type: 'tempLogin' }));
			return Promise.resolve({ success: false });
		}

		if (hasToken && !isLogin) {
			return Promise.resolve({ success: false });
		}

		if (!valid || status !== 'PASS') {
			await dispatch(getUser());

			const {
				user: {
					data: { valid: newValid, status: newStatus },
				},
			} = getState();

			if (!newValid) {
				if (pathname.includes('VesShare') && signupStep <= 1) {
					await dispatch(closeModal({ category: 'auth', type: 'tempLogin' }));
				}

				await dispatch(openModal({ category: 'dialog', type: 'statusReject' }));
				await dispatch(initVesShareForm());
				return Promise.resolve({ success: false });
			}

			if (newStatus !== 'PASS') {
				if (pathname.includes('VesShare') && signupStep <= 1) {
					await dispatch(closeModal({ category: 'auth', type: 'tempLogin' }));
				}

				await dispatch(openModal({ category: 'dialog', type: 'status' }));
				await dispatch(initVesShareForm());
				return Promise.resolve({ success: false });
			}
		}

		if (pathname.includes('VesShare')) {
			await dispatch(closeModal({ category: 'auth', type: 'login' }));
			await dispatch(fetchCarType());

			const {
				rental: {
					vesShareForm: { rentalCarTypesId },
					rentalCarTypes,
				},
			} = getState();

			const licenseUnavailable =
				rentalCarTypes.length > 0 &&
				rentalCarTypes.filter(type => type.is_eligible_license_type === false);

			const outOfCarTypeStock =
				rentalCarTypes.length > 0 && rentalCarTypes.filter(type => type.available === false);

			if (licenseUnavailable.length > 0 && licenseUnavailable[0].id === rentalCarTypesId.value) {
				await dispatch(closeModal({ category: 'auth', type: 'tempLogin' }));
				dispatch(openModal({ category: 'dialog', type: 'ineligibleLicense' }));
				dispatch(initVesShareForm());
				return Promise.resolve({ success: false });
			}
			if (outOfCarTypeStock.length > 0 && outOfCarTypeStock[0].id === rentalCarTypesId.value) {
				await dispatch(closeModal({ category: 'auth', type: 'tempLogin' }));
				dispatch(openModal({ category: 'dialog', type: 'outOfCarTypeStock' }));
				dispatch(initVesShareForm());
				return Promise.resolve({ success: false });
			}
		}

		return Promise.resolve({ success: true });
	},
);

export const checkCreditCard = createAction('CHECK_CREDIT_CARD', async () => {
	const { message, data } = await wrapAuthFetch('me/creditCards', {
		method: 'GET',
	});

	return message === 'success'
		? { data: { ...data.data, message } }
		: { data: { ...defaultCreditCardData, message, no: '尚未綁定信用卡' } };
});

export const uploadAvatar = createAction('UPLOAD_AVATAR', file => async dispatch => {
	const { status, message } = await wrapAuthFetch('avatar', {
		method: 'PUT',
		body: JSON.stringify({
			avatar: file,
		}),
	});

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	dispatch(getUser());
});

export const emailVerify = createAction('EMAIL_VERIFY', () => async (dispatch, getState) => {
	dispatch(validateForm('userBasicForm', 'email', true, ''));
	const {
		user: {
			userBasicForm: { email },
		},
	} = getState();

	if (email.value === '') {
		dispatch(validateForm('userBasicForm', 'email', false, '此欄位不得空白'));
		return null;
	}

	const { status, message } = await wrapAuthFetch('auth/sendVerifyEmail', {
		method: 'POST',
		body: JSON.stringify({
			email: email.value,
		}),
	});

	if (status !== 200 && status !== 201) {
		if (message === 'already has another user used this email.') {
			dispatch(validateForm('userBasicForm', 'email', false, '已存在此 email 帳號'));
		} else if (message === 'The email must be a valid email address.') {
			dispatch(validateForm('userBasicForm', 'email', false, '您輸入的信箱有誤'));
		} else if (status === 429 && message === 'Too Many Attempts.') {
			dispatch(openModal({ category: 'dialog', type: 'reachedSendingEmailLimit' }));
		}

		return Promise.resolve({ success: false, message });
	}

	dispatch(clearFormError('userBasicForm'));
	return null;
});

export const sendUserBasic = createAction('SEND_BASIC', () => async (dispatch, getState) => {
	dispatch(clearFormError('userBasicForm'));

	const {
		user: { userBasicForm },
	} = getState();

	let validStatus = true;

	if (
		!examAge(userBasicForm.birthday.year, userBasicForm.birthday.month, userBasicForm.birthday.day)
	) {
		dispatch(validateForm('userBasicForm', 'birthday', false, '年齡未滿 18 歲'));
		validStatus = false;
	}

	if (!/^[A-Z][A-Z0-9][0-9]{8}$/.test(userBasicForm.idNumber.value)) {
		dispatch(validateForm('userBasicForm', 'idNumber', false, '身分證字號（永居證）格式不正確'));
		validStatus = false;
	}

	// 檢查空值
	const checkDataBasic = examSignupUserBasicFormData(userBasicForm);
	if (!checkDataBasic.value) {
		checkDataBasic.notValid.map(key =>
			dispatch(validateForm('userBasicForm', key, false, '此欄位不得空白')),
		);
		validStatus = false;
	}

	if (!validStatus) {
		throw new Error();
	}

	// API: Basic
	const { status: basicStatus, message } = await wrapAuthFetch('me', {
		method: 'PUT',
		body: JSON.stringify({
			email: userBasicForm.email.value,
			name: userBasicForm.name.value,
			gender: userBasicForm.gender.value, // 男性(MALE)| 女性(FEMALE)|其他(OTHER)
			birth: dayjs({
				y: userBasicForm.birthday.year,
				M: userBasicForm.birthday.month - 1,
				d: userBasicForm.birthday.day,
			}).format('YYYY-MM-DD'),
			id_number: userBasicForm.idNumber.value,
			city_id: userBasicForm.address.county,
			district_id: userBasicForm.address.district,
			address: userBasicForm.address.other,
			emergency_contact_name: userBasicForm.emergencyContactName.value,
			emergency_contact_mobile: userBasicForm.emergencyContactMobile.value,
		}),
	});

	if (basicStatus !== 200 && basicStatus !== 201) {
		if (basicStatus === 422) {
			dispatch(validateForm('userBasicForm', 'idNumber', false, '身分證字號（永居證）格式不正確'));
		}
		throw new Error(message);
	}

	await dispatch(getUser());

	if (basicStatus === 201) {
		dispatch(openModal({ category: 'dialog', type: 'statusReview' }));
	} else {
		dispatch(openModal({ category: 'dialog', type: 'updated' }));
	}
});

export const sendIdUpload = createAction('SEND_ID_UPLOAD', () => async (dispatch, getState) => {
	dispatch(clearFormError('idUploadForm'));

	const {
		user: { idUploadForm },
	} = getState();

	// 檢查空值
	const checkDataId = examSignupIdUploadFormData(idUploadForm);
	if (!checkDataId.value) {
		checkDataId.notValid.map(key =>
			dispatch(validateForm('idUploadForm', key, false, '此欄位不得空白')),
		);

		throw new Error('failed');
	}

	const { status: idStatus, message: idMessage } = await wrapAuthFetch('identityCards', {
		method: 'PUT',
		body: JSON.stringify({
			id_card_front: idUploadForm.idPhotos.front,
			id_card_back: idUploadForm.idPhotos.back,
			drivers_license_type: idUploadForm.driverLicenseType.value,
			drivers_license_front: idUploadForm.driverLicensePhotos.front,
			drivers_license_back: idUploadForm.driverLicensePhotos.back,
		}),
	});

	if (idStatus !== 200 && idStatus !== 201) {
		throw new Error(idMessage);
	}

	await dispatch(getUser());

	dispatch(openModal({ category: 'dialog', type: 'statusReview' }));
});

export const sendCreditCard = createAction('SEND_CREDIT_CARD', () => async (dispatch, getState) => {
	dispatch(clearFormError('creditCardForm'));

	const {
		user: { creditCardForm, creditCard },
	} = getState();

	let validStatus = 0;

	if (!/^[0-9]{16}$/.test(creditCardForm.creditCardNo.value)) {
		dispatch(validateForm('creditCardForm', 'creditCardNo', false, '信用卡卡號格式不正確'));
		validStatus = 400;
	}

	const YY = parseInt(dayjs().format('YY'), 10);
	const MM = parseInt(dayjs().format('MM'), 10);

	if (!/^0[1-9]|1[0-2]?[0-9]{2}$/.test(creditCardForm.creditCardExp.value)) {
		dispatch(validateForm('creditCardForm', 'creditCardExp', false, '期限格式不正確'));
		validStatus = 400;
	} else if (/^0[1-9]|1[0-2]?[0-9]{2}$/.test(creditCardForm.creditCardExp.value)) {
		if (parseInt(creditCardForm.creditCardExp.value.slice(2), 10) < YY) {
			dispatch(validateForm('creditCardForm', 'creditCardExp', false, '信用卡期限已過期'));
			validStatus = 400;
		} else if (parseInt(creditCardForm.creditCardExp.value.slice(2), 10) === YY) {
			if (parseInt(creditCardForm.creditCardExp.value.slice(0, 2), 10) < MM) {
				dispatch(validateForm('creditCardForm', 'creditCardExp', false, '信用卡期限已過期'));
				validStatus = 400;
			}
		}
	}

	if (!/^[0-9]{3}$/.test(creditCardForm.creditCardCvc.value)) {
		dispatch(validateForm('creditCardForm', 'creditCardCvc', false, '安全碼格式不正確'));
		validStatus = 400;
	}

	if (validStatus === 400) {
		return null;
	}

	const checkData = examSignupCreditCardFormData(creditCardForm);
	if (!checkData.value) {
		checkData.notValid.map(key =>
			dispatch(validateForm('creditCardForm', key, false, '此欄位不得空白')),
		);
		return null;
	}

	// API: POST credit card api

	const { status, message } = await wrapAuthFetch('creditCards', {
		method: creditCard.message === 'success' ? 'PUT' : 'POST',
		body: JSON.stringify({
			card_no: creditCardForm.creditCardNo.value,
			card_exp: `${creditCardForm.creditCardExp.value.slice(
				-2,
			)}${creditCardForm.creditCardExp.value.slice(0, 2)}`,
			card_cvc: creditCardForm.creditCardCvc.value,
		}),
	});

	if (status === 503) {
		dispatch(
			openModal({
				category: 'toast',
				type: 'failed',
				data: { message: '第三方金流服務異常中，請稍後再試' },
			}),
		);
		throw new Error(message);
	}

	if (status !== 200 && status !== 201) {
		dispatch(validateForm('creditCardForm', 'creditCardNo', false, '卡號或格式錯誤'));
		throw new Error(message);
	}

	await dispatch(checkCreditCard());
	dispatch(
		openModal({
			category: 'toast',
			type: 'success',
			data: { message: creditCard.id ? '信用卡更新成功' : '信用卡新增成功' },
		}),
	);
	dispatch(countDown());

	return Promise.resolve({ success: true });
});

export const deleteCreditCard = createAction('DELETE_CREDIT_CARD', () => async dispatch => {
	const { status, message } = await wrapAuthFetch('creditCards', {
		method: 'DELETE',
	});

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	await dispatch(checkCreditCard());
});

export const sendSetPassword = createAction(
	'SENT_SET_PASSWORD',
	({ firstReset = true }) => async (dispatch, getState) => {
		dispatch(clearFormError('setPasswordForm'));

		const {
			user: { setPasswordForm, data },
		} = getState();

		let validStatus = 0;

		if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(setPasswordForm.password.value)) {
			dispatch(validateForm('setPasswordForm', 'password', false, '密碼格式不正確'));
			validStatus = 400;
		}

		if (setPasswordForm.oldPassword.value === setPasswordForm.password.value) {
			dispatch(validateForm('setPasswordForm', 'password', false, '新密碼不可與舊密碼相同'));
			validStatus = 400;
		}

		if (setPasswordForm.password.value !== setPasswordForm.repeatPassword.value) {
			dispatch(validateForm('setPasswordForm', 'repeatPassword', false, '確認密碼和密碼不符合'));
			validStatus = 400;
		}

		if (data.password_set && setPasswordForm.oldPassword.value === '') {
			dispatch(validateForm('setPasswordForm', 'oldPassword', false, '此欄位不得空白'));
			validStatus = 400;
		}

		if (data.password_set && !/^[a-zA-Z0-9]{8,}$/.test(setPasswordForm.oldPassword.value)) {
			dispatch(validateForm('setPasswordForm', 'oldPassword', false, '密碼格式不正確'));
			validStatus = 400;
		}

		const checkData = examMemberSetPasswordFormData(setPasswordForm);
		if (!checkData.value) {
			checkData.notValid.map(key =>
				dispatch(validateForm('setPasswordForm', key, false, '此欄位不得空白')),
			);
			return;
		}

		if (validStatus === 400) {
			return;
		}

		let putData = {};
		if (data.password_set) {
			putData = {
				old_password: setPasswordForm.oldPassword.value,
				password: setPasswordForm.password.value,
			};
		} else {
			putData = {
				password: setPasswordForm.password.value,
			};
		}

		const { status, message } = await wrapAuthFetch('password', {
			method: 'PUT',
			body: JSON.stringify(putData),
		});

		if (status !== 200 && status !== 201) {
			if (message === 'the old password was wrong.') {
				dispatch(validateForm('setPasswordForm', 'oldPassword', false, '舊密碼輸入錯誤'));
			}

			throw new Error(message);
		}

		dispatch(clearForm('setPasswordForm'));
		dispatch(
			openModal({
				category: 'toast',
				type: 'success',
				data: { message: firstReset ? '密碼設定成功' : '密碼修改成功' },
			}),
		);
	},
);

export const clearInvoiceForm = createAction('CLEAR_INVOICE_FORM');

export const updateInvoiceForm = createAction(
	'UPDATE_INVOICE_FORM',
	({ type, key, data }) => dispatch => {
		if (key === 'invoiceCarrierType') {
			dispatch(clearInvoiceForm());
		}
		return {
			type,
			key,
			data,
		};
	},
);

const updateMultipleInvoiceFields = updates => {
	return dispatch => {
		updates.forEach(update => {
			dispatch(updateInvoiceForm(update));
		});
	};
};

// 檢查是否有設定發票載具
export const checkInvoiceSetting = createAction(
	'CHECK_INVOICE_SETTING',
	() => async (dispatch, getState) => {
		const {
			cities: { cities },
		} = getState();

		const { status, message, data } = await wrapAuthFetch('me/invoice', {
			method: 'GET',
		});

		if (status === 200 && data.data) {
			if (data.data.type === 'INITIAL') {
				console.log('no invoice setting');
				return;
			}
			if (data.data.type === 'MOBILE') {
				dispatch(
					updateMultipleInvoiceFields([
						{
							type: 'invoiceForm',
							key: 'invoiceCarrierType',
							data: { value: 'MOBILE' },
						},
						{
							type: 'invoiceForm',
							key: 'invoiceCarrierNum',
							data: { value: data.data.mobile_num },
						},
					]),
				);
			} else if (data.data.type === 'DEFAULT') {
				const county = cities.filter(c => c.name === data.data.city)[0];
				const district = county && county.districts.filter(d => d.name === data.data.district)[0];
				dispatch(
					updateMultipleInvoiceFields([
						{
							type: 'invoiceForm',
							key: 'invoiceCarrierType',
							data: { value: 'DEFAULT' },
						},
						{
							type: 'invoiceForm',
							key: 'invoiceAddress',
							data: { county: county.id, district: district.id, other: data.data.detail },
						},
					]),
				);
			} else if (data.data.type === 'DONATE') {
				dispatch(
					updateMultipleInvoiceFields([
						{
							type: 'invoiceForm',
							key: 'invoiceCarrierType',
							data: { value: 'DONATE' },
						},
						{
							type: 'invoiceForm',
							key: 'loveCode',
							data: { value: data.data.love_code },
						},
					]),
				);
			} else if (data.data.type === 'MOICA') {
				dispatch(
					updateMultipleInvoiceFields([
						{
							type: 'invoiceForm',
							key: 'invoiceCarrierType',
							data: { value: 'MOICA' },
						},
						{
							type: 'invoiceForm',
							key: 'invoiceCarrierNum',
							data: { value: data.data.moica_num },
						},
					]),
				);
			} else if (data.data.type === 'BUSINESS') {
				dispatch(
					updateMultipleInvoiceFields([
						{
							type: 'invoiceForm',
							key: 'invoiceCarrierType',
							data: { value: 'BUSINESS' },
						},
						{
							type: 'invoiceForm',
							key: 'taxNum',
							data: { value: data.data.tax_num },
						},
						{
							type: 'invoiceForm',
							key: 'companyName',
							data: { value: data.data.company_name },
						},
					]),
				);
			}
		} else {
			throw new Error(message);
		}
	},
);

export const getUserProcess = createAction(
	'FETCH_USER_PROCESS',
	() => async (dispatch, getState) => {
		await Promise.all([
			dispatch(getUser()),
			dispatch(checkCreditCard()),
			dispatch(fetchNotification()),
			dispatch(fetchCities()),
		]);

		// 檢查是否有設定發票載具需要等到取得城市資料 fetchCities 後才能執行
		await dispatch(checkInvoiceSetting());

		const {
			user: {
				data: { valid },
			},
		} = getState();

		if (!valid) {
			dispatch(openModal({ category: 'dialog', type: 'statusReject' }));
		}

		dispatch(setLogin());
	},
);

export const clearUser = createAction('CLEAR_USER');

// 設定發票載具
export const sendInvoice = createAction('SEND_INVOICE', () => async (dispatch, getState) => {
	await dispatch(clearFormError('invoiceForm'));
	const {
		user: { invoiceForm },
		cities: { cities },
	} = getState();

	let validStatus = 0;

	// 載具選擇
	if (invoiceForm.invoiceCarrierType.value === '') {
		dispatch(validateForm('invoiceForm', 'invoiceCarrierType', false, '此欄位不得空白'));
		validStatus = 400;
	}

	// 會員載具
	if (
		invoiceForm.invoiceCarrierType.value === 'DEFAULT' &&
		(invoiceForm.invoiceAddress.other === '' ||
			invoiceForm.invoiceAddress.county === '' ||
			invoiceForm.invoiceAddress.district === '')
	) {
		dispatch(validateForm('invoiceForm', 'invoiceAddress', false, '地址不完整'));
		validStatus = 400;
	}

	// 手機條碼
	if (
		invoiceForm.invoiceCarrierType.value === 'MOBILE' &&
		!/^\/[-+.a-zA-Z0-9]{7}$/.test(invoiceForm.invoiceCarrierNum.value)
	) {
		dispatch(
			validateForm(
				'invoiceForm',
				'invoiceCarrierNum',
				false,
				'您所輸入的手機載具不存在，請確認後重新填寫',
			),
		);
		validStatus = 400;
	}

	// 發票捐贈
	if (
		invoiceForm.invoiceCarrierType.value === 'DONATE' &&
		!/^[0-9]{3,7}$/.test(invoiceForm.loveCode.value)
	) {
		dispatch(
			validateForm('invoiceForm', 'loveCode', false, '您所輸入的愛心碼不存在，請確認後重新填寫'),
		);
		validStatus = 400;
	}

	// 自然人憑證
	if (
		invoiceForm.invoiceCarrierType.value === 'MOICA' &&
		!/^[A-Z]{2}[0-9]{14}$/.test(invoiceForm.invoiceCarrierNum.value)
	) {
		dispatch(
			validateForm(
				'invoiceForm',
				'invoiceCarrierNum',
				false,
				'您所輸入的自然人憑證不存在，請確認後重新填寫',
			),
		);
		validStatus = 400;
	}

	// 公司發票
	if (
		invoiceForm.invoiceCarrierType.value === 'BUSINESS' &&
		!/^[0-9]{8}$/.test(invoiceForm.taxNum.value)
	) {
		dispatch(
			validateForm('invoiceForm', 'taxNum', false, '您所輸入的統編不存在，請確認後重新填寫'),
		);
		validStatus = 400;
	}

	if (validStatus === 400) {
		throw new Error('failed');
	}

	const county = cities.filter(c => c.id === invoiceForm.invoiceAddress.county)[0];
	const district =
		county && county.districts.filter(d => d.id === invoiceForm.invoiceAddress.district)[0];

	const body = {
		...(invoiceForm.invoiceCarrierType.value === 'DEFAULT' && {
			type: invoiceForm.invoiceCarrierType.value,
			city: county.id.toString(),
			district: district.id.toString(),
			detail: invoiceForm.invoiceAddress.other,
		}),
		...(invoiceForm.invoiceCarrierType.value === 'MOBILE' && {
			type: invoiceForm.invoiceCarrierType.value,
			mobile_num: invoiceForm.invoiceCarrierNum.value,
		}),
		...(invoiceForm.invoiceCarrierType.value === 'MOICA' && {
			type: invoiceForm.invoiceCarrierType.value,
			moica_num: invoiceForm.invoiceCarrierNum.value,
		}),
		...(invoiceForm.invoiceCarrierType.value === 'DONATE' && {
			type: invoiceForm.invoiceCarrierType.value,
			love_code: invoiceForm.loveCode.value,
		}),
		...(invoiceForm.invoiceCarrierType.value === 'BUSINESS' && {
			type: invoiceForm.invoiceCarrierType.value,
			tax_num: invoiceForm.taxNum.value,
			company_name: invoiceForm.companyName.value,
		}),
	};

	const { status, message } = await wrapAuthFetch('me/invoice', {
		method: 'PUT',
		body: JSON.stringify(body),
	});

	if (status === 422) {
		if (invoiceForm.invoiceCarrierType.value === 'DEFAULT') {
			const defaultErrors = [
				'城市 不能多於 5 個字元。',
				'City does not exist',
				'district 不能多於 5 個字元。',
				'District does not exist',
			];
			if (defaultErrors.includes(message)) {
				dispatch(
					validateForm('invoiceForm', 'invoiceAddress', false, '縣市鄉鎮資料異常，請稍後重新嘗試'),
				);
				return;
			}
			if (message === 'detail 不能多於 100 個字元。') {
				dispatch(
					validateForm('invoiceForm', 'invoiceAddress', false, '超出字數上限，請調整地址長度'),
				);
				return;
			}
		} else if (invoiceForm.invoiceCarrierType.value === 'MOBILE') {
			const mobileErrors = ['mobile num 必須是 8 個字元。', 'mobile num 的格式錯誤。'];
			if (mobileErrors.includes(message)) {
				dispatch(
					validateForm(
						'invoiceForm',
						'invoiceCarrierNum',
						false,
						'您所輸入的手機載具不存在，請確認後重新填寫',
					),
				);
				return;
			}
		} else if (invoiceForm.invoiceCarrierType.value === 'DONATE') {
			if (message === 'love code 的格式錯誤。') {
				dispatch(
					validateForm(
						'invoiceForm',
						'loveCode',
						false,
						'您所輸入的愛心碼不存在，請確認後重新填寫',
					),
				);
			}
			return;
		} else if (invoiceForm.invoiceCarrierType.value === 'MOICA') {
			const moicaErrors = ['moica num 必須是 16 個字元。', 'moica num 的格式錯誤。'];
			if (moicaErrors.includes(message)) {
				dispatch(
					validateForm(
						'invoiceForm',
						'invoiceCarrierNum',
						false,
						'您所輸入的自然人憑證不存在，請確認後重新填寫',
					),
				);
			}
			return;
		} else if (invoiceForm.invoiceCarrierType.value === 'BUSINESS') {
			const taxErrors = ['tax num 必須是 8 個字元。', 'tax num 的格式錯誤。'];
			if (taxErrors.includes(message)) {
				dispatch(
					validateForm('invoiceForm', 'taxNum', false, '您所輸入的統編不存在，請確認後重新填寫'),
				);
				return;
			}
			if (message === 'company name 不能多於 100 個字元。') {
				dispatch(
					validateForm(
						'invoiceForm',
						'companyName',
						false,
						'您所輸入的統編不存在，請確認後重新填寫',
					),
				);
				return;
			}
		}
	}

	dispatch(
		openModal({
			category: 'toast',
			type: 'success',
			data: { message: '發票設定成功' },
		}),
	);
});

const reducer = {
	user: handleActions(
		{
			COUNT_DOWN: (state, action) => ({
				...state,

				creditCard: {
					...state.creditCard,

					countDown: action.payload.leftTime,
				},
			}),

			UPDATE_MEMBER_FORM: (state, action) => ({
				...state,

				[action.payload.type]: {
					...state[action.payload.type],
					[action.payload.key]: {
						...state[action.payload.type][action.payload.key],
						...action.payload.data,
					},
				},
			}),

			VALIDATE_MEMBER_FORM: (state, action) => ({
				...state,

				[action.payload.type]: {
					...state[action.payload.type],
					[action.payload.key]: {
						...state[action.payload.type][action.payload.key],
						valid: action.payload.valid,
						error: action.payload.error,
					},
				},
			}),

			CLEAR_FORM_ERROR: (state, action) => ({
				...state,

				[action.payload.type]: action.payload.data,
			}),

			CLEAR_FORM: (state, action) => {
				if (action.payload.type === 'creditCardForm') {
					return {
						...state,

						creditCardForm: defaultCreditCardForm,
					};
				}
				return state;
			},

			FETCH_USER_FULFILLED: (state, action) => ({
				...state,

				data: {
					...state.data,
					...action.payload.data,
				},

				avatarForm: {
					...state.avatarForm,
					avatar: {
						value: action.payload.data.avatar.thumbnail || '',
						valid: true,
						error: '',
						loading: false,
					},
				},

				userBasicForm: {
					...state.userBasicForm,
					email: {
						...state.userBasicForm.email,
						value: action.payload.data.email || '',
						valid: true,
						error: '',
					},
					name: { value: action.payload.data.name || '', valid: true, error: '' },
					gender: { value: action.payload.data.gender || '', valid: true, error: '' },
					birthday: {
						year: parseInt(dayjs(action.payload.data.birth).format('YYYY'), 10),
						month: parseInt(dayjs(action.payload.data.birth).format('M'), 10),
						day: parseInt(dayjs(action.payload.data.birth).format('D'), 10),
						valid: true,
						error: '',
					},
					idNumber: { value: action.payload.data.id_number, valid: true, error: '' },
					address: {
						county: action.payload.data.city_id || '',
						district: action.payload.data.district_id || '',
						other: action.payload.data.address || '',
						valid: true,
						error: '',
					},
					emergencyContactName: {
						value: action.payload.data.emergency_contact_name || '',
						valid: true,
						error: '',
					},
					emergencyContactMobile: {
						value: action.payload.data.emergency_contact_mobile || '',
						valid: true,
						error: '',
					},
					loading: false,
				},

				idUploadForm: {
					...state.idUploadForm,
					idPhotos: {
						front: action.payload.data.id_card_front || '',
						back: action.payload.data.id_card_back || '',
						valid: true,
						error: '',
					},
					driverLicenseType: {
						value: action.payload.data.drivers_license_type || '',
						valid: true,
						error: '',
					},
					driverLicensePhotos: {
						front: action.payload.data.drivers_license_front || '',
						back: action.payload.data.drivers_license_back || '',
						valid: true,
						error: '',
					},
				},
			}),

			CHECK_CREDIT_CARD_FULFILLED: (state, action) => ({
				...state,

				// creditCard: action.payload.data,
				creditCard: {
					...state.creditCard,
					id: action.payload.data.id || null,
					creditCardNo: { value: action.payload.data.no || '', valid: true, error: '' },
					creditCardExp: { value: action.payload.data.exp || '', valid: true, error: '' },
					message: action.payload.data.message,
				},
			}),

			UPLOAD_AVATAR_PENDING: state => ({
				...state,

				avatarForm: {
					...state.avatarForm,
					loading: true,
					error: '',
				},
			}),

			UPLOAD_AVATAR_FULFILLED: state => ({
				...state,

				avatarForm: {
					...state.avatarForm,
					loading: false,
				},
			}),

			UPLOAD_AVATAR_REJECTED: (state, action) => ({
				...state,

				avatarForm: {
					...state.avatarForm,
					loading: false,
					error: action.payload.message,
				},
			}),

			SEND_CREDIT_CARD_PENDING: state => ({
				...state,

				creditCardForm: {
					...state.creditCardForm,
					loading: true,
					error: '',
				},
			}),

			SEND_CREDIT_CARD_FULFILLED: state => ({
				...state,

				creditCardForm: {
					...state.creditCardForm,
					loading: false,
				},
			}),

			SEND_CREDIT_CARD_REJECTED: (state, action) => ({
				...state,

				creditCardForm: {
					...state.creditCardForm,
					loading: false,
					error: action.payload.message,
				},
			}),

			CLEAR_USER: state => ({
				...state,

				data: defaultUserData,
				avatarForm: defaultAvatarFormData,
				creditCard: defaultCreditCardData,
				userBasicForm: defaultUserBasicData,
				idUploadForm: defaultIdUploadData,
				creditCardForm: defaultCreditCardForm,
				setPasswordForm: defaultSetPassword,
				invoiceForm: defaultInvoiceData,
			}),

			CLEAR_USER_FORM_FULFILLED: (state, action) => ({
				...state,

				[action.payload.type]: action.payload.data,
			}),

			SEND_BASIC_PENDING: state => ({
				...state,

				userBasicForm: {
					...state.userBasicForm,
					loading: true,
					error: '',
				},
			}),

			SEND_BASIC_FULFILLED: state => ({
				...state,

				userBasicForm: {
					...state.userBasicForm,
					loading: false,
				},
			}),

			SEND_BASIC_REJECTED: (state, action) => ({
				...state,

				userBasicForm: {
					...state.userBasicForm,
					loading: false,
					error: action.payload.message,
				},
			}),

			SEND_ID_UPLOAD_PENDING: state => ({
				...state,

				idUploadForm: {
					...state.idUploadForm,
					loading: true,
					error: '',
				},
			}),

			SEND_ID_UPLOAD_FULFILLED: state => ({
				...state,

				idUploadForm: {
					...state.idUploadForm,
					loading: false,
				},
			}),

			SEND_ID_UPLOAD_REJECTED: (state, action) => ({
				...state,

				idUploadForm: {
					...state.idUploadForm,
					loading: false,
					error: action.payload.message,
				},
			}),

			CHECK_INVOICE_SETTING_FULFILLED: state => ({
				...state,
			}),

			SEND_INVOICE_PENDING: state => ({
				...state,

				invoiceForm: {
					...state.invoiceForm,
					loading: true,
				},
			}),

			SEND_INVOICE_FULFILLED: state => ({
				...state,

				invoiceForm: {
					...state.invoiceForm,
					loading: false,
				},
			}),

			UPDATE_INVOICE_FORM: (state, action) => ({
				...state,

				[action.payload.type]: {
					...state[action.payload.type],
					[action.payload.key]: {
						...state[action.payload.type][action.payload.key],
						...action.payload.data,
					},
				},
			}),

			CLEAR_INVOICE_FORM: state => ({
				...state,

				invoiceForm: defaultInvoiceData,
			}),
		},
		{
			data: defaultUserData,
			creditCard: defaultCreditCardData,
			avatarForm: defaultAvatarFormData,
			userBasicForm: defaultUserBasicData,
			idUploadForm: defaultIdUploadData,
			creditCardForm: defaultCreditCardForm,
			setPasswordForm: defaultSetPassword,
			invoiceForm: defaultInvoiceData,
		},
	),
};

const selectUserData = createSelector(
	state => state.user.data,
	data => data,
);

export const useUserData = () =>
	useRedux(selectUserData, {
		getUser,
		getUserProcess,
		checkUserUsageRight,
		emailVerify,
		sendUserBasic,
		sendIdUpload,
	});

const selectUserCreditCard = createSelector(
	state => state.user.creditCard,
	data => data,
);

export const useUserCreditCard = () => useRedux(selectUserCreditCard, { deleteCreditCard });

const selectUserAvatarForm = state => state.user.avatarForm;

export const useUserAvatarForm = () =>
	useRedux(selectUserAvatarForm, {
		updateForm,
		uploadAvatar,
	});

const selectUserBasicForm = state => state.user.userBasicForm;

export const useUserBasicForm = () =>
	useRedux(selectUserBasicForm, {
		updateForm,
	});

const selectIdUploadForm = state => state.user.idUploadForm;

export const useIdUploadForm = () =>
	useRedux(selectIdUploadForm, {
		updateForm,
	});

const selectCreditCardForm = state => state.user.creditCardForm;

export const useCreditCardForm = () =>
	useRedux(selectCreditCardForm, {
		updateForm,
		sendCreditCard,
		clearForm,
	});

const selectInvoiceForm = state => state.user.invoiceForm;

export const useInvoiceForm = () =>
	useRedux(selectInvoiceForm, {
		updateInvoiceForm,
		checkInvoiceSetting,
		sendInvoice,
		clearForm,
	});

const selectSetPasswordForm = state => state.user.setPasswordForm;

export const useSetPasswordForm = () =>
	useRedux(selectSetPasswordForm, {
		updateForm,
		sendSetPassword,
		clearForm,
	});

export default { reducer };
