const clear = data => ({
	...data,
	valid: true,
	error: '',
});

const clearData = (data, keyArray) => {
	const newData = { ...data };
	for (let index = 0; index < keyArray.length; index += 1) {
		newData[keyArray[index]] = clear(newData[keyArray[index]]);
	}
	return newData;
};

export const clearLoginFormData = data => {
	const newData = clearData(data, ['phone', 'password']);

	return newData;
};

export const clearForgotFormData = data => {
	const newData = clearData(data, ['email']);

	return newData;
};

export const clearResetFormData = data => {
	const newData = clearData(data, ['newPassword', 'repeatPassword']);

	return newData;
};

export const clearSignupAccountFormData = data => {
	const newData = clearData(data, [
		'phone',
		'phoneVerify',
		'password',
		'repeatPassword',
		'email',
		'invitationCode',
		'vmodMember',
	]);

	return newData;
};

export const clearSignupUserBasicFormData = data => {
	const newData = clearData(data, [
		'name',
		'gender',
		'birthday',
		'idNumber',
		'address',
		'emergencyContactName',
		'emergencyContactMobile',
	]);

	return newData;
};

export const clearSignupIdUploadFormData = data => {
	const newData = clearData(data, ['idPhotos', 'driverLicenseType', 'driverLicensePhotos']);

	return newData;
};

export const clearSignupCreditCardFormData = data => {
	const newData = clearData(data, ['creditCardNo', 'creditCardExp', 'creditCardCvc']);

	return newData;
};

export const clearVesShareFormData = data => {
	const newData = clearData(data, [
		'shopId',
		'rentalCarTypesId',
		'date',
		'rentingTime',
		'returningTime',
		'invoiceCarrierType',
		'invoiceCarrierNum',
		'loveCode',
		'taxNum',
		'invoiceAddress',
		'companyName',
		'contract',
	]);

	return newData;
};

export const clearInvoiceData = data => {
	const newData = clearData(data, [
		'invoiceCarrierType',
		'invoiceCarrierNum',
		'loveCode',
		'taxNum',
		'invoiceAddress',
		'companyName',
	]);

	return newData;
};

export const clearOwnershipFormData = data => {
	const newData = clearData(data, ['colorId', 'paymentType', 'shopId', 'signature']);

	return newData;
};

export const clearUserBasicFormData = data => {
	const newData = clearData(data, [
		'name',
		'gender',
		'birthday',
		'idNumber',
		'address',
		'emergencyContactName',
		'emergencyContactMobile',
	]);

	return newData;
};

export const clearUserCarModelFormData = data => {
	const newData = clearData(data, ['vehicleRegistrationPlate', 'vinNumber', 'edit']);

	return newData;
};

export const clearReserveMaintenanceFormData = data => {
	const newData = clearData(data, [
		'vehicleRegistrationPlate',
		'km',
		'serviceType',
		'firstReservationDate',
		'firstReservationTime',
		'name',
		'mobile',
		'email',
	]);

	return newData;
};

export const clearPickupFormData = data => {
	const newData = clearData(data, ['licensePlate', 'fuelCapacity', 'mileage', 'time', 'signed']);

	return newData;
};

export const clearReturnFormData = data => {
	const newData = clearData(data, ['fuelCapacity', 'mileage', 'time']);

	return newData;
};

export const clearMemberSetPasswordFormData = data => {
	const newData = clearData(data, ['oldPassword', 'password', 'repeatPassword']);

	return newData;
};

export const clearReceiveFormData = (data, step) => {
	if (step === 1) {
		return clearData(data, ['delivery', 'recipient', 'phone', 'address']);
	}

	if (step === 2) {
		return clearData(data, ['coupon']);
	}

	if (step === 3) {
		return clearData(data, ['invoiceCarrierValue']);
	}

	return clearData(data, [
		'delivery',
		'recipient',
		'phone',
		'address',
		'coupon',
		'invoiceCarrierValue',
	]);
};

export const clearCouponFormData = data => {
	const newData = clearData(data, ['redeemCode']);

	return newData;
};

export const clearTestDriveFormData = data => {
	const newData = clearData(data, [
		'carType',
		'shopId',
		'contactMorning',
		'contactAfternoon',
		'contactNight',
		'name',
		'gender',
		'email',
		'mobile',
		'privacyPolicy',
	]);

	return newData;
};
