import { map, filter } from 'rxjs/operators';

import storage from 'util/storage';
import { openModal } from 'models/modal';

export const handleError = (action$, state$) =>
	action$.pipe(
		filter(action => action.type.includes('REJECTED')),
		map(action => {
			const {
				routing: { pathname },
			} = state$.value;

			if (
				(action.payload.message === 'Unauthorized' ||
					action.payload.message === 'Access token expired.' ||
					action.payload.message === 'Access token has revoked.' ||
					action.payload.message === 'Access token verified failed.') &&
				pathname !== '/verify' &&
				pathname !== '/login' &&
				pathname !== '/signup' &&
				pathname !== '/reset' &&
				!pathname.includes('/rental/') &&
				!pathname.includes('/webview/')
			) {
				storage.removeItem('token');
				// Reload app
				window.location.href = `/login`;
				return { type: 'NOT_HANDLE_TOKEN_ERROR' };
			}

			if (action.payload.message === 'Server Error') {
				return openModal({ category: 'dialog', type: 'serverError' });
			}

			return { type: 'NOT_HANDLE_ERROR' };
		}),
	);
