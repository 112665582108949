const AFTERSALES_HOST_MAP = {
	dev: 'http://localhost:9000',
	demo: 'https://vespa-aftersales-dev.25demo.com',
	stage: 'https://vespa-aftersales-uat.25demo.com',
	preProd: 'https://vespapre.25demo.com',
	production: 'https://www.vespastore.com.tw',
};

const NORMAL_HOST_MAP = {
	dev: 'http://localhost:9000',
	demo: 'https://vespa.25demo.com',
	stage: 'https://vespastg.25demo.com',
	preProd: 'https://vespapre.25demo.com',
	production: 'https://www.vespastore.com.tw',
};

export const HOST_MAP =
	process.env.ENABLE_AFTERSALES === 'true' ? AFTERSALES_HOST_MAP : NORMAL_HOST_MAP;

const AFTERSALES_SELF_HOST_MAP = {
	dev: 'http://localhost:3000',
	demo: 'https://vespa-aftersales-dev.25demo.com',
	stage: 'https://vespa-aftersales-uat.25demo.com',
	preProd: 'https://vespapre.25demo.com',
	production: 'https://www.vespastore.com.tw',
};

const NORMAL_SELF_HOST_MAP = {
	dev: 'http://localhost:3000',
	demo: 'https://vespa.25demo.com',
	stage: 'https://vespastg.25demo.com',
	preProd: 'https://vespapre.25demo.com',
	production: 'https://www.vespastore.com.tw',
};

const SELF_HOST_MAP =
	process.env.ENABLE_AFTERSALES === 'true' ? AFTERSALES_SELF_HOST_MAP : NORMAL_SELF_HOST_MAP;

const AFTERSALES_IMAGE_ENDPOINT_MAP = {
	dev: 'http://localhost:9000/backstage/storage',
	demo: 'https://vespa-aftersales-dev.25demo.com/backstage/storage',
	stage: 'https://vespa-aftersales-uat.25demo.com/s3',
	preProd: 'https://vespapre.25demo.com/s3',
	production: 'https://www.vespastore.com.tw/s3',
};

const NORMAL_IMAGE_ENDPOINT_MAP = {
	dev: 'http://localhost:9000/backstage/storage',
	demo: 'https://vespa.25demo.com/backstage/storage',
	stage: 'https://vespastg.25demo.com/s3',
	preProd: 'https://vespapre.25demo.com/s3',
	production: 'https://www.vespastore.com.tw/s3',
};

export const IMAGE_ENDPOINT_MAP =
	process.env.ENABLE_AFTERSALES === 'true'
		? AFTERSALES_IMAGE_ENDPOINT_MAP
		: NORMAL_IMAGE_ENDPOINT_MAP;

const LOGIN_REDIRECT_URL_MAP = {
	dev: 'https://vespa.25demo.com/redirect',
	demo: 'https://vespa.25demo.com/redirect',
	stage: 'https://vespastg.25demo.com/redirect',
	preProd: 'https://vespapre.25demo.com/appleLogin/redirect',
	production: 'https://www.vespastore.com.tw/appleLogin/redirect',
};

const DYNAMIC_LINK_URL_MAP = {
	dev: 'https://25sprout.page.link',
	demo: 'https://25sprout.page.link',
	stage: 'https://vespastg-link.25demo.com',
	preProd: 'https://vespapre-link.25demo.com',
	production: 'https://link.vespastore.com.tw',
};

const DYNAMIC_LINK_IBI_MAP = {
	dev: 'com.25sprout.Vespa',
	demo: 'com.25sprout.Vespa',
	stage: 'com.taikoo.Vespa-iOS',
	preProd: 'com.taikoo.Vespa',
	production: 'com.taikoo.Vespa',
};

const ACCOUNT_DELETION_SURVEY_MAP = {
	dev: 'https://forms.gle/RLhTtMfKUWmjsF2v7',
	demo: 'https://forms.gle/RLhTtMfKUWmjsF2v7',
	stage: 'https://forms.gle/n1RtL3s5MvGPJoiz8',
	preProd: 'https://forms.gle/txsWUpiCrSCh7Evu5',
	production: 'https://forms.gle/9ptWbdxi5hWSCWhr6',
};

export const API_ENDPOINT = HOST_MAP[process.env.API];
export const SELF_HOST_ENDPOINT = SELF_HOST_MAP[process.env.API];
export const IMAGE_ENDPOINT = IMAGE_ENDPOINT_MAP[process.env.API];
export const LOGIN_REDIRECT_URL = LOGIN_REDIRECT_URL_MAP[process.env.API];
export const DYNAMIC_LINK_URL = DYNAMIC_LINK_URL_MAP[process.env.API];
export const DYNAMIC_LINK_IBI = DYNAMIC_LINK_IBI_MAP[process.env.API];
export const ACCOUNT_DELETION_SURVEY = ACCOUNT_DELETION_SURVEY_MAP[process.env.API];

export const APPLE_STORE_URL =
	'https://apps.apple.com/tw/app/vespa-%E5%AE%98%E6%96%B9%E5%95%86%E5%9F%8E/id1567793078';
export const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.taikoo.vespa';

export default {
	// Set API endpoint
	API_ENDPOINT: JSON.stringify(API_ENDPOINT),
	SELF_HOST_ENDPOINT: JSON.stringify(SELF_HOST_ENDPOINT),
	IMAGE_ENDPOINT: JSON.stringify(IMAGE_ENDPOINT),
	LOGIN_REDIRECT_URL: JSON.stringify(LOGIN_REDIRECT_URL),
	DYNAMIC_LINK_URL: JSON.stringify(DYNAMIC_LINK_URL),
	DYNAMIC_LINK_IBI: JSON.stringify(DYNAMIC_LINK_IBI),
	ACCOUNT_DELETION_SURVEY: JSON.stringify(ACCOUNT_DELETION_SURVEY),
	APPLE_STORE_URL: `"${APPLE_STORE_URL}"`,
	GOOGLE_PLAY_URL: `"${GOOGLE_PLAY_URL}"`,
};
