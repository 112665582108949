import React from 'react';
import classnames from 'classnames';

import styles from './index.css';

const Division = ({ className, vertical = false }) => (
	<div className={classnames(styles.division, className, { [styles.vertical]: vertical })} />
);

export default Division;
