import { createAction, handleActions } from 'redux-actions';
import classnames from 'classnames';
import { map, filter } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import _ from 'lodash';

import { useRedux } from 'util/hook/redux';
import { isEmpty, isExist } from 'util/helper';

import { pushRoute } from 'models/routing';
import { verifyEventCoupon, bindCoolbeLineBot } from 'models/coupon';

export const openModal = createAction('OPEN_MODAL', ({ category, type, data = {} }) => ({
	category,
	type,
	data,
}));

export const closeModal = createAction('CLOSE_MODAL', ({ category }) => ({ category }));

export const openNormalModal = createAction('OPEN_NORMAL_MODAL', ({ type, data = {} }) => ({
	type,
	data,
}));

export const closeNormalModal = createAction(
	'CLOSE_NORMAL_MODAL',
	({ type } = { type: '' }) => (__, getState) => {
		const {
			modal: {
				normal: { list, value },
			},
		} = getState();
		if (isExist(type)) {
			const newList = list.filter(l => l !== type);
			const newValue = _.omit(value, type);
			return { list: newList, value: newValue };
		}

		return { list: [], value: {} };
	},
);

const setModalBackgroundScrollY = createAction('SET_MODAL_BACKGROUND_SCROLLY', () => {
	if (!document.body.className.includes('no-scroll')) {
		document.body.style.top = `-${window.pageYOffset}px`;
		document.body.className = classnames(document.body.className, 'no-scroll');
	}
});

const restoreModalBackgroundScrollY = createAction('RESTORE_MODAL_BACKGROUND_SCROLLY', () => {
	const classNameArray = document.body.className.split(' ');
	const newClassName = classNameArray.filter(item => item !== 'no-scroll').join(' ');
	document.body.className = newClassName;

	const matchesTop = document.body.style.top.match(/\d+/g);
	document.body.style.top = 'unset';
	if (matchesTop !== null && matchesTop.length > 0) {
		window.scrollTo(0, parseInt(matchesTop[0], 10));
	} else {
		window.scrollTo(0, 0);
	}
});

export const openModalEpic = action$ =>
	action$.pipe(
		ofType('OPEN_MODAL', 'OPEN_NORMAL_MODAL'),
		filter(action => action.payload.category !== 'toast'),
		map(() => setModalBackgroundScrollY()),
	);

export const closeModalEpic = action$ =>
	action$.pipe(
		ofType('CLOSE_MODAL', 'CLOSE_NORMAL_MODAL'),
		filter(action => action.payload.category !== 'toast'),
		map(() => restoreModalBackgroundScrollY()),
	);

export const closeModalRedirectEpic = (action$, state$) =>
	action$.pipe(
		filter(action => action.type === 'CLOSE_MODAL' && action.payload.category === 'auth'),
		map(() => {
			const {
				routing: { pathname, search },
				auth: {
					token: { access_token: accessToken },
					guideUrl,
				},
			} = state$.value;

			if (isExist(guideUrl) && !pathname.includes('VesShare')) {
				if (isExist(search)) {
					window.location.href = `${guideUrl}`;
				} else return pushRoute({ pathname: '/' });
			}

			if (
				(isEmpty(accessToken) && pathname.includes('member')) ||
				(isEmpty(accessToken) && pathname.includes('verify')) ||
				pathname.includes('login') ||
				pathname.includes('signup') ||
				pathname.includes('reset') ||
				pathname === 'verify'
			) {
				return pushRoute({ pathname: '/' });
			}

			if (isExist(accessToken) && pathname.includes('verify')) {
				if (pathname.includes('line_bot_bind')) {
					return bindCoolbeLineBot();
				}
				return verifyEventCoupon();
			}

			if (
				isEmpty(accessToken) &&
				pathname.includes('vespa-models') &&
				pathname.includes('checkout')
			) {
				return pushRoute({
					pathname: pathname
						.split('/')
						.slice(0, 4)
						.join('/'),
					search,
				});
			}

			return { type: 'NO_NEED_TO_CHANGE_URL' };
		}),
	);

const detectScrollTop = createAction('DETECT_SCROLL_TOP', scrollTop => ({
	scrollTop,
}));

const reducer = {
	modal: handleActions(
		{
			OPEN_MODAL: (state, action) => ({
				...state,
				[action.payload.category]: {
					type: action.payload.type,
					data: action.payload.data,
				},
			}),

			CLOSE_MODAL: (state, action) => ({
				...state,
				[action.payload.category]: {
					type: '',
					data: {},
				},
			}),

			OPEN_NORMAL_MODAL: (state, action) => ({
				...state,
				normal: {
					list: [...state.normal.list, action.payload.type],
					value: {
						...state.normal.value,
						[action.payload.type]: action.payload.data,
					},
				},
			}),

			CLOSE_NORMAL_MODAL: (state, action) => ({
				...state,
				normal: {
					list: action.payload.list,
					value: action.payload.value,
				},
			}),

			DETECT_SCROLL_TOP: (state, action) => ({
				...state,
				scrollTop: action.payload.scrollTop,
			}),
		},
		{
			auth: { type: '', data: {} }, // only one modal at once time
			dialog: { type: '', data: {} }, // only one modal at once time
			toast: { type: '', data: {} }, // only one modal at once time
			normal: { list: [], value: {} }, // allow multiple modals
			scrollTop: 0,
		},
	),
};

const selectAuth = state => state.modal.auth;

export const useAuthModal = () =>
	useRedux(selectAuth, {
		openModal,
		closeModal,
		setModalBackgroundScrollY,
		restoreModalBackgroundScrollY,
	});

const selectDialog = state => state.modal.dialog;

export const useDialogModal = () =>
	useRedux(selectDialog, {
		openModal,
		closeModal,
		setModalBackgroundScrollY,
		restoreModalBackgroundScrollY,
	});

const selectToast = state => state.modal.toast;

export const useToastModal = () =>
	useRedux(selectToast, {
		openModal,
		closeModal,
		setModalBackgroundScrollY,
		restoreModalBackgroundScrollY,
	});

const selectNormalModal = state => state.modal.normal;

export const useNormalModal = () =>
	useRedux(selectNormalModal, {
		openNormalModal,
		closeNormalModal,
		setModalBackgroundScrollY,
		restoreModalBackgroundScrollY,
	});

const selectModal = state => state.modal;

export const useModal = () =>
	useRedux(selectModal, {
		openModal,
		closeModal,
		openNormalModal,
		closeNormalModal,
		setModalBackgroundScrollY,
		restoreModalBackgroundScrollY,
		detectScrollTop,
	});

export default { reducer };
