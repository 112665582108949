import React from 'react';

export default {
	path: '/member',
	components: () => [import(/* webpackChunkName: 'MemberLayout' */ './component')],
	render: ([MemberLayout], children) => <MemberLayout>{children}</MemberLayout>,
	children: [
		{
			path: '/basic',
			components: () => [import('./Basic')],
			render: ([Basic]) => <Basic />,
		},
		{
			path: '/identities',
			components: () => [import('./Basic')],
			render: ([Basic]) => <Basic />,
		},
		{
			path: '/connection',
			components: () => [import('./Basic')],
			render: ([Basic]) => <Basic />,
		},
		{
			path: '/password',
			components: () => [import('./Basic')],
			render: ([Basic]) => <Basic />,
		},
		{
			path: '/credit-card',
			components: () => [import('./CreditCard')],
			render: ([CreditCard]) => <CreditCard />,
		},
		{
			path: '/coupon',
			components: () => [import('./Coupon')],
			render: ([Coupon]) => <Coupon />,
		},
		{
			path: '/invitation',
			components: () => [import('./Invitation')],
			render: ([Invitation]) => <Invitation />,
		},
		{
			path: '/notification-center',
			components: () => [import('./NotificationCenter')],
			render: ([NotificationCenter]) => <NotificationCenter />,
		},
		{
			path: '/my-order',
			components: () => [],
			render: (_, children) => children,
			children: [
				{
					path: '',
					components: () => [import('./MyOrder')],
					render: ([MyOrder]) => <MyOrder />,
				},
				{
					path: '/:type/:id',
					components: () => [import('./MyOrderInner')],
					render: ([MyOrderInner]) => <MyOrderInner />,
				},
			],
		},
	],
};
