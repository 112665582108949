/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classnames from 'classnames';

import AppleIcon from 'images/icon/apple.inline.svg';

import Button from '../Button';

import styles from './index.css';

export const ButtonAppleComponent = ({ className, iconOnly, ...other }) => (
	<Button
		className={classnames(styles.buttonApple, { [styles.text]: iconOnly }, className)}
		Icon={AppleIcon}
		variant="outlined"
		color="customize"
		{...other}
	>
		{iconOnly ? null : '使用 APPLE 註冊'}
	</Button>
);

const ButtonApple = props => {
	const loginApple = () => {};

	return <ButtonAppleComponent onClick={loginApple} {...props} />;
};

export default ButtonApple;
