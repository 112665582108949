/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classnames from 'classnames';

import ContractIcon from 'images/icon/contract.inline.svg';
import UploadedIcon from 'images/icon/uploaded.inline.svg';

import Button from '../Button';

import styles from './index.css';

export const ButtonContract = ({
	className,
	iconOnly,
	checked,
	title = '審閱合約',
	error,
	...other
}) => (
	<Button
		className={classnames(
			styles.buttonContract,
			{ [styles.checked]: checked, [styles.error]: error },
			className,
		)}
		Icon={checked ? UploadedIcon : ContractIcon}
		variant="outlined"
		color="customize"
		{...other}
	>
		{iconOnly ? null : title}
	</Button>
);

export default ButtonContract;
