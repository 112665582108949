import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import objectSupport from 'dayjs/plugin/objectSupport';

import { loadAuthToken, defaultTokenData, isGoogleLibraryLoaded } from 'models/auth';
import storage from './storage';

const insertScript = (id, src, options = { async: true, defer: true }, onLoad) => {
	if (document.getElementById(id)) {
		return 1;
	}

	const jss = document.getElementsByTagName('script')[0];
	const js = document.createElement('script');

	js.id = id;
	js.src = src;

	if (options.async) {
		js.async = true;
	}

	if (options.defer) {
		js.defer = true;
	}
	js.onerror = errorEvent => {
		console.log('insert Script onerror ===>', errorEvent);
	};

	if (onLoad) {
		js.onload = onLoad;
	}

	jss.parentNode.insertBefore(js, jss);
	return 0;
};

window.fbAsyncInit = window.fbAsyncInit || {};

const { FACEBOOK_ID } = process.env;

export const loadFacebookLibrary = () =>
	new Promise(resolve => {
		window.fbAsyncInit = () => {
			window.FB.init({
				appId: FACEBOOK_ID,
				xfbml: true, // parse social plugins on this page
				version: 'v7.0',
			});
			window.FB.AppEvents.logPageView();
			resolve();
		};

		const result = insertScript('facebook-jssdk', '//connect.facebook.net/zh_TW/sdk.js');

		// Aready insert
		if (result === 1) {
			resolve();
		}
	});

export const loadGoogleLibrary = store => {
	insertScript(
		'google-client-script',
		'//accounts.google.com/gsi/client',
		{ async: true, defer: true },
		() => {
			store.dispatch(isGoogleLibraryLoaded(true));
		},
	);
};

// 舊的 google login
// export const loadGoogleLibrary = () =>
//	new Promise(resolve => {
//		window.onGoogleStart = () => {
//			window.gapi.load('auth2', () => {
//				window.auth2 = window.gapi.auth2.init({
//					client_id: GOOGLE_CLIENT_ID,
//					// Scopes to request in addition to 'profile' and 'email'
//					scope: 'profile email',
//					prompt: 'select_account',
//					plugin_name: 'google_login',
//				});
//			});
//			resolve();
//		};

//		const result = insertScript(
//			'google-jssdk',
//			'//apis.google.com/js/client:platform.js?onload=onGoogleStart',
//		);

//		// Aready insert
//		if (result === 1) {
//			resolve();
//		}
//	});

export const loadAppleLibrary = () => {
	insertScript(
		'applejs-sdk',
		'//appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/zh_TW/appleid.auth.js',
	);
};

export const loadConfigFromLocalStorage = store => {
	const tokenData = storage.getItem('token');

	const token = tokenData === null ? defaultTokenData : JSON.parse(tokenData);

	store.dispatch(loadAuthToken(token));
};

export const extendDayjsPlugin = () => {
	dayjs.extend(customParseFormat);
	dayjs.extend(objectSupport);
};
