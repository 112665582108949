import React from 'react';
import { clearTargetShop } from 'models/rental';

export default {
	path: '/VesShare',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ store }) => {
		await store.dispatch(clearTargetShop());
	},
	children: [
		{
			path: '',
			components: () => [import('./component')],
			render: ([VesShare]) => <VesShare />,
		},
	],
};
