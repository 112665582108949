import { isExist, isEmpty } from './helper';

export const examValue = (data, keyValue) => ({
	value: keyValue.every(key => isExist(data[key].value)),
	notValid: keyValue.filter(key => isEmpty(data[key].value)),
});

export const examBool = (data, keyValue) => ({
	value: keyValue.every(key => data[key].value === true),
	notValid: keyValue.filter(key => data[key].value === false),
});

export const examSingleValue = (name, data, keyValue) => ({
	value: keyValue.every(key => isExist(data[key])),
	notValid: !keyValue.every(key => isExist(data[key])) ? [name] : [],
});

export const examArray = (data, keyArray) => ({
	value: keyArray.every(key => data[key].value.length !== 0),
	notValid: keyArray.filter(key => data[key].value.length === 0),
});

export const examAge = (year, month, date) => {
	const d = new Date();
	if (d.getFullYear() - parseInt(year, 10) > 18) {
		return true;
	}
	if (d.getFullYear() - parseInt(year, 10) === 18 && d.getMonth() + 1 > parseInt(month, 10)) {
		return true;
	}
	if (
		d.getFullYear() - parseInt(year, 10) === 18 &&
		d.getMonth() + 1 === parseInt(month, 10) &&
		d.getDate() >= parseInt(date, 10)
	) {
		return true;
	}
	return false;
};

export const examLoginFormData = data => {
	const result = examValue(data, ['phone', 'password']);

	return {
		value: result.value,
		notValid: [...result.notValid],
	};
};

export const examForgotFormData = data => {
	const result = examValue(data, ['email']);

	return {
		value: result.value,
		notValid: [...result.notValid],
	};
};

export const examResetFormData = data => {
	const result = examValue(data, ['newPassword', 'repeatPassword']);

	return {
		value: result.value,
		notValid: [...result.notValid],
	};
};

export const examSignupAccountFormData = (data, isFastLogin) => {
	const result = isFastLogin
		? examValue(data, ['phone', 'phoneVerify', 'email'])
		: examValue(data, ['phone', 'phoneVerify', 'password', 'repeatPassword', 'email']);

	return {
		value: result.value,
		notValid: [...result.notValid],
	};
};

export const examPhoneData = data => {
	const result = examValue(data, ['phone']);

	return {
		value: result.value,
		notValid: [...result.notValid],
	};
};

export const examSignupUserBasicFormData = data => {
	const valueResult = examValue(data, ['name', 'gender', 'idNumber']);
	const addressResult = examSingleValue('address', data.address, ['county', 'district', 'other']);
	const birthdayResult = examSingleValue('birthday', data.birthday, ['year', 'month', 'day']);

	return {
		value: valueResult.value && addressResult.value && birthdayResult.value,
		notValid: [...valueResult.notValid, ...addressResult.notValid, ...birthdayResult.notValid],
	};
};

export const examSignupIdUploadFormData = data => {
	const valueResult = examValue(data, ['driverLicenseType']);
	const idPhotosResult = examSingleValue('idPhotos', data.idPhotos, ['front', 'back']);
	const driverLicensePhotosResult = examSingleValue(
		'driverLicensePhotos',
		data.driverLicensePhotos,
		['front', 'back'],
	);

	return {
		value: valueResult.value && idPhotosResult.value && driverLicensePhotosResult.value,
		notValid: [
			...valueResult.notValid,
			...idPhotosResult.notValid,
			...driverLicensePhotosResult.notValid,
		],
	};
};

export const examSignupCreditCardFormData = data => {
	const result = examValue(data, ['creditCardNo', 'creditCardExp', 'creditCardCvc']);

	return {
		value: result.value,
		notValid: [...result.notValid],
	};
};

export const examRentalFormData = data => {
	const valueResult = examValue(data, [
		'shopId',
		'rentalCarTypesId',
		'rentingTime',
		'returningTime',
	]);
	const dateResult = examSingleValue('date', data.date, ['year', 'month', 'date']);

	return {
		value: valueResult.value && dateResult.value,
		notValid: [...valueResult.notValid, ...dateResult.notValid],
	};
};

export const examInvoiceData = data => {
	const contractResult = examBool(data, ['contract']);

	if (data.invoiceCarrierType.value === 'DEFAULT') {
		const valueResult = examValue(data, ['invoiceCarrierType', 'contract']);
		const addressResult = examSingleValue('invoiceAddress', data.invoiceAddress, [
			'county',
			'district',
			'other',
		]);

		return {
			value: valueResult.value && contractResult.value && addressResult.value,
			notValid: [...valueResult.notValid, ...contractResult.notValid, ...addressResult.notValid],
		};
	}

	let result;
	if (data.invoiceCarrierType.value === '') {
		result = examValue(data, ['invoiceCarrierType']);
	}
	if (data.invoiceCarrierType.value === 'MOBILE') {
		result = examValue(data, ['invoiceCarrierNum']);
	}
	if (data.invoiceCarrierType.value === 'DONATE') {
		result = examValue(data, ['loveCode']);
	}
	if (data.invoiceCarrierType.value === 'MOICA') {
		result = examValue(data, ['invoiceCarrierNum']);
	}
	if (data.invoiceCarrierType.value === 'BUSINESS') {
		result = examValue(data, ['taxNum', 'companyName']);
	}
	return {
		value: contractResult.value && result.value,
		notValid: [...result.notValid, ...contractResult.notValid],
	};
};

export const examOwnershipFormData = data => {
	const result = examValue(data, ['colorId', 'paymentType', 'shopId', 'signature']);

	return {
		value: result.value,
		notValid: [...result.notValid],
	};
};

export const examRentalPickupFormData = data => {
	const valueResult = examValue(data, ['licensePlate', 'fuelCapacity', 'mileage']);

	const dateResult = examSingleValue('time', data.time, ['year', 'month', 'day', 'hour', 'min']);

	return {
		value: valueResult.value && dateResult.value,
		notValid: [...valueResult.notValid, ...dateResult.notValid],
	};
};

export const examRentalReturnFormData = data => {
	const valueResult = examValue(data, ['fuelCapacity', 'mileage']);

	const dateResult = examSingleValue('time', data.time, ['year', 'month', 'day', 'hour', 'min']);

	return {
		value: valueResult.value && dateResult.value,
		notValid: [...valueResult.notValid, ...dateResult.notValid],
	};
};

export const examMemberSetPasswordFormData = data => {
	const result = examValue(data, ['password', 'repeatPassword']);

	return {
		value: result.value,
		notValid: [...result.notValid],
	};
};

export const examReceiveData = (data, step) => {
	if (step === 1) {
		const valueResult = examValue(data, ['recipient', 'phone']);
		const addressResult = examSingleValue('address', data.address, ['county', 'district', 'other']);

		return {
			value: valueResult.value && addressResult.value,
			notValid: [...valueResult.notValid, ...addressResult.notValid],
		};
	}

	if (step === 3 && data.invoiceCarrierType.value === 'B2B') {
		const businessResult = examValue(data, ['taxNum']);

		return {
			value: businessResult.value,
			notValid: [...businessResult.notValid],
		};
	}

	return {
		value: true,
		notValid: [],
	};
};

export const examReserveMaintenanceFormData = data => {
	const valueResult = examValue(data, ['firstReservationTime', 'name', 'mobile', 'email']);
	const dateResult = examSingleValue('firstReservationDate', data.firstReservationDate, [
		'year',
		'month',
		'date',
	]);

	return {
		value: valueResult.value && dateResult.value,
		notValid: [...valueResult.notValid, ...dateResult.notValid],
	};
};

export const examTestDriveFormData = data => {
	const valueResult = examValue(data, ['shopId', 'name', 'gender', 'email', 'mobile']);

	return {
		value: valueResult.value,
		notValid: [...valueResult.notValid],
	};
};
