/* eslint-disable react/jsx-props-no-spreading */
import React, { useLayoutEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTransition, animated, config } from 'react-spring';
import { useModal } from 'models/modal';
import classnames from 'classnames';

import styles from './index.css';

const ModalComponent = ({
	className,
	children,
	active,
	onRest = () => {},
	onDeactivate = () => {},
}) => {
	const transitions = useTransition(active, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: config.stiff,
		onDestroyed: onRest,
	});

	const [, { detectScrollTop }] = useModal();
	const ref = useRef(null);
	const handleScroll = () => {
		detectScrollTop(ref.current.scrollTop);
	};

	const handleClick = e => {
		if (e.target === e.currentTarget) {
			onDeactivate();
		}
	};

	return (
		<>
			{transitions.map(
				({ item, props, key }) =>
					item && (
						<animated.div
							key={key}
							className={classnames(styles.modal, className)}
							style={props}
							onClick={handleClick}
							ref={ref}
							onScroll={handleScroll}
						>
							{children}
						</animated.div>
					),
			)}
		</>
	);
};

const Modal = ({ children, ...other }) => {
	const refDiv = useRef(document.createElement('div'));

	useLayoutEffect(() => {
		let modalRoot = document.getElementById('modal-root');

		if (modalRoot === null) {
			modalRoot = document.createElement('div');
			modalRoot.setAttribute('id', 'modal-root');
			document.body.appendChild(modalRoot);
		}

		modalRoot.appendChild(refDiv.current);

		return () => {
			modalRoot.removeChild(refDiv.current);
		};
	}, []);

	return ReactDOM.createPortal(
		<ModalComponent {...other}>{children}</ModalComponent>,
		refDiv.current,
	);
};

export default Modal;
