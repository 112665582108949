import React from 'react';
import classnames from 'classnames';
import { useTransition, animated, config } from 'react-spring';

import { useOrder } from 'models/order';
import { useDialogModal } from 'models/modal';

import Button from 'components/atoms/Button';
import Counter from 'components/atoms/Counter';
import Input from 'components/atoms/Input';
import ReasonSelector from 'components/molecules/ReasonSelector';

import { useBoolean } from 'util/hook';

import styles from './index.css';

const { IMAGE_ENDPOINT } = process.env;

const Form = ({ close, toggleWarning }) => {
	const [, { openModal }] = useDialogModal();
	const [
		{ targetOrder, returnForm, loading, contract },
		{ updateForm, updateContract, submitReturn },
	] = useOrder();
	const { item, only_return_all: onlyReturnAll } = targetOrder;
	const [isCheckable, { setTrue: checkable }] = useBoolean({
		defaultBoolean: onlyReturnAll,
	});

	const onChangeQuantity = (count, index) => {
		updateForm({
			index,
			key: 'quantity',
			data: count,
		});
	};

	const handleScroll = e => {
		if (isCheckable) {
			return;
		}

		if (e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10) {
			checkable();
		}
	};

	return (
		<>
			{onlyReturnAll ? (
				<div className={classnames(styles.content, styles.onlyReturnAllContent)}>
					<p className={styles.onlyReturnAllTitle}>此訂單僅可整筆退貨</p>
					<p className={styles.onlyReturnAll}>
						此訂單因已使用活動優惠，如需退貨僅可整筆退貨，您可以至線上商城 FAQ 查看退貨相關規則 。
					</p>
					<p className={styles.contract}>
						若您在發票當月提出「全部商品退貨」，即已同意由 Vespa
						台灣官方商城代為處理銷貨退回證明單，以利加速退貨、退款作業。並請務必將紙本發票隨商品及贈品一同退回，待商品完成驗收退貨作業後，本商城將直接開立折讓單上傳至財政部電子發票整合服務平台存證。
					</p>
				</div>
			) : (
				<div className={styles.content} onScroll={handleScroll}>
					<p className={styles.title}>選擇退貨商品</p>
					{item.map((i, index) => (
						<div key={i.id}>
							<div className={styles.contentRow}>
								<Input
									checkboxClassName={styles.input}
									labelClassName={styles.label}
									type="checkbox"
									label={i.name}
									checked={returnForm[index].return}
									onClick={e => {
										updateForm({
											index,
											key: 'return',
											data: e.target.checked,
										});
									}}
								/>
								<div
									className={styles.image}
									style={{
										backgroundImage: `url(${IMAGE_ENDPOINT}/thumbnail/${i.photos[0] &&
											i.photos[0].filename})`,
									}}
								/>
								<div className={styles.infoWrapper}>
									<div className={styles.titleWrapper}>
										<p>{i.name}</p>
										<p>尺寸：{i.spec === '' ? '-' : i.spec}</p>
									</div>
									<Counter
										className={styles.counter}
										count={returnForm[index].quantity}
										setCount={c => onChangeQuantity(c, index)}
										maxCount={i.quantity}
									/>
								</div>
							</div>
							{returnForm[index].return && <ReasonSelector index={index} />}
						</div>
					))}
					<p className={styles.contract}>
						1. 訂單商品申請部分商品退貨時，即已同意由 Vespa
						台灣官方商城代為處理銷貨退回證明單，以利加速退貨、退款作業。待商品完成驗收退貨作業後，本商城將直接開立折讓單上傳至財政部電子發票整合服務平台存證。紙本發票不須隨商品一同退回。
					</p>
					<p className={styles.contract}>
						2. 若您在發票當月提出「全部商品退貨」，即已同意由 Vespa
						台灣官方商城代為處理銷貨退回證明單，以利加速退貨、退款作業。並請務必將紙本發票隨商品一同退回，待商品完成驗收退貨作業後，本商城將直接開立折讓單上傳至財政部電子發票整合服務平台存證。
					</p>
				</div>
			)}
			<div className={classnames(styles.buttonWrapper, { [styles.noBorder]: onlyReturnAll })}>
				<Input
					className={styles.contractCheckbox}
					label="我已閱讀並同意上述辦法，繼續進行退貨。"
					type="checkbox"
					labelClassName={classnames(styles.label, { [styles.disabled]: !isCheckable })}
					checked={contract.value}
					disabled={!isCheckable}
					onChange={e => {
						if (e.target.checked) {
							updateContract();
						}
					}}
				/>
				<Button
					size="large"
					color="primary"
					disabled={
						loading || (!onlyReturnAll && returnForm.every(rf => !rf.return)) || !contract.value
					}
					onClick={() => {
						submitReturn({ type: 'return' }).then(({ value }) => {
							if (value.success) {
								close();
								setTimeout(() => {
									openModal({ category: 'dialog', type: 'returnSuccess' });
								}, 500);
							} else if (value.errorCode === 'return_amount_less_0') {
								toggleWarning();
							}
						});
					}}
				>
					送出
				</Button>
			</div>
		</>
	);
};

const Warning = ({ back }) => {
	return (
		<>
			<div className={styles.warningContent}>
				<h2>提醒！建議整筆退貨</h2>
				<p>
					系統試算您部分退貨後之訂單不滿足站內促銷 / 個人優惠券 /
					免運門檻的條件，導致「部分退貨後訂單」與「原始訂單」有價差，且需由您補足差額的情形。因此，如需申請退貨還請您整筆退單。
				</p>
				<p>您也可查看線上商城 FAQ 了解詳情。</p>
			</div>
			<div className={styles.buttonWrapper}>
				<Button size="large" color="primary" onClick={back}>
					返回
				</Button>
			</div>
		</>
	);
};

const ReturnRequest = ({ close = () => {} }) => {
	const [warning, { toggle: toggleWarning, setFalse: setWarning }] = useBoolean({
		defaultBoolean: false,
	});
	const transitions = useTransition(warning, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: config.stiff,
	});

	return transitions.map(({ item, props, key }) => (
		<animated.div key={key} className={classnames(styles.wrapper, styles.animate)} style={props}>
			{!item && <Form close={close} toggleWarning={toggleWarning} />}
			{item && <Warning back={setWarning} />}
		</animated.div>
	));
};

export default ReturnRequest;
