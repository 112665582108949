import React from 'react';

export default {
	path: '/rental/:id',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import('./Pickup')],
			render: ([PickupCheckForm]) => <PickupCheckForm />,
		},
		{
			path: '/pickup',
			components: () => [import('./Pickup')],
			render: ([PickupCheckForm]) => <PickupCheckForm />,
		},
		{
			path: '/returning',
			components: () => [import('./Return')],
			render: ([ReturnCheckForm]) => <ReturnCheckForm />,
		},
	],
};
