import Compressor from 'compressorjs';

export const compressor = file =>
	new Promise((resolve, reject) => {
		// eslint-disable-next-line
		new Compressor(file, {
			quality: 0.6,
			mimeType: 'image/jpeg',
			success: resolve,
			error: reject,
			maxWidth: 1920,
			maxHeight: 1920,
		});
	});
