import { combineReducers } from 'redux';

import routing from './routing';
import auth from './auth';
import user from './user';
import modal from './modal';
import rental from './rental';
import banner from './banner';
import ownership from './ownership';
import cities from './cities';
import news from './news';
import order from './order';
import notification from './notification';
import coupon from './coupon';
import shop from './shop';
import faq from './faq';
import contract from './contract';
import afterSales from './afterSales';
import testDrive from './testDrive';

const reducers = combineReducers({
	...routing.reducer,
	...auth.reducer,
	...user.reducer,
	...modal.reducer,
	...rental.reducer,
	...banner.reducer,
	...ownership.reducer,
	...cities.reducer,
	...news.reducer,
	...order.reducer,
	...notification.reducer,
	...coupon.reducer,
	...shop.reducer,
	...faq.reducer,
	...contract.reducer,
	...afterSales.reducer,
	...testDrive.reducer,
});

export default reducers;
