import React from 'react';
import { openModal } from 'models/modal';
import { replaceRoute } from 'models/routing';

export default {
	path: '/notSupport',
	components: () => [import(/* webpackChunkName: 'notSupport' */ './component')],
	render: ([NotSupport]) => <NotSupport />,
	onEnter: ({ store }) => {
		const isIE = false || !!document.documentMode;
		if (!isIE) {
			store.dispatch(replaceRoute({ pathname: '/' }));
			return;
		}

		store.dispatch(openModal({ category: 'dialog', type: 'ie' }));
	},
};
