import React from 'react';
import classnames from 'classnames';

import { useRouting } from 'models/routing';
import { useModal } from 'models/modal';
import { useAuth } from 'models/auth';
import { useUserData } from 'models/user';
import { useNotification } from 'models/notification';
import { useShop } from 'models/shop';

import { useBoolean } from 'util/hook';
import { useMedia } from 'util/hook/useMedia';

import Link from 'components/atoms/Link';
import Button from 'components/atoms/Button';
import DropdownMenu from 'components/atoms/DropdownMenu';
import Avatar from 'components/atoms/Avatar';

import AppleStore from 'images/home/applestore-md.png';
import GooglePlay from 'images/home/googleplay-md.png';
import CollapseIcon from 'images/icon/arrow-collapse.inline.svg';

import styles from './index.css';

const { APPLE_STORE_URL, GOOGLE_PLAY_URL } = process.env;

const LinkItem = ({ to, selected = false, onClick = () => {}, children, small = false }) => {
	const media = useMedia();

	return (
		<div className={styles.item}>
			<Link
				className={classnames(selected && styles.selected, { [styles.small]: small })}
				to={to}
				onClick={onClick}
			>
				{children}
			</Link>
			{media === 'desktop' && selected && <div className={styles.borderBottom} />}
		</div>
	);
};

export const NavigatorFirstLevel = ({ className, closeMenu = () => {}, setPage = () => {} }) => {
	const media = useMedia();
	const [{ pathname }] = useRouting();
	const [{ isLogin }, { logout }] = useAuth();
	const [{ name, avatar }] = useUserData();
	const [{ notification }] = useNotification();
	const [, { openModal, setModalBackgroundScrollY }] = useModal();
	const [, { fetchShopItems, initShopFilter }] = useShop();
	const [isActive, { toggle }] = useBoolean({ defaultBoolean: false });

	const hasNotification = notification.some(n => n.read_at === null);

	const onClickLogin = () => {
		openModal({ category: 'auth', type: 'login' });
		setTimeout(() => {
			closeMenu();
			setModalBackgroundScrollY();
		}, 300);
	};

	const onClickSignup = () => {
		openModal({ category: 'auth', type: 'signupLanding' });
		setTimeout(() => {
			closeMenu();
			setModalBackgroundScrollY();
		}, 300);
	};

	const onClickMenu = () => {
		closeMenu();
		toggle();
	};

	return (
		<nav className={classnames(styles.nav, className)}>
			<div className={classnames(styles.navList, { [styles.active]: isActive })}>
				<LinkItem to="/VesShare" selected={pathname === '/VesShare'} onClick={onClickMenu}>
					開始租車
				</LinkItem>
				<LinkItem
					to="/vespa-models"
					selected={pathname.includes('/vespa-models')}
					onClick={onClickMenu}
				>
					成為車主
				</LinkItem>
				<LinkItem
					to="/shop"
					selected={pathname === '/shop'}
					onClick={() => {
						onClickMenu();
						initShopFilter();
						fetchShopItems({ page: 1 });
					}}
				>
					線上商城
				</LinkItem>
				<div className={styles.dropdownContainer}>
					{media === 'desktop' && (
						<LinkItem
							to="/after-sales/model"
							selected={pathname.includes('/after-sales')}
							onClick={onClickMenu}
						>
							售後服務
						</LinkItem>
					)}
					{media !== 'desktop' && (
						<button
							className={classnames(styles.dropdownTitle, {
								[styles.active]: isActive,
								[styles.selected]: pathname.includes('/after-sales'),
							})}
							type="button"
							onClick={toggle}
						>
							售後服務
							<CollapseIcon />
						</button>
					)}
					{media !== 'desktop' && isActive && (
						<div className={styles.dropdownContent}>
							<Link className={styles.dropdownItem} to="/after-sales/model" onClick={onClickMenu}>
								我的車款
							</Link>
							<Link
								className={styles.dropdownItem}
								to="/after-sales/notifications"
								onClick={onClickMenu}
							>
								愛車提醒
							</Link>
							<Link
								className={styles.dropdownItem}
								to="/after-sales/service-activity"
								onClick={onClickMenu}
							>
								服務活動
							</Link>
							<Link
								className={styles.dropdownItem}
								to="/after-sales/maintenance"
								onClick={onClickMenu}
							>
								預約保修服務
							</Link>
							<Link
								className={styles.dropdownItem}
								to="https://www.vespa.com/tw_ZH/dealer-locator/?f=all"
								isExternal
								onClick={onClickMenu}
							>
								維修據點查詢
							</Link>
						</div>
					)}
				</div>
				<LinkItem to="/news" selected={pathname === '/news'} onClick={onClickMenu}>
					最新消息
				</LinkItem>
				<LinkItem to="/#download" onClick={onClickMenu}>
					下載 APP
				</LinkItem>
				{media === 'desktop' && <div className={styles.vl} />}
				<div className={styles.item}>
					<a
						href="https://www.vespa.com/tw_ZH/"
						target="_blank"
						rel="noopener noreferrer"
						onClick={closeMenu}
					>
						官方網站
					</a>
				</div>
			</div>
			{media !== 'desktop' && (
				<div className={styles.socialButtons}>
					<Link className={styles.btn} to={APPLE_STORE_URL} isExternal>
						<img src={AppleStore} alt="" />
					</Link>
					<Link className={styles.btn} to={GOOGLE_PLAY_URL} isExternal>
						<img src={GooglePlay} alt="" />
					</Link>
				</div>
			)}

			<div className={styles.actionGroup}>
				{isLogin ? (
					<DropdownMenu
						className={styles.dropdown}
						setPage={setPage}
						name={name}
						avatar={avatar}
						logout={logout}
						hasNotification={hasNotification}
					/>
				) : (
					<>
						{media === 'desktop' ? (
							<button className={styles.login} onClick={onClickLogin} type="button">
								登入
							</button>
						) : (
							<Button size="large" color="primary" variant="outlined" onClick={onClickLogin}>
								登入
							</Button>
						)}
						<Button
							size="large"
							color="primary"
							variant={media !== 'desktop' ? 'normal' : 'outlined'}
							onClick={onClickSignup}
						>
							{media !== 'desktop' ? '註冊' : '立即註冊'}
						</Button>
					</>
				)}
			</div>
		</nav>
	);
};

export const NavigatorSecondLevel = ({ className, closeMenu = () => {} }) => {
	const [, { logout }] = useAuth();
	const [{ pathname }] = useRouting();
	const [{ name, avatar }] = useUserData();
	const [{ notification }] = useNotification();

	const hasNotification = notification.some(n => n.read_at === null);

	return (
		<nav className={classnames(styles.nav, className)}>
			<div className={styles.avatarButton}>
				<Avatar name={name} image={avatar.thumbnail} />
			</div>
			<div className={classnames(styles.navList, styles.navListSecond)}>
				<LinkItem to="/member/basic" selected={pathname === '/member/basic'} onClick={closeMenu}>
					會員基本資料
				</LinkItem>
				<LinkItem
					small
					to="/member/basic"
					selected={pathname === '/member/basic'}
					onClick={closeMenu}
				>
					基本資料
				</LinkItem>
				<LinkItem
					small
					to="/member/identities"
					selected={pathname === '/member/identities'}
					onClick={closeMenu}
				>
					身分證與駕照
				</LinkItem>
				<LinkItem
					small
					to="/member/connection"
					selected={pathname === '/member/connection'}
					onClick={closeMenu}
				>
					帳號連結
				</LinkItem>
				<LinkItem
					small
					to="/member/password"
					selected={pathname === '/member/password'}
					onClick={closeMenu}
				>
					設定密碼
				</LinkItem>
				<LinkItem
					to="/member/credit-card"
					selected={pathname === '/member/credit-card'}
					onClick={closeMenu}
				>
					付款資訊設定
				</LinkItem>
				<LinkItem
					to="/after-sales/model"
					selected={pathname === '/after-sales/model'}
					onClick={closeMenu}
				>
					我的車款
				</LinkItem>
				<LinkItem
					to="/member/my-order"
					selected={pathname === '/member/my-order'}
					onClick={closeMenu}
				>
					我的訂單
				</LinkItem>
				<LinkItem to="/member/coupon" selected={pathname === '/member/coupon'} onClick={closeMenu}>
					我的優惠券
				</LinkItem>
				<LinkItem
					to="/member/invitation"
					selected={pathname === '/member/invitation'}
					onClick={closeMenu}
				>
					好友推薦活動
				</LinkItem>
				<LinkItem
					to="/member/notification-center"
					selected={pathname === '/member/notification-center'}
					onClick={closeMenu}
				>
					訊息中心
					{hasNotification && <span />}
				</LinkItem>
			</div>

			<div className={classnames(styles.actionGroup, styles.actionGroupSecond)}>
				<div
					role="button"
					tabIndex={0}
					onKeyPress={() => {}}
					onClick={() => {
						logout();
						closeMenu();
					}}
				>
					登出
				</div>
			</div>
		</nav>
	);
};
