import React from 'react';
import classnames from 'classnames';

import { useToastModal } from 'models/modal';

import Modal from 'components/molecules/Modal';

import Success from 'images/icon/toast-success.inline.svg';
import Warning from 'images/icon/status-warning.inline.svg';

import styles from './index.css';

const ModalToast = ({ className }) => {
	const [
		{
			type,
			data: { message = '' },
		},
		{ closeModal },
	] = useToastModal();

	const onRestHandler = () => {
		if (type !== '') {
			setTimeout(() => {
				closeModal({ category: 'toast' });
			}, 3000);
		}
	};

	return (
		<Modal
			active={type}
			className={classnames(styles.modalToast, className)}
			onRest={onRestHandler}
		>
			{(message || type) && (
				<div className={styles.toastWrapper}>
					{type === 'success' && <Success />}
					{type === 'failed' && <Warning />}
					<p>{message}</p>
				</div>
			)}
		</Modal>
	);
};

export default ModalToast;
