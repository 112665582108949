import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useTransition, animated, config } from 'react-spring';

import { useModal } from 'models/modal';

import { useMedia } from 'util/hook/useMedia';
import { useBoolean } from 'util/hook';

import Link from 'components/atoms/Link';
import { NavigatorFirstLevel, NavigatorSecondLevel } from 'components/molecules/Navigation';
import Logo from 'images/icon/vespa-logo.inline.svg';
import MenuBarDefault from 'images/icon/menu.inline.svg';
import MenuBarClose from 'images/icon/close.inline.svg';
import BackIcon from 'images/icon/back.inline.svg';

import styles from './index.css';

const DesktopComponent = () => (
	<header className={classnames(styles.header, styles.desktop)}>
		<Link className={styles.logoWrapper} to="/">
			<Logo />
		</Link>
		<NavigatorFirstLevel />
	</header>
);

const MobileComponent = ({ isMenuOpen, closeMenu, toggleMenu, page, setPage = () => {} }) => {
	const transitions = useTransition(page, null, {
		initial: null,
		from: { transform: page === 2 ? `translate3d(100%,0,0)` : `translate3d(-100%,0,0)` },
		enter: { transform: 'translate3d(0%,0,0)' },
		leave: { transform: page === 2 ? `translate3d(-50%,0,0)` : `translate3d(50%,0,0)` },
		config: config.default,
		immediate: !isMenuOpen,
	});

	const onClose = () => {
		toggleMenu();
		setTimeout(() => setPage(1), 300);
	};

	return transitions.map(({ item, props, key }) => (
		<animated.div
			key={key}
			className={classnames(styles.header, styles.mobile, isMenuOpen && styles.open)}
			style={props}
		>
			{item === 1 && (
				<>
					<div className={styles.main}>
						<Link className={styles.logoWrapper} to="/" onClick={closeMenu}>
							<Logo />
						</Link>
						<div
							className={styles.menuButton}
							role="button"
							tabIndex={0}
							onClick={toggleMenu}
							onKeyPress={() => {}}
						>
							{isMenuOpen ? <MenuBarClose /> : <MenuBarDefault />}
						</div>
					</div>
					<NavigatorFirstLevel
						className={styles.navigation}
						closeMenu={closeMenu}
						setPage={setPage}
					/>
				</>
			)}
			{item === 2 && (
				<>
					<div className={styles.main}>
						<div
							className={styles.leftIcon}
							onClick={() => setPage(1)}
							onKeyPress={() => {}}
							role="button"
							tabIndex="0"
						>
							<BackIcon />
						</div>
						<div
							className={styles.menuButton}
							role="button"
							tabIndex={0}
							onClick={onClose}
							onKeyPress={() => {}}
						>
							{isMenuOpen ? <MenuBarClose /> : <MenuBarDefault />}
						</div>
					</div>
					<NavigatorSecondLevel className={styles.navigation} closeMenu={onClose} />
				</>
			)}
		</animated.div>
	));
};

const Header = () => {
	const [page, setPage] = useState(1);
	const [, { setModalBackgroundScrollY, restoreModalBackgroundScrollY }] = useModal();
	const media = useMedia();

	const [isMenuOpen, { toggle: toggleMenu, setFalse: closeMenu }] = useBoolean({
		onTrue: setModalBackgroundScrollY,
		onFalse: restoreModalBackgroundScrollY,
	});

	useEffect(() => {
		if (media === 'desktop') {
			closeMenu();
		}
	}, [media]);

	if (media === 'desktop') {
		return <DesktopComponent />;
	}

	return (
		<MobileComponent
			isMenuOpen={isMenuOpen}
			toggleMenu={toggleMenu}
			closeMenu={closeMenu}
			page={page}
			setPage={setPage}
		/>
	);
};

export const DisabledHeader = () => (
	<div className={styles.disabledHeaderWrapper}>
		<div className={styles.disabledHeader} />
		<Header />
	</div>
);

export default Header;
