import { createAction, handleActions } from 'redux-actions';
import DOMPurify from 'dompurify';

import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { decodeHtmlEntities } from 'util/helper';

export const fetchFaq = createAction('FETCH_FAQ', query => async () => {
	const { data } = await wrapFetch(
		'faq',
		{
			method: 'GET',
		},
		query,
	);

	const sanitizeData = data.data.map(item => ({
		...item,
		answer: DOMPurify.sanitize(decodeHtmlEntities(item.answer)),
	}));

	return sanitizeData;
});

const reducer = {
	faq: handleActions(
		{
			FETCH_FAQ_PENDING: state => ({
				...state,

				loading: true,
				faq: [],
			}),

			FETCH_FAQ_FULFILLED: (state, action) => ({
				...state,

				loading: false,
				faq: action.payload,
			}),

			FETCH_FAQ_REJECTED: state => ({
				...state,

				loading: false,
				faq: [],
			}),
		},
		{
			faq: [],
			loading: false,
		},
	),
};

const selectFaq = state => state.faq;

export const useFaq = () =>
	useRedux(selectFaq, {
		fetchFaq,
	});

export default { reducer };
