import React from 'react';
import { fetchModels, clearTargetShop } from 'models/ownership';
import { fetchCarTourShops } from 'models/testDrive';

export default {
	path: '/vespa-models',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import('./List')],
			render: ([OwnerShip]) => <OwnerShip />,
		},
		{
			path: '/:type',
			components: () => [import('./List')],
			render: ([OwnerShip]) => <OwnerShip />,
		},
		{
			path: '/:type/:model',
			components: () => [],
			render: (_, children) => children,
			children: [
				{
					path: '',
					components: () => [import('./Inner')],
					render: ([OwnerShipInner]) => <OwnerShipInner />,
					onEnter: async ({ store }) => {
						await store.dispatch(fetchModels());
						await store.dispatch(fetchCarTourShops());
					},
				},
				{
					path: '/option',
					components: () => [import('./Option')],
					render: ([OwnerShipOption]) => <OwnerShipOption />,
				},
				{
					path: '/shopping-list',
					components: () => [import('./ShoppingList')],
					render: ([OwnerShipShoppingList]) => <OwnerShipShoppingList />,
				},
				{
					path: '/checkout',
					components: () => [import('./Checkout')],
					render: ([OwnerShipCheckout]) => <OwnerShipCheckout />,
					onEnter: async ({ store }) => {
						await store.dispatch(clearTargetShop());
					},
				},
				{
					path: '/mpg-payment',
					components: () => [import('./MpgPayment')],
					render: ([MpgPayment]) => <MpgPayment />,
				},
			],
		},
	],
};
