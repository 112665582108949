import dayjs from 'dayjs';

export const genderOptions = [
	{ value: 'MALE', label: '男性' },
	{ value: 'FEMALE', label: '女性' },
];

export const genderTitleOptions = [
	{ value: 'MALE', label: '先生' },
	{ value: 'FEMALE', label: '小姐' },
];

export const birthYearOptions = [];

const thisYear = dayjs().year() - 18;
for (let i = thisYear; i >= 1900; i -= 1) {
	birthYearOptions.push({ value: i, label: i });
}

const deafultMonthOptions = [
	{ value: 1, label: '一月' },
	{ value: 2, label: '二月' },
	{ value: 3, label: '三月' },
	{ value: 4, label: '四月' },
	{ value: 5, label: '五月' },
	{ value: 6, label: '六月' },
	{ value: 7, label: '七月' },
	{ value: 8, label: '八月' },
	{ value: 9, label: '九月' },
	{ value: 10, label: '十月' },
	{ value: 11, label: '十一月' },
	{ value: 12, label: '十二月' },
];

export const birthMonthOptions = year => {
	return year === thisYear
		? deafultMonthOptions.slice(0, dayjs().month() + 1)
		: deafultMonthOptions;
};

export const birthDaysOptions = (year = null, month = null) => {
	if (!month || !year) {
		return [];
	}
	const options = [];
	const daysInMonth = dayjs(`${year}/${month < 10 ? `0${month}` : month}`, 'YYYY/MM').daysInMonth();
	for (let i = 1; i <= daysInMonth; i += 1) {
		options.push({ value: i, label: i });
	}
	return year === thisYear && month === dayjs().month() + 1
		? options.slice(0, dayjs().date())
		: options;
};

export const countyOptions = (countyArray = []) => {
	if (countyArray.length < 1) {
		return [];
	}
	const options = [];
	for (let i = 0; i < countyArray.length; i += 1) {
		options.push({ value: countyArray[i].id, label: countyArray[i].name });
	}
	return options;
};

export const districtOptions = (countyArray = [], county) => {
	const districtArray = countyArray.filter(c => c.id === county)[0]
		? countyArray.filter(c => c.id === county)[0].districts
		: [];

	if (districtArray.length < 1) {
		return [];
	}
	const options = [];
	for (let i = 0; i < districtArray.length; i += 1) {
		options.push({ value: districtArray[i].id, label: districtArray[i].name });
	}
	return options;
};

export const licenseOptions = [
	{ value: 'MIDDLE', label: '普通重型機車（白牌機車）' },
	{ value: 'HEAVY', label: '大型重型機車（黃牌以上機車）' },
];

export const fuelCapacityOptions = [
	{ value: 'FULL', label: '全滿' },
	{ value: 'LESS', label: '未滿 1/2' },
	{ value: 'HALF', label: '超過 1/2 但未滿箱' },
];

export const rentTimeOptions = (rentTimes = [], rentalAvailable) => {
	const options = [];

	if (rentalAvailable && rentTimes.length > 0) {
		// eslint-disable-next-line no-restricted-syntax
		for (const rt of rentTimes) {
			options.push({ value: rt, label: rt });
		}

		return options;
	}

	return options;
};

export const returnTimeOptions = (returnTimes = [], rentingTime = '') => {
	if (rentingTime === '') {
		return [];
	}

	const options = [];
	if (returnTimes.length > 0) {
		// eslint-disable-next-line no-restricted-syntax
		for (const rt of returnTimes) {
			options.push({ value: rt, label: rt });
		}

		return options;
	}

	return options;
};

export const invoiceOptions = [
	{ value: 'DEFAULT', label: '會員載具' },
	{ value: 'MOBILE', label: '手機條碼載具' },
	{ value: 'DONATE', label: '發票捐贈' },
	{ value: 'MOICA', label: '自然人憑證載具' },
	{ value: 'BUSINESS', label: '公司發票' },
];

// 購物車頁的選項
export const cartInvoiceOptions = [
	{ value: 'PERSONAL', label: '個人發票' },
	{ value: 'MOBILE', label: '手機條碼載具' },
	{ value: 'DONATE', label: '發票捐贈' },
	{ value: 'MOICA', label: '自然人憑證載具' },
	{ value: 'BUSINESS', label: '公司發票' },
];

export const mapPaymentType = {
	ALL: '一次付清',
	DEPOSIT: '訂金5000',
	LOAN: '貸款分期',
	LOAN_IGET: '貸款分期',
	LOAN_CUSTOM: '貸款分期',
};

export const mapStatusType = {
	rental: {
		ACCEPTED: '訂單成立',
		TIMEOUT: '逾時取車',
		SUCCESS: '取車完成',
		COMPLETED: '還車完成',
		CANCELLED: '交易取消',
		FAILED: '付款失敗',
	},
	carSale: {
		LOAN_APPLYING: '貸款申請中',
		ACCEPTED: '訂單成立',
		LOANPAYING: '貸款撥款中',
		LOANPAID: '貸款已撥款',
		PREPARED: '車輛整備完成',
		COMPLETED: '交車完成',
		CANCELLED: '交易取消',
		FAILED: '付款失敗',
	},
	mall: {
		ACCEPTED: '商品準備中',
		SHIPPING: '已配送',
		CANCELLED: '交易取消',
		FAILED: '交易失敗',
		RETURNING: '退貨中',
		RETURNED: '退貨處理完成',
	},
	mallItem: {
		ACCEPTED: '商品準備中',
		SHIPPING: '已配送',
		RETURNING: '退貨中',
		RETURN_CONFIRM: '退貨確認',
		RETURN_REFUSE: '退貨拒絕',
		CANCELLED: '已取消',
		FAILED: '交易失敗',
	},
	afterSales: {
		reserved: '已預約',
		finished: '已完成',
		canceled: '已取消',
		進廠接待: { title: '進廠接待', description: '誠摯歡迎您的蒞臨！' },
		完工結案: { title: '完工結案', description: '包含配件加裝的工作項目' },
		結帳離廠: { title: '結帳離廠', description: '與您現場確認無誤，歡迎再次光臨' },
	},
};

export const mapInvoiceCarrierType = {
	DEFAULT: {
		invoice: '會員載具',
		title: '中獎發票寄送地址',
	},
	MOBILE: {
		invoice: '手機條碼載具',
		title: '手機條碼載具',
	},
	MOICA: {
		invoice: '自然人憑證載具',
		title: '自然人憑證載具',
	},
	DONATE: {
		invoice: '發票捐贈',
		title: '愛心碼',
	},
	BUSINESS: {
		invoice: '公司發票',
		title: '統一編號',
		title2: '抬頭',
	},
};

export const sortOptions = [
	{ value: 'PRICE_DESC', label: '價格由高到低' },
	{ value: 'PRICE_ASC', label: '價格由低到高' },
	{ value: 'LATEST_ONLINE', label: '最新上架' },
];

export const reservationOptions = [
	{ value: 'repair', label: '維修服務' },
	{ value: 'maintenance', label: '保養' },
];

export const vehicleRegistrationPlateOptions = (vehicleRegistrationPlateArray = []) => {
	const options = [];

	if (vehicleRegistrationPlateArray.length < 1) {
		return [];
	}

	for (let i = 0; i < vehicleRegistrationPlateArray.length; i += 1) {
		options.push({
			value: vehicleRegistrationPlateArray[i].license_plate_number,
			label: vehicleRegistrationPlateArray[i].license_plate_number,
			disabled: vehicleRegistrationPlateArray[i].reserved_after_sales_reservations.length > 0,
			reserved: vehicleRegistrationPlateArray[i].reserved_after_sales_reservations.length > 0,
		});
	}

	return options;
};

export const businessHourOption = (dayArray = [], targetDay) => {
	const options = [];
	const businessHour = [
		'08:30',
		'09:00',
		'09:30',
		'10:00',
		'10:30',
		'11:00',
		'11:30',
		'12:00',
		'12:30',
		'13:00',
		'13:30',
		'14:00',
		'14:30',
		'15:00',
		'15:30',
		'16:00',
		'16:30',
		'17:00',
		'17:30',
		'18:00',
		'18:30',
		'19:00',
		'19:30',
		'20:00',
		'20:30',
		'21:00',
		'21:30',
		'22:00',
		'22:30',
		'23:00',
	];
	const dayOfWeekData = dayArray && dayArray[dayArray.findIndex(data => data.day === targetDay)]; // ex: {id: 1, day: '星期一', business_hour: ['08:30 - 22:00']}

	// 如果為空陣列，代表當天不營業
	if (!dayOfWeekData || dayOfWeekData.business_hour.length === 0) {
		return [];
	}

	// business_hour最多可以有三個時段，所以需處理
	dayOfWeekData.business_hour.forEach(timeSpan => {
		const [start, end] = timeSpan.split(' - '); // ['08:30', '22:00']

		let [openHour, openMinute] = start.split(':').map(Number); // [8, 30]
		let [closeHour, closeMinute] = end.split(':').map(Number); // [22, 0]

		// 因為後台營業時間設定為 15 分鐘為一單位，所以需處理 15、45 分之情境
		switch (openMinute) {
			case 15:
				openMinute = 30;
				break;
			case 45:
				openHour += 1;
				openMinute = 0;
				break;
			default:
				break;
		}

		// 處理結束時間
		switch (closeMinute) {
			case 15:
				closeMinute = 0;
				break;
			case 45:
				closeHour += 1;
				closeMinute = 30;
				break;
			default:
				break;
		}
		const openTime = `${String(openHour).padStart(2, '0')}:${String(openMinute).padStart(2, '0')}`; // 08:30
		const closeTime = `${String(closeHour).padStart(2, '0')}:${String(closeMinute).padStart(
			2,
			'0',
		)}`; // 22:00

		const openTimeIndex = businessHour.findIndex(time => time === openTime);
		const closeTimeIndex = businessHour.findIndex(time => time === closeTime);

		for (let i = openTimeIndex; i < closeTimeIndex; i += 1) {
			options.push({
				value: businessHour[i],
				label: businessHour[i],
				disabled: false,
			});
		}
	});
	return options;
};

export const storeOptions = shopsArray =>
	shopsArray.map(s => ({
		value: s.name,
		label: s.name,
		city: s.city.name,
		district: s.district.name,
		name: s.name,
		searchValue: `${s.city.name} ${s.district.name} ${s.name}`,
		id: s.id,
	}));

export const contactTimeOptions = [
	{ id: 1, value: 'morning', label: '上午（9:00-12:00）' },
	{ id: 2, value: 'noon', label: '下午（13:00-17:00）' },
	{ id: 3, value: 'night', label: '晚上（18:00-21:00）' },
];

export const modelOptions = (modelArray = []) => {
	return modelArray.map(model => ({
		id: model.id,
		label: model.name,
		value: model.name,
	}));
};
