import React from 'react';

import { getBanner } from 'models/banner';
import { fetchNews } from 'models/news';
import { replaceRoute } from 'models/routing';
import { fetchHomeModels } from 'models/ownership';
import { logout, setIsWebview } from 'models/auth';

import LoginRoute from './Login';
import SignupRoute from './Signup';
import ResetRoute from './Reset';
import VerifyRoute from './Verify';
import VesShareRoute from './VesShare';
import OwnerShipRoute from './OwnerShip';
import MemberRoute from './Member';
import CheckRoute from './Check';
import NewsRoute from './News';
import AfterSalesRoute from './AfterSales';
import DisclaimerRoute from './Disclaimer';
import PrivacyRoute from './Privacy';
import MembershipTermsRoute from './MembershipTerms';
import RegulationRoute from './Regulation';
import FaqRoute from './Faq';
import ShopRoute from './Shop';
import WebViewRoute from './WebView';
import NotSupportRoute from './NotSupport';
import TestDrivePrivacyPreviewRoute from './TestDrivePrivacyPreview';

let timeoutId;
const routes = {
	path: '/',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next, store }) => {
		const isIE = false || !!document.documentMode;
		if (isIE) {
			store.dispatch(replaceRoute({ pathname: '/notSupport' }));
		}

		const { routing } = store.getState();
		if (routing.search.includes('src=app')) {
			store.dispatch(setIsWebview(true));
		}
		const children = await next();

		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'home' */ './Home')],
			render: ([Home]) => <Home />,
			onEnter: async ({ next, store }) => {
				await store.dispatch(getBanner());
				await store.dispatch(fetchNews());
				await store.dispatch(fetchHomeModels());
				const children = await next();

				store.subscribe(async () => {
					if (timeoutId) {
						clearTimeout(timeoutId);
					}

					timeoutId = setTimeout(async () => {
						await store.dispatch(logout());
						store.dispatch(replaceRoute({ pathname: '/' }));
					}, 1800000);
				});

				return children;
			},
		},
		LoginRoute,
		SignupRoute,
		ResetRoute,
		VerifyRoute,
		VesShareRoute,
		OwnerShipRoute,
		MemberRoute,
		CheckRoute,
		NewsRoute,
		AfterSalesRoute,
		DisclaimerRoute,
		PrivacyRoute,
		MembershipTermsRoute,
		RegulationRoute,
		FaqRoute,
		ShopRoute,
		WebViewRoute,
		NotSupportRoute,
		TestDrivePrivacyPreviewRoute,
	],
};

export default routes;
