import React from 'react';

import { fetchServiceActivity } from 'models/afterSales';

export default {
	path: '/news',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import('./List')],
			render: ([NewsList]) => <NewsList />,
		},
		{
			path: '/:id',
			components: () => [],
			render: (_, children) => children,
			children: [
				{
					path: '',
					components: () => [import('./Inner')],
					render: ([NewsInner]) => <NewsInner />,
					onEnter: async ({ store, next }) => {
						await store.dispatch(fetchServiceActivity());

						const children = await next();

						return children;
					},
				},
			],
		},
	],
};
