import { createAction, handleActions } from 'redux-actions';
import dataURLtoBlob from 'blueimp-canvas-to-blob';
import lodash from 'lodash';
import qs from 'qs';
import DOMPurify from 'dompurify';

import { wrapFetch, wrapAuthFetchFormData, wrapAuthFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { examOwnershipFormData } from 'util/exam';
import { clearOwnershipFormData } from 'util/clear';
import { decodeHtmlEntities } from 'util/helper';

import { openModal, openNormalModal } from 'models/modal';
import { pushRoute } from 'models/routing';

const validateForm = createAction('VALIDATE_OWNERSHIP_FORM', (type, key, valid, error) => ({
	type,
	key,
	valid,
	error,
}));

const clearFormError = createAction('CLEAR_FORM_ERROR', type => (_, getState) => {
	const {
		ownership: { ownershipForm },
	} = getState();

	const clearData = clearOwnershipFormData(ownershipForm);

	return {
		type,
		data: clearData,
	};
});

const clearForm = createAction('CLEAR_OWNERSHIP_FORM');

export const updateForm = createAction('UPDATE_OWNERSHIP_FORM', ({ type, key, data }) => () => ({
	type,
	key,
	data,
}));

const fetchData = createAction('FETCH_OWNERSHIP_DATA', ({ key, api }) => async () => {
	const { status, message, data } = await wrapFetch(api, {
		method: 'GET',
	});
	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	// 滲透測試 H2 要針對 sellingCarTypes api 做 dompurify
	if (api === 'sellingCarTypes') {
		const sanitizeData = data.data.map(item => {
			const sanitizeTypes = item.types.map(type => {
				const sanitizedDescription = DOMPurify.sanitize(decodeHtmlEntities(type.description));
				return {
					...type,
					description: sanitizedDescription,
				};
			});

			return {
				...item,
				types: sanitizeTypes,
			};
		});

		return { key, data: sanitizeData };
	}
	return { key, data: data.data };
});

export const fetchHomeModels = () => dispatch => {
	dispatch(fetchData({ key: 'homeModels', api: 'indexCarTypes' }));
};

export const fetchModels = () => dispatch => {
	dispatch(fetchData({ key: 'models', api: 'sellingCarTypes' }));
};

export const fetchTargetModel = createAction(
	'FETCH_TARGET_OWNERSHIP_MODEL',
	() => async (_, getState) => {
		const {
			routing: { pathname, search },
		} = getState();
		const { preview_key: previewKey, wording_item: wordingItem } = qs.parse(search, {
			ignoreQueryPrefix: true,
		});
		const carId = pathname.split('/')[3];

		const api = `sellingCarTypes/${carId}`;
		const headers = (() => {
			switch (true) {
				case previewKey && wordingItem:
					return {};
				case previewKey && !wordingItem:
					return { 'X-VESPA-PREVIEW-KEY': previewKey };
				default:
					return {};
			}
		})();
		// const headers = previewKey ? { 'X-VESPA-PREVIEW-KEY': previewKey } : {};
		const { status, message, data } = await wrapFetch(api, {
			method: 'GET',
			headers,
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		const sanitizeDetails = DOMPurify.sanitize(decodeHtmlEntities(data.data.details));

		const sanitizeData = {
			...data.data,
			details: sanitizeDetails,
		};

		return sanitizeData;
	},
);

export const fetchModelIncreasePurchases = createAction(
	'FETCH_MODEL_INCREASE_PURCHASES',
	() => async (_, getState) => {
		const {
			ownership: { targetModel },
		} = getState();
		const carId = targetModel.id;
		const api = `sellingCarTypes/${carId}/increasePurchases`;
		const { status, message, data } = await wrapFetch(api, {
			method: 'GET',
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return data.data;
	},
);

export const fetchModelAdditionalPurchases = createAction(
	'FETCH_MODEL_ADDITIONAL_PURCHASES',
	() => async (_, getState) => {
		const {
			ownership: { targetModel },
			auth: {
				token: { access_token: accessToken },
			},
		} = getState();
		const carId = targetModel.id;
		const api = `sellingCarTypes/${carId}/additional-purchases`;
		const { status, message, data } = await wrapFetch(api, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return data.data;
	},
);

export const fetchShops = () => dispatch => {
	dispatch(fetchData({ key: 'shops', api: 'shops?service=CAR_SALE' }));
};

export const getDistance = createAction('SET_DISTANCE', origin => async (_, getState) => {
	const {
		ownership: {
			targetShop: { latitude, longitude },
		},
	} = getState();

	const destination = `${latitude},${longitude}`;

	const { rows } = await wrapFetch(`distances?origins=${origin}&destinations=${destination}`, {
		method: 'GET',
	});

	return { data: rows[0].elements[0].distance.text };
});

export const fetchTargetShop = () => async (dispatch, getState) => {
	let origin;
	const {
		ownership: {
			ownershipForm: { shopId },
		},
	} = getState();
	const getOrigin = async () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(position => {
				origin = `${position.coords.latitude},${position.coords.longitude}`;
			});
		}
	};
	await getOrigin();
	await dispatch(fetchData({ key: 'targetShop', api: `shops/${shopId.value}` }));
	dispatch(getDistance(origin));
};

export const fetchOwnershipPrice = createAction(
	'FETCH_OWNERSHIP_PRICE',
	() => async (dispatch, getState) => {
		const {
			ownership: {
				ownershipForm: { colorId, increasePurchases, additionalPurchases, paymentType, coupon },
			},
			routing: { search },
		} = getState();

		const parsed = qs.parse(search, { ignoreQueryPrefix: true });
		const query = () => {
			const addiData = JSON.stringify(
				additionalPurchases.value.map(_ => {
					return { sid: _.sid, amount: _.amount };
				}),
			);

			let queryValue = {
				color_id: colorId.value || parsed.color,
				increase_purchases: increasePurchases.value,
				additional_purchases: additionalPurchases.value.map(_ => {
					return { sid: _.sid, amount: _.amount };
				}),
				payment_type: paymentType.value,
				coupon_id: coupon.value,
			};

			if (additionalPurchases.value.length > 0) {
				queryValue = { ...queryValue, additional_purchases: addiData };
			}

			if (coupon.checked && coupon.value) {
				queryValue = { ...queryValue, coupon_id: coupon.value };
			}
			return queryValue;
		};
		const { status, message, data } = await wrapAuthFetch(
			'carSales/calc',
			{
				method: 'GET',
			},
			query(),
		);
		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return data.data;
	},
);

export const purchase = createAction('SUBMIT_PURCHASE', () => async (dispatch, getState) => {
	dispatch(clearFormError('ownershipForm'));

	const {
		ownership: { ownershipForm },
		routing: { pathname, search },
	} = getState();

	const checkData = examOwnershipFormData(ownershipForm);
	if (!checkData.value) {
		checkData.notValid.forEach(key => {
			if (key === 'signature') {
				dispatch(validateForm('ownershipForm', 'signature', false, '尚未審閱合約'));
			}
			dispatch(validateForm('ownershipForm', key, false, '此欄位不得空白'));
		});
		throw new Error('purchase form not completed');
	}
	if (
		ownershipForm.paymentType.value === 'LOAN_IGET' &&
		ownershipForm.loanIgetOption.value === ''
	) {
		dispatch(validateForm('ownershipForm', 'loanIgetOption', false, '此欄位不得空白'));
		throw new Error('purchase form not completed');
	}

	const formdata = new FormData();
	formdata.append('shop_id', ownershipForm.shopId.value);
	formdata.append('color_id', ownershipForm.colorId.value);
	if (ownershipForm.paymentType.value === 'LOAN_IGET') {
		formdata.append('installment_month', ownershipForm.loanIgetOption.value);
	}
	formdata.append('payment_type', ownershipForm.paymentType.value);
	formdata.append('coupon_id', ownershipForm.coupon.value);
	formdata.append('user_sign', dataURLtoBlob(ownershipForm.signature.value));
	formdata.append(
		'additional_purchases',
		JSON.stringify(
			ownershipForm.additionalPurchases.value.map(_data => {
				return {
					sid: _data.sid,
					amount: _data.amount,
				};
			}),
		),
	);
	for (let i = 0; i < ownershipForm.increasePurchases.value.length; i += 1) {
		formdata.append('increase_purchases[]', ownershipForm.increasePurchases.value[i]);
	}

	const { status, message, data, error_code: errorCode, extra } = await wrapAuthFetchFormData(
		'carSales',
		{
			method: 'POST',
			body: formdata,
		},
	);

	if (status !== 200 && status !== 201) {
		if (message === 'the user was invalid') {
			dispatch(openModal({ category: 'dialog', type: 'rentalFailedByStatusReject' }));
		} else if (errorCode === 'newebpay_error') {
			dispatch(
				openModal({
					category: 'dialog',
					type: 'creditCardException',
					data: { orderMessage: extra.data },
				}),
			);
		} else if (
			errorCode === 'not_enough_additional_purchases_stock' ||
			errorCode === 'not_enough_increase_purchases_stock'
		) {
			dispatch(openModal({ category: 'dialog', type: 'PurchaseAbnormal' }));
		} else if (errorCode === 'third_party_api_unavailable') {
			dispatch(openModal({ category: 'dialog', type: 'thirdPartyApiError' }));
		} else {
			dispatch(openModal({ category: 'dialog', type: 'purchaseFailed' }));
		}
		throw new Error(message);
	}

	// 選擇分期貸款支付，金額卻小於一千元，故訂單成立，但貸款不成立，未付款
	if (data.data.fartrust_result === 'Failed') {
		dispatch(openModal({ category: 'dialog', type: 'LoanOrderStatus' }));
		return {};
	}

	if (data.data.transaction_type && data.data.transaction_type === 'mpg_pay') {
		dispatch(pushRoute({ pathname: pathname.replace('checkout', 'mpg-payment'), search }));
		return data.data.transaction_data;
	}

	if (
		ownershipForm.paymentType.value === 'LOAN' ||
		ownershipForm.paymentType.value === 'LOAN_IGET' ||
		ownershipForm.paymentType.value === 'LOAN_CUSTOM'
	) {
		dispatch(openModal({ category: 'dialog', type: 'loanSuccess', data }));
	} else {
		dispatch(openNormalModal({ type: 'threeDReturn', data: { html: data.data['3d_html'] } }));
	}
	return {};
});

export const fetchStoreStock = createAction('FETCH_STORE_STOCK', async (zipCode, id) => {
	const { status, data } = await wrapFetch(
		'sellingEntityInventories/listByZipCodeAndSellingStockId',
		{
			method: 'POST',
			body: JSON.stringify({ zip_code: zipCode, selling_stock_id: id }),
		},
	);

	if (status !== 200 || !data) {
		return {
			storeStock: [],
		};
	}

	const storeStock = data.data.map(store => ({
		...store,
	}));

	return { storeStock };
});

export const moveToTarget = createAction('MOVE_TO_TARGET', () => async (dispatch, getState) => {
	const {
		ownership: { ownershipForm },
	} = getState();

	await dispatch(fetchStoreStock(ownershipForm.zipCode.value, ownershipForm.colorId.value));
	document
		.getElementsByClassName(String(ownershipForm.selectedShop.value))[0]
		.scrollIntoView({ behavior: 'smooth', block: 'start' });
});

export const fetchAllStoreStock = createAction('FETCH_ALL_STORE_STOCK', async id => {
	const { status, data } = await wrapFetch(
		'sellingEntityInventories/listByZipCodeAndSellingStockId',
		{
			method: 'POST',
			body: JSON.stringify({ zip_code: 'ALL', selling_stock_id: id }),
		},
	);

	if (status !== 200 || !data) {
		return {
			allStoreStock: [],
		};
	}

	const allStoreStock = data.data.map(store => ({
		...store,
	}));

	return { allStoreStock };
});

const fetchWordingData = createAction(
	'FETCH_WORDING_DATA',
	({ key, api }) => async (_, getState) => {
		const {
			routing: { search },
		} = getState();
		const { preview_key: previewKey, wording_item: wordingItem } = qs.parse(search, {
			ignoreQueryPrefix: true,
		});
		const apiWording = api.slice(13);
		const headers =
			previewKey && apiWording === wordingItem ? { 'X-VESPA-PREVIEW-KEY': previewKey } : {};

		const { status, message, data } = await wrapFetch(api, {
			method: 'GET',
			headers,
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return { key, data };
	},
);

export const fetchWording = () => dispatch => {
	dispatch(fetchWordingData({ key: 'loanIGetTitle', api: 'wording?item=輕鬆多期購 - 標題' }));
	dispatch(fetchWordingData({ key: 'loanCustomTitle', api: 'wording?item=輕鬆低日付 - 標題' }));
	dispatch(fetchWordingData({ key: 'freebieWording', api: 'wording?item=交車禮 - 備註文字' }));
	dispatch(fetchWordingData({ key: 'loanWording', api: 'wording?item=零利率貸款分期 - 備註文字' }));
};

export const clearTargetShop = createAction('CLEAR_TARGET_SHOP', type => ({
	type,
}));

export const defaultTargetShopData = {
	address: '',
	city: { id: 0, name: '' },
	district: { id: 0, name: '' },
	id: 0,
	name: '',
	openHours: '',
	phone: '',
	rentalCarTypes: [],
	distance: '',
	latitude: '',
	longitude: '',
};

export const defaultOwnershipFormData = {
	couponList: [],
	loanInfo: '',
	loanIgetOption: { value: '', valid: true, error: '' },
	shopId: { value: '', valid: true, error: '' },
	colorId: { value: '', valid: true, error: '' },
	increasePurchases: { value: [], valid: true, error: '' },
	additionalPurchases: { value: [], valid: true, error: '' },
	paymentType: { value: 'ALL', valid: true, error: '' },
	signature: { value: '', valid: true, error: '' },
	coupon: { checked: false, value: '', valid: true, error: '' },
	selectedShop: { value: '' },
	zipCode: { value: '114' },
	price: {
		car_price: 0,
		increase_purchases_price: 0,
		additional_purchases_price: 0,
		check_price: 0,
		discount: 0,
		amount: 0,
		use_coupon: false,
		coupons: [],
		loan_iget_options: [],
		loan_custom_info: '',
	},
	loading: false,
	error: '',
	// 只有當結帳金額超過 20 萬需要轉藍新金流頁面所需要的資料
	mpgData: {},
};

export const defaultTargetModalData = {
	id: 0,
	name: '',
	description: '',
	colors: [],
	price: 0,
	discount_desc: '',
	discount_price: 0,
	details: '',
	spec_img: '',
	spec_lat_img: '',
	length: '',
	width: '',
	wheelbase: '',
	seat_cushion_height: '',
	fuel_consumption: '',
	max_speed: '',
	engine_name: '',
	fuel_capacity: '',
	freebie: [],
	partial_freebie: [],
	iget_installment_remark: '',
	url: '',
	pdf_path: '',
};

const reducer = {
	ownership: handleActions(
		{
			FETCH_OWNERSHIP_DATA_FULFILLED: (state, action) => ({
				...state,

				[action.payload.key]: action.payload.data,
			}),

			FETCH_WORDING_DATA_FULFILLED: (state, action) => ({
				...state,

				[action.payload.key]: action.payload.data,
			}),

			FETCH_TARGET_OWNERSHIP_MODEL_PENDING: state => ({
				...state,

				loading: true,
				targetModel: defaultTargetModalData,
			}),

			FETCH_TARGET_OWNERSHIP_MODEL_FULFILLED: (state, action) => ({
				...state,

				loading: false,
				targetModel: action.payload,
			}),

			FETCH_MODEL_INCREASE_PURCHASES_PENDING: state => ({
				...state,

				increasePurchases: [],
			}),

			FETCH_MODEL_INCREASE_PURCHASES_FULFILLED: (state, action) => ({
				...state,

				increasePurchases: action.payload,
			}),

			FETCH_MODEL_ADDITIONAL_PURCHASES_PENDING: state => ({
				...state,

				additionalPurchases: [],
			}),

			FETCH_MODEL_ADDITIONAL_PURCHASES_FULFILLED: (state, action) => ({
				...state,

				additionalPurchases: action.payload,
			}),

			UPDATE_OWNERSHIP_FORM: (state, action) => ({
				...state,

				[action.payload.type]: {
					...state[action.payload.type],
					[action.payload.key]: {
						...state[action.payload.type][action.payload.key],
						...action.payload.data,
					},
				},
			}),

			VALIDATE_OWNERSHIP_FORM: (state, action) => ({
				...state,

				[action.payload.type]: {
					...state[action.payload.type],
					[action.payload.key]: {
						...state[action.payload.type][action.payload.key],
						valid: action.payload.valid,
						error: action.payload.error,
					},
				},
			}),

			CLEAR_FORM_ERROR: (state, action) => ({
				...state,

				[action.payload.type]: action.payload.data,
			}),

			SET_DISTANCE_FULFILLED: (state, action) => ({
				...state,

				targetShop: {
					...state.targetShop,
					distance: action.payload.data,
				},
			}),

			CLEAR_OWNERSHIP_FORM: state => ({
				...state,

				ownershipForm: defaultOwnershipFormData,
			}),

			FETCH_OWNERSHIP_PRICE_FULFILLED: (state, action) => ({
				...state,

				ownershipForm: {
					...state.ownershipForm,

					price: lodash.omit(action.payload, ['coupons']),
					couponList: action.payload.coupons,
					loanInfo: action.payload.loan_info || '',
				},
			}),

			SUBMIT_PURCHASE_PENDING: state => ({
				...state,

				ownershipForm: {
					...state.ownershipForm,
					error: '',
					loading: true,
				},
			}),

			SUBMIT_PURCHASE_FULFILLED: (state, action) => ({
				...state,

				ownershipForm: {
					...state.ownershipForm,
					loading: false,
					mpgData: action.payload,
				},
			}),

			SUBMIT_PURCHASE_REJECTED: (state, action) => ({
				...state,

				ownershipForm: {
					...state.ownershipForm,
					loading: false,
					error: action.payload.message,
				},
			}),

			FETCH_STORE_STOCK_FULFILLED: (state, action) => ({
				...state,
				loading: false,
				storeStock: action.payload.storeStock,
			}),

			FETCH_ALL_STORE_STOCK_FULFILLED: (state, action) => ({
				...state,
				loading: false,
				allStoreStock: action.payload.allStoreStock,
			}),

			CLEAR_TARGET_SHOP: state => ({
				...state,
				targetShop: defaultTargetShopData,
			}),
		},
		{
			ownershipForm: defaultOwnershipFormData,
			models: [],
			homeModels: [],
			targetModel: defaultTargetModalData,
			increasePurchases: [],
			additionalPurchases: [],
			shops: [],
			targetShop: defaultTargetShopData,
			storeStock: [],
			allStoreStock: [],
			loanIGetTitle: '',
			loanCustomTitle: '',
			freebieWording: '',
			loanWording: '',
			loading: false,
		},
	),
};

const selectOwnership = state => state.ownership;

export const useOwnership = () =>
	useRedux(selectOwnership, {
		fetchModels,
		fetchTargetModel,
		fetchModelIncreasePurchases,
		fetchModelAdditionalPurchases,
		fetchShops,
		fetchTargetShop,
		fetchStoreStock,
		fetchAllStoreStock,
		fetchWording,
		moveToTarget,
	});

const selectOwnershipForm = state => state.ownership.ownershipForm;

export const useOwnershipForm = () =>
	useRedux(selectOwnershipForm, {
		updateForm,
		fetchOwnershipPrice,
		purchase,
		clearForm,
		validateForm,
	});

export default { reducer };
