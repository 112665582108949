/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import { createAction, handleActions } from 'redux-actions';
import qs from 'qs';
import lodash from 'lodash';

import { openModal, openNormalModal } from 'models/modal';
import { pushRoute } from 'models/routing';

import { wrapFetch, wrapAuthFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { clearReceiveFormData } from 'util/clear';
import { examReceiveData } from 'util/exam';
import storage from 'util/storage';

const validateForm = createAction('VALIDATE_RECEIVE_FORM', (type, key, valid, error) => ({
	type,
	key,
	valid,
	error,
}));

const clearFormError = createAction(
	'CLEAR_RECEIVE_FORM_ERROR',
	(type, step = null) => (_, getState) => {
		const {
			shop: { receiveForm },
		} = getState();
		const clearData = clearReceiveFormData(receiveForm, step);

		return {
			type,
			data: clearData,
		};
	},
);

const clearReceiveForm = createAction('CLEAR_RECEIVE_FORM', type => ({
	type,
}));

export const updateFilter = createAction('UPDATE_SHOP_FILTER', ({ type, data }) => ({
	type,
	data,
}));

export const updateQueryFilter = createAction('UPDATE_SHOP_QUERY_FILTER', queryArr => queryArr);

const initReceiveForm = createAction('INIT_RECEIVE_FORM', () => dispatch => {
	dispatch(clearFormError('receiveForm'));
	dispatch(clearReceiveForm('receiveForm'));
});

export const updateForm = createAction('UPDATE_RECEIVE_FORM', ({ type, key, data }) => ({
	type,
	key,
	data,
}));

const checkStep = createAction('CHECK_STEP', step => (dispatch, getState) => {
	const {
		shop: {
			receiveForm: { recipient, phone, address, coupon, invoiceCarrierType, invoiceCarrierValue },
		},
	} = getState();
	let data = {};
	if (step === 1) {
		data = { step1: recipient.valid && phone.valid && address.valid };
	} else if (step === 2) {
		data = { step2: coupon.valid };
	} else if (step === 3) {
		data = {
			step3:
				invoiceCarrierType.value === 'PERSONAL' ||
				(invoiceCarrierType.value !== 'PERSONAL' && invoiceCarrierValue.valid),
		};
	}
	dispatch(
		updateForm({
			type: 'receiveForm',
			key: 'stepCheck',
			data: {
				...data,
			},
		}),
	);
});

export const initShopFilter = createAction('INIT_SHOP_FILTER');

const validateStepForm = createAction('VALIDATE_STEP_FORM', step => (dispatch, getState) => {
	dispatch(clearFormError('receiveForm', step));
	const {
		shop: { receiveForm },
	} = getState();

	let validStatus = 0;

	if (step === 1) {
		if (!/^09[0-9]{8}$/.test(receiveForm.phone.value)) {
			dispatch(validateForm('receiveForm', 'phone', false, '手機號碼格式不正確'));
			validStatus = 400;
		}

		const checkData = examReceiveData(receiveForm, step);
		if (!checkData.value) {
			checkData.notValid.map(key =>
				dispatch(validateForm('receiveForm', key, false, '此欄位不得空白')),
			);
			validStatus = 400;
		}
	} else if (step === 3) {
		if (
			receiveForm.invoiceCarrierType.value === 'MOBILE' &&
			!/^\/[-+.a-zA-Z0-9]{7}$/.test(receiveForm.invoiceCarrierValue.value)
		) {
			dispatch(validateForm('receiveForm', 'invoiceCarrierValue', false, '手機條碼格式不正確'));
			validStatus = 400;
		}
		if (
			receiveForm.invoiceCarrierType.value === 'DONATE' &&
			!/^[0-9]{3,7}$/.test(receiveForm.invoiceCarrierValue.value)
		) {
			dispatch(validateForm('receiveForm', 'invoiceCarrierValue', false, '愛心碼格式不正確'));
			validStatus = 400;
		}
		if (
			receiveForm.invoiceCarrierType.value === 'MOICA' &&
			!/^[A-Z]{2}[0-9]{14}$/.test(receiveForm.invoiceCarrierValue.value)
		) {
			dispatch(validateForm('receiveForm', 'invoiceCarrierValue', false, '自然人憑證格式不正確'));
			validStatus = 400;
		}
		if (
			receiveForm.invoiceCarrierType.value === 'BUSINESS' &&
			!/^[0-9]{8}$/.test(receiveForm.invoiceCarrierValue.value)
		) {
			dispatch(validateForm('receiveForm', 'invoiceCarrierValue', false, '統一編號格式不正確'));
			validStatus = 400;
		}
	}

	const checkData = examReceiveData(receiveForm, step);
	if (!checkData.value) {
		checkData.notValid.map(key =>
			dispatch(validateForm('receiveForm', key, false, '此欄位不得空白')),
		);
		validStatus = 400;
	}

	dispatch(checkStep(step));

	return validStatus;
});

const submitReceiveForm = createAction('SUBMIT_RECEIVE', () => async (dispatch, getState) => {
	const {
		shop: { receiveForm },
		cities: { cities },
	} = getState();

	const validateStep1 = dispatch(validateStepForm(1));
	const validateStep2 = dispatch(validateStepForm(2));
	const validateStep3 = dispatch(validateStepForm(3));

	if (
		validateStep1.payload === 400 ||
		validateStep2.payload === 400 ||
		validateStep3.payload === 400
	) {
		throw new Error('failed');
	}

	const recipientAddress = () => {
		const countyIdx = cities.findIndex(c => c.id === receiveForm.address.county);
		const districtIdx = cities[countyIdx].districts.findIndex(
			d => d.id === receiveForm.address.district,
		);
		return `${cities[countyIdx].name}${cities[countyIdx].districts[districtIdx].name}${receiveForm.address.other}`;
	};

	const body = () => {
		let _body = {
			district: receiveForm.address.district,
			recipient: receiveForm.recipient.value,
			recipient_mobile: receiveForm.phone.value,
			recipient_address: recipientAddress(),
			invoice_carrier_type: receiveForm.invoiceCarrierType.value,
			free_shipping_event_id: receiveForm.price.free_shipping_event
				? receiveForm.price.free_shipping_event.id
				: null,
		};
		if (receiveForm.coupon.checked && receiveForm.coupon.value) {
			_body = {
				..._body,
				coupon_id: receiveForm.coupon.value,
			};
		} else if (receiveForm.discount.checked) {
			_body = {
				..._body,
				use_discount_event: true,
			};
		}

		if (
			receiveForm.invoiceCarrierType.value === 'MOBILE' ||
			receiveForm.invoiceCarrierType.value === 'MOICA'
		) {
			_body = {
				..._body,
				invoice_carrier_num: receiveForm.invoiceCarrierValue.value,
			};
		} else if (receiveForm.invoiceCarrierType.value === 'DONATE') {
			_body = {
				..._body,
				love_code: receiveForm.invoiceCarrierValue.value,
			};
		} else if (receiveForm.invoiceCarrierType.value === 'BUSINESS') {
			_body = {
				..._body,
				tax_num: receiveForm.invoiceCarrierValue.value,
			};
		}

		return _body;
	};

	const { status, data, error_code: errorCode, extra } = await wrapAuthFetch('mall', {
		method: 'POST',
		body: JSON.stringify(body()),
	});

	if (status !== 200 && status !== 201) {
		if (errorCode === 'newebpay_error') {
			dispatch(
				openModal({
					category: 'dialog',
					type: 'shopFailed',
					data: {
						orderMessage: extra.data,
					},
				}),
			);
		} else if (
			errorCode === 'mall_product_not_enough_stock' ||
			errorCode === 'shopping_cart_is_empty' ||
			errorCode === 'shopping_cart_has_wrong_product'
		) {
			dispatch(
				openModal({
					category: 'dialog',
					type: 'shopCheckOutNotEnoughStock',
				}),
			);
		} else if (errorCode === 'free_shipping_event_is_empty') {
			dispatch(
				openModal({
					category: 'dialog',
					type: 'ShopCheckoutFreeShippingEvent',
				}),
			);
		} else if (errorCode === 'third_party_api_unavailable') {
			dispatch(
				openModal({
					category: 'dialog',
					type: 'thirdPartyApiError',
				}),
			);
		}
	}

	dispatch(
		openNormalModal({
			type: 'threeDReturn',
			data: {
				html:
					process.env.NODE_ENV === 'development'
						? data.data['3d_html']
						: data.data['3d_html']
								.split('<html><body onload="document.forms[0].submit();">')[1]
								.split('</body></html>')[0],
			},
		}),
	);
});

export const updateMeta = createAction('UPDATE_SHOP_LIST_META', meta => meta);

export const fetchCategory = createAction('FETCH_CATEGORY', () => async (_, getState) => {
	const {
		routing: { search },
	} = getState();
	const { preview_key: previewKey } = qs.parse(search, { ignoreQueryPrefix: true });

	const headers = previewKey ? { 'X-VESPA-PREVIEW-KEY': previewKey } : {};
	const { status, message, data } = await wrapFetch('product/category', {
		method: 'GET',
		headers,
	});

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	return data.data;
});

export const fetchShopItems = createAction(
	'FETCH_SHOP_ITEMS',
	(query = {}, goBack = false) => async dispatch => {
		const { status, message, data } = await wrapFetch(
			'product',
			{
				method: 'GET',
			},
			!query.sort_by ? { ...query, sort_by: 'LATEST_ONLINE' } : query,
		);
		const searchCarCategories =
			query && query.car_categories && query.car_categories.length > 0
				? { ...query, car_categories: query.car_categories.join('+') }
				: query;
		const searchSortBy = !searchCarCategories.sort_by
			? { ...searchCarCategories, sort_by: 'LATEST_ONLINE' }
			: searchCarCategories;
		const searchMeta =
			query.page !== data.meta.current_page
				? { ...searchSortBy, page: data.meta.current_page }
				: searchSortBy;

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		if (!goBack) {
			dispatch(
				pushRoute({
					search: qs.stringify(searchMeta, { addQueryPrefix: true }),
				}),
			);
		}
		dispatch(updateMeta(data.meta));

		return data.data;
	},
);

const fetchRelatedShopItems = createAction(
	'FETCH_RELATED_SHOP_ITEMS',
	() => async (_, getState) => {
		const {
			shop: {
				targetShopItem: { product_category_id: productCategoryId },
			},
		} = getState();
		const { status, message, data } = await wrapFetch(
			'product',
			{
				method: 'GET',
			},
			{
				category: productCategoryId,
			},
		);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return data.data;
	},
);

const clearCheckoutCart = createAction('CLEAR_CHECKOUT_CART');

export const fetchDifference = createAction('FETCH_DIFFERENCE', () => async () => {
	const { status, message, data } = await wrapAuthFetch('mall/difference', {
		method: 'GET',
	});

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	return data.data;
});

export const setLocalCartItem = createAction(
	'SET_LOCAL_CART_ITEM',
	({ sid, amount }, checkout = false, toast = true) => (dispatch, getState) => {
		const {
			shop: {
				cart: { cart },
			},
		} = getState();
		const itemIndex = cart.findIndex(c => c.sid === sid);
		const updatedCart = (() => {
			if (itemIndex > -1) {
				if (amount === 0) {
					return [...cart.slice(0, itemIndex), ...cart.slice(itemIndex + 1)];
				}
				return [...cart.slice(0, itemIndex), { sid, amount }, ...cart.slice(itemIndex + 1)];
			}
			return [...cart, { sid, amount }];
		})();

		storage.setItem('cart', JSON.stringify(updatedCart));

		if (toast) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'success',
					data: { message: checkout ? '成功更新購物車' : '成功加入購物車' },
				}),
			);
		}

		return updatedCart;
	},
);

export const setCartItem = createAction(
	'SET_CART_ITEM',
	({ value, additional }, checkout = false, toast = true) => async (dispatch, getState) => {
		const {
			shop: {
				cart: { cart, checkoutCart },
			},
		} = getState();
		const itemIndex = value ? cart.value.findIndex(c => c.sid === value.sid) : -1;
		const updatedCart =
			itemIndex > -1
				? [...cart.value.slice(0, itemIndex), value, ...cart.value.slice(itemIndex + 1)]
				: [...cart.value, value];
		const itemAdditionalIndex = additional
			? cart.additional_purchase_value.findIndex(c => c.sid === additional.sid)
			: -1;
		const updatedAdditionalCart =
			itemAdditionalIndex > -1
				? [
						...cart.additional_purchase_value.slice(0, itemAdditionalIndex),
						additional,
						...cart.additional_purchase_value.slice(itemAdditionalIndex + 1),
				  ]
				: [...cart.additional_purchase_value, additional];

		if (additional && itemAdditionalIndex > -1) {
			const info = checkoutCart.filter(_ => _.sid === additional.sid)[0].product;
			const maxCount =
				info.spec.filter(_ => _.sid === additional.sid)[0].stock >
				info.additional_purchase_count_limit
					? info.additional_purchase_count_limit
					: info.spec.filter(_ => _.sid === additional.sid)[0].stock;
			if (additional.amount >= maxCount) {
				dispatch(
					openModal({
						category: 'dialog',
						type: 'AdditionalPurchase',
					}),
				);
			} else {
				dispatch(
					openModal({
						category: 'toast',
						type: 'success',
						data: { message: '成功更新購物車' },
					}),
				);
			}
		}

		const { status, message, data } = await wrapAuthFetch('mall/shopping-cart', {
			method: 'PUT',
			body: JSON.stringify({
				products: value ? updatedCart : cart.value,
				additional_purchase_products:
					updatedCart.length === 1 && updatedCart[0].amount === 0
						? cart.additional_purchase_value.map(_ => {
								return { sid: _.sid, amount: 0 };
						  })
						: additional
						? updatedAdditionalCart
						: cart.additional_purchase_value,
			}),
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		if (toast) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'success',
					data: { message: checkout ? '成功更新購物車' : '成功加入購物車' },
				}),
			);
		}

		return data.data;
	},
);

export const fetchTargetShopItemStock = createAction(
	'FETCH_SHOP_ITEM_STOCK',
	(query, cart, offShelf = false) => async (dispatch, getState) => {
		const {
			auth: { isLogin },
		} = getState();
		if (query.sid.length === 0) {
			return [];
		}
		const { status, message, data } = await wrapFetch(
			'mall/shopping-cart-info',
			{
				method: 'GET',
			},
			query,
		);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}
		const stockChangedItem = [];
		const checkStockChanged = data.data.some(d =>
			d.product.spec.some(s => {
				if (isLogin) {
					if (
						cart.value.findIndex(c => c.sid === s.sid) > -1 &&
						cart.value[cart.value.findIndex(c => c.sid === s.sid)].amount > s.stock
					) {
						stockChangedItem.push({ sid: s.sid, stock: s.stock });
						return true;
					}
				} else if (
					cart.findIndex(c => c.sid === s.sid) > -1 &&
					cart[cart.findIndex(c => c.sid === s.sid)].amount > s.stock
				) {
					stockChangedItem.push({ sid: s.sid, stock: s.stock });
					return true;
				}
				return false;
			}),
		);
		const checkAddtionalStockChanged = data.data.some(d =>
			d.product.spec.some(s => {
				if (
					isLogin &&
					cart.additional_purchase_value.findIndex(c => c.sid === s.sid) > -1 &&
					cart.additional_purchase_value[
						cart.additional_purchase_value.findIndex(c => c.sid === s.sid)
					].amount > s.stock
				) {
					stockChangedItem.push({ sid: s.sid, stock: s.stock });
					return true;
				}
				return false;
			}),
		);

		if (offShelf) {
			if (isLogin) {
				if (checkStockChanged) {
					await Promise.all(
						cart.value
							.filter(sc => sc.unavailable)
							.map(
								_item =>
									new Promise(resolve => {
										dispatch(setCartItem({ value: { sid: _item.sid, amount: 0 } }, true, false));
										resolve();
									}),
							),
					);
				}
				if (checkAddtionalStockChanged) {
					await Promise.all(
						cart.additional_purchase_value
							.filter(sc => sc.unavailable)
							.map(
								_item =>
									new Promise(resolve => {
										dispatch(
											setCartItem({ additional: { sid: _item.sid, amount: 0 } }, true, false),
										);
										resolve();
									}),
							),
					);
				}
			} else {
				await Promise.all(
					cart
						.filter(sc => sc.unavailable)
						.map(
							_item =>
								new Promise(resolve => {
									dispatch(setLocalCartItem({ sid: _item.sid, amount: 0 }, true, false));
									resolve();
								}),
						),
				);
			}
			if (!checkStockChanged && !checkAddtionalStockChanged) {
				await Promise.all(
					cart.value
						.filter(sc => sc.unavailable)
						.map(
							_item =>
								new Promise(resolve => {
									dispatch(setCartItem({ value: { sid: _item.sid, amount: 0 } }, true, false));
									resolve();
								}),
						),
				);

				dispatch(
					openModal({
						category: 'dialog',
						type: 'shopCartNotEnoughStock',
					}),
				);
			}
		}

		if (checkStockChanged || checkAddtionalStockChanged) {
			if (isLogin) {
				if (checkStockChanged) {
					await Promise.all(
						stockChangedItem.map(
							_item =>
								new Promise(resolve => {
									dispatch(
										setCartItem({ value: { sid: _item.sid, amount: _item.stock } }, true, false),
									);
									resolve();
								}),
						),
					);
				}
				if (checkAddtionalStockChanged) {
					await Promise.all(
						stockChangedItem.map(
							_item =>
								new Promise(resolve => {
									dispatch(
										setCartItem(
											{ additional: { sid: _item.sid, amount: _item.stock } },
											true,
											false,
										),
									);
									resolve();
								}),
						),
					);
				}
			} else {
				await Promise.all(
					stockChangedItem.map(
						_item =>
							new Promise(resolve => {
								dispatch(setLocalCartItem({ sid: _item.sid, amount: _item.stock }, true, false));
								resolve();
							}),
					),
				);
			}
			if (!offShelf) {
				dispatch(
					openModal({
						category: 'dialog',
						type: 'shopCartNotEnoughStock',
					}),
				);
			}
		}

		if (offShelf && (checkStockChanged || checkAddtionalStockChanged)) {
			dispatch(
				openModal({
					category: 'dialog',
					type: 'shopCartNotEnoughStock',
				}),
			);
		}

		return data.data;
	},
);

export const fetchAdditionalPurchases = createAction(
	'FETCH_ADDITIONAL_PURCHASES',
	() => async () => {
		const { status, message, data } = await wrapAuthFetch('mall/additional-purchases', {
			method: 'GET',
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return data.data;
	},
);

export const fetchCartItems = createAction(
	'FETCH_CART_ITEMS',
	(fetchStock = false) => async dispatch => {
		const { status, message, data } = await wrapAuthFetch('mall/shopping-cart', {
			method: 'GET',
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		const { shopping_cart: shoppingCartData } = data.data;
		const shoppingCart = shoppingCartData;
		const checkOffShelf = shoppingCart.value.some(sc => sc.unavailable);
		const checkOffAdditional = shoppingCart.additional_purchase_value.some(sc => sc.unavailable);

		if (fetchStock && (checkOffShelf || checkOffAdditional)) {
			if (
				shoppingCart.value.filter(sc => !sc.unavailable).length > 0 ||
				shoppingCart.additional_purchase_value.filter(sc => !sc.unavailable).length > 0
			) {
				await dispatch(
					fetchTargetShopItemStock(
						{
							sid: shoppingCart.value
								.concat(shoppingCart.additional_purchase_value)
								.filter(sc => !sc.unavailable)
								.map(c => c.sid),
						},
						shoppingCart,
						true,
					),
				);
			} else {
				await Promise.all(
					shoppingCart.value
						.filter(sc => sc.unavailable)
						.map(
							_item =>
								new Promise(resolve => {
									dispatch(setCartItem({ value: { sid: _item.sid, amount: 0 } }, true, false));
									resolve();
								}),
						),
				);
				await Promise.all(
					shoppingCart.additional_purchase_value
						.filter(sc => sc.unavailable)
						.map(
							_item =>
								new Promise(resolve => {
									dispatch(setCartItem({ additional: { sid: _item.sid, amount: 0 } }, true, false));
									resolve();
								}),
						),
				);
				dispatch(
					openModal({
						category: 'dialog',
						type: 'shopCartNotEnoughStock',
					}),
				);
			}
		} else if (fetchStock && !checkOffShelf && shoppingCart.value.length > 0) {
			await dispatch(
				fetchTargetShopItemStock(
					{
						sid: shoppingCart.value.concat(shoppingCart.additional_purchase_value).map(c => c.sid),
					},
					shoppingCart,
				),
			);
		}

		return fetchStock && (checkOffShelf || checkOffAdditional)
			? {
					value: shoppingCart.value.filter(sc => !sc.unavailable),
					additional_purchase_value: shoppingCart.additional_purchase_value.filter(
						sc => !sc.unavailable,
					),
			  }
			: shoppingCart;
	},
);

const fetchLocalCartItems = createAction(
	'FETCH_LOCAL_CART_ITEMS',
	(fetchStock = false) => dispatch => {
		const cartStorage = JSON.parse(storage.getItem('cart')) || [];

		if (fetchStock && cartStorage.length > 0) {
			dispatch(fetchTargetShopItemStock({ sid: cartStorage.map(c => c.sid) }, cartStorage));
		}

		return cartStorage;
	},
);

export const fetchCartPrice = createAction(
	'FETCH_CART_PRICE',
	(propsDistrict = '') => async (dispatch, getState) => {
		const {
			shop: {
				receiveForm: {
					address: { district },
					coupon,
					discount,
				},
			},
		} = getState();
		const query = () => {
			let queryValue = {};
			if (propsDistrict) {
				queryValue = { ...queryValue, district: propsDistrict };
			} else if (district) {
				queryValue = { ...queryValue, district };
			}
			if (coupon.checked && coupon.value) {
				queryValue = { ...queryValue, coupon_id: coupon.value };
			}
			if (discount.checked) {
				queryValue = { ...queryValue, use_discount_event: true };
			}
			return queryValue;
		};
		const { status, message, data } = await wrapAuthFetch(
			'mall/calc',
			{
				method: 'GET',
			},
			query(),
		);

		if (status !== 200 && status !== 201) {
			dispatch(validateForm('receiveForm', 'address', false, '會員地址不在配送範圍內，請重新選擇'));
			dispatch(
				updateForm({
					type: 'receiveForm',
					key: 'address',
					data: { county: '', district: '', other: '' },
				}),
			);
			throw new Error(message);
		}

		return data.data;
	},
);

export const clearFilter = createAction('CLEAR_SHOP_FILTER', ({ type }, inner = false) => ({
	type,
	inner,
}));

export const combineLocalCartWithAuth = createAction(
	'COMBINE_LOCAL_CART_WITH_AUTH',
	() => async (dispatch, getState) => {
		const {
			routing: { pathname },
		} = getState();
		const cartStorage = JSON.parse(storage.getItem('cart')) || [];

		if (cartStorage.length > 0) {
			const { status, message, data } = await wrapAuthFetch('mall/shopping-cart', {
				method: 'PUT',
				body: JSON.stringify({ products: cartStorage, additional_purchase_products: [] }),
			});

			if (status !== 200 && status !== 201) {
				throw new Error(message);
			}

			storage.removeItem('cart');
			dispatch(fetchCartItems(pathname === '/shop/cart' || pathname === '/shop/cart/checkout'));
			return data.data.shopping_cart || { value: [], additional_purchase_value: [] };
		}

		dispatch(fetchCartItems(pathname === '/shop/cart' || pathname === '/shop/cart/checkout'));
		return { value: [], additional_purchase_value: [] };
	},
);

export const fetchTargetShopItem = createAction(
	'FETCH_TARGET_SHOP_ITEM',
	() => async (_, getState) => {
		const {
			routing: { pathname, search },
		} = getState();
		const { preview_key: previewKey } = qs.parse(search, { ignoreQueryPrefix: true });
		const productId = pathname.split('/')[2];

		const api = `product/${productId}`;
		const headers = previewKey ? { 'X-VESPA-PREVIEW-KEY': previewKey } : {};
		const { status, message, data } = await wrapFetch(api, {
			method: 'GET',
			headers,
		});

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		return data.data;
	},
);

export const checkEmptyStock = createAction('CHECK_EMPTY_STOCK', () => (dispatch, getState) => {
	const {
		shop: {
			cart: { cart, checkoutCart },
		},
		auth: { isLogin },
	} = getState();

	if (checkoutCart.length > 0) {
		if (isLogin) {
			cart.value.forEach(c => {
				const checkoutCartIdx = checkoutCart.findIndex(_c => _c.sid === c.sid);
				const checkoutCartSpecIdx =
					checkoutCart[checkoutCartIdx] &&
					checkoutCart[checkoutCartIdx].product.spec.findIndex(_s => _s.sid === c.sid);
				const { stock } =
					checkoutCartSpecIdx > -1 &&
					checkoutCart[checkoutCartIdx] &&
					checkoutCart[checkoutCartIdx].product.spec[checkoutCartSpecIdx];

				if (stock === 0) {
					dispatch(
						setCartItem({ value: { sid: c.sid, amount: 0 } }, true, false),
						fetchDifference(),
					);
				}
			});
			cart.additional_purchase_value.forEach(c => {
				const checkoutCartIdx = checkoutCart.findIndex(_c => _c.sid === c.sid);
				const checkoutCartSpecIdx =
					checkoutCart[checkoutCartIdx] &&
					checkoutCart[checkoutCartIdx].product.spec.findIndex(_s => _s.sid === c.sid);
				const { stock } =
					checkoutCartSpecIdx > -1 &&
					checkoutCart[checkoutCartIdx] &&
					checkoutCart[checkoutCartIdx].product.spec[checkoutCartSpecIdx];

				if (stock === 0) {
					dispatch(
						setCartItem({ additional: { sid: c.sid, amount: 0 } }, true, false),
						fetchDifference(),
					);
				}
			});
		} else {
			cart.forEach(c => {
				const checkoutCartIdx = checkoutCart.findIndex(_c => _c.sid === c.sid);
				const checkoutCartSpecIdx =
					checkoutCart[checkoutCartIdx] &&
					checkoutCart[checkoutCartIdx].product.spec.findIndex(_s => _s.sid === c.sid);
				const { stock } =
					checkoutCartSpecIdx > -1 &&
					checkoutCart[checkoutCartIdx] &&
					checkoutCart[checkoutCartIdx].product.spec[checkoutCartSpecIdx];

				if (stock === 0) {
					dispatch(setLocalCartItem({ sid: c.sid, amount: 0 }, true, false));
				}
			});
		}
	}
});

export const fetchFreeShippingEvents = createAction('FETCH_FREE_SHIPPING_EVENTS', async () => {
	const { status, data } = await wrapFetch('freeShippingEvents', {
		method: 'GET',
	});

	if (status !== 200 || !data) {
		return {
			freeShippingEvents: [],
		};
	}

	const freeShippingEvents = data.data.map(event => ({
		...event,
	}));

	return { freeShippingEvents };
});

const defaultCategoryData = {
	accessory: [],
	product: [],
	sale: [],
};

const defaultTargetShopItemData = {
	photos: [],
	description: '',
	id: null,
	name: '',
	price: null,
	sellingCategories: [],
	spec: [],
	type: '',
	product_category_id: null,
	freeShippingEvents: [],
};

export const defaultFilterData = {
	category: null,
	discountEvent: null,
	carCategories: [],
	search: '',
	sortBy: '',
};

export const defaultPriceData = {
	sum: null,
	discount_coupon: null,
	discount_event: [],
	discount_rebate: null,
	free_shipping_event: [],
	freebies: [],
	payable: null,
	send_coupon: false,
	shipping: null,
	use_coupon: null,
	coupons: [],
};

const defaultDifferenceData = {
	difference: 0,
	discount_events: [],
};

const defaultMetaData = {
	currentPage: null,
	from: null,
	lastPage: null,
	path: '',
	perPage: null,
	to: null,
	total: null,
};

export const defaultReceiveFormData = {
	couponList: [],
	eventList: [],
	delivery: { value: '宅配', valid: true, error: '' },
	recipient: { value: '', valid: true, error: '' },
	phone: { value: '', valid: true, error: '' },
	address: { county: '', district: '', other: '', valid: true, error: '' },
	coupon: { checked: false, value: '', valid: true, error: '' },
	discount: { checked: true, value: '', valid: true, error: '' },
	price: defaultPriceData,
	invoiceCarrierType: { value: 'PERSONAL', valid: true, error: '' },
	invoiceCarrierValue: { value: '', valid: true, error: '' },
	stepCheck: { step1: null, step2: null, step3: null },
	loading: false,
	error: '',
};

export const defaultCartData = {
	cart: { value: [], additional_purchase_value: [] },
	checkoutCart: [],
	difference: defaultDifferenceData,
	additionalPurchases: [],
	loading: false,
};

const reducer = {
	shop: handleActions(
		{
			FETCH_DIFFERENCE_PENDING: state => ({
				...state,

				cart: { ...state.cart, difference: defaultDifferenceData },
			}),

			FETCH_DIFFERENCE_FULFILLED: (state, action) => ({
				...state,

				cart: { ...state.cart, difference: action.payload },
			}),

			FETCH_ADDITIONAL_PURCHASES_PENDING: state => ({
				...state,

				cart: { ...state.cart, additionalPurchases: [] },
			}),

			FETCH_ADDITIONAL_PURCHASES_FULFILLED: (state, action) => ({
				...state,

				cart: { ...state.cart, additionalPurchases: action.payload },
			}),

			UPDATE_RECEIVE_FORM: (state, action) => ({
				...state,

				[action.payload.type]: {
					...state[action.payload.type],
					[action.payload.key]: {
						...state[action.payload.type][action.payload.key],
						...action.payload.data,
					},
				},
			}),

			VALIDATE_RECEIVE_FORM: (state, action) => ({
				...state,

				[action.payload.type]: {
					...state[action.payload.type],
					[action.payload.key]: {
						...state[action.payload.type][action.payload.key],
						valid: action.payload.valid,
						error: action.payload.error,
					},
				},
			}),

			CLEAR_RECEIVE_FORM_ERROR: (state, action) => ({
				...state,

				[action.payload.type]: action.payload.data,
			}),

			CLEAR_RECEIVE_FORM: state => ({
				...state,

				receiveForm: defaultReceiveFormData,
			}),

			INIT_SHOP_FILTER: state => ({
				...state,

				filter: defaultFilterData,
			}),

			SUBMIT_RECEIVE_PENDING: state => ({
				...state,
				receiveForm: {
					...state.receiveForm,
					loading: true,
					error: '',
				},
			}),

			SUBMIT_RECEIVE_FULFILLED: state => ({
				...state,

				receiveForm: {
					...state.receiveForm,
					loading: false,
				},
			}),

			SUBMIT_RECEIVE_REJECTED: (state, action) => ({
				...state,

				receiveForm: {
					...state.receiveForm,
					loading: false,
					error: action.payload.message,
				},
			}),

			FETCH_CATEGORY_FULFILLED: (state, action) => ({
				...state,

				category: action.payload,
			}),

			FETCH_SHOP_ITEMS_PENDING: state => ({
				...state,

				loading: true,
				shopItems: [],
			}),

			FETCH_SHOP_ITEMS_FULFILLED: (state, action) => ({
				...state,

				loading: false,
				shopItems: action.payload,
			}),

			FETCH_RELATED_SHOP_ITEMS_PENDING: state => ({
				...state,

				relatedShopItems: [],
			}),

			FETCH_RELATED_SHOP_ITEMS_FULFILLED: (state, action) => ({
				...state,

				relatedShopItems: action.payload,
			}),

			CLEAR_CHECKOUT_CART: state => ({
				...state,

				cart: {
					...state.cart,

					checkoutCart: [],
				},
			}),

			FETCH_LOCAL_CART_ITEMS: (state, action) => ({
				...state,

				cart: {
					...state.cart,

					cart: action.payload,
				},
			}),

			FETCH_CART_ITEMS_PENDING: state => ({
				...state,

				cart: {
					...state.cart,

					cart: { value: [], additional_purchase_value: [] },
					loading: true,
				},
			}),

			FETCH_CART_ITEMS_FULFILLED: (state, action) => ({
				...state,

				cart: {
					...state.cart,

					loading: false,
					cart: action.payload,
				},
			}),

			FETCH_TARGET_SHOP_ITEM_PENDING: state => ({
				...state,

				targetShopItem: defaultTargetShopItemData,
			}),

			FETCH_TARGET_SHOP_ITEM_FULFILLED: (state, action) => ({
				...state,

				targetShopItem: action.payload,
			}),

			FETCH_SHOP_ITEM_STOCK_PENDING: state => ({
				...state,

				cart: {
					...state.cart,

					checkoutCart: [],
				},
			}),

			FETCH_SHOP_ITEM_STOCK_FULFILLED: (state, action) => ({
				...state,

				cart: {
					...state.cart,

					loading: false,
					checkoutCart: action.payload,
				},
			}),

			SET_CART_ITEM_FULFILLED: (state, action) => ({
				...state,

				cart: {
					...state.cart,

					cart: action.payload.shopping_cart,
				},
			}),

			SET_LOCAL_CART_ITEM: (state, action) => ({
				...state,

				cart: {
					...state.cart,

					cart: action.payload,
				},
			}),

			COMBINE_LOCAL_CART_WITH_AUTH_FULFILLED: (state, action) => ({
				...state,

				cart: {
					...state.cart,

					cart: action.payload,
				},
			}),

			FETCH_CART_PRICE_FULFILLED: (state, action) => ({
				...state,

				receiveForm: {
					...state.receiveForm,

					price: lodash.omit(action.payload, ['coupons', 'discount_event']),
					couponList: action.payload.coupons,
					eventList: action.payload.discount_event,
				},
			}),

			FETCH_FREE_SHIPPING_EVENTS_PENDING: state => ({
				...state,
				loading: true,
			}),
			FETCH_FREE_SHIPPING_EVENTS_FULFILLED: (state, action) => ({
				...state,
				loading: false,
				freeShippingEvents: action.payload.freeShippingEvents,
			}),

			UPDATE_SHOP_LIST_META: (state, action) => ({
				...state,

				meta: {
					...state.meta,

					currentPage: action.payload.current_page,
					from: action.payload.from,
					lastPage: action.payload.last_page,
					path: action.payload.path,
					perPage: action.payload.per_page,
					to: action.payload.to,
					total: action.payload.total,
				},
			}),

			UPDATE_SHOP_FILTER: (state, action) => ({
				...state,

				filter: {
					...state.filter,

					[action.payload.type]: action.payload.data,
				},
			}),

			UPDATE_SHOP_QUERY_FILTER: (state, action) => ({
				...state,

				filter: {
					...state.filter,

					...action.payload.reduce((ac, t) => ({ ...ac, [t.type]: t.data }), {}),
				},
			}),

			CLEAR_SHOP_FILTER: (state, action) => ({
				...state,

				filter: {
					...state.filter,

					...action.payload.type.reduce((ac, t) => ({ ...ac, [t]: defaultFilterData[t] }), {}),
				},
			}),
		},
		{
			shopItems: [],
			relatedShopItems: [],
			meta: defaultMetaData,
			category: defaultCategoryData,
			targetShopItem: defaultTargetShopItemData,
			filter: defaultFilterData,
			cart: defaultCartData,
			receiveForm: defaultReceiveFormData,
			loading: false,
			freeShippingEvents: [],
		},
	),
};

const selectShop = state => state.shop;

export const useShop = () =>
	useRedux(selectShop, {
		initShopFilter,
		fetchCategory,
		fetchShopItems,
		fetchRelatedShopItems,
		fetchTargetShopItem,
		updateMeta,
		updateFilter,
		updateQueryFilter,
		clearFilter,
		fetchFreeShippingEvents,
	});

const selectCart = state => state.shop.cart;

export const useCart = () =>
	useRedux(selectCart, {
		fetchCartItems,
		fetchLocalCartItems,
		fetchDifference,
		setCartItem,
		setLocalCartItem,
		checkEmptyStock,
		clearCheckoutCart,
		fetchAdditionalPurchases,
		fetchTargetShopItemStock,
	});

const selectReceiveForm = state => state.shop.receiveForm;

export const useReceiveForm = () =>
	useRedux(selectReceiveForm, {
		initReceiveForm,
		updateForm,
		validateForm,
		validateStepForm,
		submitReceiveForm,
		clearReceiveForm,
		fetchCartPrice,
	});

export default { reducer };
