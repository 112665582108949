import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import CustomGoogleMap from 'components/molecules/GoogleMapForStore';
import { useModal } from 'models/modal';
import { findCity } from 'util/city';
import { DAY_MAP } from 'util/day';

import Address from 'images/icon/address.inline.svg';
import Phone from 'images/icon/phone.inline.svg';
import ArrowIcon from 'images/icon/arrow-down.inline.svg';
import TipsIcon from 'images/icon/atom-icon-functional-close.inline.svg';
import UncheckIcon from 'images/icon/atom-icon-check-box-default.inline.svg';
import CheckedIcon from 'images/icon/atom-icon-checked.inline.svg';
import MapIcon from 'images/icon/atom-icon-action-list.inline.svg';
import EmptyIcon from 'images/icon/atom-image-search.inline.svg';

import { useOwnership, useOwnershipForm } from 'models/ownership';
import { IMAGE_ENDPOINT } from '../../../../config/endpoint';

import styles from './index.css';

const ColorOval = ({ color, checked, onClick }) => {
	return (
		<div
			style={{ backgroundColor: color }}
			className={classnames(styles.colorOval, { [styles.checked]: checked })}
			role="presentation"
			onClick={onClick}
		/>
	);
};

const StockOval = ({ isAvailable = {} }) => {
	return (
		<div className={styles.ovalWrapper}>
			{isAvailable ? (
				<>
					<span style={{ backgroundColor: '#6dcdb1' }} className={styles.stockOval} />
					<span className={styles.state}>尚有庫存</span>
				</>
			) : (
				<>
					<span style={{ backgroundColor: '#c2002f' }} className={styles.stockOval} />
					<span className={styles.state}>暫無庫存</span>
				</>
			)}
		</div>
	);
};

const ColorOption = ({ colors, colorIdx, setColorIdx }) => {
	const [, { updateForm }] = useOwnershipForm();
	/* 暫無庫存車色 disabled */
	return (
		<div className={styles.colorOption}>
			{colors.map((c, index) => (
				<ColorOval
					key={c.color_code}
					color={c.color_code}
					checked={colorIdx === index}
					onClick={() => {
						setColorIdx(index);
						updateForm({
							type: 'ownershipForm',
							key: 'colorId',
							data: { value: c.id },
						});
					}}
				/>
			))}
		</div>
	);
};

const NoStore = () => {
	const [{ storeStock, ownershipForm }] = useOwnership();
	return (
		<div className={styles.info}>
			<p className={styles.cityName}>
				{storeStock.length > 0
					? storeStock.map(store => store.shop.city.name)[0]
					: findCity(ownershipForm.zipCode.value)}
			</p>
			<div>
				<div
					className={classnames(styles.empty, {
						[styles.sp]: ownershipForm.zipCode.value.length !== 3,
					})}
				>
					<EmptyIcon />
					<p>該區域目前暫無門市據點</p>
				</div>
			</div>
		</div>
	);
};

const StoreNameCard = ({ shopId, isOpen, name, setOpen = () => {} }) => (
	<div
		className={classnames(styles.titleRow, `${shopId}`, {
			[styles.isOpen]: isOpen.includes(shopId),
		})}
		onKeyPress={() => {}}
		role="button"
		tabIndex={0}
		onClick={() => {
			setOpen(() => (isOpen.includes(shopId) ? isOpen.filter(_id => _id !== shopId) : [shopId]));
		}}
	>
		<p>{name}</p>
		<ArrowIcon />
	</div>
);

const StoreContentCard = ({ shopId, isOpen, city, district, address, phone, businessHours }) => (
	<div
		className={classnames(styles.content, {
			[styles.isOpen]: isOpen.includes(shopId),
		})}
	>
		<div className={styles.infoRow}>
			<p>營業時間：</p>
		</div>
		{businessHours &&
			businessHours
				.sort((a, b) => DAY_MAP[a.day] - DAY_MAP[b.day])
				.map(r => (
					<div className={styles.openingHours} key={r.id}>
						<span>{r.day}</span>
						<span>{r.business_hour}</span>
					</div>
				))}
		<a
			href={`http://maps.google.com/maps?daddr=${city}${district}${address}`}
			className={styles.infoRow}
			target="_blank"
			rel="noreferrer"
		>
			<p>
				地址：
				{city}
				{district}
				{address}
			</p>
			<div>
				<Address />
				<p>導航</p>
			</div>
		</a>
		<div className={styles.infoRow}>
			<p>電話：{phone}</p>
			<a
				href={`tel:+886${phone
					.split('-')
					.join('')
					.slice(1)}`}
			>
				<Phone />
				<p>致電</p>
			</a>
		</div>
	</div>
);

const StoreInfo = ({ showOnlyHaveStock = {} }) => {
	const [{ storeStock, ownershipForm }] = useOwnership();
	const [isOpen, setOpen] = useState([]);
	const storeHaveStock = storeStock.filter(store => store.is_available);
	const storeRender = showOnlyHaveStock ? storeHaveStock : storeStock;

	useEffect(() => {
		setOpen(() => [ownershipForm.selectedShop.value]);
	}, [ownershipForm.selectedShop]);

	return (
		<>
			{showOnlyHaveStock && storeHaveStock.length === 0 ? (
				<NoStore />
			) : (
				<div className={classnames(styles.info)}>
					<p className={styles.cityName}>{storeStock.map(store => store.shop.city.name)[0]}</p>
					{storeRender.map(store => (
						<div key={store.shop_id}>
							<StoreNameCard
								isOpen={isOpen}
								setOpen={setOpen}
								shopId={store.shop_id}
								key={store.shop_id}
								name={store.shop.name}
							/>
							<StockOval isAvailable={store.is_available} />
							<StoreContentCard
								isOpen={isOpen}
								shopId={store.shop_id}
								city={store.shop.city.name}
								district={store.shop.district.name}
								address={store.shop.address}
								phone={store.shop.phone}
								businessHours={store.shop.business_hours}
							/>
						</div>
					))}
				</div>
			)}
		</>
	);
};

const Inner = ({ info, colorIdx, setColorIdx }) => {
	const { name, colors } = info;
	const [{ storeStock }] = useOwnership();
	const [{ scrollTop }] = useModal();
	const [isSelected, setSelect] = useState(false);
	const scrollToMap = () => {
		document
			.getElementsByClassName('map')[0]
			.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};

	return (
		<div className={classnames(styles.inner, 'inner')}>
			<div
				className={styles.imgWrap}
				style={{
					backgroundImage: `url(${IMAGE_ENDPOINT}/${colors[colorIdx] && colors[colorIdx].img})`,
				}}
			/>
			<div className={styles.info}>
				<h1>{name}</h1>
				<p className={styles.subtitle}>
					車款顏色
					<span>{colors[colorIdx] && colors[colorIdx].name}</span>
				</p>
				<ColorOption colors={colors} colorIdx={colorIdx} setColorIdx={setColorIdx} />
			</div>
			<div className={classnames(styles.map, 'map')}>
				<CustomGoogleMap showOnlyHaveStock={isSelected} />
			</div>
			<div className={styles.reminder}>
				<TipsIcon />
				<span>由於各店存貨量經常變化，無法保證車款供應。</span>
			</div>
			<div
				className={styles.check}
				onKeyPress={() => {}}
				role="button"
				tabIndex={0}
				onClick={() => setSelect(!isSelected)}
			>
				{isSelected ? <CheckedIcon /> : <UncheckIcon />}
				<span>僅顯示有庫存的分店</span>
			</div>
			{storeStock.length > 0 ? <StoreInfo showOnlyHaveStock={isSelected} /> : <NoStore />}
			{scrollTop > 700 && (
				<div
					className={styles.mapList}
					onClick={scrollToMap}
					onKeyPress={() => {}}
					role="button"
					tabIndex={0}
				>
					<MapIcon />
					<span>地圖</span>
				</div>
			)}
		</div>
	);
};

const OwnerShipStoreStock = () => {
	const [{ targetModel, loading, ownershipForm }, { fetchTargetModel }] = useOwnership();
	const [, { updateForm }] = useOwnershipForm();
	const selectedColorIndex = targetModel.colors.findIndex(
		c => c.id === ownershipForm.colorId.value,
	);
	const [colorIdx, setColorIdx] = useState(selectedColorIndex);

	useEffect(() => {
		fetchTargetModel();
	}, []);

	useEffect(() => {
		if (targetModel.colors.length > 0) {
			setColorIdx(selectedColorIndex);
			updateForm({
				type: 'ownershipForm',
				key: 'colorId',
				data: {
					value: targetModel.colors[selectedColorIndex].id,
				},
			});
		}
	}, [targetModel.colors]);

	if (loading) {
		return null;
	}

	return (
		<div className={styles.ownerShip}>
			<Inner info={targetModel} colorIdx={colorIdx} setColorIdx={setColorIdx} />
		</div>
	);
};

export default OwnerShipStoreStock;
