import { createAction, handleActions } from 'redux-actions';
import qs from 'qs';

import { wrapAuthFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { clearCouponFormData } from 'util/clear';
import { isEmpty } from 'util/helper';

import { openModal } from 'models/modal';
import { fetchCartPrice } from 'models/shop';
import { fetchRentalPrice } from 'models/rental';
import { fetchOwnershipPrice } from 'models/ownership';
import { pushRoute } from 'models/routing';

export const updateForm = createAction('UPDATE_COUPON_FORM', ({ type, key, data }) => ({
	type,
	key,
	data,
}));

const validateForm = createAction('VALIDATE_COUPON_FORM', (type, key, valid, error) => ({
	type,
	key,
	valid,
	error,
}));

const clearFormError = createAction('CLEAR_COUPON_FORM_ERROR', type => (_, getState) => {
	const {
		coupon: { couponForm },
	} = getState();

	return {
		type,
		data: clearCouponFormData(couponForm),
	};
});

export const initCouponForm = createAction('INIT_COUPON_FORM');

export const fetchCoupon = createAction('FETCH_COUPON', type => async () => {
	const { data, status, message } = await wrapAuthFetch(
		'me/coupons',
		{
			method: 'GET',
		},
		type,
	);

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	return data.data;
});

export const submitCoupon = createAction(
	'SUBMIT_COUPON',
	(type = '') => async (dispatch, getState) => {
		dispatch(clearFormError('couponForm'));
		const {
			coupon: {
				couponForm: { redeemCode },
			},
		} = getState();
		const { status, message, error_code: errorCode } = await wrapAuthFetch('coupon', {
			method: 'POST',
			body: JSON.stringify(
				type ? { code: redeemCode.value, can_used_type: type } : { code: redeemCode.value },
			),
		});
		const errMap = {
			coupon_has_been_received: '此優惠券不可重複領取',
			not_in_coupon_convertible_time: '優惠券已過期',
			reached_coupon_receive_limit: '此優惠券不可重複領取',
			reached_coupon_send_limit: '該優惠券已到達兌換上限',
			not_found: '優惠券不存在，請重新確認',
			coupon_can_used_type_not_match: '優惠券適用範圍不符，無法領取',
		};

		if (status !== 200 && status !== 201) {
			if (status === 404) {
				dispatch(validateForm('couponForm', 'redeemCode', false, errMap.not_found));
			} else if (status === 400) {
				dispatch(validateForm('couponForm', 'redeemCode', false, errMap[errorCode]));
			}
			throw new Error(message);
		}

		dispatch(
			updateForm({
				type: 'couponForm',
				key: 'redeemCode',
				data: { value: '' },
			}),
		);

		// fetch new coupon list
		dispatch(fetchCoupon({ type: 'available' }));
		if (type === 'mall') {
			dispatch(fetchCartPrice());
		} else if (type === 'rental') {
			dispatch(fetchRentalPrice());
		} else if (type === 'carSale') {
			dispatch(fetchOwnershipPrice());
		}

		dispatch(
			openModal({
				category: 'toast',
				type: 'success',
				data: { message: '優惠券兌換成功' },
			}),
		);
	},
);

export const verifyEventCoupon = createAction(
	'VERIFY_EVENT_COUPON',
	() => async (dispatch, getState) => {
		const {
			routing: { search },
		} = getState();
		const { code } = qs.parse(search, { ignoreQueryPrefix: true });

		if (isEmpty(code)) {
			dispatch(pushRoute({ pathname: '/' }));
		}

		const { status, message, error_code: errorCode } = await wrapAuthFetch(
			'verify/receive-coupon',
			{
				method: 'POST',
				body: JSON.stringify({ code }),
			},
		);
		const errMap = {
			coupon_has_been_received: '此優惠券不可重複領取',
			not_in_coupon_convertible_time: '優惠券已過期',
			reached_coupon_receive_limit: '此優惠券不可重複領取',
			reached_coupon_send_limit: '該優惠券已到達兌換上限',
			not_found: '優惠券不存在，請重新確認',
			coupon_can_used_type_not_match: '優惠券適用範圍不符，無法領取',
		};

		if (status !== 200 && status !== 201) {
			if (status === 404) {
				dispatch(
					openModal({
						category: 'toast',
						type: 'failed',
						data: { message: errMap.not_found },
					}),
				);
			} else if (status === 400) {
				dispatch(
					openModal({
						category: 'toast',
						type: 'failed',
						data: { message: errMap[errorCode] },
					}),
				);
			}

			dispatch(pushRoute({ pathname: '/' }));
			throw new Error(message);
		}

		dispatch(pushRoute({ pathname: '/' }));
		dispatch(
			openModal({
				category: 'toast',
				type: 'success',
				data: { message: '優惠券兌換成功' },
			}),
		);
	},
);

export const bindCoolbeLineBot = createAction(
	'BIND_COOLBE_LINE_BOT',
	() => async (dispatch, getState) => {
		const {
			routing: { search },
		} = getState();

		const { uid, reg_event: regEvent } = qs.parse(search, { ignoreQueryPrefix: true });

		const { data } = await wrapAuthFetch('verify/bind-coolbe-line-bot', {
			method: 'POST',
			body: JSON.stringify({ line_uid: uid, reg_event: regEvent }),
		});

		window.location.assign(data.data.redirect_url);
	},
);

const defaultCouponFormData = {
	redeemCode: { value: '', valid: true, error: '' },
	loading: false,
	error: '',
};

const reducer = {
	coupon: handleActions(
		{
			INIT_COUPON_FORM: state => ({
				...state,

				couponForm: defaultCouponFormData,
			}),

			UPDATE_COUPON_FORM: (state, action) => ({
				...state,

				[action.payload.type]: {
					...state[action.payload.type],
					[action.payload.key]: {
						...state[action.payload.type][action.payload.key],
						...action.payload.data,
					},
				},
			}),

			CLEAR_COUPON_FORM_ERROR: (state, action) => ({
				...state,

				[action.payload.type]: action.payload.data,
			}),

			VALIDATE_COUPON_FORM: (state, action) => ({
				...state,

				[action.payload.type]: {
					...state[action.payload.type],
					[action.payload.key]: {
						...state[action.payload.type][action.payload.key],
						valid: action.payload.valid,
						error: action.payload.error,
					},
				},
			}),

			FETCH_COUPON_PENDING: state => ({
				...state,

				coupon: [],
			}),

			FETCH_COUPON_FULFILLED: (state, action) => ({
				...state,

				coupon: action.payload,
			}),

			FETCH_COUPON: (state, action) => ({
				...state,

				coupon: action.payload,
			}),

			SUBMIT_COUPON_PENDING: state => ({
				...state,

				couponForm: {
					...state.couponForm,

					loading: true,
				},
			}),

			SUBMIT_COUPON_FULFILLED: state => ({
				...state,

				couponForm: {
					...state.couponForm,

					loading: false,
				},
			}),

			SUBMIT_COUPON_REJECTED: (state, action) => ({
				...state,

				couponForm: {
					...state.couponForm,

					loading: false,
					error: action.payload.message,
				},
			}),
		},
		{
			coupon: [],
			couponForm: defaultCouponFormData,
		},
	),
};

const selectCoupon = state => state.coupon;

export const useCoupon = () =>
	useRedux(selectCoupon, {
		fetchCoupon,
		submitCoupon,
	});

const selectCouponForm = state => state.coupon.couponForm;

export const useCouponForm = () =>
	useRedux(selectCouponForm, {
		updateForm,
		initCouponForm,
	});

export default { reducer };
