import React from 'react';

import { fetchShops, clearForm, clearTargetShop } from 'models/afterSales';

export default {
	path: '/after-sales',
	components: () => [import(/* webpackChunkName: 'AfterSalesLayout' */ './component')],
	render: ([AfterSalesLayout], children) => <AfterSalesLayout>{children}</AfterSalesLayout>,
	children: [
		{
			path: '/model',
			components: () => [import('./Basic')],
			render: ([Basic]) => <Basic />,
			onEnter: async ({ store, next }) => {
				await store.dispatch(clearForm('userCarModelForm'));

				const children = await next();

				return children;
			},
		},
		{
			path: '/notifications',
			components: () => [import('./Basic')],
			render: ([Basic]) => <Basic />,
		},
		{
			path: '/service-activity',
			components: () => [import('./Basic')],
			render: ([Basic]) => <Basic />,
		},
		{
			path: '/maintenance',
			components: () => [import('./Basic')],
			render: ([Basic]) => <Basic />,
			onEnter: async ({ store, next }) => {
				await store.dispatch(fetchShops());
				await store.dispatch(clearForm('reserveMaintenanceForm'));
				await store.dispatch(clearTargetShop());

				const children = await next();

				return children;
			},
		},
		{
			path: '/location',
			components: () => [import('./Basic')],
			render: ([Basic]) => <Basic />,
		},
	],
};
