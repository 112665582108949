import React from 'react';
import classnames from 'classnames';

import Loading from 'images/icon/loading.inline.svg';

import styles from './index.css';

const Avatar = ({ className, name, image, small = false, loading = false }) => (
	<div className={classnames(styles.avatar, className, { [styles.small]: small })}>
		<div
			className={styles.image}
			style={{ backgroundImage: `url(${image})`, opacity: loading ? 0.4 : 1 }}
		/>
		{name && <span className={styles.name}>{name}</span>}
		{loading && (
			<div className={styles.loading}>
				<Loading />
				<p>上傳中</p>
			</div>
		)}
	</div>
);

export default Avatar;
