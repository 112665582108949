import React from 'react';
import { openModal } from 'models/modal';

export default {
	path: '/login',
	components: () => [import(/* webpackChunkName: 'login' */ './component')],
	render: ([Login]) => <Login />,
	onEnter: async ({ store }) => {
		await store.dispatch(openModal({ category: 'auth', type: 'login' }));
	},
};
