import React, { useState, useEffect, memo, useRef } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { useOwnership, useOwnershipForm } from 'models/ownership';

import Center from 'images/icon/center.inline.svg';
import CurrentLocation from 'images/maps/current-location.png';
import UnselectedShop from 'images/maps/shop.svg';
import SearchIcon from 'images/icon/search.inline.svg';
import NoStockIcon from 'images/maps/no-stock.svg';

import mapStyle from './mapStyle';
import styles from './index.css';

const { GOOGLE_MAP_API_KEY } = process.env;

const CustomGoogleMap = ({ showOnlyHaveStock = {} }) => {
	const [currentLatLng, setCurrentLatLng] = useState(null);
	const [center, setCenter] = useState({ lat: 25.071971, lng: 121.574597 });
	const [userInput, setInput] = useState('');
	const [zipCode, setZipCode] = useState('114');
	const [libraries] = useState(['places']);
	const [
		{ ownershipForm, allStoreStock },
		{ fetchStoreStock, fetchAllStoreStock, moveToTarget },
	] = useOwnership();
	const [, { updateForm: updateOwnershipForm }] = useOwnershipForm();
	const storeHaveStock = allStoreStock.filter(store => store.is_available);
	const storeRender = showOnlyHaveStock ? storeHaveStock : allStoreStock;
	const getGeoLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(position => {
				setCurrentLatLng({
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				});
				setCenter({
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				});
				const geocoder = new window.google.maps.Geocoder();
				const latLng = {
					lat: parseFloat(position.coords.latitude),
					lng: parseFloat(position.coords.longitude),
				};
				geocoder.geocode({ location: latLng }, results => {
					setZipCode(results[0].address_components.slice(-1)[0].long_name);
				});
			});
		} else {
			setCenter({
				lat: 25.071971,
				lng: 121.574597,
			});
		}
	};
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: GOOGLE_MAP_API_KEY,
		libraries,
	});

	// const [searchBox, setSearchBox] = useState([]);
	// const [lat, setLat] = useState(25.071971);
	// const [lng, setLng] = useState(121.574597);

	// const onPlacesChanged = async () => {
	// 	const aLat = await searchBox.getPlace().geometry.location.lat();
	// 	const aLng = await searchBox.getPlace().geometry.location.lng();
	// 	setInput(
	// 		searchBox
	// 			.getPlace()
	// 			.formatted_address.split(' ')
	// 			.slice(1)
	// 			.join(' '),
	// 	);
	// 	setLat(aLat);
	// 	setLng(aLng);
	// 	const geocoder = new window.google.maps.Geocoder();
	// 	const latLng = {
	// 		lat: parseFloat(aLat),
	// 		lng: parseFloat(aLng),
	// 	};
	// 	await geocoder.geocode({ location: latLng }, results => {
	// 		setZipCode(results[0].address_components.slice(-1)[0].long_name);
	// 	});
	// };

	// const onLoad = ref => {
	// 	setSearchBox(ref);
	// };

	// // const restrictions = { country: 'tw' };
	// const types = [
	// 	'administrative_area_level_1',
	// 	'administrative_area_level_2',
	// 	'administrative_area_level_3',
	// ];
	// const removeLogo = async () => {
	// 	document.querySelector('.pac-container').classList.remove('pac-logo');
	// 	document.querySelectorAll('.gm-style-cc').forEach(d => d.remove());
	// };

	const handleKeyPress = event => {
		if (event.key === 'Enter') {
			const sessionToken = new window.google.maps.places.AutocompleteSessionToken();
			const service = new window.google.maps.places.AutocompleteService();
			service.getPlacePredictions(
				{
					input: userInput,
					sessionToken,
					types: [
						'administrative_area_level_1',
						'administrative_area_level_2',
						'administrative_area_level_3',
					],
					// componentRestrictions: { country: 'tw' },
				},
				predictions => {
					const geocoder = new window.google.maps.Geocoder();
					geocoder.geocode({ placeId: predictions[0].place_id }, responses => {
						const a = responses[0].geometry.location.lat();
						const b = responses[0].geometry.location.lng();
						const containsNumber = str => {
							return /[0-9]/.test(str);
						};
						setCenter({
							lat: a,
							lng: b,
						});

						if (containsNumber(responses[0].address_components.slice(-1)[0].long_name)) {
							setZipCode(responses[0].address_components.slice(-1)[0].long_name);
						} else {
							const latLng = {
								lat: parseFloat(responses[0].geometry.location.lat()),
								lng: parseFloat(responses[0].geometry.location.lng()),
							};
							geocoder.geocode({ location: latLng }, results => {
								setZipCode(results[0].address_components.slice(-1)[0].long_name);
							});
						}
					});
				},
			);
			event.target.blur();
		}
	};

	const mapRef = useRef(null);
	const handleLoad = map => {
		mapRef.current = map;
	};
	const handleCenterChanged = () => {
		if (!mapRef.current) return;
		updateOwnershipForm({
			type: 'ownershipForm',
			key: 'selectedShop',
			data: { value: '' },
		});
		const currentCenter = mapRef.current.getCenter().toJSON();
		const geocoder = new window.google.maps.Geocoder();
		const latLng = {
			lat: parseFloat(currentCenter.lat),
			lng: parseFloat(currentCenter.lng),
		};
		geocoder.geocode({ location: latLng }, results => {
			setZipCode(results[0].address_components.slice(-1)[0].long_name);
		});
	};

	useEffect(() => {
		getGeoLocation();
	}, []);

	useEffect(() => {
		updateOwnershipForm({
			type: 'ownershipForm',
			key: 'zipCode',
			data: { value: zipCode },
		});
		fetchStoreStock(zipCode, ownershipForm.colorId.value);
	}, [zipCode]);

	useEffect(() => {
		fetchStoreStock(zipCode, ownershipForm.colorId.value);
		fetchAllStoreStock(ownershipForm.colorId.value);
	}, [ownershipForm.colorId.value]);

	const renderMap = () => {
		const options = {
			disableDefaultUI: true,
			zoomControl: true,
			scaleControl: true,
			styles: mapStyle,
		};

		return (
			<>
				<div className={styles.wrapper}>
					<SearchIcon />
					<div>
						<input
							onKeyPress={handleKeyPress}
							onChange={e => {
								setInput(e.target.value);
							}}
							value={userInput}
							type="text"
							placeholder="請輸入縣市區域"
							enterkeyhint="search"
						/>
					</div>
				</div>
				<GoogleMap
					mapContainerStyle={{
						width: '100%',
						height: '320px',
					}}
					center={center}
					zoom={12}
					options={options}
					onLoad={handleLoad}
					onDragEnd={handleCenterChanged}
				>
					<div
						className={styles.centerWrapper}
						role="button"
						tabIndex={0}
						onClick={getGeoLocation}
						onKeyPress={() => {}}
					>
						<Center />
						定位我目前位置
					</div>
					<Marker
						position={currentLatLng}
						icon={{
							url: CurrentLocation,
							scaledSize: { width: 86, height: 86 },
						}}
					/>

					{storeRender.length > 0 &&
						storeRender.map(shop => (
							<Marker
								key={shop.shop_id}
								position={{
									lat: parseFloat(shop.shop.latitude, 10),
									lng: parseFloat(shop.shop.longitude, 10),
								}}
								icon={{
									url: shop.is_available ? UnselectedShop : NoStockIcon,
									scaledSize: { width: 48, height: 57 },
								}}
								onClick={() => {
									setCenter({
										lat: parseFloat(shop.shop.latitude, 10),
										lng: parseFloat(shop.shop.longitude, 10),
									});
									setZipCode(shop.shop.district.zip_code);
									updateOwnershipForm({
										type: 'ownershipForm',
										key: 'selectedShop',
										data: { value: shop.shop_id },
									});
									moveToTarget();
								}}
							/>
						))}
				</GoogleMap>
			</>
		);
	};

	if (loadError) {
		return <h1>Map cannot be loaded right now, sorry.</h1>;
	}

	return isLoaded ? renderMap() : null;
};

export default memo(CustomGoogleMap);
