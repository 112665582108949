import React, { useEffect } from 'react';

import { useAuth } from 'models/auth';

const { GOOGLE_CLIENT_ID } = process.env;

const ButtonGoogleSignIn = ({
	buttonRef,
	type,
	text,
	size,
	width,
	actionType,
	onChangeModal,
	shape,
}) => {
	const [{ isGoogleLibraryLoad }, { loginGoogle, bindGoogle }] = useAuth();
	const handleGoogleSignIn = res => {
		if (!res.clientId || !res.credential) return;
		if (actionType === 'login') loginGoogle(res.credential);
		if (actionType === 'bind') bindGoogle(res.credential);
		if (actionType === 'signup') {
			loginGoogle(res.credential).then(({ value }) => {
				if (value.success) {
					onChangeModal('signupFast');
				}
			});
		}
	};

	const initializeGsi = () => {
		window.google.accounts.id.initialize({
			client_id: GOOGLE_CLIENT_ID,
			callback: handleGoogleSignIn,
		});

		window.google.accounts.id.renderButton(buttonRef.current, {
			type,
			text,
			theme: 'outline',
			size,
			width: Number(width),
			shape,
			logo_alignment: 'center',
		});
	};

	useEffect(() => {
		if (isGoogleLibraryLoad) {
			initializeGsi();
		}
	}, [isGoogleLibraryLoad]);

	return <div ref={buttonRef} />;
};

export default ButtonGoogleSignIn;
