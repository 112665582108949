import React from 'react';
import classnames from 'classnames';

import { useMedia } from 'util/hook/useMedia';

import Check from 'images/icon/check.inline.svg';

import styles from './index.css';

export const StepBarStatus = ({ step = 1, className, steps = [], textLayout = 'column' }) => {
	return (
		<div
			className={classnames(styles.wrapper, className, {
				[styles.row]: textLayout === 'row',
			})}
		>
			{steps.map(({ label, status }, i) => (
				<div key={label} className={styles.stepContainer}>
					{step === i + 1 ? (
						<div className={classnames(styles.unactive, styles.active)}>{i + 1}</div>
					) : (
						<>
							{status ? (
								<div className={styles.passed}>
									<Check />
								</div>
							) : (
								<div className={classnames(styles.unactive)}>{i + 1}</div>
							)}
						</>
					)}

					<p>{label}</p>
				</div>
			))}
			<div className={styles.hl} />
		</div>
	);
};

const StepBar = ({ step = 1, className, steps = [], disabled = false }) => {
	const media = useMedia();

	return (
		<div
			className={classnames(styles.wrapper, className)}
			/* eslint-disable no-mixed-operators */
			style={media !== 'phone' ? { width: 72 * steps.length - 24 } : null}
		>
			{steps.map((s, i) => (
				<div key={s || i} className={styles.stepContainer}>
					{step > i + 1 ? (
						<div className={styles.passed}>
							<Check />
						</div>
					) : (
						<div
							className={classnames(styles.unactive, {
								[styles.active]: step === i + 1,
								[styles.disabled]: disabled,
							})}
						>
							{i + 1}
						</div>
					)}
					{(media !== 'phone' || step === i + 1) && <p>{s}</p>}
				</div>
			))}
			<div className={styles.hl} />
		</div>
	);
};

export const StepBarDescription = ({
	step = 1,
	className,
	steps = [],
	disabled = false,
	startServiceAt,
	finishServiceAt,
	leaveServiceAt,
}) => {
	const media = useMedia();

	return (
		<div
			className={classnames(styles.wrapper, className)}
			/* eslint-disable no-mixed-operators */
			style={
				media === 'phone' ? { width: `100%`, marginRight: 0 } : { width: 160 * steps.length - 24 }
			}
		>
			{steps.map((s, i) => (
				<div key={s.title || i} className={classnames(styles.stepContainer, styles.alignStart)}>
					{step > i + 1 ? (
						<div className={styles.passed}>
							<Check />
						</div>
					) : (
						<div
							className={classnames(styles.unactive, {
								[styles.disabled]: disabled,
							})}
						>
							{i + 1}
						</div>
					)}
					<p className={styles.title}>{s.title}</p>
					<p className={styles.description}>{s.description}</p>
					{i === 0 && <p className={styles.description}>{startServiceAt.replace(/-/g, '/')}</p>}
					{i === 1 && <p className={styles.description}>{finishServiceAt.replace(/-/g, '/')}</p>}
					{i === 2 && <p className={styles.description}>{leaveServiceAt.replace(/-/g, '/')}</p>}
				</div>
			))}
			<div className={classnames(styles.hl, styles.wide, styles.stepDescriptionHl)} />
		</div>
	);
};

export default StepBar;
