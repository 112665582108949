import React from 'react';
import classnames from 'classnames';

import styles from './index.css';

const List = ({ className, children }) => (
	<div className={classnames(styles.listItem, className)}>
		<span>•</span>
		<div>{children}</div>
	</div>
);

const SubList = ({ className, children }) => (
	<div className={classnames(styles.subListItem, className)}>
		<span>◦</span>
		<div>{children}</div>
	</div>
);

const VMODHint = ({ className }) => (
	<div className={classnames(styles.vMODHint, className)}>
		<List>VMOD 是台灣 Vespa 車主俱樂部的簡稱。</List>
		<List>
			VMOD 會員資料庫與 Vespa 台灣官方商城串接因作業時間之故，若您尚未收到您的 VMOD
			專屬會員卡，則註冊時須請您輸入完整資訊。
		</List>
		<List>
			若欲查詢您的 VMOD 會籍，請洽 VMOD 總部
			<div className={styles.subList}>
				<SubList>
					官網：
					<a href="http://www.vmod.com.tw/" target="_blank" rel="noreferrer">
						http://www.vmod.com.tw/
					</a>
				</SubList>
				<SubList>電話：02-2517-9818 </SubList>
				<SubList>傳真：02-2516-8155</SubList>
				<SubList>電子信箱：service@vmod.com.tw</SubList>
			</div>
		</List>
	</div>
);

export default VMODHint;
