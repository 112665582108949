import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { useRouting } from 'models/routing';
import { updateAccessToken, setLogin } from 'models/auth';
import { getUserProcess } from 'models/user';

import { useScrollDirection } from 'util/hook/event';

import CookieConsent from 'components/atoms/CookieConsent';
import AppBanner from 'components/molecules/AppBanner';
import Header, { DisabledHeader } from 'components/organisms/Header';
import Footer, { DisabledFooter } from 'components/organisms/Footer';
import ModalAuth from 'components/organisms/ModalAuth';
import ModalDialog from 'components/organisms/ModalDialog';
import ModalToast from 'components/organisms/ModalToast';
import PreviewCover from 'components/atoms/PreviewCover';

import storage from 'util/storage';

import styles from './index.css';

const App = ({ children }) => {
	// Internet Explorer 6-11
	const dispatch = useDispatch();
	const scrollDirection = useScrollDirection();
	const [showAppBanner, setShowAppBanner] = useState(true);
	const [layout, setLayout] = useState('page');
	const [
		{
			pathname,
			queries: { src, preview_key: previewKey, fullscreen },
		},
	] = useRouting();

	const detectPageType = path => {
		let type;
		switch (true) {
			case /^\/rental\/[0-9]*\/pickup/.test(path):
			case /^\/rental\/[0-9]*\/returning/.test(path):
			case /^\/webview\//.test(path):
			case /^\/notSupport/.test(path):
				type = 'fullscreen';
				break;

			default:
				type = 'page';
		}

		return type;
	};

	useEffect(() => {
		const userFetching = async () => {
			await dispatch(getUserProcess());
		};
		setLayout(detectPageType(pathname));
		// 因為弱掃，所以原本從 url 取得手機使用者的 token 改成從 localStorage 取
		// 怕有 webveiw 儲存與取得的時間上的落差，所以不在 loadConfigFromLocalStorage 處理
		if (src === 'app') {
			const currentToken = storage.getItem('token');
			const parseToken =
				currentToken === null
					? { access_token: '', token_type: 'Bearer' }
					: JSON.parse(currentToken);
			if (parseToken.access_token) {
				dispatch(updateAccessToken(parseToken));
				dispatch(setLogin());
				userFetching();
			}
		}
	}, [pathname]);

	if (previewKey) {
		const isFullscreen = fullscreen === 'true';
		return (
			<div className={styles.app}>
				<PreviewCover
					className={classnames(!isFullscreen && styles.fullscreenCover, {
						[styles.penetrate]: pathname.includes('cartour-privacy'),
					})}
				/>
				{isFullscreen ? <Header /> : <DisabledHeader />}
				<div className={classnames(styles.content, styles.withPadding)}>{children}</div>
				<ModalAuth />
				<ModalDialog />
				{isFullscreen ? <Footer /> : <DisabledFooter />}
			</div>
		);
	}

	return (
		<div className={styles.app}>
			{layout === 'fullscreen' && (
				<>
					<div className={styles.content}>{children}</div>
					<ModalDialog />
					<ModalToast />
				</>
			)}

			{layout === 'page' && (
				<>
					{src !== 'app' && (
						<AppBanner
							onClose={() => setShowAppBanner(false)}
							open={showAppBanner && scrollDirection === 'up'}
						/>
					)}
					{src !== 'app' && <Header />}
					<div className={classnames(styles.content, src !== 'app' && styles.withPadding)}>
						{children}
					</div>
					{src !== 'app' && <Footer />}
					<ModalAuth />
					<ModalDialog />
					<ModalToast />
					<CookieConsent />
				</>
			)}
		</div>
	);
};

export default App;
