import React from 'react';
import qs from 'qs';
import storage from 'util/storage';
import { openModal } from 'models/modal';
import { getGuideUrlByRegisterEvent } from 'models/auth';

export default {
	path: '/signup',
	components: () => [import(/* webpackChunkName: 'signup' */ './component')],
	render: ([Signup]) => <Signup />,
	onEnter: async ({ store: { dispatch, getState } }) => {
		const {
			auth: {
				token: { access_token: accessToken },
				isLogin,
			},
			routing: { search },
		} = getState();

		const { reg_event: regEvent } = qs.parse(search, { ignoreQueryPrefix: true });

		if (!isLogin && !accessToken) {
			await dispatch(openModal({ category: 'auth', type: 'signupLanding' }));
		} else {
			if (regEvent) {
				storage.setItem('regEvent', JSON.stringify(regEvent));
				getGuideUrlByRegisterEvent();
			}
			await dispatch(getGuideUrlByRegisterEvent());
			const {
				auth: { guideUrl },
			} = getState();
			storage.removeItem('regEvent');
			window.location.href = `${guideUrl}`;
		}
	},
};
