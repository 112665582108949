import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import qs from 'qs';

import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

const { IMAGE_ENDPOINT } = process.env;

export const getBanner = createAction('FETCH_BANNER', () => async (_, getState) => {
	const {
		routing: { search },
	} = getState();
	const { preview_key: previewKey } = qs.parse(search, { ignoreQueryPrefix: true });

	const headers = previewKey ? { 'X-VESPA-PREVIEW-KEY': previewKey } : {};
	const { status, message, data } = await wrapFetch('banners', {
		method: 'GET',
		headers,
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data.data.map(({ pc_img: desktop, mobile_img: mobile, url }) => ({
		desktop: `${IMAGE_ENDPOINT}/${desktop}`,
		mobile: `${IMAGE_ENDPOINT}/${mobile}`,
		url,
	}));
});

const reducer = {
	banner: handleActions(
		{
			FETCH_BANNER_FULFILLED: (state, action) => ({
				...state,
				list: action.payload,
			}),
		},
		{
			list: [],
		},
	),
};

const selectBanner = createSelector(
	state => state.banner.list,
	list => ({ list }),
);

export const useBanner = () => useRedux(selectBanner);

export default { reducer };
