import React from 'react';
import classnames from 'classnames';

import PreviewIcon from 'images/icon/preview.inline.svg';

import styles from './index.css';

const PreviewCover = ({ className }) => (
	<div className={classnames(styles.previewCover, className)}>
		<div className={styles.tag}>
			<PreviewIcon />
			<span>PREVIEW</span>
		</div>
	</div>
);

export default PreviewCover;
