import { createAction, handleActions } from 'redux-actions';
import dayjs from 'dayjs';
import qs from 'qs';
import DOMPurify from 'dompurify';

import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { isExist, decodeHtmlEntities } from 'util/helper';

export const fetchNews = createAction('FETCH_NEWS', () => async () => {
	const { data } = await wrapFetch('news');

	return data.data.map(d => ({
		...d,
		contents: DOMPurify.sanitize(decodeHtmlEntities(d.contents)),
		published_at: isExist(d.published_at)
			? dayjs(d.published_at).format('YYYY 年 MM 月 DD 日')
			: '',
	}));
});

export const fetchNewsInner = createAction('FETCH_NEWS_INNER', () => async (_, getState) => {
	const {
		routing: { pathname, search },
	} = getState();
	const { preview_key: previewKey } = qs.parse(search, { ignoreQueryPrefix: true });
	const newsId = pathname.split('/')[2];

	const headers = previewKey ? { 'X-VESPA-PREVIEW-KEY': previewKey } : {};
	const { data } = await wrapFetch(`news/${newsId}`, {
		method: 'GET',
		headers,
	});

	return {
		...data.data,
		contents: DOMPurify.sanitize(decodeHtmlEntities(data.data.contents)),
		published_at: isExist(data.data.published_at)
			? dayjs(data.data.published_at).format('YYYY 年 MM 月 DD 日')
			: '',
	};
});

const defaultTargetNewsData = {
	id: null,
	photos: [],
	title: '',
	contents: '',
};

const reducer = {
	news: handleActions(
		{
			FETCH_NEWS_FULFILLED: (state, action) => ({
				...state,

				news: action.payload,
			}),

			FETCH_NEWS_INNER_FULFILLED: (state, action) => ({
				...state,

				targetNews: action.payload,
			}),
		},
		{
			news: [],
			targetNews: defaultTargetNewsData,
		},
	),
};

const selectNews = state => state.news;

export const useNews = () =>
	useRedux(selectNews, {
		fetchNews,
		fetchNewsInner,
	});

export default { reducer };
