import React from 'react';

import { verifyEmail } from 'models/auth';
import { openModal } from 'models/modal';
import { verifyEventCoupon, bindCoolbeLineBot } from 'models/coupon';

export default {
	path: '/verify',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '/email',
			components: () => [import(/* webpackChunkName: 'verify' */ './component')],
			render: ([Verify]) => <Verify />,
			onEnter: async ({ store }) => {
				await store.dispatch(verifyEmail());
			},
		},
		{
			path: '/:event',
			components: () => [import('./component')],
			render: ([Event]) => <Event />,
			onEnter: async ({ store: { dispatch, getState } }) => {
				const {
					auth: {
						token: { access_token: accessToken },
					},
					routing: { pathname },
				} = getState();

				if (!accessToken) {
					await dispatch(openModal({ category: 'auth', type: 'signupLanding' }));
				} else if (pathname.includes('line_bot_bind')) {
					await dispatch(bindCoolbeLineBot());
				} else {
					await dispatch(verifyEventCoupon());
				}
			},
		},
	],
};
